const Rename = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12236_131411)">
    <path d="M4.8105 12.0015L12.417 4.39503L11.3565 3.33453L3.75 10.941V12.0015H4.8105ZM5.43225 13.5015H2.25V10.3193L10.8263 1.74303C10.9669 1.60242 11.1576 1.52344 11.3565 1.52344C11.5554 1.52344 11.7461 1.60242 11.8868 1.74303L14.0085 3.86478C14.1491 4.00542 14.2281 4.19615 14.2281 4.39503C14.2281 4.5939 14.1491 4.78463 14.0085 4.92528L5.43225 13.5015ZM2.25 15.0015H15.75V16.5015H2.25V15.0015Z" fill="#3B3F43"/>
    </g>
    <defs>
    <clipPath id="clip0_12236_131411">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>  
  );
};

export default Rename;
