import {
  Avatar,
  Button,
  ConversationHeader,
} from '@chatscope/chat-ui-kit-react';
import React, { useContext } from 'react';

import { AuthContext } from '../navigation/AuthProvider';
import { LogoutOutlined } from '@ant-design/icons';

export const ConversationsHeader = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <ConversationHeader className="chat-conversation-header">
      <Avatar
        src={user.displayPictureUrl}
        name={user.displayName}
        status={user.presence.status.toLowerCase()}
      />
      <ConversationHeader.Content
        userName={user.name}
        info={user.presence.status}
      />
      <ConversationHeader.Actions>
        <Button
          type="button"
          icon={<LogoutOutlined />}
          onClick={() => logout()}
          className="chat-conversation-header__logout-btn"
          title="Logout"
        />
      </ConversationHeader.Actions>
    </ConversationHeader>
  );
};

export default ConversationsHeader;
