import React, { useEffect, useState, useRef } from "react";
import classNames from "./ClientChatPopup.module.scss";
// import ChatKitty from "@chatkitty/core";
import ChatKitty from 'chatkitty';
import { Input, Avatar, Spin, notification, Skeleton } from "antd";
import { InboxOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import { useAccount } from "../../../store/StoreProvider";
import { useMutation } from "react-query";
import api from "../../API";
import { CTAButton, Message } from "../../common";
import Bugsnag from "@bugsnag/js";

function ClientBookkeeperQuery({transactionId, accountData, isClient, receiver}) {
  const secureLocalStore = window.localStorage || window.sessionStorage;
  let { selectedBusiness } = useAccount();
  const [channel, setChannel] = useState(null);
  // const [username] = useState(secureLocalStore.getItem('email'));
  const [
    // sessionUser
    , setSessionUser
  ] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [, setContacts] = useState([]);
  const [typing, setTyping] = useState(null);
  const [, setStyle] = useState('none');
  const [loading, setLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState(true);
  const [, setIsLoadingAIResponse] = useState(false);
  const [generatedQuestion, setGeneratedQuestion] = useState(null);
  
  const { TextArea } = Input;
  const messagesEndRef = useRef(null);

  // Assigning a default value to selectedBusiness if it's an empty object
  selectedBusiness = Object.keys(selectedBusiness).length === 0 ? accountData : selectedBusiness;
  const [username, ] = useState(
    receiver === "taxsupport@otterz.co" ? secureLocalStore.getItem('email') : "taxsupport@otterz.co"
  );

  const chatkitty = ChatKitty.getInstance(process.env.REACT_APP_CHATKITTY_API_KEY);

  const initSession = async () => {
    const result = await chatkitty.startSession({ username });
    if (result.succeeded) {
      const session = result.session;
      setSessionUser(session);
      chatkitty.getUsers().then((result) => {
        setContacts(result.paginator.items);
      });
      initChannel(session);
    } else {
      console.log("Failed to start session:", result.error);
    }
  };

  const initChannel = async (user) => {
    const result = await chatkitty.createChannel({
      type: 'DIRECT',
      members: [{ username: receiver }],
    });

    if (result.succeeded) {
      setChannel(result.channel);
      let chat = chatkitty.startChatSession({
        channel: result.channel,
        onReceivedMessage: (message) => {
          if (message.user.id === user.user.id) return;
          setMessages((draft) => [
            ...draft, 
            {
              sender: message.user.name,
              channel_id: result.channel.id,
              message: message.body,
              created_at: message.createdTime,
              business_id: selectedBusiness.business_id,
            }
          ]);
          scrollToBottom();
          notifyUser(message);
        },
        onTypingStarted: (typingUser) => {
          if (typingUser.id !== user.user.id) {
            setTyping(typingUser);
          }
        },
        onTypingStopped: (typingUser) => {
          if (typingUser.id !== user.user.id) {
            setTyping(null);
          }
        },
      });

      if (chat.failed) {
        console.error("Failed to start chat session:", chat.error);
        setLoading(false);
      }
      setLoading(false);
      getMessagesForTransaction.mutate({
        business_id: selectedBusiness.business_id,
        channel_id: result.channel.id,
        transaction_id: transactionId?.key,
      });

    } else {
      console.error("Failed to create channel:", result.error);
    }
  };

  const notifyUser = (message) => {
    setStyle('initial');
    notification.open({
      message: getCurrentDate(),
      description: `New message from Accountant, ${message.body}`,
      duration: 2,
      onClick: () => {},
    });
  };

  useEffect(() => {
    chatkitty.endSession();
    initSession();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const getMessagesForTransaction = useMutation(data => api.Services.Dashboard.ClientQueries.getMessagesForTransaction(data), {
    onSuccess: async (data) => {
      if (!data.status) {
        return;
      }
      if (data && data.data) {
        setMessages(data.data);
  
        if (data.data.length === 0 && !isClient) {
          const questionResponse = await api.Services.Dashboard.ClientQueries.getQuestionForTransaction({ 
            business_id: selectedBusiness.business_id, 
            transactionId: transactionId?.key,
          });
  
          const lastQuestion = questionResponse.data?.question;
          setGeneratedQuestion(lastQuestion);
  
          // Add the generated question to messages with the buttons
          const userMessage = {
            sender: username,
            message: `${lastQuestion.trim()} <br/> <br/> Should I send this question to user?`,
            created_at: new Date().toISOString(),
            business_id: selectedBusiness.business_id,
            isGenerated: true, // Mark as generated question
          };
          setMessages([userMessage]);
        }
        
        setMessageLoading(false);
        scrollToBottom();
      }
    },
    onError: (error) => {
      console.error("getMessagesBusiness error: ", error);
      setMessageLoading(false);
    },
  });  

  const sendMessageBookkeeping = useMutation(
    (data) => api.Services.Dashboard.ChatKitty.sendMessageBookkeeping(data),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: "Couldn't get response from Accountant, Please try again" });
          setIsLoadingAIResponse(false);
          return;
        }
        if (data && data.data) {
          getMessagesForTransaction.mutate({
            business_id: selectedBusiness.business_id,
            channel_id: channel.id,
            transaction_id: transactionId?.key,
          });
          setMessageLoading(false);
          setIsLoadingAIResponse(false);
        }
      },
      onError: (error) => {
        console.error("AI response error: ", error);
        Bugsnag.notify("error: ", error, "error sending message to AI");
        Message({ type: "error", content: "Couldn't get response from Accountant, Please try again" });
        setMessageLoading(false);
        setIsLoadingAIResponse(false);
      },
    }
  );

  useEffect(() => {
    return () => {
      if (channel) {
        // channel.end();
      }
      chatkitty.endSession();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const sendMessage = async () => {
    if (!messageInput.trim()) return;

    const userMessage = {
      sender: username,
      message: messageInput.trim(),
      created_at: new Date().toISOString(),
      business_id: selectedBusiness.business_id,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    // setIsLoadingAIResponse(true);
    setMessageInput("");

    sendMessageBookkeeping.mutate({
      business_id: selectedBusiness.business_id,
      messageData: {
        channel_id: channel.id,
        message: messageInput.trim(),
        sender: username,
        transaction_id: transactionId?.key
      },
    });

    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [isLoadingNewQuestion, setIsLoadingNewQuestion] = useState(false);  

  const renderStyledText = (text, index) => {
    return (
      <div key={index}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        {messages[index]?.isGenerated && (
          <div style={{display: 'flex', gap: '20px', margin: '1rem 0'}}>
            <CTAButton onClick={handleAcceptQuestion} loading={isLoadingAccept}>Accept</CTAButton>
            <CTAButton onClick={handleNewQuestion} loading={isLoadingNewQuestion}>New Question</CTAButton>
          </div>
        )}
      </div>
    );
  };
  
  const handleAcceptQuestion = () => {
    setIsLoadingAccept(true);
    sendMessageBookkeeping.mutate({
      business_id: selectedBusiness.business_id,
      messageData: {
        channel_id: channel.id,
        message: generatedQuestion,
        sender: username,
        transaction_id: transactionId?.key,
      },
    }, {
      onSuccess: () => {
        setGeneratedQuestion(null);
      },
      onSettled: () => {
        setIsLoadingAccept(false);
      },
    });
  };
  
  const handleNewQuestion = async () => {
    setIsLoadingNewQuestion(true);
  
    try {
      // Call the API to generate a new question
      const questionResponse = await api.Services.Dashboard.ClientQueries.getQuestionForTransaction({
        business_id: selectedBusiness.business_id,
        transactionId: transactionId?.key,
      });
  
      const newQuestion = questionResponse.data?.question;
  
      // Update the message in place with the new question
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.length - 1;
  
        // Update the last message with the new question and reset buttons
        if (updatedMessages[lastMessageIndex]?.isGenerated) {
          updatedMessages[lastMessageIndex].message = `${newQuestion.trim()} <br/> <br/> Should I send this question to user?`;
        } else {
          // If the last message is not the generated one, add the new question
          updatedMessages.push({
            sender: username,
            message: newQuestion.trim(),
            created_at: new Date().toISOString(),
            business_id: selectedBusiness.business_id,
            isGenerated: true,
          });
        }
  
        return updatedMessages;
      });
  
      setGeneratedQuestion(newQuestion);
    } catch (error) {
      console.error('Error generating new question:', error);
      Message({ type: 'error', content: 'Failed to generate a new question. Please try again.' });
    }
  
    setIsLoadingNewQuestion(false);
  };  

  function getCurrentDate() {
    const currentDate = new Date();
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return currentDate.toLocaleDateString('en-US', options);
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className={classNames.wrapper}>
      {(loading || messageLoading) ? (
        <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} />
      ) : (
        <>
          <div ref={messagesEndRef} className={classNames.messagesContainer}>
            {messages.length === 0 ? (
              <div style={{ 
                textAlign: 'center',
                padding: '20px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                opacity: '0.5',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center' 
              }}>
                <span>Your messages will appear here</span>
                <InboxOutlined style={{ marginLeft: '8px', fontSize: '24px', color: '#82898F' }} />
              </div>
            ) : (
              messages.map((message, index) => (
                message.message !== "" && <div key={index} className={message.sender === username ? classNames.message : classNames.receiver}>
                  <div className={message.sender === username ? classNames.username : classNames.aiUsername}>
                    <div className={classNames.messageAuthor}>{message.sender}</div>
                  </div>
                  <div className={message.sender === username ? classNames.messageContentSender : classNames.messageContent}>
                    <div className={classNames.messageAvatar}>
                      <Avatar icon={<UserOutlined />} />
                    </div>
                    <div className={classNames.messageBody}>
                      {message.isLoading ? (
                        <><Skeleton active /></>
                      ) : (
                        renderStyledText(message.message, index)
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          
          {typing && (
            <div className={classNames.typing}>
              {typing.displayName} is typing...
            </div>
          )}

          <div className={classNames.inputContainer}>
            <TextArea
              placeholder="Ask a query"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              autoSize={{ minRows: 3 }}
              onKeyDown={handleKeyPress}
            />
            <SendOutlined className={classNames.submitBtn} onClick={sendMessage}  />
          </div>
        </>
      )}
    </div>
  );
}

export default ClientBookkeeperQuery;
