import React, { useEffect, useState } from "react";
import classNames from "./BusinessOnboardingSteps.module.scss";
import api from "../../../API";
import { Modal, Spin } from "antd";
import { useBusiness } from "../../../../store/StoreProvider";
import MethodOfBookeeping from "./MethodOfBookeeping";
import { useMutation } from "react-query";
import { Message } from "../../../common";

const secureLocalStore = window.localStorage || window.sessionStorage;
const BusinessOnboardingSteps = ({ setBusinessOnboarded }) => {
  const { selectedBusiness, setIsInitialBusinessCenterView } = useBusiness();
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Mutation to fetch tax onboarding status
  const getTaxOnboardingStatus = useMutation(
    (data) => api.Services.Dashboard.TaxFilling.getTaxOnboardingStatus(data),
    {
      onSuccess: (data) => {
        setIsModalVisible(true);
        if (!data.status) {
          setIsModalVisible(true);
        }
      },
      onError: (error) => {
        setIsModalVisible(true);
        console.error("Error fetching tax onboarding status: ", error);
      },
    }
  );

  const sendMessageAccountant = useMutation(
    (data) => api.Services.Dashboard.ChatKitty.sendMessageAccountant(data),
    {}
  );

  const pushBusinessQuestion = useMutation(
    (data) => api.Services.Dashboard.TaxFilling.pushBusinessQuestion(data),
    {}
  );

  const updateTaxOnboardingStatus = useMutation(
    (data) => api.Services.Dashboard.TaxFilling.updateTaxOnboardingStatus(data),
    {
      onSuccess: (data) => {
        // Handle errors from the API call
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }

        // Handle successful registration
        if (data && data.data) {
          console.log("Registration successful", data);
          setBusinessOnboarded("BUSINESS_ONBOARDING_COMPLETE");
          Message({
            type: "success",
            content: "Bookkeeping Onboarding Complete",
          });
        }
      },
      onError: (error) => {
        // Handle any errors from the API call
        console.error("Signup error: ", error);
        Message({ type: "error", content: error.message });
      },
    }
  );

  useEffect(() => {
    // Fetch tax onboarding status when selectedBusiness changes
    if (selectedBusiness) {
      getTaxOnboardingStatus.mutate({
        business_id: selectedBusiness.business_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const handleModalClose = () => {
    setIsModalVisible(false);
    const businessQuestions = [
      {
        key: "bookkeeping_method",
        question: "Bookkeeping Method",
        value: "Not Sure",
        meta_data: {},
      },
    ];

    pushBusinessQuestion.mutate(
      {
        business_id: selectedBusiness.business_id,
        body: businessQuestions,
      },
      {
        onSuccess: (data) => {
          setIsInitialBusinessCenterView(false);
          if (data.status) {
            updateTaxOnboardingStatus.mutate(
              {
                business_id: selectedBusiness.business_id,
                body: {
                  business_onboarding_status: "BUSINESS_ONBOARDING_COMPLETE",
                  meta_data: { any_other_data_required_for_frontend: null },
                },
              },
              {
                onSuccess: () => {
                  getTaxOnboardingStatus.mutate({
                    business_id: selectedBusiness.business_id,
                  });
                  sendMessageAccountant.mutate({
                    messageData: {
                      sender: secureLocalStore.getItem("email"),
                      message:
                        "I am not sure about my bookkeeping method. Please review my books and indicate whether the appropriate bookkeeping method on my business profile.",
                    },
                    business_id: selectedBusiness?.business_id,
                  });
                  setIsInitialBusinessCenterView(false);
                },
              }
            );
          }
        },
      }
    );
  };

  const renderStepContent = () => {
    return <MethodOfBookeeping setBusinessOnboarded={setBusinessOnboarded} />;
  };

  return (
    <div className={classNames.container}>
      {/* <section className={classNames.greetingsSection}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ color: "#82898F", margin: 0 }}>
            Hello, {userData?.first_name}
          </h1>
        </div>
      </section> */}

      <section className={classNames.innerContent}>
        {getTaxOnboardingStatus.isLoading ||
        updateTaxOnboardingStatus.isLoading ||
        pushBusinessQuestion.isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Modal
            title={
              <span style={{ fontWeight: "bold" }}>
                {"Business Center Setup"}
              </span>
            }
            visible={isModalVisible}
            footer={null}
            onCancel={handleModalClose}
            centered
            width={500}
            style={{ top: 40, padding: "2rem" }}
          >
            {renderStepContent()}
          </Modal>
        )}
      </section>
    </div>
  );
};

export default BusinessOnboardingSteps;
