import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import classNames from './BusinessLinkQuickbooks.module.scss';
import {CTAButton, Message} from '../../../../common';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from '../../../../API';
import { CloseCircleOutlined } from '@ant-design/icons';
import endpoints from '../../../../API/endpoints';
// import { ROUTES } from '../../../../constants';

const { Text } = Typography;

const BusinessLinkQuickbooks = () => {
  const navigate = useNavigate();
  let env = process.env.REACT_APP_ENV?.trim();

  const location = useLocation();
  const additionalData = location.state;

  const [accounts, setAccounts] = useState([]);
  const [, setServices] = useState({});
  const [connected, setConnected] = useState(false); // State to track connection status
  const secureLocalStore = window.localStorage || window.sessionStorage;

  // Mutation definition for API calls

  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: (data) => {
      if (data.status && data.data) {
        const businessNames = data.data.map((business) => {
          return {
            business_id: business.business_id,
            name: business.name
          }
        });
        setAccounts(businessNames);
      } else {
        Message({ type: "error", content: data.errors[0].message });
      }
    },
    onError: (error) => {
      console.error("Error fetching businesses: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // View user's owner status
  const viewUser = useMutation(data => api.Services.Onboarding.viewUserByID(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("User viewed", data.data[0].services.accept_payments);
        setServices(data.data[0].services);
        // Check if QuickBooks is already connected
        // if (data.data[0].services.accept_payments === 'Y') {
        //   setConnected(true); // Set connected state to true
        // }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // View user's owner status
  const getQbStatus = useMutation(data => api.Services.Onboarding.getQuickbooksStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("QbStatus", data.data);
        // Check if QuickBooks is already connected
        if (data.data.length !== 0 && data.data.access_token) {
          setConnected(true); // Set connected state to true
        }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  const handleConnect = async (businessName) => {
    try {
      // const response = await getQuickbooksToken.mutateAsync(secureLocalStore.getItem('otterz_id'));
      // // Assuming response contains a property 'success' indicating successful OAuth completion
      // if (response.success) {
      //   // Update the button style for the connected business
      //   // For example, you could add a class to change the button color to red
      //   // Implement as needed based on your UI requirements
      // }
      console.log("Ids: ", accounts[0].business_id, additionalData.business_id);
      window.open(`${endpoints.SERVICES.ONBOARDING.QUICKBOOKS_OAUTH[env]}${additionalData.business_id}`, '_blank');
    } catch (error) {
      console.error("Error connecting to QuickBooks: ", error);
      Message({ type: "error", content: error.message });
    }
  };

  const handleContinue = () => {
    navigate('/app')
  };

  // Fetch businesses on component mount
  React.useEffect(() => {
    viewUser.mutate({ otterz_id: secureLocalStore.getItem('otterz_id') });
    viewAllBusiness.mutate(secureLocalStore.getItem('otterz_id'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Polling QuickBooks status
  React.useEffect(() => {
    // Polling interval in milliseconds (e.g., 5 seconds)
    const pollingInterval = 2000;

    // Function to fetch QuickBooks status and update state
    const fetchQbStatus = async () => {
      try {
        if (accounts.length === 0) return;
        const response = await getQbStatus.mutateAsync(additionalData.business_id);
        // Handle response to update state accordingly
        if (response && response.data) {
          // Update state with the new QuickBooks status
          console.log("QbLinkStatus", response.data);
          // Check if QuickBooks is already connected
          if (response.data.length !== 0 && response.data.access_token) {
            setConnected(true); // Set connected state to true
            clearInterval(pollingId); // Stop polling
            // handleContinue(); // Continue to the next step
          }
        }
      } catch (error) {
        console.error("Error fetching QuickBooks status: ", error);
        // Handle error accordingly
      }
    };

    // Start polling on component mount
    const pollingId = setInterval(fetchQbStatus, pollingInterval);

    // Cleanup function to stop polling on component unmount
    return () => clearInterval(pollingId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className={classNames.setupOtterzComponentContainer}>
      <div className={classNames.setupOtterzComponent}>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <h1 style={{margin: 0}}>Setup Otterz Co-pilot</h1>
          <CloseCircleOutlined onClick={() => {navigate('/app')}} />
        </div>

        <h4 className={classNames.subText}>
          Connect your businesses to QuickBooks
        </h4>

        {/* Rendering flexbox column with rows */}
        {accounts.map((acc, index) => (acc.business_id === additionalData.business_id) && (
          <div key={index} className={classNames.flexRow}>
            <Text>{acc.name}</Text>
            <Button size='large' type="primary" disabled={connected} className={classNames.connectButton} onClick={() => handleConnect(acc.name)}>
              {connected ? "Connected" : "Connect"} {/* Change button text based on connection status */}
            </Button>
          </div>
        ))}

        <CTAButton
          htmlType="submit"
          type="primary"
          disabled={!connected} /* Disable button if not connected */
          className={classNames.signupFormInputs}
          onClick={handleContinue}
        >
          Continue
        </CTAButton>
      </div>
    </div>
  );
};

export default BusinessLinkQuickbooks;
