import endpoints from "../../../endpoints";

export default async function exchangeLinkToken(data) {
    let env = process.env.REACT_APP_ENV?.trim();

    const baseURL = endpoints.SERVICES.ONBOARDING.PLAID[env];

    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${baseURL}/token/public/${data.business_id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                public_token: data.public_token
            })
        })
    ).json();
}
