import React from 'react';
import { Col, Row, Typography /*, theme */ } from 'antd';
// import { useNavigate } from 'react-router-dom';
import classNames from './PaymentAcceptanceComponent.module.scss';
import { useAccount } from '../../../store/StoreProvider';
// import 'antd/dist/antd.css'; // Import Ant Design styles

const { Title, Paragraph } = Typography;

const PaymentAcceptanceComponent = () => {
  const { userData } = useAccount();

  const wrapperStyle = {
    width: '500px',
    // border: '1px solid rgb(255 255 255)',
    // boxShadow: '0px 0px 8px 0px #c2c2c2',
    // borderRadius: '3px',
    overflow: 'hidden'
  };

  return (
    <div className={classNames.container}>
    <section className={classNames.greetingsSection}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h1 style={{ color: '#82898F', margin: 0 }}>Hello, {userData?.first_name}</h1>
    </div>
    </section>
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col>
        <div style={{ textAlign: 'center' }}>
          <Title level={2}>Setup Payment Acceptance</Title>
          <Paragraph>
            Book a call with our account manager to enable payment acceptance
          </Paragraph>
          <div style={wrapperStyle}>
            <iframe
              title='Schedule a meeting with Otterz'
              src="https://meetings.hubspot.com/tapan-r?embed=true"
              width="100%"
              height="700px"
              frameBorder="0"
              style={{ border: 'none' }}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Col>
    </Row>
    </div>
  );
};

export default PaymentAcceptanceComponent;
