const Delete = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12236_131410)">
    <path d="M3 6H15V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5H3.75C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75V6ZM4.5 7.5V15H13.5V7.5H4.5ZM6.75 9H8.25V13.5H6.75V9ZM9.75 9H11.25V13.5H9.75V9ZM5.25 3.75V2.25C5.25 2.05109 5.32902 1.86032 5.46967 1.71967C5.61032 1.57902 5.80109 1.5 6 1.5H12C12.1989 1.5 12.3897 1.57902 12.5303 1.71967C12.671 1.86032 12.75 2.05109 12.75 2.25V3.75H16.5V5.25H1.5V3.75H5.25ZM6.75 3V3.75H11.25V3H6.75Z" fill="#3B3F43"/>
    </g>
    <defs>
    <clipPath id="clip0_12236_131410">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>       
  );
};

export default Delete;
