import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import classNames from './TermsAndConditionModal.module.scss';
import { CTAButton, Message } from '../../../common';
import TermsOfService from './TermsOfService/TermsOfService';
import api from '../../../API';
import { useBusiness } from '../../../../store/StoreProvider';

const TermsAndConditionModal = ({ isVisible, onClose, onSubmit }) => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const { selectedBusiness } = useBusiness();

  const handleAgree = async () => {
    setLoading(true);
    if (name.trim()) {
      const response = await api.Services.Onboarding.businessAgreementCollection({
        business_id: selectedBusiness?.business_id,
        agreement: {
          name,
          agreed: true,
        },
      });

      onSubmit();

      if (response.status) {
        Message({ type: "success", content: "Agreement submitted successfully!" });
        setLoading(false);
        onClose(false);
      } else {
        setLoading(false);
        Message({ type: "error", content: "Error submitting agreement. Please try again." });
        console.error('Error submitting agreement:', response.errors);
      }
    } else {
      setLoading(false);
      Message({ type: "error", content: "Please enter your full name." });
    }
  };

  const renderModalContent = () => {
    return (
      <div className={classNames.termsModalContent}>
        <div className={classNames.termsText}>
          {/* Insert the text from the provided Google Doc */}
          <p style={{height: '50vh', overflowY: 'scroll'}}>
            <TermsOfService />
          </p>
        </div>
        <div className={classNames.termsInputSection}>
          <p style={{margin: '0 0 1rem 0'}}>Please type in your name and agree to the Otterz Terms of Service.</p>
          <Input 
            placeholder="Enter Your Full Name" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classNames.inputField}
          />
          <CTAButton 
            type="primary" 
            onClick={handleAgree} 
            disabled={!name.trim()}
            className={classNames.agreeButton}
            loading={loading}
          >
            Agree
          </CTAButton>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Terms of Service"
      visible={isVisible}
      footer={null}
      closable={true}
      maskClosable={true}
      onCancel={() => {
        onSubmit();
        onClose(false)}
      }
      width={"50%"}
      className={classNames.termsModal}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default TermsAndConditionModal;
