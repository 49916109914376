import endpoints from "../../../endpoints";

export default async function viewAllUsers(){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = endpoints.SERVICES.ONBOARDING.USER[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        })
    ).json();
}
