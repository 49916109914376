import React, { useRef, useState } from 'react';
import { Typography, Row, Col, Select } from 'antd';
import classNames from './LinkBusiness.module.scss';
import { CTAButton, Message } from '../../common';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from '../../API';
import { ROUTES } from '../../constants';

// Import Plaid Link
// import { usePlaidLink } from 'react-plaid-link';
import gsap from 'gsap';
import { linkBusiness } from '../../API/Services/Onboarding';

const { Text } = Typography;
const { Option } = Select;

const LinkBusiness = () => {
  const navigate = useNavigate();
  
  const [businessList, setBusinessList] = useState([]);
  // State to store fetched link token
  // const [linkToken, setLinkToken] = useState(null);
  const [showBusinessSection, setShowBusinessSection] = useState(false); // State to manage visibility of business section
  const [accounts, setAccounts] = useState([
    { account: '**** **** **** 5624', bank: 'Bank of America' },
    { account: '**** **** **** 1234', bank: 'Chase Bank' },
    { account: '**** **** **** 7890', bank: 'Wells Fargo' },
  ]);
  const secureLocalStore = window.localStorage || window.sessionStorage;

  // State to keep track of selected business for each account
  const [selectedBusinesses, setSelectedBusinesses] = useState({});

  // update status mutation
  const updateOnboardingStatus = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return; // Exit early if there's an error
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
        Message({ type: "success", content: "Businesses Linked with Bank account" });

        // Determine the next route based on the preferences
        // Navigate or update state as needed
        navigate(ROUTES.ONBOARDING.LINK_QUICKBOOKS)
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // View user's owner status
  const viewUser = useMutation(data => api.Services.Onboarding.viewUserByID(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses added successful", data);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // View all businesses of user
  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses fetched successfully", data);
        const businessList = data.data.map(b => ({name: b.name, id: b.id}));
        setBusinessList(businessList);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  const fetchAccounts = async () => {
    try {
      const accountsResponse = await api.Services.Onboarding.getAccountsPlaid(secureLocalStore.getItem('otterz_id'));
      console.log("Accounts:", accountsResponse);
  
      if (accountsResponse.status) {
        const accountsData = accountsResponse.data.accounts;
        // Transform accounts data to match the structure expected in the component
        const transformedAccounts = accountsData.flat().map(account => ({
          account_id: account.account_id,
          account: `**** **** **** ${account.mask}`,
          bank: account.official_name || account.name,
        }));

        setAccounts(transformedAccounts);
      } else {
        Message({ type: "error", content: "Failed to fetch accounts" });
      }

      setShowBusinessSection(true);
      gsap.fromTo(businessSectionRef.current, { autoAlpha: 0, y: -20 }, { autoAlpha: 1, y: 0, duration: 0.5 });
    } catch (error) {
      Message({ type: "error", content: "Failed to fetch accounts" });
    }
  };

  React.useEffect(() => {
    viewAllBusiness.mutate(secureLocalStore.getItem('otterz_id'));
    viewUser.mutate({ otterz_id: secureLocalStore.getItem('otterz_id') });

    fetchAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContinue = () => {
    let linkAccounts = []
    console.log("Selected Businesses:", selectedBusinesses, accounts);
    accounts.forEach((account, idx) => {
      const selectedBusiness = selectedBusinesses[idx];
      const id = selectedBusiness.toString();
      console.log("Selected Business:", selectedBusiness, id);
      linkAccounts.push({ account_id: account?.account_id, business_id: id });
    });
    
    console.log("Accounts:", linkAccounts, selectedBusinesses);

    // Call the mutation to link business with bank account
    api.Services.Onboarding.linkBusiness({data: linkAccounts});

    // Call the mutation to update the onboarding status
    updateOnboardingStatus.mutate({otterz_id: secureLocalStore.getItem('otterz_id'), status: "CONNECT_QUICKBOOKS"});
  };

  // Fetch link token from backend
  // const fetchLinkToken = async () => {
  //   try {
  //     const response = await api.Services.Onboarding.createLinkToken(sessionStorage.getItem('otterz_id')); // Fetch link token from backend
  //     setLinkToken(response.data.link_token);
  //   } catch (error) {
  //     Message({ type: "error", content: "Failed to fetch link token" });
  //   }
  // };

  // React.useEffect(() => {
  //   fetchLinkToken(); // Fetch link token when component mounts
  // }, []);

  // // Plaid Link success callback
  // const onSuccess = async (data) => {
  //   try {
  //     console.log("Plaid Link success:", data);
  //     // Exchange public token for access token on your backend
  //     const response = await api.Services.Onboarding.exchangeLinkToken({ public_token: data, otterz_id: sessionStorage.getItem('otterz_id')});
  //     console.log("Access Token:", response, response?.data.access_token);
  //     Message({ type: "success", content: "Plaid connected successfully" });

  //     const accountsResponse = await api.Services.Onboarding.getAccountsPlaid(sessionStorage.getItem('otterz_id'));
  //     console.log("Accounts:", accountsResponse);
  
  //     if (accountsResponse.status) {
  //       const accountsData = accountsResponse.data.accounts;
  //       // Transform accounts data to match the structure expected in the component
  //       const transformedAccounts = accountsData.map(account => ({
  //         account_id: account.account_id,
  //         account: `**** **** **** ${account.mask}`,
  //         bank: account.official_name || account.name,
  //       }));

  //       setAccounts(transformedAccounts);
  //     } else {
  //       Message({ type: "error", content: "Failed to fetch accounts" });
  //     }

  //     setShowBusinessSection(true);
  //     gsap.fromTo(businessSectionRef.current, { autoAlpha: 0, y: -20 }, { autoAlpha: 1, y: 0, duration: 0.5 });
  //   } catch (error) {
  //     Message({ type: "error", content: "Failed to exchange public token" });
  //   }
  // };

  // // Plaid Link configuration
  // const config = {
  //   token: linkToken,
  //   onSuccess,
  //   // Add other configuration options here as needed
  // };

  // // Initialize Plaid Link
  // const { open, ready } = usePlaidLink(config);

  // // Handle "Connect" button click
  // const handleConnectClick = () => {
  //   if (ready && linkToken) {
  //     open(); // Open Plaid Link when ready
  //   } else {
  //     Message({ type: "error", content: "Plaid Link is not ready" });
  //   }
  // };

  // Function to handle dropdown change
  const handleBusinessSelect = (accountIndex, business) => {
    setSelectedBusinesses(prevState => ({
      ...prevState,
      [accountIndex]: business,
    }));
  };

  // // Determine if Continue button should be enabled
  const isContinueEnabled = Object.keys(selectedBusinesses).length === accounts.length && Object.values(selectedBusinesses).every(business => business !== undefined && business !== '');

  // // Change Connect button text to "Connected" when Plaid flow completes
  // const connectButtonText = showBusinessSection ? 'Connected' : 'Connect';

  /* Animation setup reference */
  const businessSectionRef = useRef(null);

  return (
    <div className={classNames.setupOtterzComponentContainer}>
      <div className={classNames.setupOtterzComponent}>
        {/* <svg onClick={() => {navigate(-1)}} style={{position: "relative", left: '-70px', top: "45px"}} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_8949_97551)">
          <path d="M20.4974 3.41406C29.9274 3.41406 37.5807 11.0674 37.5807 20.4974C37.5807 29.9274 29.9274 37.5807 20.4974 37.5807C11.0674 37.5807 3.41406 29.9274 3.41406 20.4974C3.41406 11.0674 11.0674 3.41406 20.4974 3.41406ZM20.4974 34.1641C28.0482 34.1641 34.1641 28.0482 34.1641 20.4974C34.1641 12.9466 28.0482 6.83073 20.4974 6.83073C12.9466 6.83073 6.83073 12.9466 6.83073 20.4974C6.83073 28.0482 12.9466 34.1641 20.4974 34.1641ZM20.4974 18.7891H27.3307V22.2057H20.4974V27.3307L13.6641 20.4974L20.4974 13.6641V18.7891Z" fill="black"/>
          </g>
          <defs>
          <clipPath id="clip0_8949_97551">
          <rect width="41" height="41" fill="white"/>
          </clipPath>
          </defs>
        </svg> */}
        <h1 style={{margin: 0}}>Setup Otterz Co-pilot</h1>
        <h4 className={classNames.subText}>
        Link your bank accounts with your businesses
        </h4>

        {/* <Row align="middle" className={classNames.connectRow}>
          <Col span={12}>Connect your account to Plaid</Col>
          <Col span={8}>
            <CTAButton type="primary" className={classNames.connectedButton} onClick={handleConnectClick} disabled={showBusinessSection}>
              {connectButtonText}
            </CTAButton>
          </Col>
        </Row> */}

        {/* Business section */}
        <div ref={businessSectionRef} style={{ display: showBusinessSection ? 'block' : 'none' }}>
          {/* <Text className={classNames.subText}>
            Which businesses do these accounts link with?
          </Text> */}

          {accounts.map((account, index) => (
            <Row key={index} className={classNames.accountRow}>
              <Col span={12}>
                <Text>{account.account}</Text>
                <div>{account.bank}</div>
              </Col>
              <Col span={12}>
                <Select 
                  placeholder="Select Business"
                  className={classNames.selectBusiness} 
                  onChange={(business) => handleBusinessSelect(index, business)}
                >
                  {businessList.map((business) => (
                    <Option key={business.id} value={business.id}>{business.name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          ))}
        </div>

        <CTAButton
          htmlType="button"
          type="primary"
          loading={linkBusiness.isLoading || updateOnboardingStatus.isLoading }

          // disabled={!showBusinessSection}
          disabled={!isContinueEnabled}
          className={classNames.signupFormInputs}
          onClick={handleContinue}
        >
          Continue
        </CTAButton>
      </div>
    </div>
  );
};

export default LinkBusiness;
