import classNames from "./Message.module.scss";
import { message } from "antd";
import PropTypes from "prop-types";

message.config({
  zIndex: 2000, // Set a high z-index
});

export default function Message({ type, content }) {
  const duration = 5
  switch (type) {
    case "success":
      message.success({
        className: `${classNames.message} ${classNames.success}`,
        content: content,
        duration: duration,
      })
      break;
    case "info":
      message.info({
        className: `${classNames.message} ${classNames.info}`,
        content: content,
        duration: duration,
      })
      break;
    case "warning":
      message.warning({
        className: `${classNames.message} ${classNames.warning}`,
        content: content,
        duration: duration,
      })
      break;
    case "error":
      // message.error(content, duration, {className: `${classNames.message} ${classNames.error}`});
      message.error({
        className: `${classNames.message} ${classNames.error}`,
        content: content,
        duration: duration,
      })
      break;
    default:
      message.info({
        className: `${classNames.message} ${classNames.info}`,
        content: content,
        duration: duration,
      })
  }
}

Message.propTypes = {
  type: PropTypes.oneOf(["success", "info", "warning", "error"]),
  content: PropTypes.string,
};
