import endpoints from "../../../endpoints";

export default async function getSyncedTransactions(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = `${endpoints.SERVICES.ACCOUNTANTS_DASHBOARD.CATEGORIZATION.GET_SYNCED_TRANSACTIONS[env]}${data?.business_id}?page=${1}&per_page=${50}&account=${encodeURIComponent(data?.bank_account)}`;

    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
