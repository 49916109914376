import React, { useState, useEffect } from 'react';
import { Upload, Button, Row, Col, Tooltip, Form } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import classNames from './UploadArticleIncorporation.module.scss'; // Adjust the import path as needed
import { CTAButton, Message } from '../../../../common';
import { useMutation } from 'react-query';
import { openDB } from 'idb'
import api from '../../../../API';
import { useBusiness } from '../../../../../store/StoreProvider';
import { uploadTaxFile } from '../../../../API/Services';
import { sendMessageAccountant } from '../../../../API/Services/Dashboard';

const UploadArticleIncorporation = ({ setStatus, setTaxOnboarded }) => {
  const secureLocalStore = window.localStorage || window.sessionStorage;
  const [form] = Form.useForm();

  const { selectedBusiness } = useBusiness();
  const [loading, setLoading] = useState(false);

  const [articlesFileList, setArticlesFileList] = useState([]);
  const [einFileList, setEinFileList] = useState([]);
  const [stockLedgerFileList, setStockLedgerFileList] = useState([]);

  const saveFileToIndexedDB = async (file, fileType) => {
    const db = await openDB('file-storage', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files');
        }
      },
    });
  
    // Save the file object
    await db.put('files', file, fileType);
  };

  const getFileFromIndexedDB = async (fileType) => {
    const db = await openDB('file-storage', 1);
    const file = await db.get('files', fileType);
    return file;
  };

    // Load form data from localStorage when the component mounts
    // useEffect(() => {
    //   const savedData = JSON.parse(localStorage.getItem('uploadArticleIncorporationFormData'));
    //   if (savedData) {
    //     form.setFieldsValue(savedData);

    //     // Convert saved extensionFileList back to File objects
    //     if (savedData.articlesFileList) {
    //       const articlesFileList = savedData.articlesFileList.map((fileData) => {
    //         const byteString = atob(fileData.base64.split(',')[1]);
    //         const mimeType = fileData.type;
    //         const ab = new ArrayBuffer(byteString.length);
    //         const ia = new Uint8Array(ab);
    //         for (let i = 0; i < byteString.length; i++) {
    //           ia[i] = byteString.charCodeAt(i);
    //         }
    //         const blob = new Blob([ab], { type: mimeType });
    //         return new File([blob], fileData.name, { type: mimeType });
    //       });
        
    //       console.log("extensionFileList: ", articlesFileList);
    //       setArticlesFileList(articlesFileList);
    //     }
      
    //     // Convert saved taxfileList back to File objects
    //     if (savedData.einFileList) {
    //       const einFileList = savedData.einFileList.map((fileData) => {
    //         const byteString = atob(fileData.base64.split(',')[1]);
    //         const mimeType = fileData.type;
    //         const ab = new ArrayBuffer(byteString.length);
    //         const ia = new Uint8Array(ab);
    //         for (let i = 0; i < byteString.length; i++) {
    //           ia[i] = byteString.charCodeAt(i);
    //         }
    //         const blob = new Blob([ab], { type: mimeType });
    //         return new File([blob], fileData.name, { type: mimeType });
    //       });
        
    //       console.log("extensionFileList: ", einFileList);
    //       setEinFileList(einFileList);
    //     }

    //     // Convert saved taxfileList back to File objects
    //     if (savedData.stockLedgerFileList) {
    //       const stockLedgerFileList = savedData.stockLedgerFileList.map((fileData) => {
    //         const byteString = atob(fileData.base64.split(',')[1]);
    //         const mimeType = fileData.type;
    //         const ab = new ArrayBuffer(byteString.length);
    //         const ia = new Uint8Array(ab);
    //         for (let i = 0; i < byteString.length; i++) {
    //           ia[i] = byteString.charCodeAt(i);
    //         }
    //         const blob = new Blob([ab], { type: mimeType });
    //         return new File([blob], fileData.name, { type: mimeType });
    //       });
        
    //       console.log("extensionFileList: ", stockLedgerFileList);
    //       setStockLedgerFileList(stockLedgerFileList);
    //     }
    //   }
    // }, [form]);

  useEffect(() => {
    const fetchSavedData = async () => {
      const savedData = JSON.parse(localStorage.getItem('uploadArticleIncorporationFormData'));

      if (savedData) {
        form.setFieldsValue(savedData);

        // Fetch saved files from IndexedDB
        const articlesFile = await getFileFromIndexedDB('articlesFileList');
        const einFile = await getFileFromIndexedDB('einFileList');
        const stockLedgerFile = await getFileFromIndexedDB('stockLedgerFileList');

        console.log("Saved files: ", articlesFile, einFile, stockLedgerFile);
        

        if (articlesFile) {
          setArticlesFileList([{ uid: articlesFile.uid, name: articlesFile.name, originFileObj: articlesFile, status: 'done' }]);
        }

        if (einFile) {
          setEinFileList([{ uid: einFile.uid, name: einFile.name, originFileObj: einFile, status: 'done' }]);
        }

        if (stockLedgerFile) {
          setStockLedgerFileList([{ uid: stockLedgerFile.uid, name: stockLedgerFile.name, originFileObj: stockLedgerFile, status: 'done' }]);
        }
      }
    };

    fetchSavedData();
  }, [form]);

  const updateTaxOnboardingStatus = useMutation(data => api.Services.Dashboard.TaxFilling.updateTaxOnboardingStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      if (data && data.data) {
        console.log("Registration successful", data);
        setStatus("TAX_ONBOARDING_COMPLETE");
        setTaxOnboarded("TAX_ONBOARDING_COMPLETE");
        Message({ type: "success", content: "Documents uploaded successfully!" });
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
      setLoading(false);
    },
  });

  // const handleFileUpload = (info, fieldName) => {
  //   let newFileList = [...info.fileList].slice(-1); // Keep only the latest file
  //   form.setFieldsValue({ [fieldName]: newFileList });
  //   // localStorage.setItem('uploadArticleIncorporationFormData', JSON.stringify({ ...JSON.parse(localStorage.getItem('uploadArticleIncorporationFormData')), [fieldName]: newFileList }));

  //   // Convert the file to Base64
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const fileData = {
  //       name: newFileList[0].name,
  //       type: newFileList[0].type,
  //       base64: reader.result, // Base64 string
  //     };

  //     // Save to localStorage
  //     const savedData = JSON.parse(localStorage.getItem('uploadArticleIncorporationFormData')) || {};
  //     savedData[fieldName] = [fileData];
  //     localStorage.setItem('uploadArticleIncorporationFormData', JSON.stringify(savedData));

  //     // setExtensionFileList([fileData]);
  //   };

  //   if (newFileList[0]?.originFileObj) {
  //     reader.readAsDataURL(newFileList[0]?.originFileObj);
  //   }
  // };

  const handleFileUpload = async (info, fieldName) => {
    let newFileList = [...info.fileList].slice(-1); // Keep only the latest file
    form.setFieldsValue({ [fieldName]: newFileList });
  
    // Save the actual file to IndexedDB
    if (newFileList[0]?.originFileObj) {
      await saveFileToIndexedDB(newFileList[0].originFileObj, fieldName);
    }

    // Save to localStorage
    const savedData = JSON.parse(localStorage.getItem('uploadArticleIncorporationFormData')) || {};
    savedData[fieldName] = newFileList.map(file => ({ name: file.name, uid: file.uid }));
    localStorage.setItem('uploadArticleIncorporationFormData', JSON.stringify(savedData));
  };  

  const handleContinue = async (values) => {
    setLoading(true);
    let isDocumentUploaded = false; // Track if any document upload was successful

    try {
      // Upload Articles of Incorporation if available
      if (values.articlesFileList?.[0]) {
        const response = await uploadTaxFile({
          file: values.articlesFileList[0].originFileObj ? values.articlesFileList[0].originFileObj : articlesFileList[0].originFileObj,
          business_id: selectedBusiness.business_id,
          key: 'articles-of-incorporation',
        });
        if (response.status) {
          isDocumentUploaded = true;
        }
      }

      // Upload EIN if available
      if (values.einFileList?.[0]) {
        const response = await uploadTaxFile({
          file: values.einFileList[0].originFileObj ? values.einFileList[0].originFileObj : einFileList[0].originFileObj,
          business_id: selectedBusiness.business_id,
          key: 'ein',
        });
        if (response.status) {
          isDocumentUploaded = true;
        }
      }

      // Upload Stock Ledger if available
      if (values.stockLedgerFileList?.[0]) {
        const response = await uploadTaxFile({
          file: values.stockLedgerFileList[0].originFileObj ? values.stockLedgerFileList[0].originFileObj : stockLedgerFileList[0].originFileObj,
          business_id: selectedBusiness.business_id,
          key: 'stock-ledger',
        });
        if (response.status) {
          isDocumentUploaded = true;
        }
      }

      // If any document was uploaded, update the tax onboarding status
      if (isDocumentUploaded) {
        await updateTaxOnboardingStatus.mutateAsync({
          business_id: selectedBusiness.business_id,
          body: {
            tax_filling_status: "TAX_ONBOARDING_COMPLETE",
            meta_data: { tax_documents_uploaded: true }
          },
        });
      } else {
        // If no document was uploaded, skip to the next step
        setStatus("TAX_ONBOARDING_COMPLETE");
        setTaxOnboarded("TAX_ONBOARDING_COMPLETE");
        secureLocalStore.setItem('taxOnboardingSkipped', true);
        sendMessageAccountant({ business_id: selectedBusiness.business_id, messageData: {
          message: "I will provide my tax and company documents later. Please let me know if these documents are needed urgently",
          sender: secureLocalStore.getItem('email')
        }});
      }
    } catch (error) {
      console.error('Error during document upload:', error);
      Message({ type: 'error', content: 'An error occurred during onboarding.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames.profileComponent}>
      <h3 style={{ fontWeight: 'bold', margin: '0 0 1.5rem 0', fontSize: '0.95rem' }}>
        Help us understand your business structure
      </h3>
      <Form form={form} onFinish={handleContinue}>
        <div className={classNames.uploadContainer}>
          {/* Articles of Incorporation Upload */}
          <Row className={classNames.uploadRow} gutter={[16, 16]}>
            <Col xs={24} md={12} className={classNames.uploadColumn}>
              <span className={classNames.uploadLabel}>
                Articles of Incorporation
                <Tooltip title="Please upload your company's Articles of Incorporation OR Certificate of Formation. This legal document verifies the formation of your business for tax filing purposes.">
                  <InfoCircleOutlined className={classNames.infoIcon} />
                </Tooltip>
              </span>
            </Col>
            <Col className={classNames.uploadButtonColumn}>
              <Form.Item name="articlesFileList" valuePropName="fileList" getValueFromEvent={(e) => e.fileList}>
                <Upload
                  fileList={articlesFileList}
                  onChange={(info) => handleFileUpload(info, 'articlesFileList')}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />} style={{ color: 'black', borderColor: 'black !important' }}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          {/* EIN Upload */}
          <Row className={classNames.uploadRow} gutter={[16, 16]}>
            <Col xs={24} md={12} className={classNames.uploadColumn}>
              <span className={classNames.uploadLabel}>
                EIN
                <Tooltip title="Please upload your IRS-issued EIN letter. This document contains your Employer Identification Number, which is required for business tax filings.">
                  <InfoCircleOutlined className={classNames.infoIcon} />
                </Tooltip>
              </span>
            </Col>
            <Col className={classNames.uploadButtonColumn}>
              <Form.Item name="einFileList" valuePropName="fileList" getValueFromEvent={(e) => e.fileList}>
                <Upload
                  fileList={einFileList}
                  onChange={(info) => handleFileUpload(info, 'einFileList')}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />} style={{ color: 'black', borderColor: 'black !important' }}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          {/* Stock Ledger Upload */}
          <Row className={classNames.uploadRow} gutter={[16, 16]}>
            <Col xs={24} md={12} className={classNames.uploadColumn}>
              <span className={classNames.uploadLabel}>
                Stock Ledger
                <Tooltip title="Please upload your company’s stock ledger, which tracks ownership of shares. This document is essential for verifying stockholder information and reporting.">
                  <InfoCircleOutlined className={classNames.infoIcon} />
                </Tooltip>
              </span>
            </Col>
            <Col className={classNames.uploadButtonColumn}>
              <Form.Item name="stockLedgerFileList" valuePropName="fileList" getValueFromEvent={(e) => e.fileList}>
                <Upload
                  fileList={stockLedgerFileList}
                  onChange={(info) => handleFileUpload(info, 'stockLedgerFileList')}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />} style={{ color: 'black', borderColor: 'black !important' }}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <p className={classNames.uploadInstruction}>
          Uploaded documents will be available in the Documents Vault. If you don’t have any of these documents, you can click “Confirm” and share them later.
        </p>

        <Row className={classNames.uploadRow} gutter={[16, 16]} style={{ gap: '20px', justifyContent: 'center' }}>
          <CTAButton
            style={{ marginTop: "4rem", width: "40%" }}
            loading={updateTaxOnboardingStatus.isLoading || loading}
            onClick={() => {
              setStatus("UPLOAD_TAX_RETURNS");
              setTaxOnboarded("UPLOAD_TAX_RETURNS");
            }}
            disabled={updateTaxOnboardingStatus.isLoading || loading}
          >
            Back
          </CTAButton>

          <CTAButton
            style={{ marginTop: "4rem", width: "40%" }}
            loading={updateTaxOnboardingStatus.isLoading || loading}
            htmlType="submit"
            disabled={updateTaxOnboardingStatus.isLoading || loading}
          >
            Continue
          </CTAButton>
        </Row>
      </Form>
    </div>
  );
};

export default UploadArticleIncorporation;
