import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Row, Col, Spin } from 'antd';
import classNames from './AddAccounts.module.scss';
import { CTAButton, Message } from '../../../../common';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from '../../../../API';
import { ROUTES } from '../../../../constants';

// Import Plaid Link
import { usePlaidLink } from 'react-plaid-link';
import { CloseCircleFilled, CloseCircleOutlined } from '@ant-design/icons';

const UpdateAccounts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const additionalData = location.state;

  const [linkToken, setLinkToken] = useState(null);
  const [, setBusinesses] = useState([{ id: uuidv4(), name: '' }]);
  const secureLocalStore = window.localStorage || window.secureLocalStore;
  const [linkedBanks, setLinkedBanks] = useState([]);
  const [plaidLoading, setPlaidLoading] = useState(true);

  // Fetch link token from backend
  const fetchLinkToken = async (business_id) => {
    try {
      setPlaidLoading(true);
      const response = await api.Services.Onboarding.createLinkToken(business_id);
      setLinkToken(response.data.link_token);
      setPlaidLoading(false);
    } catch (error) {
      Message({ type: "error", content: "Failed to fetch link token" });
      setPlaidLoading(false);
    }
  };

  // View all businesses of user
  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses fetched successfully", data);
        const businessList = data.data.map(b => b.business_id)
        setBusinesses(businessList);

        console.log("Businesses:", businessList);

        fetchLinkToken(additionalData.business_id);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  React.useEffect(() => {
    viewAllBusiness.mutate(secureLocalStore.getItem('otterz_id'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Plaid Link success callback
  const onSuccess = async (data) => {
    try {
      console.log("Plaid Link success:", data);
      const response = await api.Services.Onboarding.exchangeLinkToken({ public_token: data, business_id: additionalData.business_id});
      // console.log("Access Token:", response, response?.data.access_token);

      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }

      Message({ type: "success", content: "Plaid connected successfully" });
      // Reset link token to fetch a new one for the next connection
      setLinkToken(null);

      setLinkedBanks(prevBanks => [...prevBanks, {
        bank_name: response?.data?.bank_details?.bank_name,
        bank_logo: response?.data?.bank_details?.logo,
        id: response?.data?.bank_details?.item_id
      }]);
      const syncResponse = await api.Services.Onboarding.syncTransactions(additionalData.business_id);
      console.log("Linked Banks:", linkedBanks, response?.data?.bank_details?.bank_name, syncResponse);
    } catch (error) {
      console.log("Plaid Link error:", error);
      Message({ type: "error", content: "Failed to exchange public token" });
    }
  };

  // Plaid Link configuration
  const config = {
    token: linkToken,
    onSuccess,
  };

  // useEffect to fetch new link token when linkToken is set to null
  useEffect(() => {
    if (linkToken === null) {
      fetchLinkToken(additionalData.business_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkToken]);

  // Initialize Plaid Link
  const { open, ready } = usePlaidLink(config);

  // Handle '+ Connect' card click
  const handleConnectClick = () => {
    if (ready && linkToken) {
      open();
    } else {
      Message({ type: "error", content: "Plaid Link is not ready" });
    }
  };

  // Handle close icon click
  const handleCloseClick = (id) => {
    setLinkedBanks(prevBanks => prevBanks.filter(bank => bank.id !== id));
  };

  return (
    <div className={classNames.setupOtterzComponentContainer}>
      <div className={classNames.setupOtterzComponent}>
        

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <h1 style={{margin: 0}}>Setup Otterz Co-pilot</h1>
          <CloseCircleOutlined onClick={() => {navigate('/app')}} />
        </div>

        <h4 className={classNames.subText}>
          Connect your bank accounts through Plaid
        </h4>

        {/* + Connect Card */}
        <Row align="middle" style={{gap: '20px'}}>
        {/* Bank Connected Cards */}
        <Row align="middle">
        {linkedBanks.map(bank => (
          <div className={classNames.bankConnectedCard} key={bank.bank_name}>
            {/* Close icon */}
            <CloseCircleFilled className={classNames.closeIcon} onClick={() => handleCloseClick(bank.id)} />
            {/* Bank logo image */}
            {bank.bank_logo === null ? <div>{bank.bank_name}</div> : <img src={`data:image/png;base64,${bank.bank_logo}`} alt={bank.bank_name} />}
          </div>
        ))}
        </Row>

        <Row align="middle" className={classNames.connectRow}>
          <Col span={8}>
            <div className={classNames.connectCard} onClick={handleConnectClick}>
              {/* + Connect */}
              {
                plaidLoading ? (
                  <div className={classNames.spinnerContainer}>
                    <Spin size="small" />
                  </div>
                ) : (
                  "+ Connect"
                )
              }
            </div>
          </Col>
        </Row>

        </Row>

        {/* Continue Button */}
        <CTAButton
          htmlType="button"
          type="primary"
          // loading={updateOnboardingStatus.isLoading}
          disabled={linkedBanks.length === 0}
          className={classNames.signupFormInputs}
          onClick={() => {
            navigate(ROUTES.PUBLIC.UPDATE_BUSINESS_LINK, 
              {
                state: additionalData
              }
            );
          }}
        >
          Continue
        </CTAButton>
      </div>
    </div>
  );
};

export default UpdateAccounts;
