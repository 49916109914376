import PropTypes from "prop-types";

export default function DateFormatter({ date }) {
  // Function to convert the date to a readable format
  const formatDateToWords = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const parsedDate = new Date(date);
    return parsedDate.toLocaleDateString(undefined, options);
  };

  return <span>{formatDateToWords(date)}</span>;
}

DateFormatter.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DateFormatter.defaultProps = {
  date: new Date(), // Default to current date if no date is passed
};
