import { useIdleTimer } from "react-idle-timer";
import { useEffect, useState, useRef } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../App/constants";
import { CTAButton } from "../App/common";

const UseIdleTimer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countdownTime, setCountdownTime] = useState(300); // Countdown time in seconds (5 mins)
  const navigate = useNavigate();
  const countdownRef = useRef(null); // To store the interval ID

  // Timer settings

  /* Test timings */
  // const modalTimeout = 10000; // for testing, set to 40s = 40000ms
  // const logoutTimeout = 20000; // for testing, set to 10s = 10000ms

  /* Actual timings */
  const modalTimeout = 1200000; // 20 minutes of inactivity to show the modal
  const logoutTimeout = 3600000; // 1 hour of inactivity to log out (3600000 ms)

  // Idle timer setup
  const { reset } = useIdleTimer({
    timeout: modalTimeout, // 20 minutes (or 10s for testing)
    onIdle: () => {
      setIsModalVisible(true); // Show the modal after 20 minutes of inactivity
      setCountdownTime(300); // Reset countdown to 5 minutes when modal shows
      startCountdown(); // Start the countdown when the modal shows
    },
    stopOnIdle: false,
  });

  useIdleTimer({
    timeout: logoutTimeout, // 20 minutes (or 10s for testing)
    onIdle: () => {
      handleLogout()
    },
    stopOnIdle: false,
  });

  // Start countdown when the modal appears
  const startCountdown = () => {
    if (countdownRef.current) clearInterval(countdownRef.current); // Clear any previous countdown
    countdownRef.current = setInterval(() => {
      setCountdownTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownRef.current); // Stop countdown at 0
          handleLogout();
        }
        return prevTime - 1;
      });
    }, 1000); // Update every second
  };

  // Effect to clear countdown when the modal is closed
  useEffect(() => {
    if (!isModalVisible && countdownRef.current) {
      clearInterval(countdownRef.current);
    }
  }, [isModalVisible]);

  // Effect to auto logout after 1 hour of inactivity
  // useEffect(() => {
  //   const autoLogoutTimer = setTimeout(() => {
  //     handleLogout();
  //   }, logoutTimeout); // 1 hour

  //   return () => clearTimeout(autoLogoutTimer);
  // }, []);

  // Format the countdown timer in "MM:SS" format
  const formatCountdown = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  // Handle the "Stay Logged In" action
  const handleStayLoggedIn = () => {
    setIsModalVisible(false);
    reset(); // Reset idle timer, preventing logout
    // resetMain();
  };

  // Handle the "Log Out" action
  const handleLogout = () => {
    clearInterval(countdownRef.current); // Stop countdown
    
    const isAccountant = localStorage.getItem("is_accountant");
    if (isAccountant === "true") {
      navigate(ROUTES.SESSION_TIMEOUT, {
        state: { from: "accountant" }
      });
      localStorage.clear();
    } else {
      navigate(ROUTES.SESSION_TIMEOUT, {
        state: { from: "client" }
      });
      localStorage.clear();
    }
  };

  return (
    <>
      {/* Modal for inactivity warning */}
      <Modal
        title="Are you still there?"
        visible={isModalVisible}
        onCancel={handleStayLoggedIn} // Close modal if the user chooses to stay logged in
        footer={[
          <div style={{display: 'flex', gap: '10px'}}>
            <CTAButton key="stay" onClick={handleStayLoggedIn}>
              Stay Logged In
            </CTAButton>
            <CTAButton key="logout" type="primary" danger onClick={handleLogout}>
              Log Out
            </CTAButton>
          </div>
        ]}
      >
        <p>Your session is about to expire due to inactivity. Would you like to stay logged in?</p>
        <p>You will be logged out in {formatCountdown(countdownTime)}</p>
      </Modal>
    </>
  );
};

export default UseIdleTimer;
