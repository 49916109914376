import endpoints from "../../../endpoints";

export default async function getDetailsForm1099(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = endpoints.SERVICES.DASHBOARD.TAX_FILLING.GET_TAXFORM_DETAILS_1099[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${url}/${data.business_id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
