import endpoints from "../../../endpoints";

export default async function uploadTaxFile(data){
    let env = process.env.REACT_APP_ENV?.trim();
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const formData = new FormData();
    formData.append('file', data.file);

    console.log(data.file);
    
    const queryParams = `${data.business_id}/${data.key}`

    const baseURL = endpoints.SERVICES.DASHBOARD.TAX_FILLING.PUSH_QUESTION_DOCUMENT[env];
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}/${queryParams}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: formData
        })
    ).json();
}
