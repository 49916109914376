import { Loader } from '@chatscope/chat-ui-kit-react';
import React, { useContext, useEffect, useState } from 'react';
// import { Route, BrowserRouter as Router, Routes as Switch } from 'react-router-dom';

import kitty from '../chatkitty';
import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/LoginScreen';

import { AuthContext } from './AuthProvider';

export default function Routes({Id, userName}) {
  const { user, setUser, login } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    if (userName) {
      login(userName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  console.log('userName', userName, user);
  useEffect(

    () =>
      kitty.onCurrentUserChanged((currentUser) => {
        setUser(currentUser);

        if (initializing) {
          setInitializing(false);
        }

        setLoading(false);
      }),
    [initializing, setUser]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {user ? <HomeScreen Id={Id} /> : <LoginScreen />}
    </>
  );
}
