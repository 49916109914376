import endpoints from "../../../endpoints";

export default async function googleLoginToken(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const baseURL = endpoints.SERVICES.AUTH.GOOGLE_LOGIN[env];
    const accessToken = secureLocalStore.getItem('accessToken');

    return await (
        await fetch(baseURL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
                // Add other headers if necessary
                // 'Authorization': `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: JSON.stringify({
                idToken: data.idToken,
            })
        })
    ).json();
}
