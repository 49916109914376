import { Outlet } from "react-router-dom";
import classNames from "./CashBalance.module.scss";

function CashBalance() {
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.sectionWrapper}>
        <Outlet />
      </div>
    </div>
  );
}

export default CashBalance;
