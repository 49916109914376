import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
      <h1>Terms of Service</h1>
      <p>Last Updated: September 10, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Otterz, an AI-powered tax filing and bookkeeping software
        service. These Terms of Service ("Terms") govern your access to and use
        of our services ("Services"). By accessing or using Otterz, you agree to
        comply with and be bound by these Terms. If you do not agree, you may
        not use the Service.
      </p>

      <h2>2. Scope of Services</h2>
      <p>
        The nature and limitations of the services we will provide for the 2024
        tax year are as follows:
      </p>
      <ul>
        <li>Consultations for current accounting and tax-related issues.</li>
        <li>
          Provide after-the-fact bookkeeping service and reconcile bank
          statements with books.
        </li>
        <li>Prepare annual business federal and state tax returns.</li>
      </ul>

      <h2>3. Client Responsibilities</h2>
      <p>
      The company’s management is responsible for the (1) proper recording of transactions in the books of accounts, for the safeguarding of assets, and for the substantial accuracy of the financial records (2) management should classify appropriately employee and independent contractors and have on file W 4/State New Hire/ I-9 and W-9 form respectively, file as required and enter all the required information in QuickBooks or provide it to us (3) management is responsible for providing us with all information necessary to identify all the states, localities, and overseas locations in which it conducts business or derives income from. 
To meet the filing requirement deadline, we depend on correct and organized information provided by you at least thirty days before the due date. You will manage any late filing/payment penalties and interest related to late filing due to the information not being provided to us in reasonable time or non-payment of any agreed term of the services. We will make no audit or other verifications of the data you provide, although we may ask for clarification. 
In preparing your returns, we rely on your representation that you have recorded those transactions appropriately in your books and that you understand and have followed the documentation requirements for reporting your revenue and deductions of expenses. You have final responsibility for various tax returns, you must review all the filing forms before you sign and file, for accuracy and completeness. 
      </p>

      <h2>4. Indemnification</h2>
      <p>
      Our fees for these services will be charged based on the subscription plan you have selected upon execution of this engagement letter. Payment is due in advance for the selected subscription period, and recurring charges will continue until you cancel your subscription. All subscription fees are non-refundable, except as required by law or at Otterz's discretion.
      This fee assumes that the information you provide is accurate and that the scope and complexity of the returns are consistent with previous years. If your tax situation becomes more complex or time-consuming, we will discuss any potential fee increase with you in advance, and the service will continue only with your approval. 
      </p>

      <h2>5. Documentation</h2>
      <p>
      We recommend that you keep all the documents, receipts, canceled checks, and other records needed to substantiate the items of income and expense claimed on the return for a minimum of seven years from the date of filing. Any original documents provided to us will be returned upon completion and we do not keep copies of your original documents. Our work paper retention policy is four years. 
      </p>

      <h2>6. Compliance</h2>
      <p>Your returns are subject to review by the taxing authorities. Any issues resolved against you by an examining agent may be subject to certain rights of appeal. If you are audited, we can represent you, review the examination results, or prepare amended returns, all for an additional fee. If we are required by law to provide information or testimony, you will indemnify us and reimburse us for our professional time and out-of-pocket expenses.
      </p>

      <h2>7. Confidentiality</h2>
      <p> 
      If you decide to send your confidential information to us in a manner other than a secure portal, you accept responsibility for all unauthorized access to your confidential information. If you request that we transmit confidential information to you in a manner other than a secure portal, you agree that we are not responsible for any liability including but not limited to, (a) any loss or damage of any nature, whether direct or indirect, that may arise as a result of our sending confidential information in a manner other than a secure portal, and (b) any damages arising as a result of any virus being passed on or with, or arising from any alteration of, any email message.  
      </p>

      <h2>8. Third-Party Providers
      </h2>
      <p>Otterz will not share your information with third parties without your explicit consent, except as required by law. We may use third-party service providers to securely store and transmit data. We take reasonable steps to secure communications and data per our obligations under applicable laws and professional standards. You recognize and accept that we have no control over the unauthorized interception or breach of any communications or data once it has been sent or has been subject to unauthorized access, despite all reasonable security measures employed by us or our third-party vendors. By engaging our services, you consent to the use of these methods.</p>

      <h2>9. Liability</h2>
      <p>Except in cases of gross negligence or willful misconduct, Otterz's liability is limited to the fees paid for the services directly related to any claim. Neither of us will, in any event, be liable to the other, for any reason, for any consequential, incidental, special, punitive, or indirect damages, including loss of profits,  revenue, data, and interest or business opportunities, regardless of whether notice has been given or there is an awareness that such damages have been or may be incurred.  </p>

      <h2>10. Termination</h2>
      <p>You may terminate this engagement at any time, provided that you notify us at least 30 days before the end of your current subscription renewal period. Otterz will also inform you at least 30 days before the end of your renewal period if we intend to terminate this engagement. Termination is subject to payment for services rendered up to the termination date. Otterz reserves the right to terminate this engagement if we determine that we cannot effectively serve you due to nonpayment, an inability to sign the tax return, compliance issues, or a lack of response to our requests (among other things).</p>

      {/* You can continue adding more sections similarly */}

      <h2>11. Governing Law and Dispute Resolution</h2>
      <p>
      In the event of any dispute arising from or related to this contract, the parties agree to first attempt to resolve the matter through informal mediation administered by the American Arbitration Association (AAA) under its Commercial Mediation Procedures. This informal mediation is a mandatory pre-condition before initiating formal arbitration. 

If mediation doesn’t resolve the issue, it will be settled through arbitration administered by the AAA, following their rules. The arbitration will be governed by the laws of Delaware or New York, and the final award can be enforced in any court with jurisdiction.
Arbitration must start within 90 days of filing, and a final decision must be made within 120 days. The arbitrators must agree to these time limits before accepting the case. All arbitration expenses, regardless of the outcome, shall be borne entirely by the party initiating arbitration against Otterz. All arbitration results, including the existence of the arbitration itself, shall remain confidential unless both parties consent in writing or as required by law.
If a party refuses to pay their share of arbitration fees, they waive the right to present evidence or cross-examine witnesses. The other party will still need to provide evidence for the arbitrator to make a decision, and there will be no default judgment against the non-paying party without that evidence.
      </p>

      <h2>12. Modifications to the Terms</h2>
      <p>Otterz may modify these Terms at any time, with notice provided by updating the Terms on our website. Continued use of the Services constitutes your consent to the modified Terms.</p>

      <h2>13. Contact Information</h2>
      <p>If you have questions or concerns, contact Otterz at:</p>

      <p>Tapan Ramchandran, President</p>
      <a href='tr@otterz.co'>tr@otterz.co</a>
      <p>
Otterz Inc<br/>
19 Gibbons Pl<br/>
Green Village, NJ 07935<br/>
      </p>

    </div>
  );
};

export default TermsOfService;
