import endpoints from "../../../endpoints";

export default async function resendInvite(invite_id){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const baseURL = endpoints.SERVICES.ONBOARDING.INVITATION[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}/${invite_id}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify({})
        })
    ).json();
}
