import React, { createContext, useContext, useEffect, useState } from "react";
import { usePATransactions, usePASettings } from "./reducers";
// import api from "../App/API";

export const StoreContext = createContext();

export const useBusiness = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useBusiness must be used within a StoreProvider");
  }
  return context;
};

export const useAccount = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useAccount must be used within a StoreProvider");
  }
  return context;
};

export const usePermissions = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useAccount must be used within a StoreProvider");
  }
  return context;
};

export const useBusinessStatus = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useAccount must be used within a StoreProvider");
  }
  return context;
};

export const useFilter = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useAccount must be used within a StoreProvider");
  }
  return context;
};

const secureLocalStore = window.localStorage || window.sessionStorage;
const StoreProvider = ({ children }) => {
  const paymentAcceptanceSettings = usePASettings();
  const paymentAcceptanceTransactions = usePATransactions();
  // eslint-disable-next-line eqeqeq
  const [isInitialBusinessCenterView, setIsInitialBusinessCenterView] =
    useState(true);
  // Initialize business state and method
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const [accessTokenValid, setAccessTokenValid] = useState(false); 
  const [userData, setUserData] = useState({}); 
  const [permissionsData, setPermissionsData] = useState({}); 
  const [businessStatus, setBusinessStatus] = useState({}); 
  const [insightFilter, setInsightFilter] = useState("month"); 
  const [dateRange, setDateRange] = useState(null);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [applyClicked, setApplyClicked] = useState(0);
  const [inputDateRange, setInputDataRange] = useState(null);

  const handleSelectBusiness = (business) => {
    setSelectedBusiness(business);
  };

  useEffect(() => {
    const initialStoredBusinessCenterState = secureLocalStore.getItem(
      "isInitialBusinessCenterView"
    );
    if (Object.keys(secureLocalStore).includes("isInitialBusinessCenterView")) {
      setIsInitialBusinessCenterView(
        JSON.parse(initialStoredBusinessCenterState)
      );
    }
  }, []);

  useEffect(() => {
    if (!isInitialBusinessCenterView) {
      secureLocalStore.setItem("isInitialBusinessCenterView", false);
    }
  }, [isInitialBusinessCenterView]);

  const handleSetTokenValidity = (status) => {
    setAccessTokenValid(status);
  };

  // Function to check if an access token is expired
  // async function checkAccessTokenValidity() {
  //   try {
  //     const response = await api.Services.Onboarding.viewUserByID({ otterz_id: window.localStorage.getItem("otterz_id")});
  //     const expired = response.status === false && response.errors && response.errors[0].code === "OTZT401";
  //     console.log("Token expired:", expired);
  //     handleSetTokenValidity(!expired);
  //   } catch (error) {
  //     console.error("Error checking token expiration:", error);
  //     handleSetTokenValidity(false); // Assume token is expired in case of any error
  //   }
  // }

  // useEffect(() => {
  //   const accessToken = window.localStorage.getItem("accessToken");
  //   if (accessToken) {
  //     checkAccessTokenValidity();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const store = {
    paymentAcceptanceSettings,
    paymentAcceptanceTransactions,
    selectedBusiness,
    handleSelectBusiness, 
    accessTokenValid,
    handleSetTokenValidity,
    permissionsData,
    setPermissionsData,
    businessStatus,
    setBusinessStatus,
    userData,
    setUserData,
    insightFilter,
    setInsightFilter,
    dateRange,
    setDateRange,
    showCustomDatePicker,
    setShowCustomDatePicker,
    applyClicked,
    setApplyClicked,
    inputDateRange,
    setInputDataRange,
    isInitialBusinessCenterView,
    setIsInitialBusinessCenterView,
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
