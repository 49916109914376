import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useMutation } from "react-query";
import { Navigate } from "react-router-dom";
import useRouterSwitch from "../hooks/useRouterSwitch";
import { useAccount } from "../store/StoreProvider";
import api from "../App/API";

const OnboardingRoute = ({ children }) => {
  const path = useRouterSwitch();
  const [, setUser] = useState(null);
  const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false); // Flag for auth check completion
  const secureLocalStore = window.localStorage || window.sessionStorage;
  const viewUser = useMutation(data => api.Services.Onboarding.viewUserByID(data));
  const { accessTokenValid, handleSetTokenValidity } = useAccount(); // Access accessTokenValid from global store

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsAuthCheckComplete(true); // Set flag to true once the user state is determined
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Function to check if an access token is expired
  async function isAccessTokenExpired() {
    try {
      const response = await viewUser.mutateAsync({ otterz_id: secureLocalStore.getItem("otterz_id")});
      return response.status === false && response.errors && response.errors[0].code === "OTZT401";
    } catch (error) {
      console.error("Error checking token expiration:", error);
      return true; // Assume token is expired in case of any error
    }
  }

  // Check access token validity and update global store
  useEffect(() => {
    const accessToken = secureLocalStore.getItem("accessToken");
    if (accessToken) {
      isAccessTokenExpired()
        .then((expired) => handleSetTokenValidity(!expired))
        .catch((error) => {
          console.error("Error checking token expiration:", error);
          handleSetTokenValidity(false); // Assume token is expired in case of any error
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Conditional rendering based on user existence and auth check completion
  if (isAuthCheckComplete) {
    if (accessTokenValid && secureLocalStore.getItem('accessToken')) {
      return children;
    } else {
      return <Navigate to={path} replace />;
    }
  } else {
    // Do not render anything while the auth check is not complete
    return null;
  }
};

export default OnboardingRoute;
