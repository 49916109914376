import { Button as AntButton } from "antd";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

export default function CTAButton({
  children,
  className,
  onClick,
  loading,
  ...props
}) {
  const Button = styled(AntButton)`
    width: 100%;
    height: ${(props) => (props.small ? "44px" : "56px")};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background: #000000;
    border-color: #000000 !important;
    color: #ffffff;

    &:hover {
      background: #fff; /* Change the hover background color to grey */
      border-color: #000 !important;
      color: #000000;
    }

    &:disabled {
      background: #82898F80;
      border-color: #82898F80 !important;
      color: #000000;
    }

    &:focus {
      background: #fff; /* Change the hover background color to grey */
      border-color: #000 !important;
      color: #000000;
    }
  `;

  return (
    <Button
      {...props}
      className={`${className}`}
      onClick={onClick}
      loading={loading}
      disabled={loading || props.disabled} // Disable the button when loading
    >
      {children}
    </Button>
  );
}

CTAButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

CTAButton.defaultProps = {
  loading: false,
};
