const Download = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12236_131408)">
    <path d="M9.75 7.5H13.5L9 12L4.5 7.5H8.25V2.25H9.75V7.5ZM3 14.25H15V9H16.5V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V9H3V14.25Z" fill="#3B3F43"/>
    </g>
    <defs>
    <clipPath id="clip0_12236_131408">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>  
  );
};

export default Download;
