import React from 'react';
import { Modal } from 'antd';
import PlansAndPreferences from '../../../Settings/PlansAndPreferences/PlansAndPreferences';
import { CTAButton } from '../../../common';

const SubscriptionModal = ({ isVisible, onClose, onSubmit }) => {

  // Conditional rendering based on confirmationReceived
  const renderModalContent = () => {
    return (
      <>
        <PlansAndPreferences />
      </>
    );
  };

  return (
    <Modal
      title="Choose Your Subscription Plan"
      visible={isVisible}
      footer={null}
      closable={true}
      onCancel={() => {
        onSubmit();
        onClose(false)}
      }
      maskClosable={true}
      width={"80%"}
    >
      {renderModalContent()}

      {/* Continue Button */}
      <div style={{
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <CTAButton 
          type="primary" 
          onClick={() => {
            onSubmit();
            onClose(false);
          }} 
          style={{ width: '15%' }}
          // disabled={!connectedQuickBooks || linkedBanks.length === 0}
        >
          Continue
        </CTAButton>
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
