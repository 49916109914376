import { MAX_MIN } from "../../util/constants";

const config = {
  firstName: {
    required: "First name is required",
    placeholder: "Please enter",
    maxLength: `Max ${MAX_MIN.name.max} characters`,
    valid: "Enter a valid first name",
  },
  lastName: {
    required: "Last name is required",
    placeholder: "Please enter",
    maxLength: `Max ${MAX_MIN.name.max} characters`,
    valid: "Enter a valid last name",
  },
  email: {
    valid: "Enter a valid email address",
    required: "Email address is required",
    placeholder: "Eg: john@mail.com",
    maxLength: `Max ${MAX_MIN.email.max} characters`,
    minLength: `Min ${MAX_MIN.email.min} characters`,
  },
  password: {
    required: "Password is required",
    minLength: "Min 8 characters",
    maxLength: "Max 16 characters",
    regex: "Insecure password.",
    placeholder: "Enter a new secured password",
    hint: "Password must be at least 8 characters long with at least 1 lowercase letter, 1 uppercase letter, 1 digit and 1 special character.",
  },
  confirmPassword: {
    required: "Password confirmation is required",
    match: "Passwords do not match",
    placeholder: "Confirm new password",
  },
  businessName: {
    required: "Name of the business is required",
    placeholder: "Please enter name",
  },
  street: {
    required: "Street is required",
    placeholder: "Street 1*",
  },
  street2: {
    placeholder: "Street 2",
  },
  city: {
    required: "City is required",
    placeholder: "City",
  },
  state: {
    required: "State is required",
    placeholder: "State",
  },
  postalCode: {
    required: "Postal code is required",
    placeholder: "Postal Code",
    // valid: "Only numbers allowed and count should be 5",
    valid: "Enter a valid postal code",
  },
  country: {
    required: "Country is required",
    placeholder: "Country",
  },
  ein: {
    required: "EIN is required",
    placeholder: "EIN",
    // onlyNumbers: "Only numbers allowed and count should be 9",
    onlyNumbers: "Enter a valid EIN",
  },
  salesItems: {
    error: "At least one item is required",
  },
  phoneNumber: {
    required: "Phone number is required",
    placeholder: "Please enter",
  },
  countryCode: {
    required: "Country code is required",
    placeholder: "Please enter",
  },
  entityType: {
    required: "Entity type is required",
  },
  identificationType: {
    required: "Identification type is required",
  },
  ssn: {
    required: "Social security number (SSN) is required",
    // valid: "Only numbers allowed and count should be 9",
    valid: "Enter a valid SSN",
  },
  passport: {
    required: "Passport number is required",
    // valid: "Only numbers allowed and count should be 9",
    valid: "Enter a valid passport number",
  },
  nationality: {
    required: "Nationality is required",
  },
  dateOfBirth: {
    required: "Date of birth is required",
  },
  title: { required: "Title is required" },
  website: {
    valid: "Please enter valid url",
    maxLength: "Maximum 150 characters",
  },
  estimatedTransactions: {
    required: "Transaction estimate is required",
    // valid: "Only numbers allowed between 0 to 100",
    valid: "Enter a transaction estimate",
  },
  estimatedAmount: {
    required: "Amount estimate is required",
    // valid: "Only numbers allowed between 0 to 100",
    valid: "Enter an amount estimate",
  },
};

export default config;
