import endpoints from "../../../endpoints";

export default async function updateBusinessQuestion(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(endpoints.SERVICES.DASHBOARD.TAX_FILLING.PUSH_BUSINESS_QUESTION[env] + "/" + data.business_id, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify(data?.body)
        })
    ).json();
}
