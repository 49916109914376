import endpoints from "../../../endpoints";

export default async function registerToken(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    
    const baseURL = endpoints.SERVICES.AUTH.SIGNUP[env];
    let bodyData = null
    if (data.email && data.password) {
        bodyData = {
            email_id: data.email,
            password: data.password
        }
    } else if (data.idToken) {
        bodyData = {
            idToken: data.idToken
        }
    }

    if (data.is_accountant) {
        bodyData = {
            email_id: data.email,
            password: data.password,
            is_accountant: data.is_accountant
        }
    }

    return await (
        await fetch(baseURL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
                // Add other headers if necessary
                // 'Authorization': `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: JSON.stringify(bodyData)
        })
    ).json();
}
