import React, { useEffect, useMemo, useState } from "react";
import classNames from "./TaxFormsComponent.module.scss";
import { Card, Col, Row, Select, Table, Typography } from "antd";
import { CTAButton } from "../../../../common";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import api from "../../../../API";
import moment from "moment";

export const TaxFormsComponent = ({ profileData }) => {
  const navigate = useNavigate();
  const [taxForms, setTaxForms] = useState([]);
  const location = useLocation();
  const [selectedFormType, setSelectedFormType] = useState("ALL_FORMS");

  const getTaxForms = useMutation(
    () =>
      api.Services.AccountantsDashboard.Tax.getTaxForms({
        business_id: profileData.business_id,
      }),
    {
      onSuccess: (response) => {
        // console.log("RESPONSE", response?.data)
        setTaxForms(response?.data?.forms);
      },
      onError: () => {},
    }
  );

  useEffect(() => {
    try {
      getTaxForms.mutate();
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSelectionOptions = Array.from(
    new Set(taxForms.map((item) => item.form_name))
  ).map((item) => ({ label: item, value: item }));

  const columns = useMemo(
    () => [
      {
        title: "Form",
        dataIndex: "form_name",
      },
      {
        title: "Contractor",
        dataIndex: "contractor_name",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record) => {
          return (
            <Select
              defaultValue="TAX_PREP_ONGOING"
              value={"TAX_PREP_ONGOING"}
              // onChange={(value) =>
              //   handleSelectChange(record.key, "otterzRecommendation", value)
              // }
              style={{ width: 150 }}
            >
              <Select.Option value="NOT_STARTED">Not Started</Select.Option>
              <Select.Option value="TAX_PREP_ONGOING">
                Tax Prep Ongoing
              </Select.Option>
              <Select.Option value="DOCUMENTS_PENDING">
                Documents Pending
              </Select.Option>
              <Select.Option value="ACCOUNTANT REVIEW">
                Accountant Review{" "}
              </Select.Option>
              <Select.Option value="FILED">Filed</Select.Option>
            </Select>
          );
        },
      },
      {
        title: "Deadlines",
        dataIndex: "deadline",
        render: (date) => moment(date).format("MM/DD/YYYY"),
      },
      {
        title: "Last Update",
        sorter: (a, b) => a.amount - b.amount,
        sortDirections: ["descend", "ascend"],
        dataIndex: "last_updated",
        render: (date) => moment(date, "DD-MM-YYYY").format("MM/DD/YYYY"),
      },
      {
        title: "",
        dataIndex: "form_id",
        render: (form_id, row) => (
          <CTAButton
            style={{
              fontSize: "0.9rem",
              height: "40px",
              width: "90px",
            }}
            onClick={() => {
              const selectedForm = taxForms.find(
                (form) => form.form_id === form_id
              );
              navigate(`${location.pathname}/details/${form_id}`, {
                state: {
                  ...location.state,
                  form: selectedForm,
                  contractor: {
                    contractor_id: row.contractor_id,
                    contractor_name: row.contractor_name,
                    contractor_email: row.contractor_email,
                    contractor_account_number: row.contractor_account_number,
                    contractor_billing_address: row.contractor_billing_address,
                  },
                },
              });
            }}
          >
            View
          </CTAButton>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taxForms, location]
  );

  const onSelectFormType = (value) => {
    setSelectedFormType(value);
  };
  return (
    <div className={classNames.container}>
      <section className={classNames.innerContent}>
        <Row gutter={16}>
          <Col span={8}>
            <Card
              bodyStyle={{
                paddingTop: 5,
                paddingBottom: 6,
                paddingLeft: 16,
                paddingRight: 16,
              }}
              className={classNames.bankCard}
              style={{ padding: 0, paddingTop: 0, background: "none" }}
            >
              <Row gutter={16} align={"middle"} justify={"space-between"}>
                <Col span={8}>
                  <Typography>View Forms</Typography>
                </Col>
                <Col span={16}>
                  <Select
                    defaultValue={"ALL_FORMS"}
                    options={[
                      { label: "All Forms", value: "ALL_FORMS" },
                      ...formSelectionOptions,
                    ]}
                    style={{width: "100%"}}
                    onSelect={onSelectFormType}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Table
          loading={getTaxForms.isLoading}
          dataSource={
            selectedFormType === "ALL_FORMS"
              ? taxForms
              : taxForms?.filter((item) => item.form_name === selectedFormType)
          }
          columns={columns}
        />
      </section>
    </div>
  );
};
