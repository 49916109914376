import endpoints from "../../../endpoints";

export default async function linkBusiness(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');
    const baseURL = endpoints.SERVICES.ONBOARDING.BUSINESS[env];

    return await(
        await fetch(`${baseURL}/accounts/link`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify(data)
        })
    ).json();
}
