import endpoints from "../../../endpoints";

export default async function getTransactionsPlaid(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const baseURL = endpoints.SERVICES.ONBOARDING.PLAID[env];
    const route = data.account_id ? `${baseURL}/get/transactions/${data.business_id}/${data.account_id}` : `${baseURL}/get/transactions/${data.business_id}`;
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(route, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
