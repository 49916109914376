import endpoints from "../../../endpoints";

// Function to create subscription
export default async function createSubscription(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    const baseURL = endpoints.SERVICES.SUBSCRIPTIONS.CREATE_SUBSCRIPTION[env];
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${baseURL}/${data.business_id}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data.subscriptionType)
        })
    ).json();
}
