import { Auth } from 'aws-amplify';
import endpoints from '../../endpoints';

export default async function addExternalAccount(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    return await(
        await fetch(endpoints.BUSINESS_BANKING.PATCH_PAYEE[env] + "add-external-account/" + data.otz_payee_id, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify({  // Stringify the body
                "otz_business_id": data?.otz_business_id,
                "account_number": data?.account_number,
                "ach_routing_number": data?.ach_routing_number,
                "wire_routing_number": data?.wire_routing_number,
                "bank_countries": [
                    "US"
                ],
                "bank_name": data?.bank_name,
                "type": data?.type,
            })
        })
    ).json();
}
