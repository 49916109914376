import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  Message,
  MessageGroup,
  MessageInput,
  MessageList,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import React, { useContext, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import kitty from '../chatkitty';
import { AuthContext } from '../navigation/AuthProvider';
import { channelType } from '../types';
import { notification } from 'antd';
import api from '../../../API';

export const ChatScreen = ({ channel, Id }) => {
  const { user } = useContext(AuthContext);
  console.log('Id', Id);
  const [contact, setContact] = useState(
    channel.members.find((member) => member.id !== user.id)
  );

  const [messages, updateMessages] = useImmer([]);

  const [typing, setTyping] = useState(null);

  function getCurrentDate() {
    const currentDate = new Date();
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const notifyUser = (message) => {
    // Show Ant Design notification
    notification.open({
        message: getCurrentDate(),
        description: `New message received, ${message.body}`,
        duration: 2,
        onClick: () => {
          // Navigate to "documents-vault" when notification is clicked
      },
    });
  };

  useEffect(() => {
    const startChatSessionResult = kitty.startChatSession({
      channel,
      onReceivedMessage: (message) => {
        updateMessages((draft) => [...draft, message]);
        if (message.user.id === user.id) return;
        notifyUser(message);
      },
      onTypingStarted: (typingUser) => {
        if (typingUser.id !== user.id) {
          setTyping(typingUser);
        }
      },
      onTypingStopped: (typingUser) => {
        if (typingUser.id !== user.id) {
          setTyping(null);
        }
      },
    });

    kitty
      .getMessages({
        channel,
      })
      .then((result) => {
        updateMessages(() => result.paginator.items.slice().reverse());
      });

    kitty.onNotificationReceived((notification) => {
      // Update UI
      console.log('Received notification', notification);
      notifyUser(notification)
    });

    return startChatSessionResult.session.end;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    const unsubscribe = kitty.onUserPresenceChanged((changed) => {
      if (changed.id === contact.id) {
        setContact(changed);
      }
    });

    setContact(channel.members.find((member) => member.id !== user.id));

    return unsubscribe;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const handleMessageChanged = async (message) => {
    await kitty.sendKeystrokes({
      channel,
      keys: message,
    });
  };

  const handleSend = async (message) => {
    const messageBody = await kitty.sendMessage({
      channel,
      body: message,
    });

    console.log('contact', contact);
    if (!contact.presence.online) {
      // Send email notification api call here
      await api.Services.Dashboard.ChatKitty.sendEmailNotificationChat({
        email: contact.name,
        message: `Hi, you have a new message from the Bookkeeper. Please login to your Otterz account to view the message.`,
        business_id: Id
      })
    }

    console.log('messageBody', messageBody);
  };

  const renderTypingIndicator = (() => {
    if (typing) {
      return <TypingIndicator content={`${typing.displayName} is typing`} />;
    }

    return null;
  })();

  return (
    <ChatContainer>
      <ConversationHeader className="chat-conversation-header">
        <Avatar
          src={contact.displayPictureUrl}
          name={contact.displayName}
          status={contact.presence.status.toLowerCase()}
        />
        <ConversationHeader.Content
          userName={contact.displayName}
          info={contact.presence.status}
        />
      </ConversationHeader>

      <MessageList
        typingIndicator={renderTypingIndicator}
        scrollBehavior="auto"
        className="chat-message-list"
      >
        {messages
          .map((m) => ({
            key: m.id,
            message: m.body,
            sender: m?.user?.displayName,
            senderUsername: m?.user?.name,
            sentTime: m.createdTime,
          }))
          .map((m) => (
            <MessageGroup
              key={m.key}
              sender={m.sender}
              sentTime={m.sentTime}
              direction={
                m.senderUsername === user.name ? 'outgoing' : 'incoming'
              }
            >
              <MessageGroup.Messages>
                <Message key={m.key} model={m} />
              </MessageGroup.Messages>
            </MessageGroup>
          ))}
      </MessageList>

      <MessageInput
        placeholder="Type message here"
        fancyScroll
        className="chat-message-input"
        attachButton={false}
        onChange={handleMessageChanged}
        onSend={handleSend}
      />
    </ChatContainer>
  );
};

ChatScreen.propTypes = {
  channel: channelType,
};

ChatScreen.defaultProps = {
  channel: null,
};

ChatScreen.displayName = 'Chat';

export default ChatScreen;
