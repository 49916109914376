import React from 'react';

import classNames from './styles.module.scss'
import { CTAButton } from '../../common';

const TaxCard = ({corporateTaxFile, personalTaxFile, corporateTaxFileCost, display, onSubscribe, selected, personalTaxFileCost}) => {
  return (
    <div className={classNames.subscriptionCard} style={{
        opacity: display ? 1 : 0, width: '30%'
    }}>
      <div className={classNames.saveBadge} style={{background: '#D9D9D9', color: '#82898F'}}>
        {/* {save} */}
        Payment Details
      </div>
 
      <div className={classNames.cardContent}>
        <div className={classNames.taxTypeContainer} style={{display: 'flex', width: '100%', gap: '15px', justifyContent: 'space-around', alignItems: 'center'}}>
            <div className={classNames.buttonContainer}>
                <p className={null}>
                    <a href="/HowToView.pdf" target="_blank" rel="noopener noreferrer">
                      <span>How To View/Update Credit Card Details</span>
                    </a>
                </p>
                {/* {
                    corporateTaxFileCost && (
                        <p className={null}>
                            <span>${corporateTaxFileCost}</span>
                        </p>
                    )
                } */}
            </div>
            <div style={{width: '100%'}}>
                {!corporateTaxFile ? (
                    <CTAButton
                        type={'primary'}
                        title={selected ? "Added" : "Add"}
                        disabled={selected}
                        className={[selected && classNames.selected, classNames.button].filter(Boolean).join(' ')}
                        onClick={selected ? () => null : onSubscribe}
                    >{selected ? "Added" : "View/Add"}</CTAButton>
                ) : (
                    <CTAButton
                        type={'primary'}
                        title="Free"
                        disabled
                        onClick={() => {}}
                    >Free</CTAButton>
                )}
            </div>
        </div>

        {/* <div className={classNames.taxTypeContainer} style={{
            // display: 'flex', 
            display: 'none', 
            width: '100%', 
            justifyContent: 'space-around', 
            alignItems: 'center'
        }}>
            <div className={classNames.buttonContainer}>
                <p className={null}>
                    <span>Personal Tax Filing</span>
                </p>
                {
                    personalTaxFileCost && (
                        <p className={null}>
                            <span>${personalTaxFileCost}</span>
                        </p>
                    )
                }
            </div>
            <div style={{width: '100%'}}>
                {!personalTaxFile ? (
                    <CTAButton
                        type={'primary'}
                        // title="Add"
                        onClick={null}
                    >View/Add</CTAButton>
                ) : (
                    <CTAButton
                        type={'primary'}
                        title="Free"
                        disabled
                        onClick={null}
                    >Free</CTAButton>
                )}
            </div>
        </div> */}
      </div>
    </div>
  );
};

export default TaxCard;
