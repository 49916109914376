import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import classNames from "../Signup.module.scss";
import { CTAButton, Message } from '../../../common';
import { useMutation } from 'react-query';
import api from '../../../API';
import Bugsnag from '@bugsnag/js';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';

const { Title, Paragraph } = Typography;

function EmailVerification({ email }) {
  const navigate = useNavigate();
  const secureLocalStore = window.localStorage || window.sessionStorage;

  const [pollingInterval, setPollingInterval] = useState(null);

  const emailVerifyMutation = useMutation(data => api.Services.Auth.emailVerify(), {
    onSuccess: (data) => {
      console.log("Registration successful", data);
      Message({ type: "success", content: "Verification email resent" });
    },
    onError: (error) => {
      Bugsnag.notify("Error in email verification", error, error.message)
      Message({ type: "error", content: error.message });
    },
  });
  
  const handleResendLink = () => {
    emailVerifyMutation.mutate();
    console.log('Resend link clicked');
  };

  const updateOnboardingStatus = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return; // Exit early if there's an error
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
        Message({ type: "success", content: "Email verified successfully" });

        // Determine the next route based on the preferences
        // Navigate or update state as needed
        navigate(ROUTES.ONBOARDING.CREATE_PROFILE);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  const viewUser = useMutation(data => api.Services.Auth.userData(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      } else if (data.data && data.data.length > 0) {
        const emailVerificationStatus = data.data[0].verifications.email;
        if (emailVerificationStatus === 'Y') {
          secureLocalStore.setItem('email_verification', true);
          Message({ type: "success", content: "Email verified successfully" });
          clearInterval(pollingInterval); // Stop polling once email is verified

          console.log("User data fetched successfully", data);

          if (data.data[0].invitation_details && data.data[0].invitation_details.invite_id) {
            console.log("invite data", data.data[0], data.data[0].invitation_details);
            updateOnboardingStatus.mutate({otterz_id: secureLocalStore.getItem('otterz_id'), status: "CREATE_PROFILE_ONBOARDED"});
          } else {
            navigate(ROUTES.ONBOARDING.CREATE_PROFILE); // Redirect to create profile
          }
        }
      }
    },
    onError: (error) => {
      console.error("Error fetching user details: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  useEffect(() => {
    const intervalId = setInterval(() => {
      viewUser.mutate(secureLocalStore.getItem('email'));
    }, 5000);
    setPollingInterval(intervalId);
  
    // Clear interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames.emailVerificationContainer}>
      <Title level={3} style={{width: '40%'}}>We’ve sent a verification link on your email id {secureLocalStore.getItem('email')}</Title>
      <Paragraph style={{width: '40%'}}>
        Please click on the link that has been sent to your email account to verify your email and continue the onboarding process
      </Paragraph>
      <CTAButton className={classNames.emailVerifyButton} type="primary" onClick={handleResendLink}>Resend link</CTAButton>
    </div>
  );
}

export default EmailVerification;
