import endpoints from "../../../endpoints";

export default async function taxFillingDataCollection(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    const formData = new FormData();
    formData.append('file', data.file);

    return await(
        await fetch(`${endpoints.SERVICES.ONBOARDING.TAX_FILLING_COLLECTION[env]}/${data.business_id}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData
        })
    ).json();
}
