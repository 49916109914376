import React, { useContext } from 'react';
import { Card } from 'antd'; // Import antd components
import { AuthContext } from '../navigation/AuthProvider';
import { Loader } from '@chatscope/chat-ui-kit-react';
// import { CTAButton } from '../../../common';

export const LoginScreen = () => {
  // const [nickname, setNickname] = useState('');
  const { loading } = useContext(AuthContext);

  return (
    <div className="d-flex h-100 flex-column align-items-center justify-content-center" style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Card className="login">
        {/* <Form>
          <h1 className="mb-3">Enter a username</h1>
          <Form.Item label="Username"> 
            <Input
              name="nickname"
              type="text"
              placeholder="nickname"
              value={nickname}
              onChange={(event) => setNickname(event.target.value)}
              autoComplete="username"
            />
          </Form.Item>
          <CTAButton
            size="small"
            className="w-100"
            onClick={() => login(nickname)}
            disabled={nickname.length === 0}
          >
            Login
          </CTAButton>
        </Form> */}

        {loading && (
          <>
            <div
              className="w-100 h-100 position-absolute d-flex align-items-center justify-content-center bg-light"
              style={{ zIndex: '10', opacity: '0.4' }}
            />
            <div
              className="w-100 h-100 position-absolute d-flex align-items-center justify-content-center"
              style={{ zIndex: '11' }}
            >
              <Loader />
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

LoginScreen.displayName = 'Login';

export default LoginScreen;
