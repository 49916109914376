import React, { useEffect, useState } from 'react';
import { Input, Button, Space, Row, Col, Tree, Modal, Form, Upload, message, Divider, Dropdown, Menu, Spin } from 'antd';
import { FileOutlined, FolderOutlined, EllipsisOutlined, UploadOutlined, CaretDownFilled, CloseCircleOutlined, FilePdfOutlined, FileImageOutlined, FileExcelOutlined, FileWordOutlined, FileTextOutlined, Loading3QuartersOutlined, FileAddOutlined, FolderAddOutlined } from '@ant-design/icons';
import classNames from './DocumentVaultComponent.module.scss';
import { CTAButton, Message } from '../../common';
import api from '../../API';

import { useAccount, useBusiness } from '../../../store/StoreProvider';
import CreateFolder from './Icons/CreateFolder';
import UploadFIle from './Icons/UploadFIle';
import Move from './Icons/Move';
import Rename from './Icons/Rename';
import Download from './Icons/Download';
import Delete from './Icons/Delete';

const { TreeNode } = Tree;
const { DirectoryTree } = Tree;
const { Item: FormItem } = Form;

const DocumentVaultComponent = ({accountData, isClient = true}) => {
  // const navigate = useNavigate()
  const secureLocalStore = window.localStorage || window.secureLocalStore;

  let { selectedBusiness } = useBusiness();
  let {userData} = useAccount();
  
  // Assigning a default value to selectedBusiness if it's an empty object
  selectedBusiness = Object.keys(selectedBusiness).length === 0 ? accountData : selectedBusiness;
  userData = Object.keys(userData).length === 0 ? accountData : userData;

  const [treeData, setTreeData] = useState([{}]);
  const [, setSelectedNodeKey] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [nodeType, setNodeType] = useState('folder');
  const [fileList, setFileList] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalKey, setDeleteModalKey] = useState(null);
  const [deleteModalNode, setdeleteModalNode] = useState(null);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [moveModalVisible, setMoveModalVisible] = useState(false);
  const [selectedFolderKey, setSelectedFolderKey] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(['0-0']);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onCreateNode(nodeType, selectedNode, file)
      // You can then proceed with uploading the file or any other desired action
      e.target.value = null;
    }
  };

  function download(url, filename) {
    fetch(url).then(function(t) {
        return t.blob().then((b)=>{
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.setAttribute("target", "_blank");
            a.click();
        }
        );
    });
  }

  const onMenuClick = (e, node) => {
    const { key } = e;
    switch (key) {
      case 'createFolder':
        onAddNode('folder', node);
        break;
      case 'upload':
        onAddNode('file', node);
        break;
      case 'move':
        showMoveModal(node);
        break;
      case 'rename':
        showRenameModal(node);
        break;
      case 'download':
        if (node?.isFolder) {
          const traverse = (node) => {
            if (node.children) {
              node.children.forEach((node, it) => {
                traverse(node);
              })
            } else {
              download(`https://ucarecdn.com/${node.fileId}/`, node?.title);
            }
          }

          node?.children && node?.children.forEach((node, it) => {
            traverse(node);
          })
          break;
        }

        download(`https://ucarecdn.com/${node.fileId}/`, node?.title);
        break;
      case 'delete':
        onDeleteNode(node);
        break;
      default:
        break;
    }
  };

  const renderMenu = (node) => (
    <Menu style={{padding: '10px', width: '200px'}} onClick={(e) => onMenuClick(e, node)}>
      {node && node.isFolder && (
        <Menu.Item style={{padding: '10px 100px 10px 10px !important'}} key="createFolder" icon={<CreateFolder />}>
          Create Folder
        </Menu.Item>
      )}
      {node && node.isFolder && (
        <Menu.Item style={{padding: '10px 100px 10px 10px !important'}} key="upload" icon={<UploadFIle />}>
          Upload File
        </Menu.Item>
      )}
      {
        node && !node.isFolder && (
          <Menu.Item style={{padding: '10px 100px 10px 10px !important'}} key="move" icon={<Move />}>
            Move
          </Menu.Item>
        )
      }
      {
        node && node.deletable && (
          <Menu.Item style={{padding: '10px 100px 10px 10px !important'}} key="rename" icon={<Rename />}>
            Rename
          </Menu.Item>
        )
      }
      {
        node && !node.isFolder && (
          <Menu.Item style={{padding: '10px 100px 10px 10px !important'}} key="download" icon={<Download />}>
            Download
          </Menu.Item>
        )
      }
      {
        node && node.deletable && (
          <Menu.Item style={{padding: '10px 100px 10px 10px !important'}} key="delete" icon={<Delete />} danger>
            Delete
          </Menu.Item>
        )
      }
    </Menu>
  );

  /* Funtions related to file or folder creation */
  const onAddNode = (type, node) => {
    setNodeType(type);
    setSelectedNodeKey(node.key || '0-0');
    setSelectedNode(node)
    if (type === 'file') {
      document.getElementById('fileInput').click();
    } else {
      setIsModalVisible(true);
    }
  };

  const onModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onCreateNode = (type, selectedNode, file) => {
    form.validateFields().then(async (values) => {
      try {
        setLoading(true);

        if (file === undefined && type === 'file') {
          Message({ type: "error", content: "Please select a file to upload" });
          setLoading(false);
          return;
        }
        if (values && values.name === undefined && type === 'folder') {
          Message({ type: "error", content: "Please enter a folder name" });
          setLoading(false);
          return;
        }

        let  data = {}
        if (file) {
          data = {
            file: file,
            business_id: selectedBusiness.business_id,
            otterz_id: secureLocalStore.getItem('otterz_id'),
            parent_folder_id: selectedNode.key === '0-0' ? 'root' : selectedNode.key,
          }
        } else {
          data = {
            folder_name: type === 'folder' ? values.name : '',
            business_id: selectedBusiness.business_id,
            otterz_id: secureLocalStore.getItem('otterz_id'),
            parent_folder_id: selectedNode.key === '0-0' ? 'root' : selectedNode.key,
          }
        }

        const response = type === 'folder' ? await api.Services.Dashboard.DocumentsVault.createFolder(data) : await api.Services.Dashboard.DocumentsVault.uploadFile(data);
        if (response && response.data) {
  
          const newNode = {
            title: type === 'folder' ? response.data.folder_name : file.name,
            key: type === 'folder' ? `${response.data.folder_id}` : `${response.data.file_id}`,
            dateAdded: new Date(),
            addedBy: userData?.first_name,
            isFolder: type === 'folder',
            deletable: true,
            fileId: type === 'folder' ? 'nil' : response.data.file_id,
          };
    
          const parentNode = selectedNode;
    
          if (type === 'folder' || !parentNode || parentNode.isFolder) {
            const updatedTreeData = [...treeData];
    
            const insertNode = (data, key, node) => {
              if (data.length === 0) return;
              for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                  data[i].children = [...(data[i].children || []), node];
                } else if (data[i].children) {
                  insertNode(data[i].children, key, node);
                }
              }
            };
    
            insertNode(updatedTreeData, selectedNode.key, newNode);
    
            setTreeData(updatedTreeData);
            setLoading(false);
            setIsModalVisible(false);
            Message({ type: "success", content: type === 'folder' ? "Folder created successfully" : "File uploaded successfully"});
            
            // Update tree state
            fetchTreeData()
          } else {
            setLoading(false);
            message.error("Cannot add items under a file.");
          }
        } else {
          setLoading(false);
          Message({ type: "error", content: "Failed to create item" });
        }
      } catch (error) {
        setLoading(false);
        Message({ type: "error", content: `Failed to create item: ${error}` });
        return;
      }

    });
  };

  const formatDate = (date) => {
    if (!date || isNaN(new Date(date))) {
      return '';
    }

    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
  
    return `${formattedDate}`;
  };

  /* Funtions related to file or folder deletion */
  const onDeleteNode = (node) => {
    setDeleteModalKey(node.key);
    setdeleteModalNode(node);
    setDeleteModalVisible(true);
  };

  const onDeleteModalCancel = () => {
    setDeleteModalVisible(false);
    form.resetFields();
  };

  const onDeleteModalConfirm = async () => {
    try {
      setLoading(true);

      const data = {
        file_id: deleteModalNode.isFolder ? '' : deleteModalNode.fileId,
        otterz_id: secureLocalStore.getItem('otterz_id'),
        folder_id: deleteModalNode.isFolder ? deleteModalNode.key : '',
        business_id: deleteModalNode.isFolder ? selectedBusiness.business_id : '',
      }
      const response = deleteModalNode.isFolder ? await api.Services.Dashboard.DocumentsVault.deleteFolder(data) : await api.Services.Dashboard.DocumentsVault.deleteFile(data);      
      if (response && response.data) {
        const updatedTreeData = removeNodeByKey(treeData, deleteModalKey);
        setTreeData(updatedTreeData);
        setLoading(false);
        setDeleteModalVisible(false);
        Message({ type: "success", content: "Item deleted successfully" });
      } else {
        setLoading(false);
        Message({ type: "error", content: "Failed to delete item" });
      }
    } catch (error) {
      setLoading(false);
      Message({ type: "error", content: `Failed to delete item: ${error}` });
    }
  };

  const removeNodeByKey = (data, keyToRemove) => {
    return data.reduce((acc, node) => {
      if (node.key === keyToRemove) {
        return acc;
      }
      if (node.children) {
        return [...acc, { ...node, children: removeNodeByKey(node.children, keyToRemove) }];
      }
      return [...acc, node];
    }, []);
  };

  /* Funtions related to file or folder renaming */
  // Function to handle the visibility of the rename modal
  const showRenameModal = (node) => {
    setSelectedNode(node)
    setRenameModalVisible(true);
  };

  const hideRenameModal = () => {
    setRenameModalVisible(false);
    form.resetFields();
  };

  // Function to handle renaming of the folder or file
  const renameNode = (newName, keyToRename, nodes = treeData) => {
    return nodes.map(node => {
      if (node.key === keyToRename) {
        // Update the title of the node
        return { ...node, title: newName };
      } else if (node.children) {
        // If the node has children, recursively call renameNode on its children
        return { ...node, children: renameNode(newName, keyToRename, node.children) };
      } else {
        return node;
      }
    });
  };

  const renameNodeAndUpdateTree = async (newName, keyToRename, nodeToRename) => {
    let data = null
    if (nodeToRename.isFolder) {
      data = {
        folder_id: keyToRename,
        new_folder_name: newName,
        business_id: selectedBusiness.business_id,
      }
    } else {
      data = {
        file_id: keyToRename,
        new_file_name: newName,
        otterz_id: secureLocalStore.getItem('otterz_id'),
      }
    }
    const response = await api.Services.Dashboard.DocumentsVault.renameItem(data);
    if (response && response.data) {
      const updatedTreeData = renameNode(newName, keyToRename);
      setTreeData(updatedTreeData);
      hideRenameModal();
      Message({ type: "success", content: "Item renamed successfully" });
    } else {
      Message({ type: "error", content: "Failed to rename item" });
    }
  };

  const onSelectNode = (selectedKeys) => {
    setSelectedNodeKey(selectedKeys[0]);
  };

  /* Funtions related to file or folder Moving */
  const showMoveModal = (node) => {
    setSelectedNode(node);
    setMoveModalVisible(true);
  };

  const hideMoveModal = () => {
    setMoveModalVisible(false);
    form.resetFields();
  };

  const onMove = async () => {
    if (!selectedFolderKey) {
      message.error('Please select a folder to move.');
      return;
    }

    setLoading(true);

    // Find the selected folder node
    const targetFolderNode = findNodeByKey(treeData, selectedFolderKey);
  
    // If the selected node is a file and the target folder is a folder
    if (!selectedNode.isFolder && targetFolderNode && targetFolderNode.isFolder) {
      // Add the file to the target folder's children
      const response = await api.Services.Dashboard.DocumentsVault.moveFile({ file_id: selectedNode.fileId, new_parent_folder_id: targetFolderNode.key, otterz_id: secureLocalStore.getItem('otterz_id') });

      if (response && response.data) {
        const updatedTreeData = moveFileToFolder(treeData, selectedNode, targetFolderNode);
        setTreeData(updatedTreeData);
        hideMoveModal();
        Message({ type: "success", content: "File moved successfully" });
        setLoading(false);
      } else {
        Message({ type: "error", content: "Failed to move file" });
        setLoading(false);
      }
    } else {
      message.error('Invalid move operation.');
      setLoading(false);
    }
  };

  // Function to move a file to a folder in the tree data
  const moveFileToFolder = (data, fileNode, targetFolderNode) => {
    // Remove the file from its original location
    const updatedTreeData = removeNodeByKey(data, fileNode.key);
    // Add the file to the target folder's children
    return addNodeToParent(updatedTreeData, targetFolderNode.key, fileNode);
  };
  
  // Function to add a node to a parent node in the tree data
  const addNodeToParent = (data, parentKey, newNode) => {
    return data.map(node => {
      if (node.key === parentKey) {
        // Add the new node to the parent node's children
        return { ...node, children: [...(node.children || []), newNode] };
      } else if (node.children) {
        // Recursively add the new node to the parent node in children nodes
        return { ...node, children: addNodeToParent(node.children, parentKey, newNode) };
      } else {
        return node;
      }
    });
  };

  // Function to find a node by key in the tree data
  const findNodeByKey = (data, key) => {
    for (let node of data) {
      if (node.key === key) {
        return node;
      }
      if (node.children) {
        const foundNode = findNodeByKey(node.children, key);
        if (foundNode) return foundNode;
      }
    }
    return null;
  }; 

  /* Search functionality */
  useEffect(() => {
    filterTreeData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, treeData]); // Re-filter tree data whenever search query changes

  const filterTreeData = () => {
    if (searchQuery.trim() === '') {
      setFilteredTreeData(treeData);

      const findPathToNode = (treeData, selectedNodeKey) => {
        // Recursive helper function to find the path to the selected node.
        const findPath = (nodes, targetKey, path = []) => {
          for (let node of nodes) {
            // Add the current node's key to the path.
            const currentPath = [...path, node.key];
            
            // Check if the current node is the selected node.
            if (node.key === targetKey) {
              return currentPath; // Return the path if found.
            }
            
            // If the current node has children, search recursively.
            if (node.children && node.children.length > 0) {
              const result = findPath(node.children, targetKey, currentPath);
              if (result) {
                return result; // Return the path if found in children.
              }
            }
          }
          return null; // Return null if the target node is not found.
        };
      
        // Invoke the recursive helper function with the initial tree data and selected node key.
        return findPath(treeData, selectedNodeKey);
      }

      const flattenTreeKeys = (nodes) => {
        return nodes.reduce((keys, node) => {
          keys.push(node.key);
          if (node.children && node.children.length > 0) {
            keys.push(...flattenTreeKeys(node.children));
          }
          return keys;
        }, []);
      };
    
      const defaultExpandedKeys = flattenTreeKeys(filteredTreeData);
      
      // setExpandedKeys(defaultExpandedKeys)
      setExpandedKeys(selectedNode ? findPathToNode(treeData, selectedNode?.key) : defaultExpandedKeys);
    } else {
      const flattenTree = (nodes) => {
        return nodes.reduce((acc, node) => {
          if (!node.children || node.children.length === 0) {
            acc.push(node);
          } else {
            acc.push(...flattenTree(node.children));
          }
          return acc;
        }, []);
      };
  
      const flatData = flattenTree(treeData);
      const filteredData = flatData.filter(node => node.title.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredTreeData(filteredData);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  const getFileIcon = (title) => {
    if (!title) return (null)
    const extension = title.split('.').pop().toLowerCase();
    switch (extension) {
      case 'png':
        return (
          <FileImageOutlined />
        );
      case 'pdf':
        return (
          <FilePdfOutlined />
        );
      case 'xlsx':
        return (
          <FileExcelOutlined />
        );
      case 'docx':
        return (
          <FileWordOutlined />
        );
      case 'txt':
        return (
          <FileTextOutlined />
        );
      default:
        return <FileOutlined />;
    }
  };

  const renderTreeNode = (nodes) =>
  nodes.map((node) => (
    <TreeNode
      style={{ width: '100% !important', display: 'flex' }}
      key={node.key}
      icon={
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 14L8 10H16L12 14Z" fill="black"/>
        </svg>
      }
      title={
        <>
          <Dropdown overlay={renderMenu(node)} trigger={[`contextMenu`]}>
            <Row className={classNames.fileLink} align="middle" style={{width: '100%', padding: node.isFolder ? '0' : '0 0 10px 0px'}}>
                <Col flex="auto">
                  <Space>
                    {node.isFolder ? (
                      <>
                        <FolderOutlined />
                        <span>{node.title}</span>
                      </>
                    ) : (
                      <a 
                        // href={`https://ucarecdn.com/${node.fileId}`}
                        className={classNames.fileLink}
                        style={{color: 'rgba(0, 0, 0, 0.85)'}}
                        href={`https://ucarecdn.com/${node.fileId}/`} 
                        target="_blank" rel="noopener noreferrer"
                      >
                        {getFileIcon(node.title)}
                        <span style={{marginLeft: '8px'}}>{node.title}</span>
                      </a>
                    )}
                  </Space>
                </Col>
                <Col
                  style={{
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                  >
                  <span style={{ padding: '0 50px' }}>{formatDate(node.dateAdded)}</span>
                  <span style={{ padding: '0 50px 0 0', overflow: 'hidden', textOverflow: 'ellipsis', width: '100px' }} title={node.addedBy}>{node.addedBy}</span>
                  <Dropdown overlay={renderMenu(node)}>
                    <Button icon={<EllipsisOutlined style={{color: 'black', transform: 'rotateZ(90deg)'}}/>} style={{border: 'none', background: 'none'}}/>
                  </Dropdown>
                </Col>
            </Row>
          </Dropdown>
          {node.isFolder && <Divider style={{
            margin: '10px 0',
            height: '0.05px',
            background: '#d6d6d680'
          }} />}
        </>
      }
    >
      {node.children && renderTreeNode(node.children)}
    </TreeNode>
  ));

  const renderTreeNodeMove = (nodes) =>
  nodes.map((node) => (
    <TreeNode
      className={classNames.treeNodeMove}
      // style={{ width: '100% !important', display: 'flex !important', flexWrap: 'wrap'}}
      key={node.key}
      icon={
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 14L8 10H16L12 14Z" fill="black"/>
        </svg>
      }
      title={
        <>
          <Row align="middle" className={classNames.treeNodeMove}>
            <Col style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Space style={{ flexGrow: 1 }}>
                {node.isFolder ? <FolderOutlined /> : getFileIcon(node.title)}
                <span>{node.title}</span>
              </Space>
              {node.isFolder && <input
                type="radio"
                checked={selectedFolderKey === node.key}
                onChange={() => setSelectedFolderKey(node.key)}
                style={{ marginLeft: 'auto' }}
              />}

            </Col>
            {node.isFolder && <Divider style={{
              margin: '10px 0',
              height: '0.05px',
              // position: 'absolute',
              background: '#d6d6d680',
              // left: '-50px',
              // width: '520px'
            }} />}
          </Row>
          {/* <Col
            style={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
            >
            <span style={{ padding: '0 50px' }}>{formatDate(node.dateAdded)}</span>
            <span style={{ padding: '0 50px 0 0' }}>{node.addedBy}</span>
            <Dropdown overlay={renderMenu(node)}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Col> */}
        </>
      }
    >
      {node.children && renderTreeNodeMove(node.children)}
    </TreeNode>
  ));

  // Function to handle the drop in the tree
  const handleDrop = (info) => {
    console.log("Drop info: ", treeData, info);
    // Update the treeData state with the new tree structure
    setTreeData(info.node);
  };

  /* Fetch Data in Tree */
  // Fetch tree Data from backend
  const fetchTreeData = async () => {
    try {
      const response = await api.Services.Dashboard.DocumentsVault.getFolderDetails({ business_id: selectedBusiness?.business_id, parent_folder_id: '' });
  
      if (response.status && response.data) {
        const { nested_folders, nested_files } = response.data;
  
        // Function to recursively build the tree data
        const buildTreeData = (folders) => {
          return folders.map((folder) => {
            const { folder_id, name, nested_folders, nested_files } = folder;
  
            const node = {
              title: name,
              key: folder_id,
              children: [],
              addedBy: userData?.first_name,
              isFolder: true,
              deletable: true, // All nodes other than '0-0' are deletable
              fileId: 'nil', // Assuming fileId is not provided for folders
            };
  
            // Recursively build nested folders
            if (nested_folders && nested_folders.length > 0) {
              node.children = buildTreeData(nested_folders);
            }
  
            // Add nested files as children if any
            if (nested_files && nested_files.length > 0) {
              nested_files.forEach((file) => {
                node.children.push({
                  title: file.name,
                  key: file.file_id,
                  addedBy: userData?.first_name,
                  isFolder: false,
                  deletable: true,
                  fileId: file.file_id,
                });
              });
            }
  
            return node;
          });
        };
  
        // Build the tree data starting from the root
        // const treeData = [{
        //   title: 'Documents',
        //   key: '0-0',
        //   children: [],
        //   isFolder: true,
        //   deletable: false, // Root node should not be deletable
        //   fileId: 'nil',
        // }];
        let treeData = [{}]
  
        // Add nested folders to the root
        treeData = buildTreeData(nested_folders);

        // Add files directly under the root
        if (nested_files && nested_files.length > 0) {
          // nested_files.forEach((file) => {
          //   treeData[0].children.push({
          //     title: file.name,
          //     key: file.file_id,
          //     addedBy: userData?.first_name,
          //     isFolder: false,
          //     deletable: true,
          //     fileId: file.file_id,
          //   });
          // });

          const rootFiles = nested_files.map((file) => {
            const node = {
              title: file.name,
              key: file.file_id,
              addedBy: userData?.first_name,
              isFolder: false,
              deletable: true,
              fileId: file.file_id,
            };
             return node
          });

          if (treeData && treeData.length > 0)
            treeData = [...treeData, ...rootFiles];
          else
            treeData = rootFiles;
        }

        // Set the treeData state
        setTreeData(treeData);
        setDataLoading(false);
      } else {
        // Handle error if the response status is false or data is missing
        Message({ type: "error", content: "Failed to fetch documents data" });
        setDataLoading(false);
      }
    } catch (error) {
      // Handle any API call errors
      Message({ type: "error", content: `Failed to fetch documents data: ${error.message}` });
      setDataLoading(false);
    }
  };  

  React.useEffect(() => {
    setDataLoading(true);
    fetchTreeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const handleExpand = (keys) => {
    setExpandedKeys(keys);
  };

  const createFirstFolder = () => {
    const baseNode = {
      // title: 'Documents',
      key: '0-0',
      children: [],
      isFolder: true,
      deletable: false,
      fileId: 'nil',
    }
    onAddNode('folder', baseNode)
  }

  return (
    <div className={classNames.container}>

      { loading &&
        <div style={{width: '100%', position: 'absolute', top: 0, height: '100vh', gap: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 50}} spin />} />
        </div>
      }
      {/* <section className={classNames.greetingsSection}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ color: "#82898F", margin: 0 }}>Hello, {
           isClient ? userData?.first_name : "Otterz Tax Support"
          }</h1>
        </div>
      </section> */}

      <section className={classNames.innerContent}>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          <Col>
            {/* Search Bar */}
            <Input
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search..."
              style={{ marginBottom: '16px' }}
              allowClear
              suffix={searchQuery && <Button onClick={clearSearch} type="text" icon={<CloseCircleOutlined />} />}
            />
          </Col>
          <Col>
            <Space>
              <Button type="link" style={{color: 'black'}} onClick={() => onAddNode('folder', {
                // title: 'Documents',
                key: '0-0',
                children: [],
                isFolder: true,
                deletable: false,
                fileId: 'nil',
              })}><FolderAddOutlined style={{ marginLeft: '8px', fontSize: '18px', color: '#82898F' }} />Create Folder</Button>
              <Button type="link" style={{color: 'black'}} onClick={() => onAddNode('file', {
                // title: 'Documents',
                key: '0-0',
                children: [],
                isFolder: true,
                deletable: false,
                fileId: 'nil',
              })}><FileAddOutlined style={{ marginLeft: '8px', fontSize: '18px', color: '#82898F' }} />Upload File</Button>

            </Space>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: '16px' }}>
          <Col flex="auto">
            <h3 style={{
              fontSize: '0.9rem',
              fontFamily: '\'Inter\'',
              margin: 0
            }}>Name</h3>
          </Col>
          <Col>
            <h3 style={{
              fontSize: '0.9rem',
              fontFamily: '\'Inter\'',
              margin: 0
            }}>Date Added</h3>
          </Col>
          <Col style={{
            paddingLeft: '55px',
            paddingRight: '95px'
          }}>
            <h3 style={{
              fontSize: '0.9rem',
              fontFamily: '\'Inter\'',
              margin: 0
            }}>Added By</h3>
          </Col>
        </Row>
        <Divider style={{
              margin: '0 0 25px 0',
              height: '0.05px',
              background: '#d6d6d680'
        }} />

        {treeData.length > 0 ? (
          <DirectoryTree
            // showLine
            // defaultExpandedKeys={['0-0']}
            expandedKeys={expandedKeys}
            multiple
            onSelect={onSelectNode}
            selectable={false}
            switcherIcon={<CaretDownFilled />}
            defaultExpandAll={false}
            onExpand={handleExpand}
            // draggable
            onDrop={handleDrop}
            // onRightClick={(e) => {
            // }}
            // selectedKeys={[selectedNodeKey]}
          >
            {renderTreeNode(filteredTreeData)}
          </DirectoryTree>
        ) : (
          <div style={{width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={createFirstFolder}>So empty, click here to create a new folder<FolderAddOutlined style={{ marginLeft: '8px', fontSize: '24px', color: '#82898F' }} /></div>
        )}

        {
          dataLoading && 
          <div style={{width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Spin size="large" />
          </div>
        }

        {/* Render the modal for Deleting a folder or a file */}
        <Modal
          title={deleteModalNode?.isFolder ? `Delete Folder - ${selectedNode?.title}` : `Delete File - ${selectedNode?.title}`}
          visible={deleteModalVisible}
          onCancel={onDeleteModalCancel}
          footer={[
            <CTAButton key="submit" type="danger" onClick={() => {onDeleteModalConfirm(deleteModalNode)}} loading={loading}>
              Delete
            </CTAButton>,
          ]}
        >
          <p>This item will be deleted permanently</p>
        </Modal>

        {/* Render the modal for renaming a folder or a file */}
        <Modal
          title={selectedNode?.isFolder ? `Rename Folder - ${selectedNode?.title}` : `Rename File - ${selectedNode?.title}`}
          visible={renameModalVisible}
          onCancel={hideRenameModal}
          footer={[
            <CTAButton key="submit" type="primary" onClick={() => renameNodeAndUpdateTree(form.getFieldValue('name'), selectedNode?.key, selectedNode)} loading={loading}>
              Rename
            </CTAButton>,
          ]}
        >
          <Form form={form} layout="vertical">
            <FormItem name="name" label={selectedNode?.isFolder ? "Folder Name" : "File Name"} initialValue={''} rules={[{ required: true, message: 'Please enter a name' }]}>
              <Input />
            </FormItem>
          </Form>
        </Modal>

        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileInputChange} />


        {/* Render the modal for Creating a folder or a file */}
        <Modal
          title={nodeType === 'file' ? "Upload File" : "Create New Folder"}
          visible={isModalVisible}
          onCancel={onModalCancel}
          footer={[
            // <Button key="back" onClick={onModalCancel}>
            //   Cancel
            // </Button>,
            <CTAButton key="submit" type="primary" onClick={() => onCreateNode(nodeType, selectedNode)} loading={loading}>
              {nodeType === 'file' ? "Upload" : "Create"}
            </CTAButton>,
          ]}
        >
          <Form form={form} layout="vertical">
            <FormItem name="type" initialValue={nodeType} hidden>
              <Input />
            </FormItem>
            <FormItem name="name" label={nodeType === 'file' ? "File Name" : "Folder Name"} >
              <Input />
            </FormItem>
            {nodeType === 'file' && (
              <FormItem name="file" label="File" rules={[{ required: true, message: 'Please select a file' }]}>
                <Upload
                  beforeUpload={(file) => {
                    setFileList([file]);
                    return false;
                  }}
                  fileList={fileList}
                >
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </FormItem>
            )}
          </Form>
        </Modal>

        {/* Render the modal for Moving a folder or a file */}
        <Modal
          // title="Move Item"
          title={selectedNode?.isFolder ? "Move Folder" : "Move File"}
          visible={moveModalVisible}
          onCancel={hideMoveModal}
          footer={[
            <CTAButton key="submit" type="primary" onClick={onMove} loading={loading}>
              Move
            </CTAButton>,
          ]}
        >
          {/* <p>Select the folder to move the item to:</p> */}
          {selectedNode?.isFolder ? <p style={{margin: '0 0 1rem 0'}}>Select the folder to move the folder to:</p> : <p style={{margin: '0 0 1rem 0'}}>Select the folder to move the file to:</p>}
          <Tree
            defaultExpandAll
            switcherIcon={<CaretDownFilled />}
            selectable={false}
          >
            {renderTreeNodeMove(treeData)}
          </Tree>
        </Modal>
      </section>
    </div>
  );
};

export default DocumentVaultComponent;
