import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useMutation } from "react-query";
import PlansAndPreferences from '../../Settings/PlansAndPreferences/PlansAndPreferences';
import api from '../../API';
import { CTAButton, Message } from '../../common';
import { useBusiness } from '../../../store/StoreProvider';
import { useNavigate } from 'react-router-dom';

const SubscriptionOnboard = () => {
    const navigate = useNavigate();
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const { selectedBusiness, handleSelectBusiness } = useBusiness();
    const [, setBusinesses] = useState([]);
    const [, setSelectedSubscriptionPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Button disabled state
    const [isTimerActive, setIsTimerActive] = useState(true);

    const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
        onSuccess: (data) => {
          if (!data.status) {
            Message({ type: "error", content: data.errors[0].message });
          }
          if (data && data.data) {
            console.log("Businesses fetched successfully", data);
            setBusinesses(data.data);
            handleSelectBusiness(data.data[0]);
          }
        },
        onError: (error) => {
          console.error("Signup error: ", error);
          Message({ type: "error", content: error.message });
        }
    });

    const updateOnboardingStatus = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data), {
        onSuccess: () => {
          // Message({ type: "success", content: "Subscription Completed" });
          navigate("/app");
        },
        onError: (error) => {
          console.error("Error updating onboarding status: ", error);
          Message({ type: "error", content: error.message });
        },
    });

    const fetchSubscription = async () => {
      const response = await api.Services.Dashboard.Subscription.getSubscribedPlanDetails(selectedBusiness.business_id)

      console.log("Subscribed Plan", response);
      if (response && response.status && response.data.subscription_type) {
        if (response && response.status && response.data.status === 'complete') {
          setSelectedSubscriptionPlan(response);
          setIsButtonDisabled(false); // Disable button if subscription plan is not empty
          setIsLoading(false);
          setIsTimerActive(false);
        } else {
          setIsLoading(false);
        }
      } else {
        const response = await api.Services.Dashboard.Subscription.getSubscribedPlanDetails({business_id: selectedBusiness.business_id, email: secureLocalStore.getItem("email")})
        if (response && response.status && response.data.subscription_type) {
          console.log("Free Subscribed Plan Check", response);
          updateOnboardingStatus.mutate({ otterz_id: secureLocalStore.getItem('otterz_id'), status: "ONBOARDED" });
          // navigate("/app")
        } else {
          setSelectedSubscriptionPlan(null);
          setIsButtonDisabled(true); // Enable button if subscription plan is empty
          setIsLoading(false);
        }
      }
    }

    useEffect(() => {
        setIsLoading(true);
        viewAllBusiness.mutateAsync(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const timer = setInterval(() => {
        if (selectedBusiness.business_id && isTimerActive) {
          fetchSubscription();
        }
      }, 2000); // Fetch subscription status every 5 seconds

      return () => clearInterval(timer); // Clear the interval when component unmounts
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBusiness, isTimerActive]);

    return (
        <div style={{width: '100%', background: 'white', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {isLoading ? (
                <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spin size="large" />
                </div>
            ) : (
              <div style={{width: '75%', textAlign: 'center'}}>
                  <PlansAndPreferences />
                  <CTAButton 
                    style={{width: '50%', alignItems: 'center'}} 
                    disabled={isButtonDisabled} // Set button disabled state
                    loading={updateOnboardingStatus.isLoading}
                    onClick={() => {
                      updateOnboardingStatus.mutate({ otterz_id: secureLocalStore.getItem('otterz_id'), status: "ONBOARDED" });
                    }}>
                    Continue
                  </CTAButton>
              </div>
            )}
        </div>
    );
};

export default SubscriptionOnboard;
