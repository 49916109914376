import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import ScrollToTop from "../hooks/scrollToTop";
import AppState from "../context/AppState";
import AuthorizedRoute from "./AuthorizedRoute";
import OnboardingRoute from "./OnboardingRoute";

// import App from "../AppRoot";
import Focused from "../App/Layouts/Focused";
// import VerifyEmail from "../App/Auth/VerifyEmail/VerifyEmail";
import AccountType from "../App/Auth/AccountType/AccountType";
import IndividualAccount from "../App/Auth/IndividualAccount/IndividualAccount";
import BusinessAccount from "../App/Auth/BusinessAccount/BusinessAccount";
import BAOfficer from "../App/Auth/BusinessAccount/BAOfficer";
import BABeneficialOwner from "../App/Auth/BusinessAccount/BABeneficialOwner";
import Success from "../App/Auth/Signup/Success/Success";
import UploadDocuments from "../App/Auth/Signup/UploadDocuments/UploadDocuments";
import Login from "../App/Auth/Login";
// import BusinessBanking from "../App/BusinessBanking/BusinessBanking";
import MakePayments from "../App/BusinessBanking/MakePayments/MakePayments";
import { Signup, VerifyPhone } from "../App/Auth/Signup";
import Overview from "../App/BusinessBanking/Overview/Overview";
import { 
  // EnterOTP, 
  OTPMessage 
} from "../App/Auth/ForgotPassword";
import ManageChecks from "../App/BusinessBanking/ManageChecks/ManageChecks";
import BillPayments from "../App/BusinessBanking/MakePayments/BillPayments/BillPayments";
import Payees from "../App/BusinessBanking/MakePayments/Payees/Payees";
import ScheduledPayments from "../App/BusinessBanking/MakePayments/ScheduledPayments/ScheduledPayments";
import ManageCards from "../App/BusinessBanking/ManageCards/ManageCards";
import RecurringPayments from "../App/BusinessBanking/MakePayments/RecurringPayments/RecurringPayments";
import MainLayout from "../App/MainLayout/MainLayout";
import Support from "../App/Support/Support";
import StatementsAndTransactions from "../App/BusinessBanking/StatementsAndTransactions/StatementsAndTransactions";
import Settings from "../App/Settings/Settings";
import SettingsHome from "../App/Settings/SettingsHome/SettingsHome";
import ATMLocator from "../App/Support/ATMLocator/ATMLocator";
import SupportHome from "../App/Support/SupportHome/SupportHome";
import RewardsOverview from "../App/Rewards/RewardsOverview/RewardsOverview";
import Rewards from "../App/Rewards/Rewards";
import CashbackHistory from "../App/Rewards/CashbackHistory/CashbackHistory";
import Receivables from "../App/Receivables/Receivables";
import ProductsServices from "../App/Receivables/Invoicing/ProductsServices/ProductsServices";
import TaxDatabase from "../App/Receivables/Invoicing/TaxDatabase/TaxDatabase";
import DraftEstimatesInvoices from "../App/Receivables/Invoicing/DraftEstimatesInvoices/DraftEstimatesInvoices";
import AllInvoices from "../App/Receivables/Invoicing/AllInvoices/AllInvoices";
import RecurringInvoices from "../App/Receivables/Invoicing/RecurringInvoices/RecurringInvoices";
import ViewInvoice from "../App/Receivables/Invoicing/ViewInvoice/ViewInvoice";
import Invoices from "../App/Receivables/Invoicing/Invoices/Invoices";
import GettingStarted from "../App/Receivables/Invoicing/GettingStarted/GettingStarted";
import AllEstimates from "../App/Receivables/Invoicing/AllEstimates/AllEstimates";
import ViewEstimate from "../App/Receivables/Invoicing/ViewEstimate/ViewEstimate";
import Customers from "../App/Receivables/Invoicing/Customers/Customers";
import TemplateSettings from "../App/Receivables/Invoicing/TemplateSettings/TemplateSettings";
import PendingCashback from "../App/Rewards/PendingCashback/PendingCashback";
import PaymentAcceptanceOverview from "../App/Receivables/PaymentAcceptance/PaymentAcceptanceOverview/PaymentAcceptanceOverview";
import Transactions from "../App/Receivables/PaymentAcceptance/Transactions/Transactions";
import Reports from "../App/Receivables/PaymentAcceptance/Reports/Reports";
import CustomersPaymentAcceptance from "../App/Receivables/PaymentAcceptance/Customers/Customers";
import RecurringSchedules from "../App/Receivables/PaymentAcceptance/RecurringSchedules/RecurringSchedules";
import UserManagement from "../App/Settings/UserManagement/UserManagement";
import PaymentAcceptanceOnboarding from "../App/Receivables/PaymentAcceptance/Onboarding/Onboarding";
import GeneralInformation from "../App/Settings/GeneralInformation/GeneralInformation";
import Payment from "../App/Payment/Payment";
import Summary from "../App/Payment/Summary/Summary";
import PlansAndPreferences from "../App/Settings/PlansAndPreferences/PlansAndPreferences";
// import ChildUserSetPassword from "../App/Auth/ChildUserOnboarding/SetNewPassword";
import DashboardLanding from "../App/DashboardLanding/DashboardLanding";
import ReportsOverview from "../App/Reports/ReportsOverview/ReportsOverview";
import ReportsMain from "../App/Reports/Reports";
// import Logout from "../App/Auth/Logout";
import Forbidden from "../App/Layouts/Forbidden";
import SessionTimeout from "../App/Layouts/SessionTimeout";
import ViewRecurringInvoice from "../App/Receivables/Invoicing/ViewRecurringInvoice/ViewRecurringInvoice";
import Mobile from "../App/Mobile/Mobile";
// import VirtualTerminal from "../App/Mobile/VirtualTerminal/VirtualTerminal";

import Payables from "../App/Payables/Payables"
import PayablesHome from "../App/Payables/AccountPayables/PayablesHome/PayablesHome"
import AccountSync from  "../App/Payables/AccountPayables/PayablesHome/AccountSync/AccountSync"
import ApprovedInvoices from "../App/Payables/AccountPayables/ApprovedInvoices/ApprovedInvoices"
import PendingInvoices from "../App/Payables/AccountPayables/PendingInvoices/PendingInvoices"
import VendorDatabase from "../App/Payables/AccountPayables/VendorDatabase/VendorDatabase";
import UploadInvoice from "../App/Payables/AccountPayables/UploadInvoice/UploadInvoice";
import BusinessAccountDocs from "../App/Auth/BusinessAccount/BusinessAccountDocs";
import BABeneficialOwnerDocs from "../App/Auth/BusinessAccount/BABeneficialOwnerDocs";
import AccounActivityDetails from "../App/Auth/BusinessAccount/AccountActivityDetails";
import BusinessProfileSuccess from "../App/Auth/BusinessAccount/BusinessProfileSuccess";

// import Disclosure from "../App/Disclosure/Disclosure";
// import DisclosureOverview from "../App/Disclosure/DisclorePage/DisclosureOverview";
// import SpecificDisclosure from "../App/Disclosure/SpecificDisclosure/SpecificDisclosure";

import useIsMobile from "../hooks/useIsMobile";
import { ROUTES } from "../App/constants";
import DownloadApplication from "../App/common/DownloadApplication/DownloadApplication";
// import OtterzAI from "../App/OtterzAI/OtterzAI";
// import Chatbot from "../App/OtterzAI/Chatbot/Chatbot";
import BusinessOperationDetails from "../App/Auth/BusinessAccount/BussinessOperationDetails";
import BOInstructions from "../App/Auth/BusinessAccount/BOInstructions";
// import SocureDocV from "../App/Auth/SocureDocV/SocureDocV";
import Onboard from "../App/Layouts/Onboard";
import { DashboardOnboard } from "../App/Auth/DashboardOnboard";
import UploadBusinessDocs from "../App/Auth/BusinessAccount/UploadBusinessDocs";
import OnboardingDocs from "../App/Auth/BusinessAccount/OnboardingDocs";
import CreateProfile from "../App/Auth/CompleteProfile";
import UsePreference from "../App/Auth/UsePreference";
import SetupOtterzCopilot from "../App/Auth/OtterzCoPilot";
import OwnersInvite from "../App/Auth/OwnersInvite";
import LinkBusiness from "../App/Auth/LinkBusiness";
import EmailVerification from "../App/Auth/Signup/EmailVerification/EmailVerification";
import SetupPaymentAcceptance from "../App/Auth/PaymentAcceptanceSchedule";
import BusinessBanking from "../App/BusinessBanking/BusinessBanking";
import Team from "../App/Settings/Team/Team";
import Roles from "../App/Settings/Roles/Roles";
import PasswordReset from "../App/Auth/Signup/PasswordReset/PasswordReset";
import Profile from "../App/Settings/Profile/Profile";
import Integrations from "../App/Settings/Integrations/Integrations";
import QuickBooksLink from "../App/Auth/QuickBooksLink";
import AddAccounts from "../App/Auth/AddAccounts";
import AppOnboarding from "../AppRoot";
import LandingPage from "../App/DashboardLanding/LandingPage/LandingPage";
import CashBalance from "../App/CashBalance/CashBalance";
import { CashBalanceComponent } from "../App/CashBalance/LandingPage";
import DocumentVault from "../App/DocumentVault/DocumentVault";
// import DocumentVaultComponent from "../App/DocumentVault/DocumentVault/DocumentVaultComponent";
import CardBalance from "../App/CardBalance/CardBalance";
import { CardBalanceComponent } from "../App/CardBalance/LandingPage";
import OtterzCoPilot from "../App/OtterzCo-Pilot/OtterzCoPilot";
import Insights from "../App/OtterzCo-Pilot/Insights/Insights";
import SubscriptionOnboard from "../App/Auth/SubscriptionOnboard";
// import CashFlowAndPL from "../App/OtterzCo-Pilot/Insights/CashFlowAndPL";
import BookKeeperChat from "../App/OtterzAI/BookkeeperSide";
import PaymentAcceptance from "../App/PaymentAcceptance/PaymentAcceptance";
import PaymentAcceptanceComponent from "../App/PaymentAcceptance/PaymentAcceptanceComponent/PaymentAcceptanceComponent";
// import NyraAI from "../App/OtterzAI/NyraAI/NyraAI";

import BookkeeperLogin from "../App/Auth/Bookkeeper/Login";
import BookkeeperSignup from "../App/Auth/Bookkeeper/Signup/Signup";
import Dashboard from "../App/BookkeeperDashboard/Layouts/Dashboard";
import BookkeeperLandingPage from "../App/BookkeeperDashboard/LandingPage/LandingPage";
import CustomerData from "../App/BookkeeperDashboard/CustomerData/CustomerData";
import BookkeeperAppRoot from "../BookkeeperAppRoot";
import CashFlow from "../App/OtterzCo-Pilot/Insights/CashFlow";
import ProfitAndLoss from "../App/OtterzCo-Pilot/Insights/ProfitAndLoss";
import ListBusiness from "../App/common/Sidebar/AddBusiness/ListBusiness";
import BusinessLinkQuickbooks from "../App/common/Sidebar/AddBusiness/BusinessLinkQuickbooks";
import UpdateAccounts from "../App/common/Sidebar/AddBusiness/AddAccounts";
import TaxOnboarding from "../App/DocumentVault/TaxOnboarding/TaxOnboarding";
import ChatPanel from "../App/OtterzAI/Chats/ChatPanel";
import BusinessSetup from "../App/OtterzCo-Pilot/BusinessSetup/BusinessSetup";
import Queries from "../App/ReviewTransactions/Queries";
// import WelcomeScreen from "../App/Payables/WelcomeScreen";


function AppRoutes() {

  const RedirectToOtterz = () => {
    useEffect(() => {
      window.location.href = "https://www.otterz.co/disclosures";
    }, []);

    return null;
  };

  const isMobile = useIsMobile()

  return (
    <Router>
      <AppState>
        <ScrollToTop />
        <Routes>
          <Route path="/session-timeout" element={<SessionTimeout />} />
          <Route path="/forbidden" element={<Forbidden />} />
          {/* <Route path="document-verification/:id" element={<SocureDocV />} /> */}
          <Route path={ROUTES.DISCLOSURE} element={<RedirectToOtterz />}>
            {/* <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<DisclosureOverview />} />
            <Route path=":param" element={<SpecificDisclosure />} /> */}
          </Route>

          <Route path="bookkeeper" element={<BookkeeperAppRoot />}>
            <Route path="login" element={
                  <BookkeeperLogin />
              } />
            <Route path="signup" element={
                  <BookkeeperSignup />
              } />

            <Route path="app" element={<Dashboard />}>
              {/* Add routes for accountant dashboard here */}
              <Route index element={
                  <BookkeeperLandingPage />
              } />
              <Route path="customer/:id/*" element={
                  <CustomerData />
              } />
            </Route>
          </Route>

          {/* Application Onboarding routes */}
          <Route element={<AppOnboarding />}>
            {/* public routes */}
            <Route path="login" element={
                <Login />
            } />
            <Route path="/" element={
                <Login />
            } />
            <Route path="signup" element={
              <Signup />
              } />

            <Route 
            path="password-reset" element={
            <PasswordReset/>} />
            <Route 
            path="update-business-list" element={
            <ListBusiness/>} />
            <Route 
            path="update-business-link" element={
            <BusinessLinkQuickbooks/>} />
            <Route 
            path="update-plaid-business" element={
            <UpdateAccounts/>} />

            <Route element={<Focused />}>  
              <Route path="email-verify" element={
                <EmailVerification/>} />
              <Route 
                path="onboarding/create-profile" 
                element={<CreateProfile />}/>
              <Route 
                path="onboarding/use-preference" element={
                <UsePreference />} />
              <Route 
                path="onboarding/setup-copilot" element={
                <SetupOtterzCopilot />} />
              <Route 
                path="onboarding/invite-owners" element={
                <OwnersInvite />} />
              <Route 
                path="onboarding/link-bank" element={
                <AddAccounts />} />
              <Route 
                path="onboarding/link-business-bank" element={
                <LinkBusiness />} />
              <Route path="onboarding/link-quickbooks" element={
                <QuickBooksLink />} />
              <Route 
                path="onboarding/payment-acceptance-schedule" element={
                <SetupPaymentAcceptance />} />
              <Route 
                path="onboarding/subscription" element={
                <SubscriptionOnboard />} />
            </Route>  

            <Route 
              path="forgot-password" element={
              <OTPMessage />} />
            
            <Route path="verify-phone" element={<VerifyPhone />} />
            
            {/* <Route path="/" element={<Focused />}>
              <Route index element={<Navigate to="/login" replace />} />
              <Route path="logout" element={<Logout />} />
              <Route path="email-otp" element={<EnterOTP />} />
              <Route path="verify-email" element={<VerifyEmail />} />
              <Route
                path="child-user-set-password"
                element={<ChildUserSetPassword />}
              />
            </Route> */}

            {/* Onboarding routes */}
            <Route path="onboarding" element={<Onboard />}>
              <Route
                path="subscription"
                element={
                  // <OnboardingRoute>
                  // </OnboardingRoute>
                    <PlansAndPreferences />
                }
              />
              <Route
                path="dashboard"
                element={
                  <OnboardingRoute>
                    <DashboardOnboard />
                  </OnboardingRoute>
                }
              />
              <Route
                path="account-type"
                element={
                  <OnboardingRoute>
                    <AccountType />
                  </OnboardingRoute>
                }
              />
              <Route
                path="individual-application"
                element={
                  <OnboardingRoute>
                    <IndividualAccount />
                  </OnboardingRoute>
                }
              />
              <Route
                path="officer-details"
                element={
                  <OnboardingRoute>
                    <BAOfficer />
                  </OnboardingRoute>
                }
              />
              <Route
                path="beneficial-owner-details"
                element={
                  <OnboardingRoute>
                    <BABeneficialOwner />
                  </OnboardingRoute>
                }
              />
              <Route
                path="beneficial-owner-documents"
                element={
                  <OnboardingRoute>
                    <BABeneficialOwnerDocs />
                  </OnboardingRoute>
                }
              />
              <Route
                path="business-application"
                element={
                  <OnboardingRoute>
                    <BusinessAccount />
                  </OnboardingRoute>
                }
              />
              <Route
                path="onboard-document-upload"
                element={
                  <OnboardingRoute>
                    <OnboardingDocs />
                  </OnboardingRoute>
                }
              />
              <Route
                path="business-operation-details"
                element={
                  <OnboardingRoute>
                    <BusinessOperationDetails />
                  </OnboardingRoute>
                }
              />
              <Route
                path="account-activity-details"
                element={
                  <OnboardingRoute>
                    <AccounActivityDetails />
                  </OnboardingRoute>
                }
              />
              <Route
                path="business-profile-success"
                element={
                  <OnboardingRoute>
                    <BusinessProfileSuccess />
                  </OnboardingRoute>
                }
              />
              <Route
                path="beneficial-owner-instructions"
                element={
                  <OnboardingRoute>
                    <BOInstructions />
                  </OnboardingRoute>
                }
              />
              <Route
                path="business-application-documents"
                element={
                  <OnboardingRoute>
                    <BusinessAccountDocs />
                  </OnboardingRoute>
                }
              />
              <Route
                path="upload-documents"
                element={
                  <OnboardingRoute>
                    <UploadDocuments />
                  </OnboardingRoute>
                }
              />
              <Route
                path="application/:status"
                element={
                  <OnboardingRoute>
                    <Success />
                  </OnboardingRoute>
                }
              />
            </Route>
            
          {/* Application routes */}
          {/* Need to also cover these with a route middleware like AppOnboarding above */}
          {isMobile ? (
            <Route path="app" element={<DownloadApplication /> }/>
          ) : (
            <>
              <Route path="app" element={<MainLayout />}>

              <Route element={<DashboardLanding />}>
                <Route
                  index
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <LandingPage />
                  }
                />
              </Route>

              <Route element={<CashBalance />}>
                <Route
                  path="cash-balance"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <CashBalanceComponent />
                  }
                />
              </Route>

              <Route element={<CardBalance />}>
                <Route
                  path="card-balance"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <CardBalanceComponent />
                  }
                />
              </Route>

              <Route
                  path="queries"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <Queries />
                  }
                />

              <Route element={<DocumentVault />}>
                <Route
                  path="documents-vault"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <TaxOnboarding />
                  }
                />
              </Route>

              <Route path="otterz-copilot" element={<OtterzCoPilot />}>
                <Route
                  path="performance"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <Insights />
                  }
                />

                <Route
                  path="cash-flow-and-pl"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <BusinessSetup />
                  }
                />
                <Route
                  path="cash-flow"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <CashFlow />
                  }
                />
                <Route
                  path="profit-and-loss"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <ProfitAndLoss />
                  }
                />
              </Route>
              
              <Route element={<PaymentAcceptance />}>
                <Route
                  path="accept-payments"
                  element={
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                      <PaymentAcceptanceComponent />
                  }
                />
              </Route>

              <Route path="banking" element={<BusinessBanking />}>
                <Route
                  path="overview"
                  element={
                      <Overview />
                    // <AuthorizedRoute>
                    // </AuthorizedRoute>
                  }
                />
                <Route
                  path="make-payments"
                  element={
                    <AuthorizedRoute>
                      <MakePayments />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="manage-checks"
                  element={
                    <AuthorizedRoute>
                      <ManageChecks />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="manage-cards"
                  element={
                    <AuthorizedRoute>
                      <ManageCards />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="statements-transactions"
                  element={
                    <AuthorizedRoute>
                      <StatementsAndTransactions />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="bill-payments"
                  element={
                    <AuthorizedRoute>
                      <BillPayments />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="recurring-payments"
                  element={
                    <AuthorizedRoute>
                      <RecurringPayments />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="scheduled-payments"
                  element={
                    <AuthorizedRoute>
                      <ScheduledPayments />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="payees"
                  element={
                    <AuthorizedRoute>
                      <Payees />
                    </AuthorizedRoute>
                  }
                />
              </Route>
              <Route path="receivables" element={<Receivables />}>
                <Route
                  path="products-services"
                  element={
                    <OnboardingRoute>
                      <ProductsServices />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="customers"
                  element={
                    <OnboardingRoute>
                      <Customers />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="tax-database"
                  element={
                    <OnboardingRoute>
                      <TaxDatabase />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="all-invoices"
                  element={
                    <OnboardingRoute>
                      <AllInvoices />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="recurring-invoices"
                  element={
                    <OnboardingRoute>
                      <RecurringInvoices />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="recurring-invoices/:id"
                  element={
                    <OnboardingRoute>
                      <ViewRecurringInvoice />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="invoices/:id"
                  element={
                    <OnboardingRoute>
                      <ViewInvoice />
                    </OnboardingRoute>
                  }
                />
                <Route
                  index
                  path="invoices"
                  element={
                    <OnboardingRoute>
                      <Invoices />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="all-estimates"
                  element={
                    <OnboardingRoute>
                      <AllEstimates />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="invoices-getting-started"
                  element={
                    <OnboardingRoute>
                      <GettingStarted />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="payment-acceptance-onboarding"
                  element={
                    <OnboardingRoute>
                      <PaymentAcceptanceOnboarding />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="estimates/:id"
                  element={
                    <OnboardingRoute>
                      <ViewEstimate />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="template-settings"
                  element={
                    <OnboardingRoute>
                      <TemplateSettings />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="draft-estimates-invoices"
                  element={
                    <OnboardingRoute>
                      <DraftEstimatesInvoices />
                    </OnboardingRoute>
                  }
                />
                <Route
                  index
                  path="payment-acceptance"
                  element={
                    <OnboardingRoute>
                      <PaymentAcceptanceOverview />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="customers-payment-acceptance"
                  element={
                    <OnboardingRoute>
                      <CustomersPaymentAcceptance />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="payables"
                  element={
                    <OnboardingRoute>
                      <PaymentAcceptanceOverview />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="reports"
                  element={
                    <OnboardingRoute>
                      <Reports />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="transactions"
                  element={
                    <OnboardingRoute>
                      <Transactions />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="recurring-schedules"
                  element={
                    <OnboardingRoute>
                      <RecurringSchedules />
                    </OnboardingRoute>
                  }
                />
              </Route>
              <Route path="payables" element={<Payables />}>
                {/* <Route
                  index
                  path="account_payable"
                  element={
                    <AuthorizedRoute>
                      <WelcomeScreen />
                    </AuthorizedRoute>
                  }
                /> */}
                <Route
                  index
                  path="account_payable"
                  element={
                    <OnboardingRoute>
                      <PayablesHome />
                    </OnboardingRoute>
                  }
                />
                <Route
                  index
                  path="accounts-sync"
                  element={
                    <OnboardingRoute>
                      <AccountSync />
                    </OnboardingRoute>
                  }
                />
                <Route
                  index
                  path="pending-invoices"
                  element={
                    <OnboardingRoute>
                      <PendingInvoices />
                    </OnboardingRoute>
                  }
                />
                <Route
                  index
                  path="approved-invoices"
                  element={
                    <OnboardingRoute>
                      <ApprovedInvoices />
                    </OnboardingRoute>
                  }
                />
                <Route
                  index
                  path="vendor-database"
                  element={
                    <OnboardingRoute>
                      <VendorDatabase />
                    </OnboardingRoute>
                  }
                />
                <Route
                  index
                  path="upload-invoice"
                  element={
                    <OnboardingRoute>
                      <UploadInvoice />
                    </OnboardingRoute>
                  }
                />
                <Route
                  index
                  path="update-invoice/:id"
                  element={
                    <OnboardingRoute>
                      <UploadInvoice />
                    </OnboardingRoute>
                  }
                />
              </Route>
              <Route path="rewards" element={<Rewards />}>
                <Route
                  path="overview"
                  index
                  element={
                    <AuthorizedRoute>
                      <RewardsOverview />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="cashback-history"
                  element={
                    <AuthorizedRoute>
                      <CashbackHistory />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="pending-cashback"
                  element={
                    <AuthorizedRoute>
                      <PendingCashback />
                    </AuthorizedRoute>
                  }
                />
              </Route>

              {/* <Route path="otterz-copilot" element={<OtterzAI />}>
              </Route> */}
                {/* <Route
                  path="ask-accountant"
                  element={
                    // <OnboardingRoute>
                    // </OnboardingRoute>
                      <Chatbot />
                  }
                />

                <Route
                  path="ask-nyra"
                  element={
                    // <OnboardingRoute>
                    // </OnboardingRoute>
                      <NyraAI />
                  }
                /> */}

                <Route
                  path="chats"
                  element={
                    // <OnboardingRoute>
                    // </OnboardingRoute>
                      <ChatPanel />
                  }
                />

              <Route path="settings" element={<Settings />}>
                <Route
                  index
                  element={
                    <OnboardingRoute>
                      <SettingsHome />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="user-management"
                  element={
                    <OnboardingRoute>
                      <UserManagement />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="team"
                  element={
                      <Team />
                    // <OnboardingRoute>
                    // </OnboardingRoute>
                  }
                />
                <Route
                  path="roles"
                  element={
                      <Roles />
                    // <OnboardingRoute>
                    // </OnboardingRoute>
                  }
                />
                <Route
                  path="profile"
                  element={
                      <Profile isClient={true}/>
                    // <OnboardingRoute>
                    // </OnboardingRoute>
                  }
                />
                <Route
                  path="integrations"
                  element={
                      <Integrations />
                    // <OnboardingRoute>
                    // </OnboardingRoute>
                  }
                />
                <Route
                  path="general-information"
                  element={
                    <OnboardingRoute>
                      <GeneralInformation />
                    </OnboardingRoute>
                  }
                />
                <Route
                  path="upload-business-documents"
                  element={
                    <OnboardingRoute>
                      <UploadBusinessDocs />
                    </OnboardingRoute>
                }
                />
                <Route
                  path="plans-preferences"
                  element={
                    // <OnboardingRoute>
                    // </OnboardingRoute>
                      <PlansAndPreferences />
                  }
                />
              </Route>

              <Route path="support" element={<Support />}>
                <Route
                  path="home"
                  index
                  element={
                    <AuthorizedRoute>
                      <SupportHome />
                    </AuthorizedRoute>
                  }
                />
                <Route
                  path="atm-locator"
                  element={
                    <AuthorizedRoute>
                      <ATMLocator />
                    </AuthorizedRoute>
                  }
                />
              </Route>
              <Route path="reports" element={<ReportsMain />}>
                <Route
                  path="overview"
                  index
                  element={
                    <OnboardingRoute>
                      <ReportsOverview />
                    </OnboardingRoute>
                  }
                />
              </Route>
              </Route>
              <Route path="mobile" element={<Mobile />}>
                {/* <Route path="virtual-terminal" element={<VirtualTerminal />} /> */}
              </Route>
            </>
          )}
          </Route>

          <Route path="/chat/bookkeeper" element={
              <BookKeeperChat />
              } />

          <Route path="payment" element={<Payment />}>
            <Route path="summary" element={<Summary />} />
          </Route>
        </Routes>
      </AppState>
    </Router>
  );
}

export default AppRoutes;
