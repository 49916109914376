import React, { useState, useRef, useEffect } from 'react';
import { /*Button,*/ Input, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import classNames from './OtterzCoPilot.module.scss';
import { CTAButton, Message } from '../../common';
import gsap from 'gsap';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from '../../constants';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';
import api from '../../API';
import { businessDataCollection } from '../../API/Services/Onboarding';

const { Title, Text } = Typography;

const SetupOtterzCopilot = () => {
  const navigate = useNavigate();
  const secureLocalStore = window.localStorage || window.sessionStorage;

  const [businesses, setBusinesses] = useState([{ id: uuidv4(), name: '', freeText: '' }]);
  const [loading, setLoading] = useState(false);
  const businessRefs = useRef([]);

  /* Mutation definition for API calls */
  // Create User mutation - Here we are updating the user preferences
  const addBusiness = useMutation(data => api.Services.Onboarding.addBusiness(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses added successful", data);
        
        setLoading(true)
        businessDataCollection({
          business_id: data.data.business_id,
          business_name: data.data.name,
          business_description: businesses[0].freeText
        }).then((response) => {
          console.log("Business data collection response: ", response);
          setLoading(false)
          
          const status = "CONNECT_PLAID";
          updateOnboardingStatus.mutate({otterz_id: secureLocalStore.getItem('otterz_id'), status: status});
        }).catch((error) => {
          console.error("Business data collection error: ", error);
          setLoading(false)
          Message({ type: "error", content: error.message });
        });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // update status mutation
  const updateOnboardingStatus = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return; // Exit early if there's an error
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
        Message({ type: "success", content: "Business added successfully" });

        // Determine the next route based on the preferences
        // Navigate or update state as needed
        navigate(ROUTES.ONBOARDING.LINK_BANK)
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // View user's owner status
  const viewUser = useMutation(data => api.Services.Onboarding.viewUserByID(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("User viewed", data);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // View all businesses of user
  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses fetched successfully", data);
        const businessList = data.data.map(b => b.name)
        if (businessList.length > 0) {
          setBusinesses(businessList.map(b => ({ id: uuidv4(), name: b })))
        }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  useEffect(() => {
    viewAllBusiness.mutate(secureLocalStore.getItem('otterz_id'));
    viewUser.mutate({ otterz_id: secureLocalStore.getItem('otterz_id') });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /* Functions to define interactions and UI state management */
  useEffect(() => {
    if (businessRefs.current.length > 0) {
      const lastElement = businessRefs.current[businessRefs.current.length - 1];
      if (lastElement) {
        gsap.fromTo(lastElement, { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 0.3 });
      }
    }
  }, [businesses.length]);

  // const handleAddBusiness = () => {
  //   setBusinesses([...businesses, { id: uuidv4(), name: '' }]);
  // };

  const handleRemoveBusiness = (id) => {
    const index = businesses.findIndex(b => b.id === id);
    const element = businessRefs.current[index];

    if (element) {
      gsap.timeline()
        .to(element, { height: 0, opacity: 0, margin: 0, duration: 0.3, onComplete: () => {
          setBusinesses(businesses.filter(b => b.id !== id));
        } })
    } else {
      setBusinesses(businesses.filter(b => b.id !== id));
    }
  };

  // const handleChange = (value, id) => {
  //   if (businesses.some(b => b.name === value && b.id !== id)) {
  //     Message({ type: "error", content: 'Business name already exists' });
  //     return;
  //   }
  //   setBusinesses(businesses.map(b => b.id === id ? { ...b, name: value } : b));
  // };

  const handleChange = (value, id, field) => {
    setBusinesses(businesses.map(b => b.id === id ? { ...b, [field]: value } : b));
  };

  const handleFreeTextBlur = (value, id) => {
    const containsAlphabet = /[a-zA-Z]/.test(value);
    if (!containsAlphabet) {
      Message({ type: "error", content: 'Free text must contain at least one alphabet' });
    }
  };
  
  const handleBlur = (value, id, field) => {
    if (field === 'name') {
      const duplicate = businesses.some(b => b.name === value && b.id !== id);
      if (duplicate) {
        Message({ type: "error", content: 'Business name already exists' });
      }
    }
  };

  const handleSubmit = () => {
    // Check for duplicate business names
    const duplicateBusinesses = new Set();
    const duplicates = businesses.some(b => {
      if (duplicateBusinesses.has(b.name.trim())) {
        return true;
      }
      duplicateBusinesses.add(b.name.trim());
      return false;
    });

    if (duplicates) {
      Message({ type: "error", content: 'Duplicate business names are not allowed' });
      return;
    }
    
    const businessList = businesses.map(b => b.name)
    console.log('Businesses: ', businesses.map(b => b.name), businessList);

    addBusiness.mutate({ 
      // otterz_id: secureLocalStore.getItem('otterz_id'), 
      // name: businessList
      name: businessList[0]
    });

    // const isOwner = true;
    // if (isOwner) {
    //   navigate(ROUTES.ONBOARDING.LINK_BUSINESS_BANK)
    // } else {
    //   navigate(ROUTES.ONBOARDING.INVITE_OWNERS)
    // }
    // Handle the submit action
  };

  // Check if at least one business name is entered
  const isAtLeastOneBusinessEntered = businesses.some(b => b.name.trim() !== '');

  return (
    <div className={classNames.setupOtterzComponentContainer}>
      <div className={classNames.setupOtterzComponent}>
        {/* <svg onClick={() => {navigate(-1)}} style={{position: "relative", left: '-70px', top: "45px"}} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_8949_97551)">
          <path d="M20.4974 3.41406C29.9274 3.41406 37.5807 11.0674 37.5807 20.4974C37.5807 29.9274 29.9274 37.5807 20.4974 37.5807C11.0674 37.5807 3.41406 29.9274 3.41406 20.4974C3.41406 11.0674 11.0674 3.41406 20.4974 3.41406ZM20.4974 34.1641C28.0482 34.1641 34.1641 28.0482 34.1641 20.4974C34.1641 12.9466 28.0482 6.83073 20.4974 6.83073C12.9466 6.83073 6.83073 12.9466 6.83073 20.4974C6.83073 28.0482 12.9466 34.1641 20.4974 34.1641ZM20.4974 18.7891H27.3307V22.2057H20.4974V27.3307L13.6641 20.4974L20.4974 13.6641V18.7891Z" fill="black"/>
          </g>
          <defs>
          <clipPath id="clip0_8949_97551">
          <rect width="41" height="41" fill="white"/>
          </clipPath>
          </defs>
        </svg> */}
        <Title level={2}>
          Setup Otterz Co-pilot
        </Title>
        <Text>Name the business that you want to manage</Text>

        <div style={{ margin: '30px 0 0' }}>
          {businesses.map((business) => (
            <div key={business.id} ref={(el) => (businessRefs.current[businesses.findIndex(b => b.id === business.id)] = el)} className={classNames.businessInput}>
              <Input
                placeholder="Legal business name"
                value={business.name}
                onChange={(e) => handleChange(e.target.value, business.id, 'name')}
                onBlur={(e) => handleBlur(e.target.value, business.id, 'name')}
              />
              <Input
                placeholder="Briefly describe your business"
                value={business.freeText}
                onChange={(e) => handleChange(e.target.value, business.id, 'freeText')}
                onBlur={(e) => handleFreeTextBlur(e.target.value, business.id)}
                style={{ marginTop: '10px' }}
              />
              {businesses.length > 1 && (
                <CloseCircleOutlined
                  className={classNames.removeIcon}
                  onClick={() => handleRemoveBusiness(business.id)}
                />
              )}
            </div>
          ))}
        </div>

        {/* <Button type="link" onClick={handleAddBusiness} className={classNames.addBusinessButton}>
          + Add Business
        </Button> */}

        <CTAButton
          htmlType="submit"
          type="primary"
          onClick={handleSubmit}
          loading={addBusiness.isLoading || updateOnboardingStatus.isLoading || loading }

          // className={classNames.signupFormInputs}
          style={{ marginTop: '20px' }}
          disabled={!isAtLeastOneBusinessEntered} // Button is disabled if no business name is entered
        >
          Continue
        </CTAButton>
      </div>
    </div>
  );
};

export default SetupOtterzCopilot;
