import classNames from "./MainLayout.module.scss";
import { 
  NavLink,
  Outlet, 
  useNavigate 
} from "react-router-dom";
import { Message, Sidebar } from "../common";
import UseIdleTimer from "../../hooks/useIdleTimer";
import { useEffect, useState } from "react";
import { useAccount, useBusiness, useBusinessStatus, usePermissions } from "../../store/StoreProvider";
import api from "../API";
import { useMutation } from "react-query";
import { Menu, Select, Spin } from "antd";
import { ROUTES } from "../constants";
import SubMenu from "antd/lib/menu/SubMenu";
import { Option } from "antd/lib/mentions";
import Settings from "../common/Sidebar/Icons/Settings";
import Profile from "../common/Sidebar/Icons/Profile";
import Roles from "../common/Sidebar/Icons/Roles";
import Teams from "../common/Sidebar/Icons/Teams";
import Subscription from "../common/Sidebar/Icons/Subscription";
import Integrations from "../common/Sidebar/Icons/Integrations";

function MainLayout() {
  const navigate = useNavigate();
  const { selectedBusiness, handleSelectBusiness } = useBusiness();
  const [loading, setLoading] = useState(true);
  const [businessLoading, setBusinessLoading] = useState(true);
  const { userData, setUserData } = useAccount();
  const [ , setSelectedSubscriptionPlan ] = useState({});
  const { permissionsData, setPermissionsData } = usePermissions();
  const { businessStatus, setBusinessStatus } = useBusinessStatus();
  const secureLocalStore = window.localStorage || window.secureLocalStore;
  const [businesses, setBusinesses] = useState([]);

  const viewBusinessList = useMutation(
    (data) => api.Services.Onboarding.viewUserByID(data),
    {
      onSuccess: (data) => {
        // Handle errors from the API call
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }

        // Handle successful registration
        if (data && data.data) {
          console.log("Data object", data.data);
          const filteredData = data.data[0].user_roles.map((userRole) => {
            return {
              business_id: userRole.business_id,
              name: userRole.business_name,
              role: userRole.role,
            };
          });

          setBusinesses(filteredData);
          console.log("Businesses fetched successfully", filteredData);
          // fetch business list for dropdown in invite modal
        }
      },
      onError: (error) => {
        // Handle any errors from the API call
        console.error("Signup error: ", error);
        Message({ type: "error", content: error.message });
      },
    }
  );

  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: async (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses fetched successfully", data);
        // if local storage has a business_id, select that business
        
        if (secureLocalStore.getItem("bid")) {
          const business = data.data.find(business => business.business_id === secureLocalStore.getItem("bid"));
          if (business) {
            handleSelectBusiness(business);
          }
        } else {
          handleSelectBusiness(data.data[0]);
        }

        const status = await api.Services.Onboarding.getBusinessStatus({business_id: data.data[0].business_id});
        if (status && status.status) {
          console.log("Business status fetched successfully", status);
          setBusinessStatus(status.data);
        } else {
          console.error("Error fetching business status: ", status);
        }

        const response = await api.Services.Dashboard.Subscription.getSubscribedPlanDetails(data.data[0].business_id)
        console.log("Subscribed Plan", response);
        if (response && response.status && response.data.subscription_type) {
          console.log("Non Free Subscribed Plan Check", response);
        } else {
          const response = await api.Services.Dashboard.Subscription.getSubscribedPlanDetails({business_id: data.data[0].business_id, email: secureLocalStore.getItem("email")})
          if (response && response.status && response.data.subscription_type) {
            console.log("Free Subscribed Plan Check", response);
          } else {
            setSelectedSubscriptionPlan({});
            // navigate("/onboarding/subscription")
          }
        }
        // fetch business list for dropdown in invite modal
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      // Message({ type: "error", content: error.message });
    },
  });

  const getUserData = async () => {
    try {
      setLoading(true)
      const response = await api.Services.Onboarding.viewUserByID({ otterz_id: secureLocalStore.getItem("otterz_id")});
      const responsePermissions = response.data[0].user_roles

      const permissionsForBusiness = responsePermissions.filter(role => role.business_id === selectedBusiness.business_id);
      console.log("permissionsForBusiness: ", permissionsForBusiness, selectedBusiness, responsePermissions);


      const permissionsHashtable = {};
      if (permissionsForBusiness.length !== 0) {
        permissionsForBusiness[0].permissions.forEach(permission => {
          permissionsHashtable[permission] = true;
        });
  
        setPermissionsData(permissionsHashtable)
        setLoading(false);
        setUserData(response.data[0]);
      }
    } catch (error) {
      console.log("Error fetching user data: ", error);
      // Message({ type: "error", content: error.message });
      setLoading(false);
    }
  }

  const addBusinessRouting = async () => {
    try {
      setBusinessLoading(true)
      console.log("business after: ", selectedBusiness);

      const balances = await api.Services.Onboarding.getBalances(selectedBusiness?.business_id);
      const plaidNotConnected = balances.status === false && balances.errors && balances.errors[0].code === "OTZ4000";
      if (plaidNotConnected) {
        // navigate(ROUTES.PUBLIC.UPDATE_PLAID_BUSINESS, 
        //   {
        //     state: { business_id: selectedBusiness?.business_id }
        //   }
        // );
        console.log("Plaid not connected", plaidNotConnected, selectedBusiness);
        return;
      }
      
      const qbConnected = await api.Services.Onboarding.getQuickbooksStatus(selectedBusiness?.business_id);
      if (qbConnected.data === null) {
        // navigate(ROUTES.PUBLIC.UPDATE_BUSINESS_LINK, 
        //   {
        //     state: { business_id: selectedBusiness?.business_id }
        //   }
        // );
      }
  
      console.log("Quickbooks status: ", qbConnected);
      console.log("Plaid connected status: ", !plaidNotConnected);
      setBusinessLoading(false);
    } catch (error) {
      console.log("Error fetching business data: ", error);
      setBusinessLoading(false);
    }
  }

  const fetchBusinessStatus = async () => {
    try {
      setBusinessLoading(true)
      console.log("business after: ", selectedBusiness);

      const status = await api.Services.Onboarding.getBusinessStatus({business_id: selectedBusiness?.business_id});
      if (status && status.status) {
        console.log("Business status fetched successfully", status);
        setBusinessStatus(status.data);
      } else {
        console.error("Error fetching business status: ", status);
      }

      setBusinessLoading(false);
    } catch (error) {
      console.log("Error fetching business data: ", error);
      setBusinessLoading(false);
    }
  }

  useEffect(() => {
    console.log("Selected business is ready: ", selectedBusiness);
    if (selectedBusiness.business_id) {
      getUserData()
      addBusinessRouting()
      fetchBusinessStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness])

  useEffect(() => {
    viewBusinessList.mutateAsync({
      otterz_id: secureLocalStore.getItem("otterz_id"),
    });
    viewAllBusiness.mutate(); // Fetch business list for dropdown in invite modal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeBusiness = (value) => {
    if (value === "add") {
      navigate(ROUTES.PUBLIC.UPDATE_BUSINESS_LIST);
    } else {
      const selected = businesses.find((b) => b.business_id === value);
      if (selected) {
        handleSelectBusiness(selected);
        secureLocalStore.setItem("bid", selected.business_id);
      }
    }
  };

  // Helper function to safely access nested properties
  const getNestedProperty = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  // Helper function to check feature access based on both permissionsData and businessStatus
  const isFeatureEnabled = (businessStatusPath, permissionsKey) => {
    const status = getNestedProperty(businessStatus, businessStatusPath);
    if (permissionsKey in ['roles', 'team', 'subscription', 'integrations']) {
      return status && permissionsData?.[permissionsKey];
    } else {
      return status; //&& permissionsData?.[permissionsKey];
    }
  };

  // Helper function to check if a root menu should be visible
  const isSubMenuVisible = (businessStatusPath, permissionKeys) => {
    if (businessStatusPath === "dashboard_status.sidebarUnlocked.settings" || businessStatusPath === "dashboard_status.sidebarUnlocked.taxCentre.documentsVault") {
      if (businessStatus?.dashboard_status?.stepsCompleted?.acceptTerms && businessStatus?.dashboard_status?.stepsCompleted?.subscribePlan) {
        return true;
      }
      return false;
    }

    const status = getNestedProperty(businessStatus, businessStatusPath);    

    if (!status?.enabled) return false;

    // Check if any of the permission keys are enabled
    return permissionKeys.some(key => permissionsData[key] && getNestedProperty(businessStatus, `${businessStatusPath}`));
  };

  const [openSubMenuKey, setOpenSubMenuKey] = useState(null);

  const handleSubmenuClick = (text) => {
    setOpenSubMenuKey(null); // Close submenu after item click
  };

  const handleSubMenuOpenChange = (openKeys) => {
    // Toggle submenu open status based on selected openKeys
    setOpenSubMenuKey(openKeys.length ? openKeys[openKeys.length - 1] : null);
  };

  return (

    <div className={classNames.wrapper}>
      <UseIdleTimer />
      {
        loading || businessLoading || viewAllBusiness.isLoading ? (
          <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }} />
        ) : (
          <>
            <div className={classNames.sidebarWrapper}>
              <Sidebar />
            </div>
            <div className={classNames.sectionWrapper}>
              <section className={classNames.greetingsSection}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "50px" }}>
                  <Select
                    value={selectedBusiness.business_id}
                    onChange={handleChangeBusiness}
                    style={{ width: 200 }}
                  >
                    {businesses.map((business) => (
                      <Option key={business.business_id} value={business.business_id}>
                        {business.name}
                      </Option>
                    ))}
                    <Option value="add" key="add">
                      + Add Business
                    </Option>
                  </Select>
                  <h4 style={{ margin: 0, fontSize: '1rem' }}>
                    {userData?.first_name} {userData?.last_name}
                  </h4>
                  <div className={classNames.menuContainer}>
                    <Menu mode="inline" 
                    className={classNames.menu}
                    openKeys={[openSubMenuKey]} // Control open/close with openSubMenuKey
                    onOpenChange={handleSubMenuOpenChange} // Update open state when submenu opens/closes  
                  >
                      {isSubMenuVisible('dashboard_status.sidebarUnlocked.settings', ['profile', 'roles', 'team', 'subscription', 'integrations']) && (
                        <SubMenu title="Settings" key="settings" icon={<Settings />}>
                          {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.profile', 'profile') || permissionsData["profile"]) && (
                            <Menu.Item key="profile" onClick={() => handleSubmenuClick("profile")} icon={<Profile />}>
                              <NavLink to="settings/profile">Profile</NavLink>
                            </Menu.Item>
                          )}
                          {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.roles', 'roles') || permissionsData["roles"]) && (
                            <Menu.Item key="roles" onClick={() => handleSubmenuClick("roles")} icon={<Roles />}>
                              <NavLink to="settings/roles">Roles</NavLink>
                            </Menu.Item>
                          )}
                          {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.teams', 'team') || permissionsData["team"]) && (
                            <Menu.Item key="team" onClick={() => handleSubmenuClick("team")} icon={<Teams />}>
                              <NavLink to="settings/team">Teams</NavLink>
                            </Menu.Item>
                          )}
                          {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.subscriptions', 'subscription') || permissionsData["subscription"]) && (
                            <Menu.Item key="plans-preferences" onClick={() => handleSubmenuClick("subscriptions")} icon={<Subscription />}>
                              <NavLink to="settings/plans-preferences">Subscriptions</NavLink>
                            </Menu.Item>
                          )}
                          {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.integrations', 'integrations') || permissionsData["integrations"]) && (
                            <Menu.Item key="integrations" onClick={() => handleSubmenuClick("integrations")} icon={<Integrations />}>
                              <NavLink to="settings/integrations">Integrations</NavLink>
                            </Menu.Item>
                          )}
                        </SubMenu>
                      )}
                    </Menu>
                  </div>
                </div>
              </section>
              <Outlet />
            </div>
          </>
        )
      }
    </div>
  );
}

export default MainLayout;
