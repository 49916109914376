import classNames from "./Dashboard.module.scss";
import { Outlet } from "react-router-dom";
import UseIdleTimer from "../../../hooks/useIdleTimer";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import { LogoutOutlined, UserOutlined/*, UsergroupAddOutlined*/ } from "@ant-design/icons";

const { Sider } = Layout;

function Dashboard() {
  const navigate = useNavigate();
  // const secureLocalStore = window.localStorage || window.sessionStorage;

  return (
    <div className={classNames.wrapper}>
      <UseIdleTimer />
      <Layout className={classNames.layout}>
        <Sider
          className={classNames.sidebar}
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className={classNames.logo}>
            <img src={logo} alt="logo" width={"100%"} />
          </div>
          <Menu theme="dark" style={{background: 'rgba(0, 0, 0, 0'}} defaultSelectedKeys={[""]}>
            <Menu.Item onClick={() => {navigate("/bookkeeper/app")}} key="1" icon={<UserOutlined />}>
              Clients
            </Menu.Item>
            {/* <Menu.Item key="2" icon={<UsergroupAddOutlined />}>
              Customers
            </Menu.Item> */}
            <Menu.Item className={classNames.footer} onClick={() => {
              localStorage.clear()
              navigate("/bookkeeper/login")
            }} key="3" icon={<LogoutOutlined />}>
              Logout
            </Menu.Item>
          </Menu>
        </Sider>
        <div className={classNames.sectionWrapper}>
          <Outlet />
        </div>
      </Layout>
    </div>
  );
}

export default Dashboard;