import endpoints from "../../../endpoints";

// Function to get subscribed plan details
export default async function getSubscribedPlanDetails(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    const baseURL = endpoints.SERVICES.SUBSCRIPTIONS.GET_SUBSCRIBED_PLAN_DETAILS[env];
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    
    let url;
    if (typeof data === 'string') {
        url = `${baseURL}/${data}`;
    } else if (typeof data === 'object' && data.business_id && data.email) {
        url = `${baseURL}/${data.business_id}?email=${data.email}`;
    } else {
        throw new Error('Invalid arguments provided.');
    }
    
    console.log("Data inside getSubscribedPlanDetails", data, url);
    return await (
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        })
    ).json();
}
