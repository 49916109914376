import React, { useEffect, useState } from 'react';
import { Tabs, Table, Tooltip, Button, Select, Modal, Form, Input } from 'antd';
import classNames from './Team.module.scss';
// import { getRandomUserData } from './utils';
import { useMutation } from 'react-query';
import api from '../../API';
import { Message } from '../../common';
import { useBusiness } from '../../../store/StoreProvider';

const { TabPane } = Tabs;
const { Option } = Select;

const Team = () => {
  const [activeKey, setActiveKey] = useState('activeUsers');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inviteesData, setInviteesData] = useState([]);
  const [activeUsersData, setActiveUsersData] = useState([]);
  // const data = getRandomUserData(8);
  const [form] = Form.useForm();

  // const [business, /*setBusiness*/] = useState({id: 1, name: "John's Workshop"});
  const { selectedBusiness } = useBusiness();

  /* Mutation definition for API calls */
  // Invite view mutation - getting a list of all invites
  const viewInvites = useMutation(data => api.Services.Onboarding.viewInvites(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        setInviteesData(data.data); // Update state with invitees data
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // Invite delete mutation - deletes an invite
  const deleteInvite = useMutation(data => api.Services.Onboarding.deleteInvite(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        Message({ type: "success", content: "Invite deleted successfully" });
        viewInvites.mutateAsync(selectedBusiness.business_id); // Refresh the invitees list
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // Invite resend mutation - resends an invite
  const resendInvite = useMutation(data => api.Services.Onboarding.resendInvite(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        Message({ type: "success", content: "Invite resent successfully" });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // View all businesses of user
  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses fetched successfully", data);
        // fetch business list for dropdown in invite modal
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // View all active users in a business
  const getBusinessUsers = useMutation(data => api.Services.Onboarding.getBusinessUsers(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        // Convert the response object into an array of objects
        const businessUsersArray = Object.values(data.data).map(user => ({
          ...user,
          email: user.email,
        }));
        setActiveUsersData(businessUsersArray);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // Invite employess mutation - makes an invite
  const inviteEmployee = useMutation(data => api.Services.Onboarding.inviteEmployee(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        Message({ type: "success", content: "Invite sent successfully" });
        form.resetFields();
        setIsModalVisible(false);
        viewInvites.mutateAsync(selectedBusiness.business_id); // Refresh the invitees list
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // Employee delete mutation - deletes an employee
  const deleteUser = useMutation(data => api.Services.Onboarding.deleteUser(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        Message({ type: "success", content: "User deleted successfully" });
        form.resetFields();
        setIsModalVisible(false);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });
  
  useEffect(() => {
    viewAllBusiness.mutateAsync(); // Fetch business list for dropdown in invite modal
    if (selectedBusiness) {
      viewInvites.mutateAsync(selectedBusiness.business_id)
      getBusinessUsers.mutateAsync({business_id: selectedBusiness.business_id})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness])

  const handleInvite = async () => {
    try {
      const values = await form.validateFields();
      const { name, email, role, businesses } = values;
      const employee = { name, email_id: email, role };
      console.log("Invite employee:", employee, businesses);
      // businesses.forEach(business_id => {
      // });
      inviteEmployee.mutateAsync({ business_id: selectedBusiness.business_id, employee });
    } catch (error) {
      console.error("Invite error:", error);
    }
  };  

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date Added',
      dataIndex: 'added_on',
      key: 'added_on',
      render: date => new Date(date * 1000).toLocaleDateString() // Convert timestamp to date string
    },
    {
      title: 'Business Name',
      // dataIndex: 'business_id',
      key: 'business_id',
      render: () => selectedBusiness.name
    },
    {
      title: 'Email ID',
      dataIndex: activeKey === 'invitees' ? 'email_id' : 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, record) =>
        activeKey === 'activeUsers' ? (
          <Select defaultValue={record.role} disabled={record.role === "Admin"} style={{ width: 125, fontSize: '0.75rem' }} size={"small"}>
            <Option value="Accountant">Accountant</Option>
            <Option value="Analyst">Analyst</Option>
            <Option value="Admin">Admin</Option>
          </Select>
        ) : (
          text
        ),
    },
    {
      key: 'action',
      render: (text, record) => (
        <div className={classNames.actionIcons}>
          {activeKey === 'invitees' && (
            <>
            <Tooltip title="Resend Invite" placement="left">
              {/* <Button icon={<RiRefreshLine />} shape="circle" /> */}
              <svg onClick={() => {resendInvite.mutateAsync(record.id)}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_10042_98916)">
                <path d="M13.9028 14.6752C12.5418 15.8545 10.8008 16.5025 9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 10.602 15.9975 12.087 15.1425 13.305L12.75 9H15C14.9999 7.61716 14.5221 6.2768 13.6475 5.20567C12.7729 4.13454 11.5551 3.39839 10.2002 3.12177C8.84536 2.84514 7.4365 3.04501 6.21201 3.68757C4.98752 4.33013 4.02257 5.37593 3.48039 6.64806C2.93822 7.92019 2.8521 9.34054 3.23662 10.6689C3.62113 11.9972 4.45267 13.1519 5.59056 13.9377C6.72845 14.7235 8.10284 15.0921 9.48123 14.9812C10.8596 14.8703 12.1574 14.2866 13.155 13.329L13.9028 14.6752Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_10042_98916">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </Tooltip>
            <Tooltip title="Delete Invite" placement="left">
            {/* <Button icon={<RiDeleteBin6Line />} shape="circle" /> */}
            <svg onClick={() => {deleteInvite.mutateAsync(record.id)}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_10042_99836)">
              <path d="M12.75 4.5H16.5V6H15V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5H3.75C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75V6H1.5V4.5H5.25V2.25C5.25 2.05109 5.32902 1.86032 5.46967 1.71967C5.61032 1.57902 5.80109 1.5 6 1.5H12C12.1989 1.5 12.3897 1.57902 12.5303 1.71967C12.671 1.86032 12.75 2.05109 12.75 2.25V4.5ZM13.5 6H4.5V15H13.5V6ZM6.75 8.25H8.25V12.75H6.75V8.25ZM9.75 8.25H11.25V12.75H9.75V8.25ZM6.75 3V4.5H11.25V3H6.75Z" fill="#1D1C1C"/>
              </g>
              <defs>
              <clipPath id="clip0_10042_99836">
              <rect width="18" height="18" fill="white"/>
              </clipPath>
              </defs>
            </svg>

            </Tooltip>
            </>
          )}
          {(activeKey === 'activeUsers' && record.role !== "Admin" ) && (
            <Tooltip title="Delete User" placement="left">
            {/* <Button icon={<RiDeleteBin6Line />} shape="circle" /> */}
              <svg onClick={() => {deleteUser.mutateAsync(record.otterz_id)}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_10042_99836)">
                <path d="M12.75 4.5H16.5V6H15V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5H3.75C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75V6H1.5V4.5H5.25V2.25C5.25 2.05109 5.32902 1.86032 5.46967 1.71967C5.61032 1.57902 5.80109 1.5 6 1.5H12C12.1989 1.5 12.3897 1.57902 12.5303 1.71967C12.671 1.86032 12.75 2.05109 12.75 2.25V4.5ZM13.5 6H4.5V15H13.5V6ZM6.75 8.25H8.25V12.75H6.75V8.25ZM9.75 8.25H11.25V12.75H9.75V8.25ZM6.75 3V4.5H11.25V3H6.75Z" fill="#1D1C1C"/>
                </g>
                <defs>
                <clipPath id="clip0_10042_99836">
                <rect width="18" height="18" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={classNames.userManagement}>
      {/* <section className={classNames.greetingsSection}>
          <h1 style={{ color: '#82898F', margin: 0 }}>Hello, {userData?.first_name}</h1>
      </section> */}
      <Tabs style={{
        padding: '2rem',
        height: '100%',
        background: 'white',
        borderRadius: '8px',
        margin: '0 10px'
      }} defaultActiveKey="1" onChange={key => setActiveKey(key)} tabBarExtraContent={<Button type="link" onClick={showModal}>+ Invite User</Button>} centered>
        <TabPane tab="Active Users" key="activeUsers">
          <Table columns={columns} dataSource={activeKey === 'activeUsers' ? activeUsersData : []} loading={getBusinessUsers.isLoading} />
        </TabPane>
        <TabPane tab="Invitees" key="invitees">
          <Table columns={columns} dataSource={activeKey === 'invitees' ? inviteesData : []} pagination={false} loading={viewInvites.isLoading} />
        </TabPane>
      </Tabs>

      <Modal title="Invite User" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form form={form} layout="horizontal">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Enter name" />
          </Form.Item>
          {/* <Form.Item label="Businesses" name="businesses" rules={[{ required: true }]}>
            <Select mode="multiple" placeholder="Select businesses">
              {viewAllBusiness?.data &&
                viewAllBusiness?.data?.data?.map(business => (
                  <Option key={business.id} value={business.id}>
                    {business.name}
                  </Option>
                ))}
            </Select>
          </Form.Item> */}
          <Form.Item label="Email ID" name="email" rules={[{ required: true, type: 'email' }]}>
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item label="Role" name="role" rules={[{ required: true }]}>
            <Select placeholder="Select a role">
              <Option value="Accountant">Accountant</Option>
              <Option value="Analyst">Analyst</Option>
              {/* <Option value="Admin">Admin</Option> */}
            </Select>
          </Form.Item>
          <Form.Item style={{marginBottom: "0 !important"}}>
            <Button
              type="primary"
              htmlType="submit"
              className={classNames.inviteButton}
              loading={inviteEmployee.isLoading}
              onClick={handleInvite}
            >
              Send Invite
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Team;
