import endpoints from "../../../endpoints";

export default async function downloadTransactionsPlaid(data) {
    let env = process.env.REACT_APP_ENV?.trim();

    const baseURL = endpoints.SERVICES.ONBOARDING.PLAID[env];

    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${baseURL}/transactions/pdf/${data.business_id}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        })
    ).json();
}
