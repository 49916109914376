import React from 'react';

import classNames from './Integrations.module.scss'

const PlaidFaqCard = () => {
  return (
    <div className={classNames.subscriptionCard} style={{
      width: 'fit-content',
      position: 'absolute',
      bottom: '5%'
    }}>
      <div className={classNames.saveBadge} style={{background: '#D9D9D9', color: '#82898F'}}>
        {/* {save} */}
        Integrations FAQs
      </div>
 
      <div className={classNames.cardContent}>
        <div className={classNames.taxTypeContainer} style={{display: 'flex', width: '100%', gap: '15px', justifyContent: 'space-around', alignItems: 'center'}}>
            <div className={classNames.buttonContainer}>
                <p className={null}>
                    <a href="/HowToSetupAQuickBooksAccount.pdf" target="_blank" rel="noopener noreferrer">
                      <span>How To Setup A QuickBooks Account</span>
                    </a>
                </p>
                <p className={null}>
                    <a href="/HowToConnectAccountsOnOtterz.pdf" target="_blank" rel="noopener noreferrer">
                      <span>How To Connect Accounts On Otterz</span>
                    </a>
                </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default PlaidFaqCard;
