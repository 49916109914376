import endpoints from "../../../endpoints";

export default async function generatePdf({  business_id, contractor_id }) {
  let env = process.env.REACT_APP_ENV?.trim();
  const secureLocalStore = window.localStorage || window.sessionStorage;
  const accessToken = secureLocalStore.getItem("accessToken");
  const url = `${endpoints.SERVICES.ACCOUNTANTS_DASHBOARD.TAX.GENERATE_PDF[env]}${business_id}/${contractor_id}`;


  return await (
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  ).json();
}
