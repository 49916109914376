import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import classNames from './QuickBooksLink.module.scss';
import { CTAButton, Message } from '../../common';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from '../../API';
import { ROUTES } from '../../constants';
import endpoints from '../../API/endpoints';

const { Text } = Typography;

const QuickBooksLink = () => {
  const navigate = useNavigate();
  let env = process.env.REACT_APP_ENV?.trim();

  const [accounts, setAccounts] = useState([]);
  const [services, setServices] = useState({});
  const [connected, setConnected] = useState(false); // State to track connection status
  const secureLocalStore = window.localStorage || window.sessionStorage;

  // Mutation definition for API calls
  // const getQuickbooksToken = useMutation(data => api.Services.Onboarding.getQuickbooksToken(data));
  
  const updateOnboardingStatus = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data), {
    onSuccess: () => {
      Message({ type: "success", content: "Quickbooks linked successfully" });
      
      // Redirect or do any further action upon successful onboarding status update
      if (services.accept_payments === 'Y') {
        navigate(ROUTES.ONBOARDING.SETUP_PAYMENTS);
      } else if (services.accept_payments === 'N') {
        navigate("/onboarding/subscription");
      }
    },
    onError: (error) => {
      console.error("Error updating onboarding status: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: (data) => {
      if (data.status && data.data) {
        const businessNames = data.data.map((business) => {
          return {
            business_id: business.business_id,
            name: business.name
          }
        });
        setAccounts(businessNames);
      } else {
        Message({ type: "error", content: data.errors[0].message });
      }
    },
    onError: (error) => {
      console.error("Error fetching businesses: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // View user's owner status
  const viewUser = useMutation(data => api.Services.Onboarding.viewUserByID(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("User viewed", data.data[0].services.accept_payments);
        setServices(data.data[0].services);
        // Check if QuickBooks is already connected
        // if (data.data[0].services.accept_payments === 'Y') {
        //   setConnected(true); // Set connected state to true
        // }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // View user's owner status
  const getQbStatus = useMutation(data => api.Services.Onboarding.getQuickbooksStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("QbStatus", data.data);
        // Check if QuickBooks is already connected
        if (data.data.length !== 0 && data.data.access_token) {
          setConnected(true); // Set connected state to true
        }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  const handleConnect = async (businessName) => {
    try {
      // const response = await getQuickbooksToken.mutateAsync(secureLocalStore.getItem('otterz_id'));
      // // Assuming response contains a property 'success' indicating successful OAuth completion
      // if (response.success) {
      //   // Update the button style for the connected business
      //   // For example, you could add a class to change the button color to red
      //   // Implement as needed based on your UI requirements
      // }
      window.open(`${endpoints.SERVICES.ONBOARDING.QUICKBOOKS_OAUTH[env]}${accounts[0].business_id}`, '_blank');
    } catch (error) {
      console.error("Error connecting to QuickBooks: ", error);
      Message({ type: "error", content: error.message });
    }
  };

  const handleContinue = () => {
    if(services.accept_payments === 'Y') {
      updateOnboardingStatus.mutate({ otterz_id: secureLocalStore.getItem('otterz_id'), status: "SETUP_PAYMENTS" });
    } else if(services.accept_payments === 'N'){
      updateOnboardingStatus.mutate({ otterz_id: secureLocalStore.getItem('otterz_id'), status: "SUBSCRIPTION_ONBOARD" });
    }
  };

  // Fetch businesses on component mount
  React.useEffect(() => {
    viewUser.mutate({ otterz_id: secureLocalStore.getItem('otterz_id') });
    viewAllBusiness.mutate(secureLocalStore.getItem('otterz_id'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Polling QuickBooks status
  React.useEffect(() => {
    // Polling interval in milliseconds (e.g., 5 seconds)
    const pollingInterval = 2000;

    // Function to fetch QuickBooks status and update state
    const fetchQbStatus = async () => {
      try {
        if (accounts.length === 0) return;
        const response = await getQbStatus.mutateAsync(accounts[0].business_id);
        // Handle response to update state accordingly
        if (response && response.data) {
          // Update state with the new QuickBooks status
          console.log("QbLinkStatus", response.data);
          // Check if QuickBooks is already connected
          if (response.data.length !== 0 && response.data.access_token) {
            setConnected(true); // Set connected state to true
            clearInterval(pollingId); // Stop polling
            handleContinue(); // Continue to the next step
          }
        }
      } catch (error) {
        console.error("Error fetching QuickBooks status: ", error);
        // Handle error accordingly
      }
    };

    // Start polling on component mount
    const pollingId = setInterval(fetchQbStatus, pollingInterval);

    // Cleanup function to stop polling on component unmount
    return () => clearInterval(pollingId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className={classNames.setupOtterzComponentContainer}>
      <div className={classNames.setupOtterzComponent}>
        {/* <svg onClick={() => {navigate(-1)}} style={{position: "relative", left: '-70px', top: "45px"}} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_8949_97551)">
            <path d="M20.4974 3.41406C29.9274 3.41406 37.5807 11.0674 37.5807 20.4974C37.5807 29.9274 29.9274 37.5807 20.4974 37.5807C11.0674 37.5807 3.41406 29.9274 3.41406 20.4974C3.41406 11.0674 11.0674 3.41406 20.4974 3.41406ZM20.4974 34.1641C28.0482 34.1641 34.1641 28.0482 34.1641 20.4974C34.1641 12.9466 28.0482 6.83073 20.4974 6.83073C12.9466 6.83073 6.83073 12.9466 6.83073 20.4974C6.83073 28.0482 12.9466 34.1641 20.4974 34.1641ZM20.4974 18.7891H27.3307V22.2057H20.4974V27.3307L13.6641 20.4974L20.4974 13.6641V18.7891Z" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_8949_97551">
              <rect width="41" height="41" fill="white"/>
            </clipPath>
          </defs>
        </svg> */}
        <h1 style={{margin: 0}}>Setup Otterz Co-pilot</h1>
        <h4 className={classNames.subText}>
          Connect your businesses to QuickBooks
        </h4>

        {/* Rendering flexbox column with rows */}
        {accounts.map((acc, index) => (
          <div key={index} className={classNames.flexRow}>
            <Text>{acc.name}</Text>
            <Button size='large' type="primary" disabled={connected} className={classNames.connectButton} onClick={() => handleConnect(acc.name)}>
              {connected ? "Connected" : "Connect"} {/* Change button text based on connection status */}
            </Button>
          </div>
        ))}

        <CTAButton
          htmlType="submit"
          type="primary"
          loading={updateOnboardingStatus.isLoading}
          disabled={!connected} /* Disable button if not connected */
          className={classNames.signupFormInputs}
          onClick={handleContinue}
        >
          Continue
        </CTAButton>
      </div>
    </div>
  );
};

export default QuickBooksLink;
