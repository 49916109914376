import React, { useEffect, useState } from "react";
import classNames from "./BusinessSetup.module.scss";
import api from "../../API";

import { useBusiness } from "../../../store/StoreProvider";
import BusinessOnboardingSteps from "./Steps/BusinessOnboardingSteps";
import { useMutation } from "react-query";
import { Spin } from "antd";
import CashFlowAndPL from "../Insights/CashFlowAndPL";
// import { useNavigate } from 'react-router-dom';

const BusinessSetup = (accountData) => {
  let { selectedBusiness, isInitialBusinessCenterView } = useBusiness();
  const [businessOnboared, setBusinessOnboarded] = useState("");
  const [questions, setTaxQuestions] = useState([]);
  // Mutation to fetch tax onboarding status
  const getTaxOnboardingStatus = useMutation(
    (data) => api.Services.Dashboard.TaxFilling.getTaxOnboardingStatus(data),
    {
      onSuccess: (data) => {
        if (data && data.data) {
          setBusinessOnboarded(data.data.business_onboarding_status);
        }
      },
    }
  );

  const getBusinessDataCollection = useMutation(() => {
    return api.Services.Dashboard.TaxFilling.getBusinessQuestion({
      business_id: selectedBusiness?.business_id,
    }).then((response) => {
      setTaxQuestions(response?.data?.questions);
    });
  });

  const hasBookkeepingMethod = questions?.find(
    (item) => item.key === "bookkeeping_method"
  );
  useEffect(() => {
    // Fetch tax onboarding status when selectedBusiness changes
    getTaxOnboardingStatus.mutate({
      business_id: selectedBusiness?.business_id,
    });

    getBusinessDataCollection.mutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const showInitialFlow = (
    isInitialBusinessCenterView && !!(hasBookkeepingMethod?.value === "Not Sure")
  )

  return (
    <div className={classNames.container}>
      {getTaxOnboardingStatus.isLoading ? ( // Render Spinner if loading
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {businessOnboared === "BUSINESS_ONBOARDING_COMPLETE" && !showInitialFlow
           ? (
            <CashFlowAndPL />
          ) : (
            <BusinessOnboardingSteps
              setBusinessOnboarded={setBusinessOnboarded}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BusinessSetup;
