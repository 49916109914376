import endpoints from "../../../endpoints";

export default async function moveFile(data){
    let env = process.env.REACT_APP_ENV?.trim();

    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
        file_id: data.file_id,
        otterz_id: data.otterz_id,
        new_parent_folder_id: data.new_parent_folder_id
    }).toString();

    const secureLocalStore = window.localStorage || window.sessionStorage;
    const baseURL = endpoints.SERVICES.DASHBOARD.DOCUMENTS_VAULT.MOVE_FILE[env];
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}?${queryParams}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
        })
    ).json();
}
