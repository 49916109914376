import endpoints from "../../../endpoints";

export default async function deleteInvite(invite_id){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const baseURL = endpoints.SERVICES.ONBOARDING.INVITATION[env];
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}/${invite_id}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
