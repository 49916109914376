import React, { useEffect, useState } from 'react';
import classNames from './TaxOnboarding.module.scss';
import api from '../../API';

import { useBusiness } from '../../../store/StoreProvider';
import DocumentVaultComponent from '../DocumentVault/DocumentVaultComponent';
import TaxOnboardingSteps from './Steps/TaxOnboardingSteps';
import { useMutation } from 'react-query';
import { Spin } from 'antd';
// import { useNavigate } from 'react-router-dom';

const TaxOnboarding = (accountData) => {
  const secureLocalStore = window.localStorage || window.sessionStorage;

  let { selectedBusiness } = useBusiness();
  const [taxOnboarded, setTaxOnboarded] = useState('');

  // Mutation to fetch tax onboarding status
  const getTaxOnboardingStatus = useMutation(data => api.Services.Dashboard.TaxFilling.getTaxOnboardingStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        setTaxOnboarded("ONBOARDING_START");
        // Message({ type: "error", content: data.errors[0].message });
      }

      if (data && data.data) {
        console.log("status", data.data.tax_filling_status);
        setTaxOnboarded(data.data.tax_filling_status);
      }
    },
    onError: (error) => {
      setTaxOnboarded("ONBOARDING_START");
      console.error("Error fetching tax onboarding status: ", error);
      // Message({ type: "error", content: error.message });
    },
  });

  const getBusinessQuestion = useMutation(data => api.Services.Dashboard.TaxFilling.getBusinessQuestion(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        console.log("Business questions error - User still needs to add business info", data);
        // Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Business questions fetched", data);
        // Message({ type: "success", content: "Upload Tax Returns successfully !" });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      // Message({ type: "error", content: error.message });
    },
  });

  useEffect(() => {
    // Fetch tax onboarding status when selectedBusiness changes
    getTaxOnboardingStatus.mutate({ business_id: selectedBusiness?.business_id });
    getBusinessQuestion.mutate({ business_id: selectedBusiness?.business_id });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  return (
    <div className={classNames.container}>
      {getTaxOnboardingStatus.isLoading ? ( // Render Spinner if loading
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spin size='large' />
        </div>
      ) : (
        <>
          {taxOnboarded === 'TAX_ONBOARDING_COMPLETE' || secureLocalStore.getItem('taxOnboardingSkipped') ? (<DocumentVaultComponent />) : (<TaxOnboardingSteps setTaxOnboarded={setTaxOnboarded}/>)}
        </>
      )}
      
    </div>
  );
};

export default TaxOnboarding;
