import endpoints from "../../../endpoints";

export default async function getBusinessOverview(business_id){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = endpoints.SERVICES.DASHBOARD.INSIGHTS.BUSINESS_OVERVIEW[env] + business_id;
    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
