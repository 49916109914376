import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Badge,
  Menu,
  // Avatar
} from "antd";
import { NavLink } from "react-router-dom";
import {
  // RiAddLine,
  RiLogoutBoxLine,
  // RiHome7Line,
  // RiBankLine,
  // RiUserReceived2Line,
  // // RiPieChart2Line,
  // RiSettings3Line,
  // RiGiftLine,
  // RiVipCrownLine,
} from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import {
  useBusiness,
  useBusinessStatus,
  usePermissions,
} from "../../../../src/store/StoreProvider";

// import { AppContext } from "../../../context/AppState";
// import useInvoiceActiveStatus from "../../../hooks/useInvoiceActiveStatus";
import api from "../../API";
// import { uuidv4 } from "../../util/functions/uuidV4";
// import OtterzCoPilotMenuItems from "./data/businessBanking.data";
// import receivablesMenuItems from "./data/receivables.data";
// import payablesMenuItems from "./data/payables.data";
import ROLES from "../../constants/scopesToRoles/roles";
import { /*APPLICATION_STATUS,*/ ROUTES } from "../../constants";
import logo from "../../../assets/logo.svg";
import ElemIcon from "./ElemIcon";
// import user from "../../../assets/user.png";
import classNames from "./Sidebar.module.scss";
// import Button from "../Button/Button";
import Message from "../Message/Message";
// import { GenIcon } from "react-icons";
import BusinessCentre from "./Icons/BusinessCentre";
import BusinessPerformance from "./Icons/BusinessPerformance";
import BankAccounts from "./Icons/BankAccounts";
import CreditAmounts from "./Icons/CreditAmounts";
import TaxCentre from "./Icons/TaxCentre";
import Documents from "./Icons/Documents";
import Integrations from "./Icons/Integrations";
import ChatIcon from "./Icons/ChatIcon";
import Books from "./Icons/Books";
import Bugsnag from "@bugsnag/js";
import { InfoCircleFilled } from "@ant-design/icons";

const getDefaultSelectedKeys = (path) => {
  let keys = [];

  if (path === ROUTES.APP.INDEX) {
    keys = ["Home", "home"];
  } else {
    keys = path.replace(`${ROUTES.APP.INDEX}/`, "").split("/");
    const paymentAcceptanceIndex =
      keys.indexOf("customers-payment-acceptance") +
      keys.indexOf("transactions") +
      keys.indexOf("recurring-schedules") +
      keys.indexOf("payment-acceptance-onboarding");

    const bankingIndex =
      keys.indexOf("recurring-payments") +
      keys.indexOf("payees") +
      keys.indexOf("scheduled-payments");

    const invoiceIndex =
      keys.indexOf("all-invoices") +
      keys.indexOf("invoices") +
      keys.indexOf("all-estimates") +
      keys.indexOf("template-settings") +
      keys.indexOf("recurring-invoices") +
      keys.indexOf("customers") +
      keys.indexOf("products-services") +
      keys.indexOf("estimates") +
      keys.indexOf("tax-database") +
      keys.indexOf("invoices-getting-started");

    const settingsIndex = keys.indexOf("atm-locator");

    const rewardsIndex = keys.indexOf("rewards");

    const reportsIndex = keys.indexOf("reports");

    if (invoiceIndex > -9) {
      keys[1] = "invoices";
    }
    if (paymentAcceptanceIndex > -4) {
      keys[1] = "payment-acceptance";
    }
    if (bankingIndex > -3) {
      keys[1] = "make-payments";
    }
    if (settingsIndex > 0) {
      keys = ["settings", "atm-locator"];
    }
    if (rewardsIndex === 0) {
      keys = ["rewards", "rewards"];
    }
    if (reportsIndex === 0) {
      keys = ["reports", "reports"];
    }
  }

  return keys;
};

export default function Sidebar({ minimalView = false }) {
  //Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(
    getDefaultSelectedKeys(location.pathname)
  );
  const [openKeys, setOpenKeys] = React.useState(selectedKeys);
  const [, setBusinesses] = useState([]);
  const [, setTaxOnboarded] = useState(false);
  const { selectedBusiness, } = useBusiness();
  const { permissionsData } = usePermissions();
  const { businessStatus } = useBusinessStatus();
  const [count, setCount] = useState({
    Bookkeeping: 0,
    Taxes: 0,
    DocumentRequests: 0,
  });
  const [disconnectedAccounts, setDisconnectedAccounts] = useState({ plaid: [], quickbooks: [] });

  // const { setUserData } = useAccount();
  const secureLocalStore = window.localStorage || window.secureLocalStore;

  // get integration status by making api call to checkConnectionStatus
  const getIntegrationStatus = useMutation(data => api.Services.Onboarding.checkConnectionStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return;
      }
      setDisconnectedAccounts(data.data);
    },
    onError: (error) => {
      // Message({ type: "error", content: error.message });
      Bugsnag.notify("Error fetching integration status: ", error);  
    },
  });

  useEffect(() => {
    const keys = getDefaultSelectedKeys(location.pathname);
    setSelectedKeys(keys);
    // setOpenKeys(keys);
  }, [location.pathname]);

  //Other variables
  const { SubMenu } = Menu;

  const rootSubmenuKeys = ["business-center", "tax-center", "chat", "settings"];

  useQuery("getAccountById", () => api.BusinessBanking.getAccount(), {
    refetchOnWindowFocus: false,
  });

  // View all businesses of user
  const viewAllBusiness = useMutation(
    (data) => api.Services.Onboarding.viewUserByID(data),
    {
      onSuccess: (data) => {
        // Handle errors from the API call
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }

        // Handle successful registration
        if (data && data.data) {
          console.log("Data object", data.data);
          const filteredData = data.data[0].user_roles.map((userRole) => {
            return {
              business_id: userRole.business_id,
              name: userRole.business_name,
              role: userRole.role,
            };
          });

          setBusinesses(filteredData);
          console.log("Businesses fetched successfully", filteredData);
          // fetch business list for dropdown in invite modal
        }
      },
      onError: (error) => {
        // Handle any errors from the API call
        console.error("Signup error: ", error);
        Message({ type: "error", content: error.message });
      },
    }
  );

  // Mutation to fetch tax onboarding status
  const getTaxOnboardingStatus = useMutation(
    (data) => api.Services.Dashboard.TaxFilling.getTaxOnboardingStatus(data),
    {
      onSuccess: (data) => {
        if (!data.status) {
          setTaxOnboarded(false);
          // Message({ type: "error", content: data.errors[0].message });
        }

        if (data && data.data) {
          console.log("status", data.data.tax_filling_status);
          if (data.data.tax_filling_status === "TAX_ONBOARDING_COMPLETE") {
            setTaxOnboarded(true);
          } else {
            setTaxOnboarded(false);
          }
        }
      },
      onError: (error) => {
        setTaxOnboarded(false);
        console.error("Error fetching tax onboarding status: ", error);
        // Message({ type: "error", content: error.message });
      },
    }
  );

  const getBookkeepingQueriesAndCount = useMutation(
    async (data) => {
      const response = await api.Services.Dashboard.ClientQueries.getBookkeepingQueriesAndCount(data);
      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }
  
      if (response.data && response.data.transactions) {
        const transactions = response.data.transactions;
        setCount((prevCount) => ({
          ...prevCount,
          Bookkeeping: transactions.length,
        })
        );
      }
    },
    {
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getBookkeepingQueriesAndCount sidebar",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const getTaxQueries = useMutation(
    async (data) => {
      const response = await api.Services.Dashboard.ClientQueries.getTaxQueries(data);
      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }
  
      if (response && response.data) {
        const vendorData = response.data;
        setCount((prevCount) => ({
          ...prevCount,
          Taxes: vendorData.length,
        }));
      }
    },
    {
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getTaxQueries sidebar",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  useEffect(() => {
    if (selectedBusiness.business_id) {
      // Fetch the list of bookkeeping queries and count
      getIntegrationStatus.mutateAsync(selectedBusiness.business_id);
      getBookkeepingQueriesAndCount.mutate(selectedBusiness.business_id);

      // Fetch the list of tax queries
      getTaxQueries.mutate(selectedBusiness.business_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  useEffect(() => {
    viewAllBusiness.mutateAsync({
      otterz_id: secureLocalStore.getItem("otterz_id"),
    }); // Fetch business list for dropdown in invite modal
    getTaxOnboardingStatus.mutate({
      business_id: selectedBusiness?.business_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedBusiness) {
      console.log("The selected business is: ", selectedBusiness);
    }
  }, [selectedBusiness, location.pathname]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    console.log("Keys that are getting opened: ", keys, latestOpenKey);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function handleTitleClick(menuItem) {
    setSelectedKeys([menuItem.parent, menuItem.item.replace(" ", "_")]);
  }

  const handleSubmenuClick = (text) => {
    console.log(text);
  };

  function hideMenuItem(sidebarPath, role) {
    switch (role) {
      case ROLES.ACCOUNTING_USER:
        if (
          [
            "overview",
            "make-payments",
            "manage-cards",
            "reports",
            "rewards",
            "settings",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      case ROLES.INVOICING_USER:
        if (
          [
            "banking", // Whole banking section restrict to INVOICING user
            "reports",
            "rewards",
            "settings",
            "home",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      case ROLES.EXPENSE_USER:
        if (
          [
            "banking",
            "reports",
            "rewards",
            "settings",
            "payment-acceptance",
            "home",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      default: // OWNER has all the access
        return false;
    }
  }

  // Helper function to safely access nested properties
  const getNestedProperty = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  // Helper function to check feature access based on both permissionsData and businessStatus
  const isFeatureEnabled = (businessStatusPath, permissionsKey) => {
    const status = getNestedProperty(businessStatus, businessStatusPath);
    if (permissionsKey in ['roles', 'team', 'subscription', 'integrations']) {
      return status && permissionsData?.[permissionsKey];
    } else {
      return status; //&& permissionsData?.[permissionsKey];
    }
  };

  // Helper function to check if a root menu should be visible
  const isSubMenuVisible = (businessStatusPath, permissionKeys) => {
    if (businessStatusPath === "dashboard_status.sidebarUnlocked.settings" || businessStatusPath === "dashboard_status.sidebarUnlocked.taxCentre.documentsVault") {
      if (businessStatus?.dashboard_status?.stepsCompleted?.acceptTerms && businessStatus?.dashboard_status?.stepsCompleted?.subscribePlan) {
        return true;
      }
      return false;
    }

    const status = getNestedProperty(businessStatus, businessStatusPath);    

    if (!status?.enabled) return false;

    // Check if any of the permission keys are enabled
    return permissionKeys.some(key => permissionsData[key] && getNestedProperty(businessStatus, `${businessStatusPath}`));
  };

  //JSX Element
  const menu = (
    <>
      {/* <Menu.Item
        key="AddBusiness"
        onClick={() => {
          navigate(ROUTES.PUBLIC.UPDATE_BUSINESS_LIST);
        }}
        icon={<RiAddLine size={18} style={{ color: "black" }} />}
        className={classNames.profilePicLogout}
        style={{ background: "#fff" }}
      >
        <span>Add Business</span>
      </Menu.Item> */}
      <Menu.Item
        key="Logout"
        onClick={() => {
          secureLocalStore.clear()
          navigate(ROUTES.PUBLIC.LOGIN);
        }}
        icon={<RiLogoutBoxLine size={18} style={{ color: "black" }} />}
        className={classNames.profilePicLogout}
        style={{ background: "#fff" }}
      >
        <span>Logout</span>
      </Menu.Item>
    </>
  );

  return (
    <div className={classNames.sidebar}>
      <div className={classNames.logo}>
        <img src={logo} alt="logo" width={"100%"} />
      </div>

      <Menu
        mode="inline"
        className={classNames.menu}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {/* Home */}
        <Menu.Item
          onClick={() => handleTitleClick({ item: "home", path: "home" })}
          key="home"
          icon={<ElemIcon />}
          hidden={hideMenuItem("home")}
        >
          <NavLink to={`/app`} activeClassName="selectedRouteClass">
            Home
          </NavLink>
        </Menu.Item>
    
        {/* Business Center */}
        {isSubMenuVisible('dashboard_status.sidebarUnlocked.businessCentre', ['insights', 'cash_balance', 'card_balance']) && (
          <SubMenu
            title={<NavLink style={{ color: "#545454" }}>Business Center</NavLink>}
            key="business-center"
            onClick={() => handleTitleClick({ item: "Business Center", path: "business-center" })}
            icon={<BusinessCentre />}
          >
            {isFeatureEnabled('dashboard_status.sidebarUnlocked.businessCentre.businessPerformance', 'insights') && (
              <Menu.Item
                key="cash-flow-and-pl"
                icon={<BusinessPerformance />}
                onClick={() => handleSubmenuClick("performance")}
              >
                <NavLink to={`otterz-copilot/cash-flow-and-pl`} activeClassName="selectedRouteClass">
                  Business Performance
                </NavLink>
              </Menu.Item>
            )}
            {isFeatureEnabled('dashboard_status.sidebarUnlocked.businessCentre.bankAccounts', 'cash_balance') && (
              <Menu.Item
                key="cash-balance"
                icon={<BankAccounts />}
                onClick={() => handleSubmenuClick("cash-balance")}
              >
                <NavLink to={`cash-balance`} activeClassName="selectedRouteClass">
                  Bank Accounts
                </NavLink>
              </Menu.Item>
            )}
            {isFeatureEnabled('dashboard_status.sidebarUnlocked.businessCentre.creditAccounts', 'card_balance') && (
              <Menu.Item
                key="card-balance"
                icon={<CreditAmounts />}
                onClick={() => handleSubmenuClick('card-balance')}
              >
                <NavLink to={`card-balance`} activeClassName="selectedRouteClass">
                  Credit Accounts
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}
  
        {/* Actions Pending */}
        {isFeatureEnabled('dashboard_status.sidebarUnlocked.actionsPending', 'queries') && (
          <Menu.Item
            key="queries"
            onClick={() => handleSubmenuClick('queries')}
            icon={<Books />}
          >
            <NavLink to={`queries`} activeClassName="selectedRouteClass">
              Actions Pending {<Badge style={{marginLeft: '10px'}} showZero count={count?.Bookkeeping + count?.Taxes + count?.DocumentRequests} color="#C85103" />} 
            </NavLink>
          </Menu.Item>
        )}
  
        {/* Tax Center */}
        {isSubMenuVisible('dashboard_status.sidebarUnlocked.taxCentre.documentsVault', ['documentsVault']) && (
          <SubMenu
            title={<NavLink to={`/app/documents-vault`} style={{ color: "#545454" }}>Tax Center</NavLink>}
            key="tax-center"
            onClick={() => handleTitleClick({ item: "Tax Center", path: "tax-center" })}
            icon={<TaxCentre />}
          >
            {isSubMenuVisible('dashboard_status.sidebarUnlocked.taxCentre.documentsVault', ['documentsVault']) && (
              <Menu.Item
                onClick={() => handleTitleClick({ item: "Document Vault", path: "documents-vault" })}
                key="documents-vault"
                icon={<Documents />}
                hidden={hideMenuItem("documents-vault")}
              >
                <NavLink to={`/app/documents-vault`} activeClassName="selectedRouteClass">
                  Documents Vault
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}
  
        {/* Assist */}
        {isFeatureEnabled('dashboard_status.sidebarUnlocked.assist', 'chats') && (
          <Menu.Item
            key="chats"
            onClick={() => handleSubmenuClick("chats")}
            icon={<ChatIcon />}
          >
            <NavLink to={`/app/chats`} activeClassName="selectedRouteClass">
              Assist
            </NavLink>
          </Menu.Item>
        )}
  
        {/* Settings */}
        {/* {isSubMenuVisible('dashboard_status.sidebarUnlocked.settings', ['profile', 'roles', 'team', 'subscription', 'integrations']) && (
          <SubMenu
            title="Settings"
            key="settings"
            icon={<Settings />}
            hidden={hideMenuItem("settings")}
            onClick={() => handleTitleClick({ item: "Settings", path: "settings" })}
          >
            {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.profile', 'profile') || permissionsData["profile"])&& (
              <Menu.Item
                key="profile"
                onClick={() => handleSubmenuClick("profile")}
                icon={<Profile />}
              >
                <NavLink to="settings/profile">Profile</NavLink>
              </Menu.Item>
            )}
  
            {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.roles', 'roles') || permissionsData["roles"]) && (
              <Menu.Item
                key="roles"
                onClick={() => handleSubmenuClick("roles")}
                icon={<Roles />}
              >
                <NavLink to="settings/roles">Roles</NavLink>
              </Menu.Item>
            )}
  
            {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.teams', 'team') || permissionsData["team"])  && (
              <Menu.Item
                key="team"
                onClick={() => handleSubmenuClick("team")}
                icon={<Teams />}
              >
                <NavLink to="settings/team">Teams</NavLink>
              </Menu.Item>
            )}
  
            {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.subscriptions', 'subscription') || permissionsData["subscription"]) && (
              <Menu.Item
                key="plans-preferences"
                onClick={() => handleSubmenuClick("subscriptions")}
                icon={<Subscription />}
              >
                <NavLink to="settings/plans-preferences">Subscriptions</NavLink>
              </Menu.Item>
            )}
  
            {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.integrations', 'integrations') || permissionsData["integrations"]) && (
              <Menu.Item
                key="integrations"
                onClick={() => handleSubmenuClick("integrations")}
                icon={<Integrations />}
              >
                <NavLink to="settings/integrations">Integrations</NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )} */}
      </Menu>

      <div className={classNames.footer}>
        {/* Assist */}
        <Menu mode="inline" className={classNames.profileMenu}>
        {(isFeatureEnabled('dashboard_status.sidebarUnlocked.settings.integrations', 'integrations') || permissionsData["integrations"]) && (
          <Menu.Item
            key="link-accounts"
            onClick={() => handleSubmenuClick("link-accounts")}
            icon={<Integrations />}
          >
            <NavLink to={`settings/integrations`} activeClassName="selectedRouteClass">
            Link Accounts {(disconnectedAccounts.plaid.length > 0 || disconnectedAccounts.quickbooks.length > 0) && <InfoCircleFilled style={{color: '#C85103', marginLeft: '10px'}} />}  
            </NavLink>
          </Menu.Item>
        )}
          {/* {businesses.length > 0 && (
            <SubMenu
              key="businesses"
              icon={<GenIcon type="shop" />}
              title="Your Businesses"
            >
              {businesses.map((business, index) => (
                <Menu.Item
                  value={selectedBusiness}
                  key={business.business_id}
                  onClick={() => {
                    if (selectedBusiness.business_id === business.business_id) {
                      return;
                    }
                    handleChange(business);
                    secureLocalStore.setItem("bid", business.business_id);
                  }}
                  style={{
                    background:
                      selectedBusiness.business_id === business.business_id
                        ? "#f0f0f0"
                        : "",
                    fontWeight:
                      selectedBusiness.business_id === business.business_id
                        ? "bold"
                        : "",
                  }}
                >
                  <span>{business.name}</span>
                </Menu.Item>
              ))}
            </SubMenu>
          )} */}
          {/* <Menu.Item h> */}
          {/* Assuming you have a logout function, you can bind it to the onClick event */}
          {/* </Menu.Item> */}
          {menu}
        </Menu>
      </div>
    </div>
  );
}
