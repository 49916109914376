const UploadFIle = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_12236_131409)">
        <path d="M8.25 10.5L4.5 10.5L9 6L13.5 10.5H9.75V15.75L8.25 15.75V10.5ZM15 3.75L3 3.75L3 9H1.5L1.5 3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25L15.75 2.25C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V9H15L15 3.75Z" fill="#3B3F43"/>
        </g>
        <defs>
        <clipPath id="clip0_12236_131409">
        <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 -1 18 18)"/>
        </clipPath>
        </defs>
        </svg>
    );
  };
  
  export default UploadFIle;
  