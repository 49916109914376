import React, { useState } from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../../firebaseConfig";
import mixpanel from "mixpanel-browser";
import Bugsnag from "@bugsnag/js";
import { Form, Button, Input as AntInput, Divider } from "antd";
import { 
  // FaMicrosoft, 
  FaGoogle, 
  // FaApple 
} from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
// import isEmpty from "lodash/isEmpty";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { CTAButton, Input, Message } from "../../common";
import api from "../../API";
import Heading from "../Heading";
import generateErrorMessage from "../../util/functions/customError";
import lowerCase from "../../util/functions/lowerCase";
import {
  ROUTES,
} from "../../constants";
import { validation } from "../config";
import classNames from "./Login.module.scss";

const Login = () => {
  const navigate = useNavigate();

  // Only for testing
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false });

  const [form] = Form.useForm();
  const [disable, setDisable] = useState(true);
  const [, setOnboardingStatus] = useState(null);

  // Use secure local storage instead of secureLocalStore
  const secureLocalStore = window.localStorage || window.sessionStorage;

  /* Mutation definition for API calls */
  // Login mutation
  const loginMutation = useMutation(data => api.Services.Auth.loginToken(data), {
    onSuccess: (data) => {
      // Handle successful login
      console.log("Login successful", data);
      
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }
      
      // Store the access token in session storage
      if (data && data.data && data.data.access_token) {
        if (data.data.is_accountant) {
          Message({ type: "error", content: "Login failed: Not an client account" });
          return;
        }

        Message({ type: "success", content: "Login successful !" });

        secureLocalStore.setItem('accessToken', data.data.access_token);
        secureLocalStore.setItem('otterz_id', data.data.otterz_id);
        secureLocalStore.setItem('email', data.data.user.email_id);
        secureLocalStore.setItem('email_verification', data.data.user.email_verification);
        secureLocalStore.setItem('is_accountant', false);

        console.log("Email: ", secureLocalStore.getItem('email_verification'))

        getOnboardingStatus.mutate({ otterz_id: data.data.otterz_id });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      Bugsnag.notify("Error in login", error, error.message)
      Message({ type: "error", content: error.message });
    },
  });

  // getch status mutation
  const getOnboardingStatus = useMutation(data => api.Services.Onboarding.getOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Onboarding status fetched successfully", data);

        const email_verified = secureLocalStore.getItem('email_verification');
        const status = data.data?.status;
        setOnboardingStatus(status);
      
        console.log("Email verified: ", email_verified);
        console.log("Status: ", status, data);

        // Based on the onboarding status, navigate to the appropriate route
        if (email_verified === "false") {
          console.log("Email is not verified");
          navigate(ROUTES.VERIFICATION.EMAIL_VERIFY);
        } else {
          switch (status) {
            case 'ADD_PREFERENCE':
              navigate(ROUTES.ONBOARDING.USE_PREFERENCE);
              break;
            case 'SETUP_COPILOT':
              navigate(ROUTES.ONBOARDING.SETUP_COPILOT);
              break;
            case 'CONNECT_PLAID':
              navigate(ROUTES.ONBOARDING.LINK_BANK);
              break;
            case 'LINK_BUSINESS':
              navigate(ROUTES.ONBOARDING.LINK_BUSINESS_BANK);
              break;
            case 'CONNECT_QUICKBOOKS':
              navigate(ROUTES.ONBOARDING.LINK_QUICKBOOKS);
              break;
            case 'SETUP_PAYMENTS':
              navigate(ROUTES.ONBOARDING.SETUP_PAYMENTS);
              break;
            case 'SUBSCRIPTION_ONBOARD':
              navigate(ROUTES.ONBOARDING.ONBOARD_SUBSCRIPTION);
              break;
            case 'INVITE_OWNERS':
              navigate(ROUTES.ONBOARDING.INVITE_OWNERS);
              break;
            case 'ONBOARDED':
              navigate(ROUTES.APP.INDEX);
              break;
            // Add more cases as needed
            default:
              navigate(ROUTES.ONBOARDING.CREATE_PROFILE);
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });  

  const googleProvider = new GoogleAuthProvider();
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // This gives you a Google Access Token. You can use it to access Google APIs.
      const credential = GoogleAuthProvider.credentialFromResult(result);

      // The signed-in user info.
      const user = result.user;

      const accessToken = result._tokenResponse.idToken

      secureLocalStore.setItem('accessToken', accessToken);
      secureLocalStore.setItem('otterz_id', user.uid);
      secureLocalStore.setItem('email', result._tokenResponse.email);
      secureLocalStore.setItem('email_verification', true);
      secureLocalStore.setItem('is_accountant', false);

      console.log("User: ", result, credential);
      Message({ type: "success", content: "Google sign in successful" });

      // loginMutation.mutate({ idToken: token });
      getOnboardingStatus.mutate({ otterz_id: user.uid });
      // Navigate to the desired route after successful login
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;

      // The email of the user's account used.
      const email = error.email;

      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);

      // Show error message
      console.error("Google Sign in error: ", errorCode, errorMessage);
      console.log("MetaData: ", credential, email)
    }
  };

  //Event handlers
  const handleOnFinish = async () => {
    try {
      await form.validateFields();
      
      const credentials = {
        email: lowerCase(form.getFieldValue("email").toString()),
        password: form.getFieldValue("password").toString()
      }

      loginMutation.mutate(credentials);
    } catch (error) {
      generateErrorMessage(error);
    }
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Login"
        //   description="Please enter your email address to login"
        />
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={handleOnFinish}
          onValuesChange={(changedValues, { email }) => {
            if (changedValues && email?.length > 0) {
              setDisable(false);
            } else if (changedValues && email?.length === 0) {
              setDisable(true);
            }
          }}
          autoComplete="off"
          scrollToFirstError={true}
          style={{marginBottom: "15vh"}}
        >
          <Form.Item
            // label="Email Address"
            name="email"
            rules={[
              { required: true, message: validation.email.required },
              { type: 'email', message: validation.email.valid }
            ]}
            className={classNames.signupFormInputs}
          >
            <Input placeholder={validation.email.placeholder} />
          </Form.Item>
          <>
            <Form.Item
              // label="Password"
              name="password"
              rules={[
                { required: true, message: validation.password.required },
              ]}
              className={classNames.signupFormInputs}
            >
              <AntInput.Password
                placeholder={validation.password.placeholder}
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                }
              />
            </Form.Item>
            <div className={classNames.options}>
              <p onClick={() => navigate(ROUTES.PUBLIC.FORGOT_PASSWORD)}>
                Forgot password?
              </p>
            </div>
          </>

          <CTAButton
            htmlType="submit"
            type="primary"
            loading={loginMutation.isLoading || getOnboardingStatus.isLoading }
            disabled={disable || loginMutation.isLoading || getOnboardingStatus.isLoading }
          >
            Login
          </CTAButton>

          {/* Divider with "OR" */}
          <Divider>OR</Divider>

          {/* Icons for Microsoft, Gmail, and Apple */}
          <div className={classNames.socialLogin}>
            {/* <FaMicrosoft className={classNames.socialIcon} onClick={() => {}} /> */}
            <FaGoogle className={classNames.socialIcon} onClick={() => {handleGoogleSignIn()}} />
            {/* <FaApple className={classNames.socialIcon} onClick={() => {}} /> */}
          </div>

          <div className={classNames.links}>
            <span>Don't have an account?</span>
            <Button
              type="link"
              size="small"
              onClick={() => navigate(ROUTES.PUBLIC.SIGN_UP)}
            >
              Sign up here
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
