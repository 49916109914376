import { Outlet, useNavigate } from "react-router-dom";
import "./App.less";
import "remixicon/fonts/remixicon.css";
import "react-vis/dist/style.css";
import "swiper/css";
import { QueryClient, QueryClientProvider, useMutation } from "react-query";
import { useEffect, useState } from "react";
import api from "./App/API";
import Bugsnag from "@bugsnag/js";
import { ROUTES } from "./App/constants";
import { Spin } from "antd";
import { useAccount } from "./store/StoreProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppOnboarding = () => {
  const navigate = useNavigate();
  
  // Use secure local storage instead of secureLocalStore
  const secureLocalStore = window.localStorage || window.sessionStorage;
  const { setUserData } = useAccount();

  const routeToRoot = () => {
    const { pathname } = window.location;
    if (pathname.includes("/login")) {
      navigate(ROUTES.PUBLIC.LOGIN);
    } else if (pathname.includes("/signup")) {
      navigate(ROUTES.PUBLIC.SIGNUP);
    } else if (pathname.includes("/forgot-password")) {
      navigate(ROUTES.PUBLIC.FORGOT_PASSWORD);
    } else {
      navigate(ROUTES.PUBLIC.LOGIN); // Default to login if neither login nor signup is found
    }
    console.log("Route to root");
    setIsLoading(false); // Update loading state
  }

  // Function to check if an access token is expired
  async function checkAccessTokenValidity() {
    try {
      const response = await api.Services.Onboarding.viewUserByID({ otterz_id: secureLocalStore.getItem("otterz_id")});
      console.log(response);
      setUserData(response.data[0]);
      const expired = response.status === false && response.errors && response.errors[0].code === "OTZT401";
      console.log("Token expired:", expired);

      if (!expired && secureLocalStore.getItem("otterz_id")) {
        try {
          console.log("Access token found is not expired ✔");
  
          // Call getOnboardingStatus to determine next screen
          const otterz_id = secureLocalStore.getItem("otterz_id");
          getOnboardingStatus.mutate({ otterz_id: otterz_id });
        } catch (error) {
          localStorage.clear()
          console.log("Access token found is expired");
          routeToRoot()
        }
      } else {
        localStorage.clear()
        console.log("Access token found is expired");
        routeToRoot()
      }
    } catch (error) {
      localStorage.clear()
      console.log("Access token found is expired");
      routeToRoot()
    }
  }

  /* Session redirect */
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Check if access token exists in local storage
    const accessToken = window.localStorage.getItem("accessToken");
    const is_accountant = window.localStorage.getItem("is_accountant");

    console.log(is_accountant, is_accountant === "true");
    if (accessToken && is_accountant === "false") {
      console.log("access token found and user is not an accountant");
      checkAccessTokenValidity();
    } else {
      console.log("No access token found or user is an accountant");
      routeToRoot();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // getch status mutation
  const getOnboardingStatus = useMutation(data => api.Services.Onboarding.getOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Bugsnag.notify(new Error(data.errors[0].message, "getOnboardingStatus-session-redirect-error"));
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Onboarding status fetched successfully", data);

        const email_verified = secureLocalStore.getItem('email_verification');
        const status = data.data?.status;
      
        console.log("Email verified: ", email_verified);
        console.log("Status: ", status);

        // Based on the onboarding status, navigate to the appropriate route
        if (email_verified === "false") {
          console.log("Email is not verified");
          navigate(ROUTES.VERIFICATION.EMAIL_VERIFY);
        } else {
          // get current route in browser
          const currentRoute = window.location.pathname;

          switch (status) {
            case 'ADD_PREFERENCE':
              navigate(ROUTES.ONBOARDING.USE_PREFERENCE);
              break;
            case 'SETUP_COPILOT':
              navigate(ROUTES.ONBOARDING.SETUP_COPILOT);
              break;
            case 'CONNECT_PLAID':
              navigate(ROUTES.ONBOARDING.LINK_BANK);
              break;
            case 'LINK_BUSINESS':
              navigate(ROUTES.ONBOARDING.LINK_BUSINESS_BANK);
              break;
            case 'CONNECT_QUICKBOOKS':
              navigate(ROUTES.ONBOARDING.LINK_QUICKBOOKS);
              break;
            case 'SETUP_PAYMENTS':
              navigate(ROUTES.ONBOARDING.SETUP_PAYMENTS);
              break;
            case 'SUBSCRIPTION_ONBOARD':
              navigate(ROUTES.ONBOARDING.ONBOARD_SUBSCRIPTION);
              break;
            case 'INVITE_OWNERS':
              navigate(ROUTES.ONBOARDING.INVITE_OWNERS);
              break;
            case 'ONBOARDED':
              navigate(currentRoute);
              break;
            // Add more cases as needed
            default:
              navigate(ROUTES.ONBOARDING.CREATE_PROFILE);
          }
        }
        setIsLoading(false); // Update loading state
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      Bugsnag.notify(new Error(error, "getOnboardingStatus-session-redirect-error"));
    },
  });

  return (
    <>
      {
        isLoading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Spin size="large" />
          </div>
        ) : (
          <QueryClientProvider client={queryClient}>
            <div className="App">
              <Outlet />
            </div>
          </QueryClientProvider>
        )
      }
      {/* <QueryClientProvider client={queryClient}>
        <div className="App">
          <Outlet />
        </div>
      </QueryClientProvider> */}
    </>
  );
};

export default AppOnboarding;
