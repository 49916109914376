import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Spin, Card, Typography } from "antd";
import styles from "./Contractors.module.scss";
import { CTAButton, Message, Select } from "../../../common";
import { useMutation } from "react-query";
import Bugsnag from "@bugsnag/js";
import api from "../../../API";

import AccountantChatPopUp from '../AccountantChatPopUp/AccountantChatPopUp';
// import { UploadOutlined } from "@ant-design/icons";
import { sendWFormRequest, updateContractorEmail } from "../../../API/Services";

const { Option } = Select;
const { Text } = Typography;
// const { Search } = Input;

const Contractors = ({ profileData }) => {
  const [activeTab, setActiveTab] = useState("QB Contractors");
  const [vendorId, setVendorId] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [selectedQbContractorsRowKeys, setSelectedQbContractorsRowKeys] = useState([]);
  const [selectedForReviewRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedOthersRowKeys, setOtherRowKeys] = useState([]);
  // const [fileLoading, ] = useState(false); // Loading state for initial data fetching
  // const [, setFileInput] = useState(null); // Input for uploading files
  // const [, setFileList] = useState([]);
  // const [ /*uploadingRows*/, setUploadingRows] = useState({});

  const [qbContractors, setQbContractors] = useState([]);
  const [forReview, setForReview] = useState([]);
  const [rejectedContractors, setRejectedContractors] = useState([]);
  // const [otherVendors, setOtherVendors] = useState([]);

  const [reload, setReload] = useState(false);
  const [selectedTaxForm, setTaxFormSelected] = useState({
    form: '1099-MISC',
    deadline: '08/01/2024',
  });
  const [loading, setLoading] = useState(false);
  const [w9Loading, setLoadingW9] = useState(false);

  /* helper functions */
  // handle for review row select
  const onQbContractorsSelectChange = (selectedKeys) => {
    console.log("selectedKeys changed: ", selectedKeys);
    setSelectedQbContractorsRowKeys(selectedKeys);
  };

  const onForReviewSelectChange = (selectedKeys) => {
    console.log("selectedKeys changed: ", selectedKeys);
    setSelectedRowKeys(selectedKeys);
  };

  // handle for review row select
  const onOthersSelectChange = (selectedKeys) => {
    console.log("selectedKeys changed: ", selectedKeys);
    setOtherRowKeys(selectedKeys);
  };

  // Function to handle file input change
  // const handleFileInputChange = (event, record, folderName) => {
  //   console.log("File input change event:", event);

  //   // Set loading state for the specific row
  //   setUploadingRows((prevState) => ({
  //     ...prevState,
  //     [record.key]: true,
  //   }));

  //   setFileInput(event.fileList[0].originFileObj);

  //   // Execute file upload logic
  //   uploadToFolder({
  //     business_id: profileData.business_id,
  //     otterz_id: localStorage.getItem("otterz_id"),
  //     folder_name: folderName || "Contractor W-9",
  //     file: event.fileList[0].originFileObj,
  //   }).then((data) => {
  //     console.log("File upload response:", data);
  //     if (data.status) {
  //       Message({
  //         type: "success",
  //         content: "File uploaded successfully",
  //       });
  //     } else {
  //       Message({
  //         type: "error",
  //         content: data.errors[0].message,
  //       });
  //     }

  //     // Reset loading state for the specific row
  //     setUploadingRows((prevState) => ({
  //       ...prevState,
  //       [record.key]: false,
  //     }));
  //   });
  // };

  // Function to handle confirm categories for forReview
  const handleCategories = (action) => {
    const filteredVendors = forReview.filter((vendor) =>
      selectedForReviewRowKeys.includes(vendor.key)
    ).map((vendor) => vendor.vendor);
    console.log("filteredVendors", filteredVendors);    

    if (filteredVendors.some((vendor) => vendor.form_type === "1099-OTHER")) {
      Message({
        type: "error",
        content: "Please select 1099-Type for all selected contractors",
      });
      return;
    }

    if (action === "confirm") {
      confirmVendor.mutate({
        business_id: profileData.business_id,
        contractors: filteredVendors,
      });
    } else if (action === "reject") {
      rejectVendor.mutate({
        business_id: profileData.business_id,
        contractors: filteredVendors,
      });
    } else {
      if (filteredVendors.some((vendor) => vendor?.PrimaryEmailAddr === undefined)) {
        Message({
          type: "error",
          content: "Please enter email for all selected contractors",
        });
        return;
      }
      if (filteredVendors.some((vendor) => vendor?.taxIdForm === undefined)) {
        Message({
          type: "error",
          content: "Please add W9 form type for the selected contractors",
        });
        return;
      }

      filteredVendors.forEach((vendor) => {
        if (vendor?.PrimaryEmailAddr === undefined) {
          return;
        }
        console.log("vendor", profileData.business_id, vendor.PrimaryEmailAddr.Address, vendor.form_type, vendor?.taxIdForm, vendor?.vendor?.taxIdForm);
        
        setLoadingW9(true);
        sendWFormRequest({
          business_id: profileData.business_id,
          email_id: vendor.PrimaryEmailAddr.Address,
          form_type: vendor.form_type,
          taxIdForm: vendor?.taxIdForm,
          id: vendor?.id
        })
          .then((data) => {
            if (data.status) {
              Message({
                type: "success",
                content: "Request sent successfully",
              });
            } else {
              Message({
                type: "error",
                content: data.errors[0].message,
              });
            }
          })
          .catch((error) => {
            Message({
              type: "error",
              content: "An error occurred. Please try again.",
            });
          })
          .finally(() => {
            setLoadingW9(false);
          });
        

      });

    }
  };

  // Function to handle confirm categories for forReview
  const handleOtherVendorsCategories = (action) => {
    const filteredVendors = rejectedContractors.filter((vendor) =>
      selectedOthersRowKeys.includes(vendor.key)
    ).map((vendor) => vendor.vendor); 

    if (filteredVendors.some((vendor) => vendor.form_type === "1099-OTHER")) {
      Message({
        type: "error",
        content: "Please select 1099-Type for all selected contractors",
      });
      return;
    }

    if (action === "confirm") {
      confirmVendor.mutate({
        business_id: profileData.business_id,
        contractors: filteredVendors,
      });
    } else if (action === "reject") {
      rejectVendor.mutate({
        business_id: profileData.business_id,
        contractors: filteredVendors,
      });
    } else {
      // api call to ask fow
      if (filteredVendors.some((vendor) => vendor?.PrimaryEmailAddr === undefined)) {
        Message({
          type: "error",
          content: "Please enter email for all selected contractors",
        });
        return;
      }
      if (filteredVendors.some((vendor) => vendor?.taxIdForm === undefined)) {
        Message({
          type: "error",
          content: "Please add W9 form type for the selected contractors",
        });
        return;
      }

      filteredVendors.forEach((vendor) => {
        if (vendor?.PrimaryEmailAddr === undefined) {
          return;
        }
        console.log("vendor", profileData.business_id, vendor.PrimaryEmailAddr.Address, vendor.form_type, vendor?.taxIdForm, vendor?.vendor?.taxIdForm);
        
        setLoadingW9(true);
        sendWFormRequest({
          business_id: profileData.business_id,
          email_id: vendor.PrimaryEmailAddr.Address,
          form_type: vendor.form_type,
          taxIdForm: vendor?.taxIdForm,
          id: vendor?.id
        })
          .then((data) => {
            if (data.status) {
              Message({
                type: "success",
                content: "Request sent successfully",
              });
            } else {
              Message({
                type: "error",
                content: data.errors[0].message,
              });
            }
          })
          .catch((error) => {
            Message({
              type: "error",
              content: "An error occurred. Please try again.",
            });
          })
          .finally(() => {
            setLoadingW9(false);
          });
        

      });
    }
  };

  // Function to handle confirm categories for forReview
  const handleQBContractorsCategories = (action) => {
    const filteredVendors = qbContractors.filter((vendor) =>
      selectedQbContractorsRowKeys.includes(vendor.key)
    ).map((vendor) => vendor.vendor); 

    if (action === "confirm") {
      confirmVendor.mutate({
        business_id: profileData.business_id,
        contractors: filteredVendors,
      });
    } else if (action === "reject") {
      rejectVendor.mutate({
        business_id: profileData.business_id,
        contractors: filteredVendors,
      });
    } else {
      // api call to ask fow
      if (filteredVendors.some((vendor) => vendor?.PrimaryEmailAddr === undefined)) {
        Message({
          type: "error",
          content: "Please enter email for all selected contractors",
        });
        return;
      }
      if (filteredVendors.some((vendor) => vendor?.taxIdForm === undefined)) {
        Message({
          type: "error",
          content: "Please add W9 form type for the selected contractors",
        });
        return;
      }

      filteredVendors.forEach((vendor) => {
        if (vendor?.PrimaryEmailAddr === undefined) {
          return;
        }
        console.log("vendor", profileData.business_id, vendor.PrimaryEmailAddr.Address, vendor.form_type, vendor?.taxIdForm, vendor?.vendor?.taxIdForm);
        
        setLoadingW9(true);
        sendWFormRequest({
          business_id: profileData.business_id,
          email_id: vendor.PrimaryEmailAddr.Address,
          form_type: vendor.form_type,
          taxIdForm: vendor?.taxIdForm,
          id: vendor?.id
        })
          .then((data) => {
            if (data.status) {
              Message({
                type: "success",
                content: "Request sent successfully",
              });
            } else {
              Message({
                type: "error",
                content: data.errors[0].message,
              });
            }
          })
          .catch((error) => {
            Message({
              type: "error",
              content: "An error occurred. Please try again.",
            });
          })
          .finally(() => {
            setLoadingW9(false);
          });
        

      });
    }
  };

  /* API calls definition */
  // Get list of contractors
  const getListOfContractors = useMutation(
    (business_id) => api.Services.AccountantsDashboard.Contractors.getListOfContractors(business_id),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getListOfContractors", data.data);

          if (data.data.contractors) {
            const contractors = data.data.contractors.map(
              (contractor, index) => {
                return {
                  key: contractor?.id,
                  name: (contractor?.GivenName || contractor?.FamilyName) ? `${contractor?.GivenName || ''} ${contractor?.FamilyName || ''}` : '-',
                  company: contractor?.CompanyName || '-',
                  address: (contractor?.BillAddr?.Line1 || contractor?.BillAddr?.City || contractor?.BillAddr?.CountrySubDivisionCode || contractor?.BillAddr?.PostalCode) 
                  ? `${contractor?.BillAddr?.Line1 || ''} ${contractor?.BillAddr?.City || ''} ${contractor?.BillAddr?.CountrySubDivisionCode || ''} ${contractor?.BillAddr?.PostalCode || ''}` 
                  : '-',
                  email: contractor?.PrimaryEmailAddr?.Address || '-',
                  accountNumber: contractor?.AcctNum || '-',
                  totalPayments: contractor?.Balance ? `$ ${contractor?.Balance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}` : '-',
                  type1099: contractor?.form_type || '-',
                  w9Status: contractor?.W9Status || '-',
                  taxIdForm: contractor?.taxIdForm || 'Choose form',
                  vendor: contractor,
                };
              }
            );
            setQbContractors(contractors);
            console.log("getListOfContractors", contractors);
          }
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getListOfContractors",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  // Get list of vendors
  const getListOfVendors = useMutation(
    (business_id) =>
      api.Services.AccountantsDashboard.Contractors.getListOfVendors(
        business_id
      ),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getListOfVendors", data.data);
          if (data.data.vendors) {
            const vendors = data.data.vendors.map(
              (vendor, index) => {
                return {
                  key: vendor?.id,
                  name: (vendor?.GivenName || vendor?.FamilyName) ? `${vendor?.GivenName || ''} ${vendor?.FamilyName || ''}` : '-',
                  company: vendor?.CompanyName || '-',
                  address: (vendor?.BillAddr?.Line1 || vendor?.BillAddr?.City || vendor?.BillAddr?.CountrySubDivisionCode || vendor?.BillAddr?.PostalCode) 
                    ? `${vendor?.BillAddr?.Line1 || ''} ${vendor?.BillAddr?.City || ''} ${vendor?.BillAddr?.CountrySubDivisionCode || ''} ${vendor?.BillAddr?.PostalCode || ''}` 
                    : '-',
                  email: vendor?.PrimaryEmailAddr?.Address || '-',
                  accountNumber: vendor?.AcctNum || '-',
                  totalPayments: vendor?.Balance ? `$ ${vendor?.Balance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}` : '-',
                  type1099: vendor?.form_type || '-',
                  taxIdForm: vendor?.taxIdForm || 'Choose form',
                  vendor: vendor,
                };
              }
            );
            setForReview(vendors);
            console.log("getListOfVendors", vendors);
          }
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getListOfVendors",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );
  
  const getListOfRejectedContractors = useMutation(
    (business_id) =>
      api.Services.AccountantsDashboard.Contractors.getListOfRejectedContractors(
        business_id
      ),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getListOfRejectedContractors", data.data);
          if (data.data.rejected_contractors) {
            const vendors = data.data.rejected_contractors.map(
              (vendor, index) => {
                return {
                  key: vendor?.id,
                  name: (vendor?.GivenName || vendor?.FamilyName) ? `${vendor?.GivenName || ''} ${vendor?.FamilyName || ''}` : '-',
                  company: vendor?.CompanyName || '-',
                  address: (vendor?.BillAddr?.Line1 || vendor?.BillAddr?.City || vendor?.BillAddr?.CountrySubDivisionCode || vendor?.BillAddr?.PostalCode) 
                    ? `${vendor?.BillAddr?.Line1 || ''} ${vendor?.BillAddr?.City || ''} ${vendor?.BillAddr?.CountrySubDivisionCode || ''} ${vendor?.BillAddr?.PostalCode || ''}` 
                    : '-',
                  email: vendor?.PrimaryEmailAddr?.Address || '-',
                  accountNumber: vendor?.AcctNum || '-',
                  totalPayments: vendor?.Balance ? `$ ${vendor?.Balance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}` : '-',
                  type1099: vendor?.form_type || '-',
                  taxIdForm: vendor?.taxIdForm || 'Choose form',
                  vendor: vendor,
                };
              }
            );
            setRejectedContractors(vendors);
            console.log("getListOfRejectedContractors", vendors);
          }
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getListOfVendors",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );
  
  const confirmVendor = useMutation(
    (data) =>
      api.Services.AccountantsDashboard.Contractors.confirmVendors(data),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("confirmVendors", data.data);
          setReload((prev) => !prev); // Toggle reload to trigger useEffect
          Message({
            type: "success",
            content: "Vendor confirmed",
          });
          setSelectedRowKeys([]);
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: confirmVendors",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const rejectVendor = useMutation(
    (data) =>
      api.Services.AccountantsDashboard.Contractors.rejectVendors(data),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("rejectVendor", data.data);
          setReload((prev) => !prev); // Toggle reload to trigger useEffect
          Message({
            type: "success",
            content: "Vendor rejected",
          });
          setSelectedRowKeys([]);
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: rejectVendor",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );
  
  useEffect(() => {
    if (profileData.business_id) {
      
      getListOfContractors.mutate({
        business_id: profileData.business_id,
        form_type: selectedTaxForm.form
      });
      getListOfVendors.mutate({
        business_id: profileData.business_id,
        form_type: selectedTaxForm.form
      });
      getListOfRejectedContractors.mutate(profileData.business_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData, selectedTaxForm]);


  useEffect(() => {
    if (selectedTaxForm) {
      getListOfContractors.mutate({
        business_id: profileData.business_id,
        form_type: selectedTaxForm.form
      });
      getListOfVendors.mutate({
        business_id: profileData.business_id,
        form_type: selectedTaxForm.form
      });
      getListOfRejectedContractors.mutate(profileData.business_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, profileData, selectedTaxForm]);

  const columnsQBContractors = [
    { title: "Full Name", dataIndex: "name" },
    { title: "Company", dataIndex: "company" },
    { title: "Address", dataIndex: "address" },
    { 
      title: "Email", 
      dataIndex: "email", 
      width: '15%',
      render: (text, record) => {
        // Handler to update the local state for email
        const handleEmailChange = (value, key) => {
          // check if value is in format of an email using regex
          if (!value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
            Message({ type: "error", content: "Please enter a valid email" });
            return;
          }
          setQbContractors((prevContractors) =>
            prevContractors.map((vendor) =>
              vendor.key === record.key
                ? { ...vendor, email: value, vendor: { ...vendor.vendor, PrimaryEmailAddr: { Address: value } } }
                : vendor
            )
          );
          
          updateContractorEmail({
            business_id: profileData.business_id,
            contractor_id: key,
            email_id: value, // Send the updated email
          })
        };

        // Handler to trigger the API call after editing
        const handleEmailSave = (value, key) => {
          console.log("DONE");
        };

        return (
          <Text
            editable={{
              onChange: (value) => handleEmailChange(value, record.key), // Update email in state
              onEnd: (value) => handleEmailSave(value, record.key), // Trigger API on save
            }}
          >
            {record.email}
          </Text>
        );
      }
    },
    { title: "Account Number", dataIndex: "accountNumber" },
    { title: "Total Amount Paid", dataIndex: "totalPayments" },
    {
      title: "1099-Type",
      dataIndex: "type1099",
      render: (text, record) => (
        <Select
          defaultValue={record.type1099}
          value={record.confirmCategory}
          onChange={(value) => {
            setQbContractors((prevContractors) =>
              prevContractors.map((vendor) =>
                vendor.key === record.key
                  ? { ...vendor, vendor: { ...vendor.vendor, form_type: value } }
                  : vendor
              )
            );
          }}
          style={{ width: 'fit-content' }}
        >
          <Option value="1099-MISC">1099-MISC</Option>
          <Option value="1099-NEC">1099-NEC</Option>
        </Select>
      ),
    },
    {
      title: "W-9/W-8",
      dataIndex: "taxIdForm",
      render: (text, record) => (
        <Select
          defaultValue={record.taxIdForm}
          value={record.confirmCategory}
          onChange={(value) => {
            setQbContractors((prevContractors) =>
              prevContractors.map((vendor) =>
                vendor.key === record.key
                  ? { ...vendor, vendor: { ...vendor.vendor, taxIdForm: value } }
                  : vendor
              )
            );
          }}
          style={{ width: 'fit-content' }}
        >
          <Option value="FormW8BEN">W-8 BEN</Option>
          <Option value="FormW8BENE">W-8 BEN-E</Option>
          <Option value="FormW9">W-9</Option>
        </Select>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <CTAButton
          style={{
            fontSize: "0.9rem",
            height: "40px",
            width: "90px",
          }}
          onClick={() => {
              setShowChat(true);
              setVendorId(record);
            }
          }
        >
          Chat
        </CTAButton>
      ),
    },
  ]

  const columnsForReview = [
    { title: "Full Name", dataIndex: "name" },
    { title: "Company", dataIndex: "company" },
    { title: "Address", dataIndex: "address" },
    { 
      title: "Email", 
      dataIndex: "email", 
      width: '15%',
      render: (text, record) => {
        // Handler to update the local state for email
        const handleEmailChange = (value, key) => {
          // check if value is in format of an email using regex
          if (!value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
            Message({ type: "error", content: "Please enter a valid email" });
            return;
          }

          console.log("record", record.key);
          
          setForReview((prevContractors) =>
            prevContractors.map((vendor) =>
              vendor.key === record.key
                ? { ...vendor, email: value, vendor: { ...vendor.vendor, PrimaryEmailAddr: { Address: value } } }
                : vendor
            )
          );

          
          updateContractorEmail({
            business_id: profileData.business_id,
            contractor_id: key,
            email_id: value, // Send the updated email
          })
        };

        // Handler to trigger the API call after editing
        const handleEmailSave = (value, key) => {
          console.log("DONE");
        };

        return (
          <Text
            editable={{
              onChange: (value) => handleEmailChange(value, record.key), // Update email in state
              onEnd: (value) => handleEmailSave(value, record.key), // Trigger API on save
            }}
          >
            {record.email}
          </Text>
        );
      }
    },
    { title: "Account Number", dataIndex: "accountNumber" },
    { title: "Total Amount Paid", dataIndex: "totalPayments" },
    {
      title: "1099-Type",
      dataIndex: "type1099",
      render: (text, record) => (
        <Select
          defaultValue={record.type1099}
          value={record.confirmCategory}
          onChange={(value) => {
            setForReview((prevContractors) =>
              prevContractors.map((vendor) =>
                vendor.key === record.key
                  ? { ...vendor, vendor: { ...vendor.vendor, form_type: value } }
                  : vendor
              )
            );
          }}
          style={{ width: 'fit-content' }}
        >
          <Option value="1099-MISC">1099-MISC</Option>
          <Option value="1099-NEC">1099-NEC</Option>
        </Select>
      ),
    },
    {
      title: "W-9/W-8",
      dataIndex: "taxIdForm",
      render: (text, record) => (
        <Select
          defaultValue={record.taxIdForm}
          value={record.confirmCategory}
          onChange={(value) => {
            setForReview((prevContractors) =>
              prevContractors.map((vendor) =>
                vendor.key === record.key
                  ? { ...vendor, vendor: { ...vendor.vendor, taxIdForm: value } }
                  : vendor
              )
            );

            console.log("record", record);
          }}
          style={{ width: 'fit-content' }}
        >
          <Option value="FormW8BEN">W-8 BEN</Option>
          <Option value="FormW8BENE">W-8 BEN-E</Option>
          <Option value="FormW9">W-9</Option>
        </Select>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <CTAButton
          style={{
            fontSize: "0.9rem",
            height: "40px",
            width: "90px",
          }}
          onClick={() => {
              setShowChat(true);
              setVendorId(record);
            }
          }
        >
          Chat
        </CTAButton>
      ),
    },
  ]

  const columnsOtherVendors = [
    { title: "Full Name", dataIndex: "name" },
    { title: "Company", dataIndex: "company" },
    { title: "Address", dataIndex: "address" },
    { 
      title: "Email", 
      dataIndex: "email", 
      width: '15%',
      render: (text, record) => {
        // Handler to update the local state for email
        const handleEmailChange = (value, key) => {
          // check if value is in format of an email using regex
          if (!value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
            Message({ type: "error", content: "Please enter a valid email" });
            return;
          }
          setRejectedContractors((prevContractors) =>
            prevContractors.map((vendor) =>
              vendor.key === record.key
                ? { ...vendor, email: value, vendor: { ...vendor.vendor, PrimaryEmailAddr: { Address: value } } }
                : vendor
            )
          );
          
          updateContractorEmail({
            business_id: profileData.business_id,
            contractor_id: key,
            email_id: value, // Send the updated email
          })
        };

        // Handler to trigger the API call after editing
        const handleEmailSave = (value, key) => {
          console.log("DONE");
        };

        return (
          <Text
            editable={{
              onChange: (value) => handleEmailChange(value, record.key), // Update email in state
              onEnd: (value) => handleEmailSave(value, record.key), // Trigger API on save
            }}
          >
            {record.email}
          </Text>
        );
      }
    },
    { title: "Account Number", dataIndex: "accountNumber" },
    { title: "Total Amount Paid", dataIndex: "totalPayments" },
    {
      title: "1099-Type",
      dataIndex: "type1099",
      render: (text, record) => (
        <Select
          defaultValue={record.type1099}
          value={record.confirmCategory}
          onChange={(value) => {
            setRejectedContractors((prevContractors) =>
              prevContractors.map((vendor) =>
                vendor.key === record.key
                  ? { ...vendor, vendor: { ...vendor.vendor, form_type: value } }
                  : vendor
              )
            );
          }}
          style={{ width: 'fit-content' }}
        >
          <Option value="1099-MISC">1099-MISC</Option>
          <Option value="1099-NEC">1099-NEC</Option>
        </Select>
      ),
    },
    {
      title: "W-9/W-8",
      dataIndex: "taxIdForm",
      render: (text, record) => (
        <Select
          defaultValue={record.taxIdForm}
          value={record.confirmCategory}
          onChange={(value) => {
            setRejectedContractors((prevContractors) =>
              prevContractors.map((vendor) =>
                vendor.key === record.key
                  ? { ...vendor, vendor: { ...vendor.vendor, taxIdForm: value } }
                  : vendor
              )
            );
          }}
          style={{ width: 'fit-content' }}
        >
          <Option value="FormW8BEN">W-8 BEN</Option>
          <Option value="FormW8BENE">W-8 BEN-E</Option>
          <Option value="FormW9">W-9</Option>
        </Select>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <CTAButton
          style={{
            fontSize: "0.9rem",
            height: "40px",
            width: "90px",
          }}
          onClick={() => {
              setShowChat(true);
              setVendorId(record);
            }
          }
        >
          Chat
        </CTAButton>
      ),
    },
  ]

  const renderTaxFormCard = [{
    form: '1099-MISC',
    deadline: '08/01/2024',
  },
  {
    form: '1099-NEC',
    deadline: '08/01/2024',
  }].map((taxForm, index) => {
    return (
      <Card
        key={index}
        className={styles.bankCard}
        style={{
          border: taxForm?.form === selectedTaxForm?.form ? "1px solid black": "none",
        }}
        onClick={() => setTaxFormSelected(taxForm)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div>Form ({taxForm.form})</div>
          <div style={{color: "#82898F"}}>Deadline: {taxForm.deadline}</div>
        </div>
      </Card>
    );
  });

  return (
    <div className={styles.contractors}>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Spin size="large" />
        </div>
      ) : ( 
        <div className={styles.tabsHeaderContainer}>
          <div className={styles.bankCardRow} style={{opacity: activeTab !== "QB Contractors" ? "0" : "1"}}>
            {renderTaxFormCard}
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              margin: "1.5rem 0 2rem 0",
            }}
          >
            {["QB Contractors", "For Review", "Other Vendors"].map((filter, index) => (
              <Button
                key={index}
                type="link"
                style={{
                  backgroundColor: filter === activeTab ? "#616161" : "white",
                  color: filter === activeTab ? "white" : "black",
                  borderRadius: "20px",
                  transition: "background-color 0.3s, color 0.3s",
                }}
                onClick={() => {
                  setActiveTab(filter);
                }}
              >
                {filter}
              </Button>
            ))}
          </div>
        </div>
      )}

      {/* QB Contractors: Contractors who have been paid $600 and are listed in QuickBooks */}
      <section style={{ display: activeTab === "QB Contractors" ? "block" : "none", width: 'calc(97vw - 200px)' }}>
        <h1 className={styles.tabHeading}>Contractor Details</h1>
        {/* <Search
          placeholder="Search Description"
          style={{ marginBottom: 16, width: "25%", float: "right" }}
        /> */}
        <Table
          columns={columnsQBContractors}
          dataSource={qbContractors}
          loading={getListOfContractors.isLoading || confirmVendor.isLoading || rejectVendor.isLoading}
          // tableLayout="fixed"
          scroll={{ x: 'true'}}
          rowSelection={{
            selectedQbContractorsRowKeys,
            onChange: onQbContractorsSelectChange,
          }}
          pagination={{
            simple: true,
            defaultPageSize: 10,  // Sets the number of rows per page
            showSizeChanger: false, // Allows users to change the page size
            total: qbContractors.length,
          }}
        />
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            gap: "1rem",
          }}
        >
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={() => {handleQBContractorsCategories("confirm")} }
            disabled={selectedQbContractorsRowKeys.length === 0}
            loading={loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Update 1099-Type {selectedQbContractorsRowKeys.length > 0 ? `(${selectedQbContractorsRowKeys.length})` : null}
          </CTAButton>
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={() => {handleQBContractorsCategories("reject")}}
            disabled={selectedQbContractorsRowKeys.length === 0}
            loading={loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Remove Contractors
          </CTAButton>
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={async () => {
              setLoading(true);
              for(let i = 0; i < selectedQbContractorsRowKeys.length; i++) {
                let message = `Hi, can you provide the email for the following contractor?`
                qbContractors.forEach((contractor) => {
                  if (contractor.key === selectedQbContractorsRowKeys[i]) {
                    console.log("contractor", contractor);
                    message = `Hi, can you provide the email for the following contractor? Name: ${contractor.name}, Company: ${contractor.company}, Address: ${contractor.address}, Email: ${contractor.email}, Account Number: ${contractor.accountNumber}, 1099-Type: ${contractor.type1099}`
                  }}
                );

                await api.Services.Dashboard.ChatKitty.sendMessageTaxes({
                  business_id: profileData.business_id,
                  messageData: {
                    message: message,
                    sender: "taxsupport@otterz.co",
                    vendor_id: selectedQbContractorsRowKeys[i]
                  },
                });
              }
              Message({
                type: "success",
                content: "Email request sent to selected contractors",
              });
              setLoading(false);
            }}
            disabled={selectedQbContractorsRowKeys.length === 0}
            loading={loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Request Email
          </CTAButton>
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={() => {handleQBContractorsCategories("request")}}
            disabled={selectedQbContractorsRowKeys.length === 0}
            // loading={rejectVendor.isLoading}
            loading={w9Loading || loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Request W-8/W-9
          </CTAButton>
        </section>
      </section>

      {/* For Review: New contractors who have been paid $600 */}
      <section style={{ display: activeTab === "For Review" ? "block" : "none", width: 'calc(97vw - 200px)' }}>
        <h1 className={styles.tabHeading}>New Contractor Details</h1>
        {/* <Search
          placeholder="Search Description"
          style={{ marginBottom: 16, width: "25%", float: "right" }}
        /> */}
        <Table
          columns={columnsForReview}
          dataSource={forReview}
          loading={confirmVendor.isLoading || rejectVendor.isLoading || getListOfVendors.isLoading}
          // tableLayout="fixed"
          scroll={{ x: 'true'}}
          rowSelection={{
            selectedForReviewRowKeys,
            onChange: onForReviewSelectChange,
          }}
          pagination={{
            simple: true,
            defaultPageSize: 10,  // Sets the number of rows per page
            showSizeChanger: false, // Allows users to change the page size
            total: forReview.length,
          }}
        />
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            gap: "1rem",
          }}
        >
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={() => {handleCategories("confirm")} }
            disabled={selectedForReviewRowKeys.length === 0}
            loading={loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Confirm Contractors {selectedForReviewRowKeys.length > 0 ? `(${selectedForReviewRowKeys.length})` : null}
          </CTAButton>
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={() => {handleCategories("reject")}}
            disabled={selectedForReviewRowKeys.length === 0}
            // loading={rejectVendor.isLoading}
            loading={loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Reject Contractors
          </CTAButton>
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={async () => {
              setLoading(true);
              for(let i = 0; i < selectedForReviewRowKeys.length; i++) {
                let message = `Hi, can you provide the email for the following contractor?`
                forReview.forEach((contractor) => {
                  if (contractor.key === selectedForReviewRowKeys[i]) {
                    message = `Hi, can you provide the email for the following contractor? Name: ${contractor.name}, Company: ${contractor.company}, Address: ${contractor.address}, Email: ${contractor.email}, Account Number: ${contractor.accountNumber}, 1099-Type: ${contractor.type1099}`
                  }}
                );

                await api.Services.Dashboard.ChatKitty.sendMessageTaxes({
                  business_id: profileData.business_id,
                  messageData: {
                    message: message,
                    sender: "taxsupport@otterz.co",
                    vendor_id: selectedForReviewRowKeys[i]
                  },
                });
              }
              Message({
                type: "success",
                content: "Email request sent to selected contractors",
              });
              setLoading(false);
            }}
            disabled={selectedForReviewRowKeys.length === 0}
            loading={loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Request Email
          </CTAButton>
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={() => {handleCategories("request")}}
            disabled={selectedForReviewRowKeys.length === 0}
            // loading={rejectVendor.isLoading}
            loading={w9Loading || loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Request W-8/W-9
          </CTAButton>
        </section>
      </section>

      {/* Other Vendors: Vendors who have been paid $600 and are not listed Contractors */}
      <section style={{ display: activeTab === "Other Vendors" ? "block" : "none", width: 'calc(97vw - 200px)' }}>
        <h1 className={styles.tabHeading}>Other Vendors</h1>
        <h5 className={styles.tabHeading}>Vendors who have been paid &gt; $600 and are not listed Contractors</h5>
        {/* <Search
          placeholder="Search Description"
          style={{ marginBottom: 16, width: "25%", float: "right" }}
        /> */}
        <Table
          columns={columnsOtherVendors}
          dataSource={rejectedContractors}
          loading={
            getListOfRejectedContractors.isLoading
          }
          pagination={{
            simple: true,
            defaultPageSize: 10,  // Sets the number of rows per page
            showSizeChanger: false, // Allows users to change the page size
            total: rejectedContractors.length,
          }}
          rowSelection={{
            selectedOthersRowKeys,
            onChange: onOthersSelectChange,
          }}
          scroll={{ x: 'true'}}
          // tableLayout="fixed"
        />
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            gap: "1rem",
          }}
        >
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={() => {handleOtherVendorsCategories("confirm")} }
            disabled={selectedOthersRowKeys.length === 0}
            loading={loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Add To Contractors {selectedOthersRowKeys.length > 0 ? `(${selectedOthersRowKeys.length})` : null}
          </CTAButton>
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={async () => {
              setLoading(true);
              for(let i = 0; i < selectedOthersRowKeys.length; i++) {
                let message = `Hi, can you provide the email for the following contractor?`
                rejectedContractors.forEach((contractor) => {
                  if (contractor.key === selectedOthersRowKeys[i]) {
                    message = `Hi, can you provide the email for the following contractor? Name: ${contractor.name}, Company: ${contractor.company}, Address: ${contractor.address}, Email: ${contractor.email}, Account Number: ${contractor.accountNumber}, 1099-Type: ${contractor.type1099}`
                  }}
                );

                await api.Services.Dashboard.ChatKitty.sendMessageTaxes({
                  business_id: profileData.business_id,
                  messageData: {
                    message: message,
                    sender: "taxsupport@otterz.co",
                    vendor_id: selectedOthersRowKeys[i]
                  },
                });
              }
              Message({
                type: "success",
                content: "Email request sent to selected contractors",
              });
              setLoading(false);
            }}
            disabled={selectedOthersRowKeys.length === 0}
            loading={loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Request Email
          </CTAButton>
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={() => {handleOtherVendorsCategories("request")}}
            disabled={selectedOthersRowKeys.length === 0}
            // loading={rejectVendor.isLoading}
            loading={w9Loading || loading || rejectVendor.isLoading || confirmVendor.isLoading}
          >
            Request W-8/W-9
          </CTAButton>
        </section>
        <Modal
          visible={showChat}
          onCancel={() => setShowChat(false)}
          footer={null}
          title={`Vendor ID - ${vendorId?.key}`}
          width={800}
          padding={0}
          destroyOnClose={true}
          style={{
            top: "auto",
            right: "0",
            bottom: "0",
            position: "absolute",
            padding: "1rem",
          }}
        >
          <AccountantChatPopUp 
            vendorId={vendorId} 
            setShowChat={setShowChat} 
            accountData={profileData} 
            isClient={false} 
            receiver={`${profileData.email}`}
          />
        </Modal>
      </section>
    </div>
  );
};

export default Contractors;
