import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Spin, Typography, Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { usePlaidLink } from 'react-plaid-link';
import { CloseCircleFilled } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { CTAButton, Message } from '../../../common';
import api from '../../../API';
import classNames from './AddAccountsModal.module.scss';
import { useBusiness } from '../../../../store/StoreProvider';
import endpoints from '../../../API/endpoints';

const { Text } = Typography;

const AddAccountsModal = ({ isVisible, onClose, onSubmit }) => {
  const {selectedBusiness} = useBusiness();
  let env = process.env.REACT_APP_ENV?.trim();

  const [linkToken, setLinkToken] = useState(null);
  const [, setBusinesses] = useState([{ id: uuidv4(), name: '' }]);
  const [accounts, setAccounts] = useState([]);
  const [connected, setConnected] = useState(false); // State to track connection status
  const [linkedBanks, setLinkedBanks] = useState([]);
  const [plaidLoading, setPlaidLoading] = useState(true);

  const secureLocalStore = window.localStorage || window.secureLocalStore;

  // Fetch businesses and initialize Plaid link token
  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return;
      }
      const businessList = data.data.map(b => b.business_id);
      setBusinesses(businessList);
      const businessNames = data.data.map((business) => {
        return {
          business_id: business.business_id,
          name: business.name
        }
      });
      setAccounts(businessNames);
      fetchLinkToken(selectedBusiness.business_id);
    },
    onError: (error) => {
      Message({ type: "error", content: error.message });
    },
  });

  useEffect(() => {
    viewAllBusiness.mutate(secureLocalStore.getItem('otterz_id'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch Plaid link token
  const fetchLinkToken = async (business_id) => {
    try {
      setPlaidLoading(true);
      const response = await api.Services.Onboarding.createLinkToken(business_id);
      setLinkToken(response.data.link_token);
      setPlaidLoading(false);
    } catch (error) {
      Message({ type: "error", content: "Failed to fetch link token" });
      setPlaidLoading(false);
    }
  };

  // Plaid Link configuration
  const config = {
    token: linkToken,
    onSuccess: async (data) => {
      try {
        const response = await api.Services.Onboarding.exchangeLinkToken({ public_token: data, business_id: selectedBusiness.business_id });
        if (!response.status) {
          Message({ type: "error", content: response.errors[0].message });
          return;
        }
        Message({ type: "success", content: "Plaid connected successfully" });
        setLinkedBanks(prevBanks => [...prevBanks, {
          bank_name: response.data.bank_details.bank_name,
          bank_logo: response.data.bank_details.logo,
          id: response.data.bank_details.item_id,
        }]);
      } catch (error) {
        Message({ type: "error", content: "Failed to exchange public token" });
      }
    },
  };

  const { open, ready } = usePlaidLink(config);

  const handleConnectClick = () => {
    if (ready && linkToken) {
      open();
    } else {
      Message({ type: "error", content: "Plaid Link is not ready" });
    }
  };

  // View user's owner status
  const getQbStatus = useMutation(data => api.Services.Onboarding.getQuickbooksStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("QbStatus", data.data);
        // Check if QuickBooks is already connected
        if (data.data.length !== 0 && data.data.access_token) {
          setConnected(true); // Set connected state to true
        }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  const handleConnect = async (business_id) => {
    try {
      window.open(`${endpoints.SERVICES.ONBOARDING.QUICKBOOKS_OAUTH[env]}${business_id}`, '_blank');
    } catch (error) {
      console.error("Error connecting to QuickBooks: ", error);
      Message({ type: "error", content: error.message });
    }
  };

  useEffect(() => {
    // Polling interval in milliseconds (e.g., 2 seconds)
    const pollingInterval = 2000;
    
    // Function to fetch QuickBooks status for all accounts
    const fetchQbStatusForAll = async () => {
      try {
        if (accounts.length === 0) return;
  
        // Loop through each account to fetch its status
        // for (let account of accounts) {
        // }
        const response = await getQbStatus.mutateAsync(selectedBusiness.business_id);

        // Handle response to update state accordingly
        if (response && response.data) {
          console.log("QbLinkStatus", response.data);

          // Check if QuickBooks is connected
          if (response.data.length !== 0 && response.data.access_token) {
            // Update the connected state for the specific account
            setAccounts(prev =>
              prev.map(acc =>
                acc.business_id === selectedBusiness.business_id
                  ? { ...acc, connected: true }
                  : acc
              )
            );
          }
        }
  
        // Check if current account is connected
        const allConnected = accounts.some(
          acc => acc.business_id === selectedBusiness.business_id && acc.connected
        );

        if (allConnected) {
          setConnected(true); // Set global connected state to true if all are connected
          // handleContinue();   // Continue to the next step
        }
  
      } catch (error) {
        // console.error("Error fetching QuickBooks status: ", error);
        // Handle error accordingly
      }
    };
  
    // Start polling only if isVisible is true
    let pollingId;
    if (isVisible) {
      pollingId = setInterval(fetchQbStatusForAll, pollingInterval);
    }
  
    // Cleanup function to stop polling when component unmounts or isVisible turns false
    return () => {
      if (pollingId) {
        clearInterval(pollingId);
      }
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, isVisible]);  

  // Handle Continue Button Click
  const handleContinue = () => {
    onSubmit();
    onClose(false);
    // if (connected) {
    //   // Proceed to next step (navigate or update onboarding status)
    //   onSubmit();
    //   onClose(false);
    // } else {
    //   // Message({ type: "error", content: "Please connect both QuickBooks and Plaid" });
    //   // Message.error('Please connect both QuickBooks and Plaid');
    //   // Message({ type: "error", content: "Please connect both QuickBooks and Plaid" });
    // }
  };

  return (
    <Modal visible={isVisible} title={"Connect Your Accounts"} onCancel={() => {
      onSubmit()
      onClose(false)}
    } footer={null} width={600}>
      <div style={{ padding: '20px' }}>

        {/* QuickBooks Section */}
        <div className={classNames.setupOtterzComponent}>
          <h3>Connect your QuickBooks account</h3>

          {/* Rendering flexbox column with rows */}
          {accounts.map((acc, index) => (
            acc.business_id === selectedBusiness.business_id && <div key={index} className={classNames.flexRow}>
              <Text>{acc.name}</Text>
              <Button size='large' type="primary" style={{background: 'green', color: 'white'}} disabled={acc?.connected || false} className={classNames.connectButton} onClick={() => handleConnect(acc.business_id)}>
                {acc?.connected ? "Connected" : "Connect"} {/* Change button text based on connection status */}
              </Button>
            </div>
          ))}
        </div>

        <br />

        {/* Plaid Section */}
        <div className={classNames.setupOtterzComponent}>
          <h3>Connect Bank Accounts through Plaid</h3>

          <Row align="middle" style={{gap: '20px'}}>
          <Row align="middle" style={{ gap: '20px' }}>
            {linkedBanks.map(bank => (
              <div className={classNames.bankConnectedCard} key={bank.id} style={{ display: 'flex', alignItems: 'center' }}>
                <CloseCircleFilled className={classNames.closeIcon} style={{ marginRight: '10px' }} onClick={() => setLinkedBanks(prev => prev.filter(b => b.id !== bank.id))} />
                {/* <img src={`data:image/png;base64,${bank.bank_logo}`} alt={bank.bank_name} style={{ width: '40px', marginRight: '10px' }} /> */}
                {bank.bank_logo === null ? <div>{bank.bank_name}</div> : <img src={`data:image/png;base64,${bank.bank_logo}`} alt={bank.bank_name} />}
                {/* <Text>{bank.bank_name}</Text> */}
              </div>
            ))}
          </Row>

          <Row align="middle" className={classNames.connectRow}>
            <Col span={8}>
              <div className={classNames.connectCard} onClick={handleConnectClick}>
                {/* + Connect */}
                {
                  plaidLoading ? (
                    <div className={classNames.spinnerContainer}>
                      <Spin size="small" />
                    </div>
                  ) : (
                    "+ Connect"
                  )
                }
              </div>
            </Col>
          </Row>
          </Row>
        </div>

        <br />

        {/* Footer Text */}
        <Text>
          If you can’t connect accounts right now, you can close this window and connect them later in Settings &gt; Integrations.
        </Text>

        <br /><br />

        {/* Continue Button */}
        <CTAButton 
          type="primary" 
          onClick={handleContinue} 
          disabled={!connected && linkedBanks.length === 0}
        >
          Continue
        </CTAButton>
      </div>
    </Modal>
  );
};

export default AddAccountsModal;
