import endpoints from "../../../endpoints";

// Function to get subscription details
export default async function getSubscriptionDetails() {
    let env = process.env.REACT_APP_ENV?.trim();
    const baseURL = endpoints.SERVICES.SUBSCRIPTIONS.GET_SUBSCRIPTION_DETAILS[env];
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${baseURL}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        })
    ).json();
}
