import React from 'react';
import { AuthProvider } from './AuthProvider';
import Routes from './Routes';

export default function Providers({business_id, userName}) {
  return (
    // <ThemeProvider theme={theme}>
    // </ThemeProvider>
      <AuthProvider>
        <Routes Id={business_id} userName={userName} />
      </AuthProvider>
  );
}

// const theme = createTheme({
//   roundness: 2,
//   colors: {
//     primary: '#5b3a70',
//     accent: '#50c878',
//     background: '#f7f9fb',
//   },
// });
