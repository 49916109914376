const Move = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12236_131412)">
    <path d="M3.75 16.5C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5H14.25C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V4.5H13.5V3H4.5V15H13.5V13.5H15V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5H3.75ZM13.5 12V9.75H8.25V8.25H13.5V6L17.25 9L13.5 12Z" fill="#3B3F43"/>
    </g>
    <defs>
    <clipPath id="clip0_12236_131412">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>  
  );
};

export default Move;
