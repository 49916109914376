import endpoints from "../../../endpoints";

export default async function getMessagesForVendor(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = endpoints.SERVICES.DASHBOARD.QUERIES.TAXES.GET_MESSAGE_FOR_TRANSACTION[env]
    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${url}${data?.business_id}/${data?.vendor_id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            }
        })
    ).json();
}
