import endpoints from "../../../endpoints";

export default async function createFolder(data){
    let env = process.env.REACT_APP_ENV?.trim();

    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
        folder_name: data.folder_name,
        business_id: data.business_id,
        otterz_id: data.otterz_id,
        parent_folder_id: data.parent_folder_id,
    }).toString();

    const secureLocalStore = window.localStorage || window.sessionStorage;
    const baseURL = endpoints.SERVICES.DASHBOARD.DOCUMENTS_VAULT.FOLDER[env];
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}?${queryParams}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
        })
    ).json();
}
