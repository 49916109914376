import endpoints from "../../../endpoints";

export default async function renameItem(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    // Construct the URL with query parameters
    let queryParams = null
    if (data?.file_id) {
        queryParams = new URLSearchParams({
            file_id: data.file_id,
            otterz_id: data?.otterz_id,
            new_file_name: data.new_file_name,
        }).toString();
    } else {
        queryParams = new URLSearchParams({
            folder_id: data.folder_id,
            business_id: data?.business_id,
            new_folder_name: data.new_folder_name,
        }).toString();
    }

    const baseURL = data?.file_id ? endpoints.SERVICES.DASHBOARD.DOCUMENTS_VAULT.FILE[env] : endpoints.SERVICES.DASHBOARD.DOCUMENTS_VAULT.FOLDER[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}?${queryParams}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
