import endpoints from "../../../endpoints";

export default async function resetPassword(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    
    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
        email_id: data.email,
    }).toString();

    const baseURL = endpoints.SERVICES.AUTH.PASSWORD_RESET[env];
    const url = `${baseURL}?${queryParams}`;

    return await (
        await fetch(url, {
            method: "GET",
            headers: {
                // Add headers if necessary
                // 'Authorizaation': `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        })
    ).json();
}
