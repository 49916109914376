import React, { useEffect, useState } from "react";
import {
  Col,
  Radio,
  Row,
  // Upload,
  // Button
} from "antd";
// import { UploadOutlined } from '@ant-design/icons';
import classNames from "./MethodOfBookeeping.module.scss"; 
import { CTAButton, Message } from "../../../../common";
import ChatKitty from 'chatkitty';
import { useMutation } from "react-query";
import api from "../../../../API";
import { useBusiness } from "../../../../../store/StoreProvider";

const MethodOfBookeeping = ({ setBusinessOnboarded }) => {
  const { selectedBusiness, setIsInitialBusinessCenterView } = useBusiness();
  const secureLocalStore = window.localStorage || window.sessionStorage;
  // const secureLocalStore = window.localStorage || window.sessionStorage;
  // const [fileList, setFileList] = useState([]);
  const [bookkeepingMethod, setBookkeepingMethod] = useState(null);
  const [hasAccountant, setHasAccountant] = useState(false);
  const sendMessageAccountant = useMutation(
    (data) => api.Services.Dashboard.ChatKitty.sendMessageAccountant(data),
    {}
  );

  const chatkitty = ChatKitty.getInstance(process.env.REACT_APP_CHATKITTY_API_KEY);

  const initChannel = async (user) => {
    await chatkitty.createChannel({
      type: 'DIRECT',
      members: [{ username: "taxsupport@otterz.co" }],
    });
  }

  const initSession = async () => {
    const result = await chatkitty.startSession({ username: secureLocalStore.getItem('email') });
    if (result.succeeded) {
      const session = result.session;
      initChannel(session);
    } else {
      console.log("Failed to start session:", result.error);
    }
  };

  useEffect(() => {
    try {
      initSession()
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pushBusinessQuestion = useMutation(
    (data) => api.Services.Dashboard.TaxFilling.pushBusinessQuestion(data),
    {}
  );

  const onPressNext = () => {
    if (bookkeepingMethod) {
      const businessQuestions = [
        {
          key: "bookkeeping_method",
          question: "Bookkeeping Method",
          value: bookkeepingMethod,
          meta_data: {},
        },
      ];
      pushBusinessQuestion.mutate({
        business_id: selectedBusiness.business_id,
        body: businessQuestions,
      }, {
        onSuccess: (data) => {
          setIsInitialBusinessCenterView(false);
          if (bookkeepingMethod === "Not Sure") {
            sendMessageAccountant.mutate({
              messageData: {
                sender: secureLocalStore.getItem("email"),
                message:
                  hasAccountant 
                  ? "I am not sure about my bookkeeping method. I have a current accountant or bookkeeper. Please review my books and indicate whether the appropriate bookkeeping method on my business profile."
                  : "I am not sure about my bookkeeping method. I don't have a current accountant or bookkeeper. Please review my books and indicate whether the appropriate bookkeeping method on my business profile.",
              },
              business_id: selectedBusiness?.business_id,
            });
          }
          if (data.status) {
            updateTaxOnboardingStatus.mutate({
              business_id: selectedBusiness.business_id,
              body: {
                business_onboarding_status: "BUSINESS_ONBOARDING_COMPLETE",
                meta_data: { any_other_data_required_for_frontend: null },
              },
            });
          }
        }
      })
    } else {
      Message({ type: "error", content: "Please select a bookkeeping method" });
    }
  };

  /* Mutation definition for API calls */
  // update status mutation
  const updateTaxOnboardingStatus = useMutation(
    (data) => api.Services.Dashboard.TaxFilling.updateTaxOnboardingStatus(data),
    {
      onSuccess: (data) => {
        // Handle errors from the API call
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }

        // Handle successful registration
        if (data && data.data) {
          console.log("Registration successful", data);
          setBusinessOnboarded("BUSINESS_ONBOARDING_COMPLETE");
          Message({
            type: "success",
            content: "Bookkeeping Onboarding Complete",
          });
        }
      },
      onError: (error) => {
        // Handle any errors from the API call
        console.error("Signup error: ", error);
        Message({ type: "error", content: error.message });
      },
    }
  );

  return (
    <div className={classNames.profileComponent}>
      <h3 style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
        What method do you use to maintain your books?
      </h3>
      <h3
        style={{ fontWeight: "400", fontSize: "0.8rem", margin: "0 0 1rem 0" }}
      >
        Do you record income/expenses when received/paid (cash) or when
        earned/billed (accrual). If you are unsure, please ask your current
        accountant.
      </h3>
      <Radio.Group
        onChange={(e) => {
          setBookkeepingMethod(e.target.value);
        }}
        value={bookkeepingMethod}
        className={classNames.radioGroup}
      >
        <Row gutter={4}>
          <Col span={8}>
            <Radio id="Cash" value={"Cash"}>
              Cash
            </Radio>
          </Col>
          <Col span={8}>
            <Radio id="Accrual" value={"Accrual"}>
              Accrual
            </Radio>
          </Col>
          <Col span={8}>
            <Radio id="Not Sure" value={"Not Sure"}>
              Not Sure
            </Radio>
          </Col>
        </Row>
      </Radio.Group>

      {bookkeepingMethod === "Not Sure" && (
        <>
          <h3
            style={{
              fontWeight: "bold",
              fontSize: "0.95rem",
              marginTop: "2rem",
            }}
          >
            Did you have an accountant or bookkeeper managing your books before
            Otterz?
          </h3>

          <Radio.Group
            onChange={(e) => {
              setHasAccountant(e.target.value);
            }}
            value={hasAccountant}
            className={classNames.radioGroup}
          >
            <Row gutter={4}>
              <Col span={8}>
                <Radio id="Yes" value={true}>
                  Yes
                </Radio>
              </Col>
              <Col span={8}>
                <Radio id="No" value={false}>
                  No
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </>
      )}
      <Row gutter={16} style={{ marginTop: "2rem" }}>
        <Col span={24}>
          <CTAButton
            loading={updateTaxOnboardingStatus.isLoading || pushBusinessQuestion.isLoading} // Show spinner when loading
            disabled={updateTaxOnboardingStatus.isLoading || pushBusinessQuestion.isLoading} // Disable button if next is not enabled
            onClick={onPressNext}
          >
            Next
          </CTAButton>
        </Col>
      </Row>
    </div>
  );
};

export default MethodOfBookeeping;
