import React, { useEffect, useState } from 'react';
import { Tree, Tabs, Spin } from 'antd';
import classNames from './Roles.module.scss';
import { useMutation } from 'react-query';
import api from '../../API';
import { CTAButton, Message } from '../../common';
import { useBusiness } from '../../../store/StoreProvider';

const { TreeNode } = Tree;
const { TabPane } = Tabs;

const treeData = {
  "name": "Otterz Co-pilot",
  "key": "Test",
  "children": [
    {
      "name": "Insights",
      "key": "insights",
      "children": [
        { 
          "key": "business_overview",
          "name": "Business Overview" 
        },
        {
          "key": "cash_balance",
          "name": "Cash Balance",
          "children": [
            {
              "key": "cash_balance_make_payments",
              "name": "Make Payments." 
            }
          ]
        },
        {
          "key": "card_balance",
          "name": "Card Balance",
          "children": [
            {
              "key": "card_balance_make_payments",
              "name": "Make Payments" 
            }
          ]
        },
        { 
          "key": "cashflow",
          "name": "Cashflow" 
        },
        { 
          "key": "profit_and_loss",
          "name": "Profit & Loss" 
        }
      ]
    },
    {
      "key": "settings",
      "name": "Settings",
      "children": [
        {
          "key": "profile", 
          "name": "Profile" 
        },
        {
          "key": "roles", 
          "name": "Roles" 
        },
        {
          "key": "team",
          "name": "Teams",
        },
        {
          "key": "subscription",
          "name": "Subscription"
        },
        {
          "key": "integrations",
          "name": "Integrations" 
        }
      ]
    }
  ]
};

const renderTreeNodes = (data, permissions) =>
  data.map(item => {
    const checked = permissions.includes(item.key);
    if (item.children) {
      return (
        <TreeNode
          title={<span>{item.name}</span>}
          key={item.key}
          // defaultCheckedKeys={permissions}
          // defaultChecked={checked}
        >
          {renderTreeNodes(item.children, permissions)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        key={item.key}
        title={<span>{item.name}</span>}
        defaultCheckedKeys={checked}
        // checkedKeys={permissions}
        // checkStrictly={true}
      />
    );
  });

const RolesAndPermissions = () => {
  const [selectedRole, setSelectedRole] = useState('Admin');
  const [permissionsData, setPermissionsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // const [business, /*setBusiness*/] = useState({id: 1, name: "John's Workshop"});
  const [updatedPermissions, setUpdatedPermissions] = useState([]);
  const { selectedBusiness } = useBusiness();

  /* Mutation definition for API calls */
  // Permissions view mutation - getting a list of all permissions enabled
  const viewPermissions = useMutation(data => api.Services.Authorization.viewPermissions(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data && data.data.permissions) {
        const permissionsData = {};
        const roles = Object.keys(data.data.permissions);
        roles.forEach((role, index) => {
          if (index > 2) return;
          permissionsData[role] = data.data.permissions[role];
        });
        console.log("Permissions data: ", permissionsData);
        setPermissionsData(permissionsData);
        setUpdatedPermissions(permissionsData)
        setIsLoading(false);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
      setIsLoading(false);
    },
  }); 

  // Permissions view mutation - getting a list of all permissions enabled
  const linkPermissions = useMutation(data => api.Services.Authorization.linkPermissions(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        Message({ type: "success", content: "Permissions updated successfully" });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  const applyPermissions = async () => {
    console.log("Applying permissions: ", updatedPermissions);
    const response = await linkPermissions.mutateAsync({ 
      business_id: selectedBusiness.business_id,
      permissions: updatedPermissions 
    })
    console.log("Response: ", response);
  };

  useEffect(() => {
    if(selectedBusiness) {
      viewPermissions.mutateAsync(selectedBusiness.business_id); // Fetch permissions for business_id 1
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const rolePermissions = permissionsData[selectedRole] || [];

  console.log("updatedPermissions: ", updatedPermissions, selectedRole, permissionsData);

  // Handler for Tree check events
  const handleTreeCheck = (checkedKeys) => {
    console.log("Checked keys: ", checkedKeys);
    const checkedPermissions = { ...updatedPermissions };
    checkedPermissions[selectedRole] = checkedKeys;
    setUpdatedPermissions(checkedPermissions); // Update state with checked keys (permissions)
  };

  return (
    <div className={classNames.rolesPermissions}>
      {/* <section className={classNames.greetingsSection}>
          <h1 style={{ color: '#82898F', margin: 0 }}>Hello, {userData?.first_name}</h1>
      </section> */}

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spin size={'large'} />
        </div>
      ) : (
        <section style={{
          padding: '2rem',
          height: '100%',
          background: 'white',
          borderRadius: '8px',
          margin: '0 10px'
        }}>
          <div className={classNames.columnTitles}>
          <div className={classNames.roleTitle}>Roles</div>
          <div className={classNames.permissionsTitle}>Permissions for { null || selectedBusiness?.name }</div>
          </div>
          <hr className={classNames.separator} />
          <div className={classNames.content}>
          <div className={classNames.rolesColumn}>
            <Tabs tabPosition="left" onChange={setSelectedRole}>
              {Object.keys(permissionsData).map(role => (
                <TabPane tab={role} key={role}>
                  <Tree
                    checkable={true}
                    defaultExpandAll
                    defaultCheckedKeys={rolePermissions}
                    selectable={false}
                    onCheck={handleTreeCheck}
                  >
                    {renderTreeNodes([treeData], rolePermissions)}
                  </Tree>
                </TabPane>
              ))}
            </Tabs>
            <div style={{
              display: 'flex',
              margin: '2rem 2rem 0 0',
              justifyContent: 'flex-end'
            }}>
              <CTAButton style={{width: '65%'}} onClick={applyPermissions}>Apply</CTAButton>
            </div>
          </div>
        </div>
        </section>
      )}      

    </div>
  );
};

export default RolesAndPermissions;
