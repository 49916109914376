import React, { useState } from 'react';
import classNames from './ChatPanel.module.scss';
import NyraAI from '../NyraAI/NyraAI';
import Chatbot from '../Chatbot/Chatbot';
// import { CustomerServiceOutlined, OpenAIOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { Select } from '../../common';
import { useLocation } from 'react-router-dom';

const { Panel } = Collapse;
const { Option } = Select;

const ChatPanel = () => {
  const location = useLocation();
  const { chatType } = location.state || {};
  
  const [selectedTab, setSelectedTab] = useState(chatType === "accountant" ? 'accountant' : 'nyra'); // State to manage selected tab

  // Function to handle tab selection
  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const faqs = [
    {
      question: "How do I register my business for federal and state taxes through the platform?",
      answer: "You can register your business for federal and state taxes by navigating to the 'Tax Registration' section in your account settings."
    },
    {
      question: "What documents do I need to file my taxes?",
      answer: "The documents required to file your taxes include income statements, expense receipts, and any relevant tax forms provided by Otterz."
    },
    {
      question: "Can the platform help calculate my C Corporation’s tax liability?",
      answer: "Yes, the platform offers tools to calculate your C Corporation’s tax liability based on the information provided."
    },
    {
      question: "Can the platform assist with filing amended returns for my business?",
      answer: "Yes, the platform provides support for filing amended returns. You can initiate this process through the 'Amend Returns' section."
    },
    {
      question: "What are the deadlines for filing my taxes?",
      answer: "Tax filing deadlines vary depending on your tax jurisdiction and business structure. You can find specific deadlines in the 'Tax Calendar' section."
    },
    {
      question: "How does Otterz ensure the security and confidentiality of my tax information?",
      answer: "Otterz employs robust security measures to safeguard your tax information, including encryption, multi-factor authentication, and regular security audits."
    },
    {
      question: "What support does Otterz offer if I receive an audit notice from the IRS?",
      answer: "If you receive an audit notice from the IRS, Otterz provides assistance and guidance throughout the audit process. You can contact our support team for personalized support."
    }
  ];

  return (
    <div className={classNames.wrapper}>
      {/* <section className={classNames.greetingsSection}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ color: "#82898F", margin: 0 }}>Hello, {userData?.first_name}</h1>
          <div className={classNames.notification} >
            <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 23.4419H0V20.8372H1.2V11.7613C1.2 5.2653 6.036 0 12 0C17.964 0 22.8 5.2653 22.8 11.7613V20.8372H24V23.4419ZM3.6 20.8372H20.4V11.7613C20.4 6.70437 16.6392 2.60465 12 2.60465C7.3608 2.60465 3.6 6.70437 3.6 11.7613V20.8372ZM9 24.7442H15C15 25.6077 14.6839 26.4358 14.1213 27.0464C13.5587 27.657 12.7956 28 12 28C11.2044 28 10.4413 27.657 9.87868 27.0464C9.31607 26.4358 9 25.6077 9 24.7442V24.7442Z" fill="#82898F"/>
            </svg>
          </div>
        </div>
      </section> */}
      <div className={classNames.container}>
        {/* First column: Switches */}

        {/* Second column: Chat component */}
        <div className={classNames.chatColumn}>
          <div className={classNames.switchColumn}>
            <div style={{ fontSize: '1.2rem', margin: '0 0 0 1rem', color: '#82898F'}}>
              You're chatting with 
              <Select
                value={selectedTab}
                onChange={handleTabChange}
                style={{ marginLeft: '0.5rem', width: 200 }}
              >
                <Option value="nyra">
                  {/* <OpenAIOutlined style={{ marginRight: '0.5rem' }} /> */}
                  <span style={{color: '#c85103', fontSize: '1.2rem'}}>Nyra</span>
                </Option>
                <Option value="accountant">
                  {/* <CustomerServiceOutlined style={{ marginRight: '0.5rem' }} /> */}
                  <span style={{color: '#c85103', fontSize: '1.2rem'}}>Accountant</span>
                </Option>
              </Select>
            </div>
          </div>
          {selectedTab === 'nyra' ? <NyraAI /> : <Chatbot receiver={process.env.REACT_APP_ACCOUNTANT_EMAIL} />}
        </div>

        {/* Third column: FAQ section */}
        <div className={classNames.faqColumn}>
          <h2 style={{fontWeight: 'bold', color: '#82898F', margin: '0 1rem'}}>FAQs</h2>
          <Collapse defaultActiveKey={['0']} style={{border: 'none', background: 'white'}}>
              {faqs.map((item, index) => (
                <Panel header={item.question} key={index} style={{margin: '1rem', border: 'none'}}>
                  <p>{item.answer}</p>
                </Panel>
              ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default ChatPanel;
