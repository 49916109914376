import { Outlet } from "react-router-dom";
import classNames from "./DashboardLanding.module.scss";

function DashboardLanding() {
  return (
    <div className={classNames.wrapper}>
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default DashboardLanding;
