import endpoints from "../../../endpoints";

// Function to create subscription
export default async function sendMessageBookkeeping(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    const url = endpoints.SERVICES.DASHBOARD.CHATKITTY.SEND_MESSAGE_BOOKKEEPING[env];
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');
    
    console.log("sendMessageBookkeeping", url, data);
    

    return await (
        await fetch(`${url}${data?.business_id}/${data.messageData.transaction_id}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                message: data.messageData.message,
                sender: data.messageData.sender,
            })
        })
    ).json();
}
