import endpoints from "../../../endpoints";

// Function to create subscription
export default async function sendMessageBusiness(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    const url = endpoints.SERVICES.DASHBOARD.CHATKITTY.SEND_MESSAGES_BUSINESS[env] + data?.business_id;
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${url}/${data.messageData.channel_id}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data.messageData)
        })
    ).json();
}
