const URL = "https://62013650fdf50900172498f0.mockapi.io";
// const URL_CUSTOMER_DEV = "https://3muqu6u3y8.execute-api.us-west-2.amazonaws.com/prod/";
const URL_INVOICES_DEV =
  "https://khjdfmpjed.execute-api.us-west-2.amazonaws.com/prod/";
const BASE_URL_DEV = "http://dev.sync.app.otterz.co/"
const BASE_URL_TTEN = "http://tten.sync.app.otterz.co/"

const BASE_URL = {
  DEV: 'http://ec2-18-233-152-212.compute-1.amazonaws.com:8000/api/',
  PROD: 'http://ec2-18-233-152-212.compute-1.amazonaws.com:8000/api/',
  NEW_PROD: 'https://api.app.otterz.co/api/',
  ISOLATED: 'http://ec2-18-233-152-212.compute-1.amazonaws.com:8000/api/'
}

const endpoints = {
  BUSINESS_BANKING: {
    SUBSCRIPTION_PLANS: {
      DEV: `${BASE_URL_DEV}api/v1/subscription/plan/list`
    },
    GET_CHECK_DEPOSIT_DETAILS: {
      MOCK: "/api/check-deposits/details/",
      DEV: "",
    },
    CREATE_DEPOSIT_CHECK: {
      MOCK: "/api/check-deposits",
      DEV: "",
    },
    UPDATE_PAYEE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      MOCK_FS: "/api/counterparties",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/payee",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/payee",
    },
    GET_CARDS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card",
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card",
    },
    SET_CARD_SPENT_LIMIT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card/1",
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card/1",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card/1",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card/1",
    },
    CARD_REPORT_STOLEN: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/report-stolen
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/report-stolen
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/report-stolen
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/report-stolen
    },
    CARD_FREEZE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/freeze
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/freeze
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/freeze
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/freeze
    },
    CARD_UNFREEZE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/unfreeze
      MOCK_FS: "/api/cards",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/unfreeze
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/unfreeze
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card", // /1/unfreeze
    },
    GET_CARD_CATEGORY_WISE_SPEND: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card-spend", // /1->5 will handle in fetch api file
      MOCK_FS: "/api/cards/spend",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card-spend", // /1->5 will handle in fetch api file
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card-spend", // /1->5 will handle in fetch api file
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card-spend", // /1->5 will handle in fetch api file
    },
    GET_PAYEES: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      MOCK_FS: "/api/payees/",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/payee",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/payee",
    },
    ADD_PAYEE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      MOCK_FS: "/api/payee",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/payee",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/payee",
    },
    DELETE_PAYEE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      MOCK_FS: "/api/payee",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/payee",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/payee",
    },
    PATCH_PAYEE: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/payee",
      DEV: `${BASE_URL_DEV}api/v1/payee/`,
      ISOLATED: `${BASE_URL_DEV}api/v1/payee/`,
      TTEN: `${BASE_URL_TTEN}api/v1/payee/`,
      PROD: `${BASE_URL_TTEN}api/v1/payee/`,
    },
    CREATE_ACH_PAYMENT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/ach-payment",
      MOCK_FS: "/api/payments/ach",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/ach-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/ach-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/ach-payment",
    },
    CREATE_WIRE_PAYMENT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/wire-payment",
      MOCK_FS: "/api/payments/wire",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/wire-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/wire-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/wire-payment",
    },
    CREATE_BILL_PAYMENT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/bill-payment",
      MOCK_FS: "/api/payments/bill",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/bill-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/bill-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/bill-payment",
    },
    CREATE_PAY_WITH_CARD: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/card-payment",
      MOCK_FS: "/api/payments/card",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/card-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/card-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/card-payment",
    },
    LIST_BILLERS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/biller",
      MOCK_FS: "api/payments/billpay/billers",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/biller",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/biller",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/biller",
    },
    GET_TRANSACTIONS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/transaction",
      MOCK_FS: "/api/transactions",
      DEV: "https://xokm93rah3.execute-api.us-west-2.amazonaws.com/prod",
      TTEN: "https://emzwwhpTTENk.execute-api.us-west-2.amazonaws.com/prod",
      DEMO: "https://pyw9k9rvzf.execute-api.us-west-2.amazonaws.com/prod",
    },
    GET_RECENT_TRANSACTIONS: {
      MOCK: "https://xokm93rah3.execute-api.us-west-2.amazonaws.com/prod/recent-transactions",
      DEV: "https://xokm93rah3.execute-api.us-west-2.amazonaws.com/prod/recent-transactions",
      TTEN: "https://emzwwhpTTENk.execute-api.us-west-2.amazonaws.com/prod/recent-transactions",
      DEMO: "https://pyw9k9rvzf.execute-api.us-west-2.amazonaws.com/prod/recent-transactions",
    },
    LIST_SCHEDULED_TRANSACTIONS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
      MOCK_FS: "/api/scheduled-transactions",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
    },
    GET_BANK_ACCOUNT_DETAILS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/accounts/1",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/accounts/1",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/accounts/1",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/accounts/1",
    },
    GET_BALANCE: {
      MOCK: "",
      DEV: "NO-LINK",
    },
    ADD_FUNDS: {
      MOCK: "",
      DEV: "NO-LINK",
    },
    GET_TRANSFER_TYPES: {
      // this endpoint not used anywhere. not found data in server.js file hence no mock api added
      MOCK: "/api/transfers",
      DEV: "NO-LINK",
    },
    UPDATE_SETTINGS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/setting",
      MOCK_FS: "/api/settings",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/setting",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/setting",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/setting",
    },
    SCHEDULED_PAYMENTS: {
      GET_SCHEDULED_PAYMENTS: {
        MOCK: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
        MOCK_FS: "/api/scheduled-payments",
        DEV: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
        TTEN: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
        DEMO: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction",
      },
      EDIT_SCHEDULED_PAYMENTS: {
        MOCK: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        MOCK_FS: "/api/scheduled-payments",
        DEV: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        TTEN: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        DEMO: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
      },
      DELETE_SCHEDULED_PAYMENTS: {
        MOCK: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        MOCK_FS: "/api/scheduled-payments",
        DEV: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        TTEN: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
        DEMO: "https://62013650fdf50900172498f0.mockapi.io/schedule-transaction/",
      },
    },
    GET_STATEMENTS: {
      MOCK: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
      MOCK_FS: "/api/statements",
      DEV: "https://itmga33umb.execute-api.us-west-2.amazonaws.com/prod/statements",
      TTEN: "https://6yh04neu7f.execute-api.us-west-2.amazonaws.com/prod/statements",
      DEMO: "https://5vifbctre4.execute-api.us-west-2.amazonaws.com/prod/statements",
    },
    GET_STATEMENT_PDF: {
      MOCK: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
      MOCK_FS: "/api/statements",
      DEV: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
      TTEN: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
      DEMO: "https://eye8bxyw66.execute-api.us-west-2.amazonaws.com/prod",
    },
    POST_FILTER_PARAMS: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/statement",
      MOCK_FS: "/api/statements",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/statement",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/statement",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/statement",
    },
  },
  MANAGE_CHECKS: {
    LIST_CHECKS_DEPOSITED: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/check-deposit",
      MOCK_FS: "/api/check-deposits",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/check-deposit",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/check-deposit",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/check-deposit",
    },
  },
  ONBOARDING: {
    REQUEST_OTP: {
      MOCK: "cdk-extend/phone/otp",
      DEV: `${BASE_URL_DEV}cdk-extend/phone/otp`,
      ISOLATED: `${BASE_URL_DEV}cdk-extend/phone/otp`,
      TTEN: `${BASE_URL_TTEN}cdk-extend/phone/otp`,
      PROD: `${BASE_URL_TTEN}cdk-extend/phone/otp`,
    },
    VERIFY_OTP: {
      MOCK: "cdk-extend/phone/verify",
      DEV: `${BASE_URL_DEV}cdk-extend/phone/verify`,
      ISOLATED: `${BASE_URL_DEV}cdk-extend/phone/verify`,
      TTEN: `${BASE_URL_TTEN}cdk-extend/phone/verify`,
      PROD: `${BASE_URL_TTEN}cdk-extend/phone/verify`,
    },
    CREATE_APPLICATION: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/application",
      DEV: "https://l29m4prill.execute-api.us-west-2.amazonaws.com/prod/application",
      TTEN: "https://8w3wbw1g27.execute-api.us-west-2.amazonaws.com/prod/application",
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    GET_APPLICATION_BY_ID: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/application",
      DEV: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/application",
      TTEN: "https://8w3wbw1g27.execute-api.us-west-2.amazonaws.com/prod/application",
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    // GET_APPLICATION_BY_TENANT_ID: {
    //   MOCK: "https://l29m4prill.execute-api.us-west-2.amazonaws.com/prod/get-by-tenant-id",
    //   DEV: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/application",
    // },
    UPLOAD_DOCUMENTS_FRONT: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/document-front",
      DEV: "https://l29m4prill.execute-api.us-west-2.amazonaws.com/prod/document-front",
      TTEN: "https://8w3wbw1g27.execute-api.us-west-2.amazonaws.com/prod/application/document-front",
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    UPLOAD_DOCUMENTS_BACK: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/document-back",
      DEV: "https://l29m4prill.execute-api.us-west-2.amazonaws.com/prod/document-back",
      TTEN: "https://8w3wbw1g27.execute-api.us-west-2.amazonaws.com/prod/application/document-back",
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    UPLOAD_REG_DOCUMENTS: {
      MOCK: "https://99volalbvk.execute-api.us-west-2.amazonaws.com/prod/document-back",
      DEV: `${BASE_URL_DEV}api/v1/docstorage/add`,
      ISOLATED: `${BASE_URL_DEV}api/v1/docstorage/add`,
      TTEN: `${BASE_URL_TTEN}api/v1/docstorage/add`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    ADD_DIGI_STAMP: {
      MOCK: "/api/v1/person/digi-stamp/",
      DEV: `${BASE_URL_DEV}api/v1/person/digi-stamp/`,
      ISOLATED: `${BASE_URL_DEV}api/v1/person/digi-stamp/`,
      TTEN: `${BASE_URL_TTEN}api/v1/person/digi-stamp/`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    GET_BUSINESS_RELATIONS: {
      MOCK: "/api/v1/business-relation",
      DEV: `${BASE_URL_DEV}api/v1/business-relation`,
      ISOLATED: `${BASE_URL_DEV}api/v1/business-relation`,
      TTEN: `${BASE_URL_TTEN}api/v1/business-relation`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    SEND_SOCURE_MAIL: {
      MOCK: "api/v1/kyckyb/docV/send-mail",
      DEV: `${BASE_URL_DEV}api/v1/kyckyb/docV/send-mail`,
      ISOLATED: `${BASE_URL_DEV}api/v1/kyckyb/docV/send-mail`,
      TTEN: `${BASE_URL_TTEN}api/v1/kyckyb/docV/send-mail`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    CREATE_ACCOUNT_RELATIONSHIP: {
      MOCK: "api/v1/account-relationship/",
      DEV: `${BASE_URL_DEV}api/v1/account-relationship/`,
      ISOLATED: `${BASE_URL_DEV}api/v1/account-relationship/`,
      TTEN: `${BASE_URL_TTEN}api/v1/account-relationship/`,
      DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
    },
    GET_LIST_DOCUMENTS: {
      MOCK: "api/v1/docstorage",
      DEV: `${BASE_URL_DEV}api/v1/docstorage`,
      ISOLATED: `${BASE_URL_DEV}api/v1/docstorage`,
      TTEN: `${BASE_URL_TTEN}api/v1/docstorage`,
      PROD: `${BASE_URL_TTEN}api/v1/docstorage`,
    },
    GET_DOCUMENT_FILE: {
      MOCK: "api/v1/docstorage/retrieve/",
      DEV: `${BASE_URL_DEV}api/v1/docstorage/retrieve/`,
      ISOLATED: `${BASE_URL_DEV}api/v1/docstorage/retrieve/`,
      TTEN: `${BASE_URL_TTEN}api/v1/docstorage/retrieve/`,
      PROD: `${BASE_URL_TTEN}api/v1/docstorage/retrieve/`,
    },
    SUPPORT: {
      GET_ATM_LOCATIONS_UNIT: {
        MOCK: "https://api.s.unit.sh/atm-locations",
        DEV: "https://api.s.unit.sh/atm-locations",
        TTEN: "https://api.s.unit.sh/atm-locations",
        DEMO: "https://api.s.unit.sh/atm-locations",
      },
    },
  },
  RECEIVABLES: {
    ENABLE_PAYMENT: {
      MOCK: "https://62013650fdf50900172498f0.mockapi.io/enable-payment",
      DEV: "https://62013650fdf50900172498f0.mockapi.io/enable-payment",
      TTEN: "https://62013650fdf50900172498f0.mockapi.io/enable-payment",
      DEMO: "https://62013650fdf50900172498f0.mockapi.io/enable-payment",
    },
    CLIENTS: {
      GET_CLIENTS: {
        MOCK: `${URL}/clients/`, //`${URL}/invoice/:companyId
        DEV: `${URL}/clients/`, //`${URL}/invoice/:companyId
        TTEN: `${URL}/clients/`, //`${URL}/invoice/:companyId
        DEMO: `${URL}/clients/`, //`${URL}/invoice/:companyId
      },
      GET_CLIENT: {
        MOCK: `${URL}/clients/:id`,
        DEV: `${URL}/clients/:id`,
        TTEN: `${URL}/clients/:id`,
        DEMO: `${URL}/clients/:id`,
      },
      CREATE_CLIENT: {
        MOCK: `${URL}/clients/:companyId`,
        DEV: `${URL}/clients/:companyId`,
        TTEN: `${URL}/clients/:companyId`,
        DEMO: `${URL}/clients/:companyId`,
      },
      DELETE_CLIENT: {
        MOCK: `${URL}/clients/:id`,
        DEV: `${URL}/clients/:id`,
        TTEN: `${URL}/clients/:id`,
        DEMO: `${URL}/clients/:id`,
      },
      UPDATE_CLIENT: {
        MOCK: `${URL}/clients/:id`,
        DEV: `${URL}/clients/:id`,
        TTEN: `${URL}/clients/:id`,
        DEMO: `${URL}/clients/:id`,
      },
    },
    COMPANIES: {
      GET_COMPANIES: {
        MOCK: `${URL}/companies/`,
        DEV: `${URL}/companies/`,
        TTEN: `${URL}/companies/`,
        DEMO: `${URL}/companies/`,
      },
      GET_COMPANY: {
        MOCK: `${URL}/companies/:id`,
        DEV: `${URL}/companies/:id`,
        TTEN: `${URL}/companies/:id`,
        DEMO: `${URL}/companies/:id`,
      },
      CREATE_COMPANY: {
        MOCK: `${URL}/companies/`,
        DEV: `${URL}/companies/`,
        TTEN: `${URL}/companies/`,
        DEMO: `${URL}/companies/`,
      },
      DELETE_COMPANY: {
        MOCK: `${URL}/companies/:id`,
        DEV: `${URL}/companies/:id`,
        TTEN: `${URL}/companies/:id`,
        DEMO: `${URL}/companies/:id`,
      },
      UPDATE_COMPANY: {
        MOCK: `${URL}/companies/:id`,
        DEV: `${URL}/companies/:id`,
        TTEN: `${URL}/companies/:id`,
        DEMO: `${URL}/companies/:id`,
      },
    },
    ESTIMATES: {
      LIST_ESTIMATES: {
        MOCK: `${URL}/estimates/`,
        DEV: `${URL_INVOICES_DEV}/estimates/`,
        TTEN: `${URL}/estimates/`,
        DEMO: `${URL}/estimates/`,
      },
      GET_ESTIMATE: {
        MOCK: `${URL}/estimate/:id`,
        DEV: `${URL_INVOICES_DEV}/estimate/`,
        TTEN: `${URL}/estimate/`,
        DEMO: `${URL}/estimate/`,
      },
      CREATE_ESTIMATE: {
        MOCK: `${URL}/estimate/`,
        DEV: `${URL_INVOICES_DEV}/estimate/`,
        TTEN: `${URL}/estimate/`,
        DEMO: `${URL}/estimate/`,
      },
      DELETE_ESTIMATE: {
        MOCK: `${URL}/estimate/:id`,
        DEV: `${URL_INVOICES_DEV}/estimate/`,
        TTEN: `${URL}/estimate/`,
        DEMO: `${URL}/estimate/`,
      },
      UPDATE_ESTIMATE: {
        MOCK: `${URL}/estimate/:id`,
        DEV: `${URL_INVOICES_DEV}/estimate/`,
        TTEN: `${URL}/estimate/`,
        DEMO: `${URL}/estimate/`,
      },
    },
    INVOICES: {
      GET_INVOICES: {
        MOCK: URL_INVOICES_DEV,
        // MOCK: `${URL}/invoices`, //`${URL}/invoice/:companyId
        // MOCK_FS: "/api/invoices",
        DEV: URL_INVOICES_DEV,
        TTEN: URL_INVOICES_DEV,
        DEMO: URL_INVOICES_DEV,
      },
      GET_OVERALL_INVOICES_DETAILS: {
        MOCK: "https://khjdfmpjed.execute-api.us-west-2.amazonaws.com/prod/",
        // MOCK: `${URL}/invoices`, //`${URL}/invoice/:companyId
        // MOCK_FS: "/api/invoices",
        DEV: "https://khjdfmpjed.execute-api.us-west-2.amazonaws.com/prod/",
      },
      CREATE_INVOICE: {
        MOCK: `${URL}/invoices`,

        DEV: `${URL}/invoices`,
        TTEN: `${URL}/invoices`,
        DEMO: `${URL}/invoices`,
      },
      DELETE_INVOICE: {
        MOCK: `${URL}/invoices/:id`,
        DEV: `${URL_INVOICES_DEV}/invoices/:id`,
        TTEN: `${URL}/invoices/:id`,
        DEMO: `${URL}/invoices/:id`,
      },
      UPDATE_INVOICE: {
        MOCK: `${URL}/invoices`,
        DEV: URL_INVOICES_DEV,
      },
      GET_INVOICE_PDF: {
        MOCK: `${URL}/pdf/:id`,
        DEV: `${URL}/pdf/:id`,
        TTEN: `${URL}/pdf/:id`,
        DEMO: `${URL}/pdf/:id`,
      },
    },
    RECURRING_INVOICES: {
      GET_RECCURING_INVOICES: {
        MOCK: `${URL}/recurring`, //`${URL}/recurring/:companyId
        DEV: `${URL}/recurring`, //`${URL}/recurring/:companyId
        TTEN: `${URL}/recurring`, //`${URL}/recurring/:companyId
        DEMO: `${URL}/recurring`, //`${URL}/recurring/:companyId
      },
      CREATE_RECURRING_INVOICE: {
        MOCK: `${URL}/recurring`,
        DEV: `${URL}/recurring`,
        TTEN: `${URL}/recurring`,
        DEMO: `${URL}/recurring`,
      },
      EDIT_RECURRING_INVOICE: {
        MOCK: `${URL}/recurring/:id`,
        DEV: `${URL}/recurring/:id`,
        TTEN: `${URL}/recurring/:id`,
        DEMO: `${URL}/recurring/:id`,
      },
    },
    TAX_RATES: {
      GET_ALL_TAX_RATES: {
        MOCK: `${URL}/tax`,
        DEV: `${URL}/tax`,
        TTEN: `${URL}/tax`,
        DEMO: `${URL}/tax`,
      },
      CREATE_TAX_RATES: {
        MOCK: `${URL}/tax`,
        DEV: `${URL}/tax`,
        TTEN: `${URL}/tax`,
        DEMO: `${URL}/tax`,
      },
      EDIT_TAX_RATES: {
        MOCK: `${URL}/tax/:id`,
        DEV: `${URL}/tax/:id`,
        TTEN: `${URL}/tax/:id`,
        DEMO: `${URL}/tax/:id`,
      },
      DELETE_TAX_RATES: {
        MOCK: `${URL}/tax/:id`,
        DEV: `${URL}/tax/:id`,
        TTEN: `${URL}/tax/:id`,
        DEMO: `${URL}/tax/:id`,
      },
    },
    CUSTOMERS: {
      GET_CUSTOMERS: {
        MOCK: `${URL}/customer`,
        DEV: `${URL_INVOICES_DEV}`,
        TTEN: `${URL}/customer`,
        DEMO: `${URL}/customer`,
      },
      ADD_CUSTOMER: {
        MOCK: `${URL}/customer`,
        DEV: `${URL}/customer`,
        TTEN: `${URL}/customer`,
        DEMO: `${URL}/customer`,
      },
      EDIT_CUSTOMER: {
        MOCK: `${URL}/customer/:id`,
        DEV: `${URL}/customer/:id`,
        TTEN: `${URL}/customer/:id`,
        DEMO: `${URL}/customer/:id`,
      },
    },

    SETTINGS: {},
  },
  PAYABLES: {
    ACCOUNTS_PAYABLES: {
      INVOICES: {
        MOCK: "/api/v1/ap/invoice/",
        DEV: `${BASE_URL_DEV}api/v1/ap/invoice/`,
        ISOLATED: `${BASE_URL_DEV}api/v1/ap/invoice/`,
        TTEN: `${BASE_URL_TTEN}api/v1/ap/invoice/`,
        DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
      },
      QUICKBOOKS: {
        MOCK: "/api/v1/ap/invoice/",
        DEV: `${BASE_URL_DEV}proxy/quick-book/tenant`,
        ISOLATED: `${BASE_URL_DEV}proxy/quick-book/tenant`,
        TTEN: `${BASE_URL_TTEN}proxy/quick-book/tenant`,
        DEMO: "https://q8787rbejb.execute-api.us-west-2.amazonaws.com/prod/application",
      },
    }
  },
  REWARDS: {
    GET_REWARDS_DETAILS: {
      MOCK: "/api/rewards/details/",
      DEV: "",
    },
    GET_REWARDS_CASHBACK_HISTORY_DETAILS: {
      MOCK: "/api/rewards/cashback-history/details/",
      DEV: "",
    },
    GET_REWARDS_CASHBACK_HISTORY_PAYMENTS: {
      MOCK: "/api/rewards/cashback-history/payments/",
      DEV: "",
    },
    GET_REWARDS_ACCUMULATED_CASHBACK_PAYMENTS: {
      MOCK: "/api/rewards/accumulated-cashback/payments/",
      DEV: "",
    },
  },
  SERVICES: {
    AUTH: {
      LOGIN: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}token/login`,
        ISOLATED: `${BASE_URL.ISOLATED}token/login`,
        NEW_PROD: `${BASE_URL.NEW_PROD}token/login`,
        TTEN: `${BASE_URL.PROD}token/login`,
        DEMO: `${BASE_URL.ISOLATED}token/login`,
      },
      GOOGLE_LOGIN: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}token/google-login`,
        ISOLATED: `${BASE_URL.ISOLATED}token/google-login`,
        NEW_PROD: `${BASE_URL.NEW_PROD}token/google-login`,
        TTEN: `${BASE_URL.PROD}token/google-login`,
        DEMO: `${BASE_URL.ISOLATED}token/google-login`,
      },
      SIGNUP: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}token/register`,
        ISOLATED: `${BASE_URL.ISOLATED}token/register`,
        NEW_PROD: `${BASE_URL.NEW_PROD}token/register`,
        TTEN: `${BASE_URL.PROD}token/register`,
        DEMO: `${BASE_URL.ISOLATED}token/register`,
      },
      GOOGLE_SIGNUP: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}token/google-register`,
        ISOLATED: `${BASE_URL.ISOLATED}token/google-register`,
        NEW_PROD: `${BASE_URL.NEW_PROD}token/google-register`,
        TTEN: `${BASE_URL.PROD}token/google-register`,
        DEMO: `${BASE_URL.ISOLATED}token/google-register`,
      },
      EMAIL_VERIFY: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}verify/email`,
        ISOLATED: `${BASE_URL.ISOLATED}verify/email`,
        NEW_PROD: `${BASE_URL.NEW_PROD}verify/email`,
        TTEN: `${BASE_URL.PROD}verify/email`,
        DEMO: `${BASE_URL.ISOLATED}verify/email`,
      },
      USER_DATA: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}verify/user`,
        ISOLATED: `${BASE_URL.ISOLATED}verify/user`,
        NEW_PROD: `${BASE_URL.NEW_PROD}verify/user`,
        TTEN: `${BASE_URL.PROD}verify/user`,
        DEMO: `${BASE_URL.ISOLATED}verify/user`,
      },
      PASSWORD_RESET: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}verify/password`,
        ISOLATED: `${BASE_URL.ISOLATED}verify/password`,
        NEW_PROD: `${BASE_URL.NEW_PROD}verify/password`,
        TTEN: `${BASE_URL.PROD}verify/password`,
        DEMO: `${BASE_URL.ISOLATED}verify/password`,
      }
    },
    ONBOARDING: {
      USER: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}user`,
        ISOLATED: `${BASE_URL.ISOLATED}user`,
        NEW_PROD: `${BASE_URL.NEW_PROD}user`,
        TTEN: `${BASE_URL.PROD}user`,
        DEMO: `${BASE_URL.ISOLATED}user`,
      },
      BUSINESS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}business`,
        ISOLATED: `${BASE_URL.ISOLATED}business`,
        NEW_PROD: `${BASE_URL.NEW_PROD}business`,
        TTEN: `${BASE_URL.PROD}business`,
        DEMO: `${BASE_URL.ISOLATED}business`,
      },
      BUSINESS_DATA_COLLECTION: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}data-collection/business`,
        ISOLATED: `${BASE_URL.ISOLATED}data-collection/business`,
        NEW_PROD: `${BASE_URL.NEW_PROD}data-collection/business`,
        TTEN: `${BASE_URL.PROD}data-collection/business`,
        DEMO: `${BASE_URL.ISOLATED}data-collection/business`,
      },
      TAX_FILLING_COLLECTION: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}data-collection/tax-filling`,
        ISOLATED: `${BASE_URL.ISOLATED}data-collection/tax-filling`,
        NEW_PROD: `${BASE_URL.NEW_PROD}data-collection/tax-filling`,
        TTEN: `${BASE_URL.PROD}data-collection/tax-filling`,
        DEMO: `${BASE_URL.ISOLATED}data-collection/tax-filling`,
      },
      INVITATION: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}invite`,
        ISOLATED: `${BASE_URL.ISOLATED}invite`,
        NEW_PROD: `${BASE_URL.NEW_PROD}invite`,
        TTEN: `${BASE_URL.PROD}invite`,
        DEMO: `${BASE_URL.ISOLATED}invite`,
      },
      STATUS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}onboarding`,
        ISOLATED: `${BASE_URL.ISOLATED}onboarding`,
        NEW_PROD: `${BASE_URL.NEW_PROD}onboarding`,
        TTEN: `${BASE_URL.PROD}onboarding`,
        DEMO: `${BASE_URL.ISOLATED}onboarding`,
      },
      QUICKBOOKS_OAUTH: {
        MOCK: "/mock",
        DEV: `http://ec2-18-233-152-212.compute-1.amazonaws.com:8001/?business_id=`,
        ISOLATED: `http://ec2-18-233-152-212.compute-1.amazonaws.com:8001/?business_id=`,
        NEW_PROD: `https://qb.app.otterz.co/?business_id=`,
        TTEN: `https://qb.app.otterz.co/?business_id=`,
        DEMO: `http://ec2-18-233-152-212.compute-1.amazonaws.com:8001/?business_id=`,
      },
      QUICKBOOKS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}quickbooks`,
        ISOLATED: `${BASE_URL.ISOLATED}quickbooks`,
        NEW_PROD: `${BASE_URL.NEW_PROD}quickbooks`,
        TTEN: `${BASE_URL.PROD}quickbooks`,
        DEMO: `${BASE_URL.ISOLATED}quickbooks`,
      },
      QUICKBOOKS_ONBOARDING: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}onboarding`,
        ISOLATED: `${BASE_URL.ISOLATED}onboarding`,
        NEW_PROD: `${BASE_URL.NEW_PROD}onboarding`,
        TTEN: `${BASE_URL.PROD}onboarding`,
        DEMO: `${BASE_URL.ISOLATED}onboarding`,
      },
      PLAID: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}plaid`,
        ISOLATED: `${BASE_URL.ISOLATED}plaid`,
        NEW_PROD: `${BASE_URL.NEW_PROD}plaid`,
        TTEN: `${BASE_URL.PROD}plaid`,
        DEMO: `${BASE_URL.ISOLATED}plaid`,
      },
      INTEGRATION_STATUS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}integration-status`,
        ISOLATED: `${BASE_URL.ISOLATED}integration-status`,
        NEW_PROD: `${BASE_URL.NEW_PROD}integration-status`,
        TTEN: `${BASE_URL.PROD}integration-status`,
        DEMO: `${BASE_URL.ISOLATED}integration-status`,
      }
    },
    AUTHORIZATION: {
      ROLES_AND_PERMISSIONS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}role`,
        ISOLATED: `${BASE_URL.ISOLATED}role`,
        NEW_PROD: `${BASE_URL.NEW_PROD}role`,
        TTEN: `${BASE_URL.PROD}role`,
        DEMO: `${BASE_URL.ISOLATED}role`,
      },
    },
    DASHBOARD: {
      PAYMENT_DETAILS_SUBSCRIPTION: {
        MOCK: "/mock",
        DEV: `https://billing.stripe.com/p/login/test_3cscP4cbWgP35LG3cc`,
        ISOLATED: `https://billing.stripe.com/p/login/test_3cscP4cbWgP35LG3cc`,
        NEW_PROD: `https://billing.stripe.com/p/login/5kA28P5E4cd6gaA6oo`,
        TTEN: `https://billing.stripe.com/p/login/5kA28P5E4cd6gaA6oo`,
        DEMO: `https://billing.stripe.com/p/login/test_3cscP4cbWgP35LG3cc`,
      },
      CLIENT_QUERIES: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}get-client-side-transactions/`,
        ISOLATED: `${BASE_URL.ISOLATED}get-client-side-transactions/`,
        NEW_PROD: `${BASE_URL.NEW_PROD}get-client-side-transactions/`,
        TTEN: `${BASE_URL.PROD}get-client-side-transactions/`,
        DEMO: `${BASE_URL.ISOLATED}get-client-side-transactions/`,
      },
      QUERIES: {
        BOOKKEEPING: {
          GET_QUESTION_FOR_TRANSACTION: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}bookkeeping/get-question/`,
            ISOLATED: `${BASE_URL.ISOLATED}bookkeeping/get-question/`,
            NEW_PROD: `${BASE_URL.NEW_PROD}bookkeeping/get-question/`,
            TTEN: `${BASE_URL.PROD}bookkeeping/get-question/`,
            DEMO: `${BASE_URL.ISOLATED}bookkeeping/get-question/`,
          },
          SEND_CONFIREMD_MESSAGE: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}bookkeeping/send-message/`,
            ISOLATED: `${BASE_URL.ISOLATED}bookkeeping/send-message/`,
            NEW_PROD: `${BASE_URL.NEW_PROD}bookkeeping/send-message/`,
            TTEN: `${BASE_URL.PROD}bookkeeping/send-message/`,
            DEMO: `${BASE_URL.ISOLATED}bookkeeping/send-message/`,
          },
          GET_MESSAGE_FOR_TRANSACTION: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}bookkeeping/get-message/`,
            ISOLATED: `${BASE_URL.ISOLATED}bookkeeping/get-message/`,
            NEW_PROD: `${BASE_URL.NEW_PROD}bookkeeping/get-message/`,
            TTEN: `${BASE_URL.PROD}bookkeeping/get-message/`,
            DEMO: `${BASE_URL.ISOLATED}bookkeeping/get-message/`,
          },
          GET_TRANSACTION_AND_COUNT: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}bookkeeping/get-transaction/`,
            ISOLATED: `${BASE_URL.ISOLATED}bookkeeping/get-transaction/`,
            NEW_PROD: `${BASE_URL.NEW_PROD}bookkeeping/get-transaction/`,
            TTEN: `${BASE_URL.PROD}bookkeeping/get-transaction/`,
            DEMO: `${BASE_URL.ISOLATED}bookkeeping/get-transaction/`,
          }
        },
        TAXES: {
          GET_QUESTION_FOR_TRANSACTION: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}tax-queries/get-question/`,
            ISOLATED: `${BASE_URL.ISOLATED}tax-queries/get-question/`,
            NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/get-question/`,
            TTEN: `${BASE_URL.PROD}tax-queries/get-question/`,
            DEMO: `${BASE_URL.ISOLATED}tax-queries/get-question/`,
          },
          SEND_CONFIREMD_MESSAGE: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}tax-queries/send-message/`,
            ISOLATED: `${BASE_URL.ISOLATED}tax-queries/send-message/`,
            NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/send-message/`,
            TTEN: `${BASE_URL.PROD}tax-queries/send-message/`,
            DEMO: `${BASE_URL.ISOLATED}tax-queries/send-message/`,
          },
          GET_MESSAGE_FOR_TRANSACTION: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}tax-queries/get-message/`,
            ISOLATED: `${BASE_URL.ISOLATED}tax-queries/get-message/`,
            NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/get-message/`,
            TTEN: `${BASE_URL.PROD}tax-queries/get-message/`,
            DEMO: `${BASE_URL.ISOLATED}tax-queries/get-message/`,
          },
          GET_TRANSACTION_AND_COUNT: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}tax-queries/get-list-of-tax-queries/`,
            ISOLATED: `${BASE_URL.ISOLATED}tax-queries/get-list-of-tax-queries/`,
            NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/get-list-of-tax-queries/`,
            TTEN: `${BASE_URL.PROD}tax-queries/get-list-of-tax-queries/`,
            DEMO: `${BASE_URL.ISOLATED}tax-queries/get-list-of-tax-queries/`,
          }
        }
      },
      INSIGHTS: {
        V2:{
          BUSINESS_OVERVIEW: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}dashboard/v2/business-overview`,
            ISOLATED: `${BASE_URL.ISOLATED}dashboard/v2/business-overview`,
            NEW_PROD: `${BASE_URL.NEW_PROD}dashboard/v2/business-overview`,
            TTEN: `${BASE_URL.PROD}dashboard/v2/business-overview`,
            DEMO: `${BASE_URL.ISOLATED}dashboard/v2/business-overview`,
          },
          PROFIT_LOSS: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}dashboard/v2/pnl`,
            ISOLATED: `${BASE_URL.ISOLATED}dashboard/v2/pnl`,
            NEW_PROD: `${BASE_URL.NEW_PROD}dashboard/v2/pnl`,
            TTEN: `${BASE_URL.PROD}dashboard/v2/pnl`,
            DEMO: `${BASE_URL.ISOLATED}dashboard/v2/pnl`,
          },
          CASHFLOW: {
            MOCK: "/mock",
            DEV: `${BASE_URL.DEV}dashboard/v2/cashflow`,
            ISOLATED: `${BASE_URL.ISOLATED}dashboard/v2/cashflow`,
            NEW_PROD: `${BASE_URL.NEW_PROD}dashboard/v2/cashflow`,
            TTEN: `${BASE_URL.PROD}dashboard/v2/cashflow`,
            DEMO: `${BASE_URL.ISOLATED}dashboard/v2/cashflow`,
          },
        },
        BUSINESS_OVERVIEW: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}dashboard/business-overview/`,
          ISOLATED: `${BASE_URL.ISOLATED}dashboard/business-overview/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}dashboard/business-overview/`,
          TTEN: `${BASE_URL.PROD}dashboard/business-overview/`,
          DEMO: `${BASE_URL.ISOLATED}dashboard/business-overview/`,
        },
        FUNDS_IN: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}dashboard/funds-in/`,
          ISOLATED: `${BASE_URL.ISOLATED}dashboard/funds-in/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}dashboard/funds-in/`,
          TTEN: `${BASE_URL.PROD}dashboard/funds-in/`,
          DEMO: `${BASE_URL.ISOLATED}dashboard/funds-in/`,
        },
        FUNDS_OUT: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}dashboard/funds-out/`,
          ISOLATED: `${BASE_URL.ISOLATED}dashboard/funds-out/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}dashboard/funds-out/`,
          TTEN: `${BASE_URL.PROD}dashboard/funds-out/`,
          DEMO: `${BASE_URL.ISOLATED}dashboard/funds-out/`,
        },
        PROFIT_LOSS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}dashboard/pnl/`,
          ISOLATED: `${BASE_URL.ISOLATED}dashboard/pnl/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}dashboard/pnl/`,
          TTEN: `${BASE_URL.PROD}dashboard/pnl/`,
          DEMO: `${BASE_URL.ISOLATED}dashboard/pnl/`,
        },
        CASHFLOW: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}dashboard/cashflow/`,
          ISOLATED: `${BASE_URL.ISOLATED}dashboard/cashflow/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}dashboard/cashflow/`,
          TTEN: `${BASE_URL.PROD}dashboard/cashflow/`,
          DEMO: `${BASE_URL.ISOLATED}dashboard/cashflow/`,
        },
      },
      DOCUMENTS_VAULT: {
        FILE: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}file`,
          ISOLATED: `${BASE_URL.ISOLATED}file`,
          NEW_PROD: `${BASE_URL.NEW_PROD}file`,
          TTEN: `${BASE_URL.PROD}file`,
          DEMO: `${BASE_URL.ISOLATED}file`,
        },
        FILE_TO_FOLDER: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}folder/folder-name-upload`,
          ISOLATED: `${BASE_URL.ISOLATED}folder/folder-name-upload`,
          NEW_PROD: `${BASE_URL.NEW_PROD}folder/folder-name-upload`,
          TTEN: `${BASE_URL.PROD}folder/folder-name-upload`,
          DEMO: `${BASE_URL.ISOLATED}folder/folder-name-upload`,
        },
        FILE_CHAT: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}chat/upload-file-via-chat`,
          ISOLATED: `${BASE_URL.ISOLATED}chat/upload-file-via-chat`,
          NEW_PROD: `${BASE_URL.NEW_PROD}chat/upload-file-via-chat`,
          TTEN: `${BASE_URL.PROD}chat/upload-file-via-chat`,
          DEMO: `${BASE_URL.ISOLATED}chat/upload-file-via-chat`,
        },
        FOLDER: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}folder`,
          ISOLATED: `${BASE_URL.ISOLATED}folder`,
          NEW_PROD: `${BASE_URL.NEW_PROD}folder`,
          TTEN: `${BASE_URL.PROD}folder`,
          DEMO: `${BASE_URL.ISOLATED}folder`,
        },
        MOVE_FILE: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}move-file`,
          ISOLATED: `${BASE_URL.ISOLATED}move-file`,
          NEW_PROD: `${BASE_URL.NEW_PROD}move-file`,
          TTEN: `${BASE_URL.PROD}move-file`,
          DEMO: `${BASE_URL.ISOLATED}move-file`,
        }
      },
      CHATKITTY: {
        GET_MESSAGES_BUSINESS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}get-message/`,
          ISOLATED: `${BASE_URL.ISOLATED}get-message/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}get-message/`,
          TTEN: `${BASE_URL.PROD}get-message/`,
          DEMO: `${BASE_URL.ISOLATED}get-message/`,
        },
        GET_MESSAGES_ACCOUNTANT: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}get-message-between-client-and-accountant/`,
          ISOLATED: `${BASE_URL.ISOLATED}get-message-between-client-and-accountant/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}get-message-between-client-and-accountant/`,
          TTEN: `${BASE_URL.PROD}get-message-between-client-and-accountant/`,
          DEMO: `${BASE_URL.ISOLATED}get-message-between-client-and-accountant/`,
        },
        SEND_MESSAGES_BUSINESS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}send-message/`,
          ISOLATED: `${BASE_URL.ISOLATED}send-message/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}send-message/`,
          TTEN: `${BASE_URL.PROD}send-message/`,
          DEMO: `${BASE_URL.ISOLATED}send-message/`,
        },
        SEND_MESSAGES_ACCOUNTANT: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}send-message-between-client-and-accountant/`,
          ISOLATED: `${BASE_URL.ISOLATED}send-message-between-client-and-accountant/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}send-message-between-client-and-accountant/`,
          TTEN: `${BASE_URL.PROD}send-message-between-client-and-accountant/`,
          DEMO: `${BASE_URL.ISOLATED}send-message-between-client-and-accountant/`,
        },
        SEND_EMAIL_NOTIFICATION: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}bookkepper/send-email-notification/`,
          ISOLATED: `${BASE_URL.ISOLATED}bookkepper/send-email-notification/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}bookkepper/send-email-notification/`,
          TTEN: `${BASE_URL.PROD}bookkepper/send-email-notification/`,
          DEMO: `${BASE_URL.ISOLATED}bookkepper/send-email-notification/`,
        },
        SEND_MESSAGE_BOOKKEEPING: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}bookkeeping/send-message/`,
          ISOLATED: `${BASE_URL.ISOLATED}bookkeeping/send-message/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}bookkeeping/send-message/`,
          TTEN: `${BASE_URL.PROD}bookkeeping/send-message/`,
          DEMO: `${BASE_URL.ISOLATED}bookkeeping/send-message/`,
        },
        SEND_MESSAGE_TAXES: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax-queries/send-message/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax-queries/send-message/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/send-message/`,
          TTEN: `${BASE_URL.PROD}tax-queries/send-message/`,
          DEMO: `${BASE_URL.ISOLATED}tax-queries/send-message/`,
        },
      },
      TAX_FILLING: {
        TAX_FILLING_ONBOARD: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax/onboarding`,
          ISOLATED: `${BASE_URL.ISOLATED}tax/onboarding`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax/onboarding`,
          TTEN: `${BASE_URL.PROD}tax/onboarding`,
          DEMO: `${BASE_URL.ISOLATED}tax/onboarding`,
        },
        PUSH_BUSINESS_QUESTION: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax/question`,
          ISOLATED: `${BASE_URL.ISOLATED}tax/question`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax/question`,
          TTEN: `${BASE_URL.PROD}tax/question`,
          DEMO: `${BASE_URL.ISOLATED}tax/question`,
        },
        PUSH_QUESTION_DOCUMENT: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax/doc/question`,
          ISOLATED: `${BASE_URL.ISOLATED}tax/doc/question`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax/doc/question`,
          TTEN: `${BASE_URL.PROD}tax/doc/question`,
          DEMO: `${BASE_URL.ISOLATED}tax/doc/question`,
        },
        GET_TAXFORM_DETAILS_1099: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}accountant-dashboard/get-details`,
          ISOLATED: `${BASE_URL.ISOLATED}accountant-dashboard/get-details`,
          NEW_PROD: `${BASE_URL.NEW_PROD}accountant-dashboard/get-details`,
          TTEN: `${BASE_URL.PROD}accountant-dashboard/get-details`,
          DEMO: `${BASE_URL.ISOLATED}accountant-dashboard/get-details`,
        },
        UPDATE_TAXFORM_DETAILS_1099: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}accountant-dashboard/update-details`,
          ISOLATED: `${BASE_URL.ISOLATED}accountant-dashboard/update-details`,
          NEW_PROD: `${BASE_URL.NEW_PROD}accountant-dashboard/update-details`,
          TTEN: `${BASE_URL.PROD}accountant-dashboard/update-details`,
          DEMO: `${BASE_URL.ISOLATED}accountant-dashboard/update-details`,
        },
        GET_TAX_QUERIES: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax-queries/get-list-of-tax-queries`,
          ISOLATED: `${BASE_URL.ISOLATED}tax-queries/get-list-of-tax-queries`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/get-list-of-tax-queries`,
          TTEN: `${BASE_URL.PROD}tax-queries/get-list-of-tax-queries`,
          DEMO: `${BASE_URL.ISOLATED}tax-queries/get-list-of-tax-queries`,
        },
        GET_TAX_QUERIES_MESSAGES: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax-queries/get-message/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax-queries/get-message/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/get-message/`,
          TTEN: `${BASE_URL.PROD}tax-queries/get-message/`,
          DEMO: `${BASE_URL.ISOLATED}tax-queries/get-message/`,
        },
        SEND_TAX_QUERY_MESSAGE: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax-queries/send-message/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax-queries/send-message/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/send-message/`,
          TTEN: `${BASE_URL.PROD}tax-queries/send-message/`,
          DEMO: `${BASE_URL.ISOLATED}tax-queries/send-message/`,
        },
        UPDATE_TAX_QUERY_STATUS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax-queries/update-status/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax-queries/update-status/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax-queries/update-status/`,
          TTEN: `${BASE_URL.PROD}tax-queries/update-status/`,
          DEMO: `${BASE_URL.ISOLATED}tax-queries/update-status/`,
        },
      }
    },
    SUBSCRIPTIONS: {
      CREATE_SUBSCRIPTION: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}subscription`,
        ISOLATED: `${BASE_URL.ISOLATED}subscription`,
        NEW_PROD: `${BASE_URL.NEW_PROD}subscription`,
        TTEN: `${BASE_URL.PROD}subscription`,
        DEMO: `${BASE_URL.ISOLATED}subscription`,
      },
      GET_SUBSCRIBED_PLAN_DETAILS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}subscription`,
        ISOLATED: `${BASE_URL.ISOLATED}subscription`,
        NEW_PROD: `${BASE_URL.NEW_PROD}subscription`,
        TTEN: `${BASE_URL.PROD}subscription`,
        DEMO: `${BASE_URL.ISOLATED}subscription`,
      },
      GET_SUBSCRIPTION_DETAILS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}subscription`,
        ISOLATED: `${BASE_URL.ISOLATED}subscription`,
        NEW_PROD: `${BASE_URL.NEW_PROD}subscription`,
        TTEN: `${BASE_URL.PROD}subscription`,
        DEMO: `${BASE_URL.ISOLATED}subscription`,
      },
      REFRESH_SUBSCRIPTION: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}refresh-subscription`,
        ISOLATED: `${BASE_URL.ISOLATED}refresh-subscription`,
        NEW_PROD: `${BASE_URL.NEW_PROD}refresh-subscription`,
        TTEN: `${BASE_URL.PROD}refresh-subscription`,
        DEMO: `${BASE_URL.ISOLATED}refresh-subscription`,
      },
      UPSERT_SUBSCRIPTION: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}subscription`,
        ISOLATED: `${BASE_URL.ISOLATED}subscription`,
        NEW_PROD: `${BASE_URL.NEW_PROD}subscription`,
        TTEN: `${BASE_URL.PROD}subscription`,
        DEMO: `${BASE_URL.ISOLATED}subscription`,
      },
      CTF_ADDON: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}add-ctf`,
        ISOLATED: `${BASE_URL.ISOLATED}add-ctf`,
        NEW_PROD: `${BASE_URL.NEW_PROD}add-ctf`,
        TTEN: `${BASE_URL.PROD}add-ctf`,
        DEMO: `${BASE_URL.ISOLATED}add-ctf`,
      },
    },
    ACCOUNTANTS_DASHBOARD: {
      GET_BUSINESS_FOR_ACCOUNTANTS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}accountant-dashboard/get-businesses/`,
        ISOLATED: `${BASE_URL.ISOLATED}accountant-dashboard/get-businesses/`,
        NEW_PROD: `${BASE_URL.NEW_PROD}accountant-dashboard/get-businesses/`,
        TTEN: `${BASE_URL.PROD}accountant-dashboard/get-businesses/`,
        DEMO: `${BASE_URL.ISOLATED}accountant-dashboard/get-businesses/`,
      },
      GET_BUSINESS_PROFILE: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}accountant-dashboard/get-business-profile/`,
        ISOLATED: `${BASE_URL.ISOLATED}accountant-dashboard/get-business-profile/`,
        NEW_PROD: `${BASE_URL.NEW_PROD}accountant-dashboard/get-business-profile/`,
        TTEN: `${BASE_URL.PROD}accountant-dashboard/get-business-profile/`,
        DEMO: `${BASE_URL.ISOLATED}accountant-dashboard/get-business-profile/`,
      },
      CATEGORIZATION: {
        GET_COUNT: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}categorization/get-count/`,
          ISOLATED: `${BASE_URL.ISOLATED}categorization/get-count/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}categorization/get-count/`,
          TTEN: `${BASE_URL.PROD}categorization/get-count/`,
          DEMO: `${BASE_URL.ISOLATED}categorization/get-count/`,
        },
        GET_SYNCED_TRANSACTIONS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}categorization/get-synced-transactions/`,
          ISOLATED: `${BASE_URL.ISOLATED}categorization/get-synced-transactions/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}categorization/get-synced-transactions/`,
          TTEN: `${BASE_URL.PROD}categorization/get-synced-transactions/`,
          DEMO: `${BASE_URL.ISOLATED}categorization/get-synced-transactions/`,
        },
        GET_FOR_REVIEW_TRANSACTIONS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}categorization/get-review-transactions/`,
          ISOLATED: `${BASE_URL.ISOLATED}categorization/get-review-transactions/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}categorization/get-review-transactions/`,
          TTEN: `${BASE_URL.PROD}categorization/get-review-transactions/`,
          DEMO: `${BASE_URL.ISOLATED}categorization/get-review-transactions/`,
        },
        GET_SYNC_PENDING_TRANSACTIONS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}categorization/get-sync-pending-transactions/`,
          ISOLATED: `${BASE_URL.ISOLATED}categorization/get-sync-pending-transactions/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}categorization/get-sync-pending-transactions/`,
          TTEN: `${BASE_URL.PROD}categorization/get-sync-pending-transactions/`,
          DEMO: `${BASE_URL.ISOLATED}categorization/get-sync-pending-transactions/`,
        },
        GET_CHART_OF_ACCOUNTS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}categorization/get-chart-of-accounts/`,
          ISOLATED: `${BASE_URL.ISOLATED}categorization/get-chart-of-accounts/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}categorization/get-chart-of-accounts/`,
          TTEN: `${BASE_URL.PROD}categorization/get-chart-of-accounts/`,
          DEMO: `${BASE_URL.ISOLATED}categorization/get-chart-of-accounts/`,
        },
        CONFIRM_CATEGORY: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}categorization/confirm-category/`,
          ISOLATED: `${BASE_URL.ISOLATED}categorization/confirm-category/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}categorization/confirm-category/`,
          TTEN: `${BASE_URL.PROD}categorization/confirm-category/`,
          DEMO: `${BASE_URL.ISOLATED}categorization/confirm-category/`,
        },
        UNDO_TRANSACTION: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}categorization/undo-synced/`,
          ISOLATED: `${BASE_URL.ISOLATED}categorization/undo-synced/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}categorization/undo-synced/`,
          TTEN: `${BASE_URL.PROD}categorization/undo-synced/`,
          DEMO: `${BASE_URL.ISOLATED}categorization/undo-synced/`,
        },
      },
      CONTRACTORS: {
        GET_LIST_OF_CONTRACTORS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}contractors/get-list-of-contractors/`,
          ISOLATED: `${BASE_URL.ISOLATED}contractors/get-list-of-contractors/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}contractors/get-list-of-contractors/`,
          TTEN: `${BASE_URL.PROD}contractors/get-list-of-contractors/`,
          DEMO: `${BASE_URL.ISOLATED}contractors/get-list-of-contractors/`,
        },
        GET_LIST_OF_VENDORS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}contractors/get-list-of-vendors/`,
          ISOLATED: `${BASE_URL.ISOLATED}contractors/get-list-of-vendors/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}contractors/get-list-of-vendors/`,
          TTEN: `${BASE_URL.PROD}contractors/get-list-of-vendors/`,
          DEMO: `${BASE_URL.ISOLATED}contractors/get-list-of-vendors/`,
        },
        GET_LIST_OF_REJECTED_CONTRACTORS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}contractors/get-rejected-contractors/`,
          ISOLATED: `${BASE_URL.ISOLATED}contractors/get-rejected-contractors/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}contractors/get-rejected-contractors/`,
          TTEN: `${BASE_URL.PROD}contractors/get-rejected-contractors/`,
          DEMO: `${BASE_URL.ISOLATED}contractors/get-rejected-contractors/`,
        },
        CONFIRM_VENDORS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}contractors/confirm-contractor/`,
          ISOLATED: `${BASE_URL.ISOLATED}contractors/confirm-contractor/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}contractors/confirm-contractor/`,
          TTEN: `${BASE_URL.PROD}contractors/confirm-contractor/`,
          DEMO: `${BASE_URL.ISOLATED}contractors/confirm-contractor/`,
        },
        REJECT_VENDORS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}contractors/reject-contractor/`,
          ISOLATED: `${BASE_URL.ISOLATED}contractors/reject-contractor/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}contractors/reject-contractor/`,
          TTEN: `${BASE_URL.PROD}contractors/reject-contractor/`,
          DEMO: `${BASE_URL.ISOLATED}contractors/reject-contractor/`,
        },
        UPDATE_EMAIL: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}contractors/update-contractors/`,
          ISOLATED: `${BASE_URL.ISOLATED}contractors/update-contractors/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}contractors/update-contractors/`,
          TTEN: `${BASE_URL.PROD}contractors/update-contractors/`,
          DEMO: `${BASE_URL.ISOLATED}contractors/update-contractors/`,
        },
        SEND_WFORM: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax/w8w9/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax/w8w9/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax/w8w9/`,
          TTEN: `${BASE_URL.PROD}tax/w8w9/`,
          DEMO: `${BASE_URL.ISOLATED}tax/w8w9/`,
        },
      },
      TAX: {
        GET_LIST_OF_FORMS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax-form/get-list-of-forms/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax-form/get-list-of-forms/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax-form/get-list-of-forms/`,
          TTEN: `${BASE_URL.PROD}tax-form/get-list-of-forms/`,
          DEMO: `${BASE_URL.ISOLATED}tax-form/get-list-of-forms/`,
        },
        GET_FORM_DETAILS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax-form/get-form/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax-form/get-form/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax-form/get-form/`,
          TTEN: `${BASE_URL.PROD}tax-form/get-form/`,
          DEMO: `${BASE_URL.ISOLATED}tax-form/get-form/`,
        },
        GENERATE_PDF: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax/1099/generate_pdf/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax/1099/generate_pdf/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax/1099/generate_pdf/`,
          TTEN: `${BASE_URL.PROD}tax/1099/generate_pdf/`,
          DEMO: `${BASE_URL.ISOLATED}tax/1099/generate_pdf/`,
        },
        SUBMIT: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax/1099/submit/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax/1099/submit/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax/1099/submit/`,
          TTEN: `${BASE_URL.PROD}tax/1099/1099/submit/`,
          DEMO: `${BASE_URL.ISOLATED}tax/1099/submit/`,
        },
        UPDATE_FORM_DETAILS: {
          MOCK: "/mock",
          DEV: `${BASE_URL.DEV}tax-form/update-form/`,
          ISOLATED: `${BASE_URL.ISOLATED}tax-form/update-form/`,
          NEW_PROD: `${BASE_URL.NEW_PROD}tax-form/update-form/`,
          TTEN: `${BASE_URL.PROD}tax-form/update-form/`,
          DEMO: `${BASE_URL.ISOLATED}tax-form/update-form/`,
        }
      },
      REQUEST_DOCUMENTS: {
        MOCK: "/mock",
        DEV: `${BASE_URL.DEV}accountant-dashboard/request-documents/`,
        ISOLATED: `${BASE_URL.ISOLATED}accountant-dashboard/request-documents/`,
        NEW_PROD: `${BASE_URL.NEW_PROD}accountant-dashboard/request-documents/`,
        TTEN: `${BASE_URL.PROD}accountant-dashboard/request-documents/`,
        DEMO: `${BASE_URL.ISOLATED}accountant-dashboard/request-documents/`,
      },
    }
  }
};

export default endpoints;
