import React from 'react';
import { Typography } from 'antd';
import classNames from "../Signup.module.scss"; // Import the CSS for additional styling
import { CTAButton, Message } from '../../../common';
import { useMutation } from 'react-query';
import api from '../../../API';
import Bugsnag from '@bugsnag/js';

const { Title, Paragraph } = Typography;

function PasswordReset() {
  const passwordResetMutation = useMutation(data => api.Services.Auth.resetPassword(data), {
    onSuccess: (data) => {
      // Handle successful registration
      console.log("Registration successful", data);
      Message({ type: "success", content: "Verification email resent" });
    },
    onError: (error) => {
      // Handle any errors from the API call
      Bugsnag.notify("Error in email verification", error, error.message)
      Message({ type: "error", content: error.message });
    },
  });
  
  const handleResetPassword = () => {
    passwordResetMutation.mutate({email: ""});
    console.log('Reset link clicked');
  };


  return (
    <div className={classNames.emailVerificationContainer}>
      <Title level={3} style={{width: '40%'}}>We will send a link to reset password on your email id. Please enter below the email id</Title>
      <Paragraph style={{width: '40%'}}>
        Please click on the link that has been sent to your email account to verify your email and continue the onboarding process
      </Paragraph>
      <CTAButton className={classNames.emailVerifyButton} type="primary" onClick={handleResetPassword}>Send Email</CTAButton>
    </div>
  );
}

export default PasswordReset;
