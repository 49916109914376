const Books = () => {
  return (
<svg style={{marginRight: '10px'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6693 10V13.5C16.6693 15.3856 16.6693 16.3284 16.0835 16.9142C15.4977 17.5 14.5549 17.5 12.6693 17.5H5.41927C4.26868 17.5 3.33594 16.5673 3.33594 15.4167V15.4167C3.33594 14.2661 4.26868 13.3333 5.41927 13.3333H12.6693C14.5549 13.3333 15.4977 13.3333 16.0835 12.7475C16.6693 12.1618 16.6693 11.219 16.6693 9.33333V6.5C16.6693 4.61438 16.6693 3.67157 16.0835 3.08579C15.4977 2.5 14.5549 2.5 12.6693 2.5H7.33593C5.45032 2.5 4.50751 2.5 3.92172 3.08579C3.33594 3.67157 3.33594 4.61438 3.33594 6.5V15.4167" stroke="#82898F" stroke-width="2"/>
<path d="M7.5 8.33073L8.45956 9.29029C8.85008 9.68081 9.48325 9.68081 9.87377 9.29029L12.5 6.66406" stroke="#82898F" stroke-width="2" stroke-linecap="round"/>
</svg>
  );
};

export default Books;
