import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";
import { Form, Modal } from "antd";
import { some } from 'lodash';

import classNames from "../Invoices.module.scss";
import { CTAButton, Message } from "../../../../common";
import CreateNewInoviceForm from "./CreateNewInvoiceForm";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { FORMATS } from "../../../../constants";
import Bugsnag from "@bugsnag/js";
import mixpanel from "mixpanel-browser";

export default function CreateNewInvoice({
  visible,
  onCancel,
  invoiceTemplateSettings,
}) {
  const [form] = Form.useForm();
  const language = useLanguage(messages);
  const [isSendInvoice, setIsSendInvoice] = useState(false);

  const queryClient = useQueryClient();
  const [summary, setSummary] = useState({ subTotal: 0, tax: 0 });
  const [discount, setDiscount] = useState(0);

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  function shareInvoiceFn(input) {
    return api.Receivables.Share.email(input);
  }



  const shareInvoiceMn = useMutation((data) => shareInvoiceFn(data));

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: productQuery } = useQuery(
    "getProducts",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const productData = productQuery?.data;

  const { data: taxQuery } = useQuery(
    "getAllTaxes",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: invoicesQuery } = useQuery(
    "getInvoices",
    () => api.Receivables.Invoices.getInvoices(
      currentUserData?.signInUserSession?.idToken?.payload?.icid
    ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  function createInvoiceFn(data) {
    let itemDto =
      data.salesItems &&
      data.salesItems.length > 0 &&
      data.salesItems.map((item, index) => {
        return {
          description: item.description,
          quantity: item.quantity ? Number(item.quantity) : 1,
          taxRate: item.taxRate ? Number(item.taxRate) : 0,
          unitCost: Number(item.unitCost),
        };
      });

    let input = {
      companyId: currentUserData?.signInUserSession?.idToken?.payload?.icid,
      // invoiceNumber: data.invoiceNumber,
      clientId: data.client,
      invoiceDate: moment(data.invoiceDate).format(FORMATS.API_OTHER_FORMAT),
      discount: data.discount ? data.discount : 0,
      reference: data.reference ? data.reference : "",
      itemDto: data.salesItems ? itemDto : [],
    };
    return api.Receivables.Invoices.createInvoice(input);
  }

  const createInvoiceMn = useMutation((event) => createInvoiceFn(event));

  const updateInvoiceFunction = (event) =>
    api.Receivables.Invoices.setInvoiceStatus(event);

  const updateInvoiceMutation = useMutation(
    (event) => updateInvoiceFunction(event),
    {
      onSuccess: () => {
        Message({
          type: "success",
          content: language.INVOICE_SENT_SUCCESS,
        });
        queryClient.invalidateQueries("getInvoices");
        form.resetFields();
        onCancel();
      },
      onSettled: () => {
        setIsSendInvoice(false);
      },
    }
  );

  const updateInvoiceStatus = (data) =>
    updateInvoiceMutation.mutate(data, {
      onSuccess: () => {
        shareInvoiceMn.mutate({
          id: data.id,
          type: "invoice",
        });
      },
    });
 
  const checkDuplicateInvoice = (newInvoiceData) => {
    const existingInvoices = invoicesQuery?.data || [];
    return some(existingInvoices, invoice => 
      invoice.Customer === newInvoiceData.Customer &&
      invoice.Date === newInvoiceData.Date
    );
  }

  const onFinish = (data) => {
    if (checkDuplicateInvoice(data)) {
      // Message({
      //   type: "error",
      //   content: "Duplicate customer data, not allowed",
      // });
      // return;
    }

    createInvoiceMn.mutate(data, {
      onSuccess: async (response) => {
      
        if (isSendInvoice) {
          updateInvoiceStatus({
            id: response.data.id,
            type: "invoice",
            status: "sent",
          });

          try {
            // Extracting values from data
            const totalItems = data.salesItems.length;
            const totalPrice = data.salesItems.reduce((acc, item) => acc + item.subTotal, 0);
            const totalTax = data.salesItems.reduce((acc, item) => acc + item.taxAmount, 0);
            const payableAmount = totalPrice + totalTax - parseFloat(data.discount);
            
            const invoice = await api.Receivables.Invoices.getInvoiceById(response.data.id)

            mixpanel.track("Invoice Creation Successful", {
              "Invoice Number": invoice?.data?.invoiceNumber,
              "Date Created": invoice?.data?.invoiceDate,
              "Date Settled": invoice?.data?.overdueDate, // This value is not provided in the data object
              "No. of items": totalItems,
              "Total Price": totalPrice,
              "Discount": data?.discount,
              "Tax": totalTax,
              "Payable Amount": payableAmount,
              "Status": invoice?.data?.invoiceStatus, // This value is not provided in the data object
              "Converted from Estimate": "false", // This value is not provided in the data object
            });
          } catch (error) {
            Bugsnag.notify("mixpanel error", error);
          }

        } else {
          Message({
            type: "success",
            content: language.INVOICE_CREATE_SUCCESS,
          });

          try {
            // Extracting values from data
            const totalItems = data.salesItems.length;
            const totalPrice = data.salesItems.reduce((acc, item) => acc + item.subTotal, 0);
            const totalTax = data.salesItems.reduce((acc, item) => acc + item.taxAmount, 0);
            const payableAmount = totalPrice + totalTax - parseFloat(data.discount);
            
            const invoice = await api.Receivables.Invoices.getInvoiceById(response.data.id)

            mixpanel.track("Invoice Creation Successful", {
              "Invoice Number": invoice?.data?.invoiceNumber,
              "Date Created": invoice?.data?.invoiceDate,
              "Date Settled": invoice?.data?.overdueDate, // This value is not provided in the data object
              "No. of items": totalItems,
              "Total Price": totalPrice,
              "Discount": data?.discount,
              "Tax": totalTax,
              "Payable Amount": payableAmount,
              "Status": invoice?.data?.invoiceStatus, // This value is not provided in the data object
              "Converted from Estimate": "false", // This value is not provided in the data object
            });
          } catch (error) {
            Bugsnag.notify("mixpanel error", error);
          }

          queryClient.invalidateQueries("getInvoices");
          form.resetFields();
          onCancel();
        }
      },      
      onError: (error) => {
        try {
          mixpanel.track("Invoice Creation Failed", {
            "Invoice Number": data?.reference,
            "Failure Message": generateErrorMessage(error),
        });
        } catch (error) {
          Bugsnag.notify("mixpanel error", error);
        }
      },
      onSettled: () => {
        setIsSendInvoice(false);
      },
    });
  };

  return (
    //Cannot use Modal from common components due to the error of react portal
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        setSummary({ subTotal: 0, tax: 0 });
        setDiscount(0);
        form.resetFields();
        onCancel();
      }}
      onCancel={() => {
        setSummary({ subTotal: 0, tax: 0 });
        setDiscount(0);
        form.resetFields();
        onCancel();
      }}
      title="Create Invoice "
      className={classNames.modal}
      bodyStyle={{
        height: "70vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={872}
      footer={
        <>
          <CTAButton
            htmlType="submit"
            type="primary"
            key="submit"
            onClick={() => {
              setIsSendInvoice(true);
              form.submit();
            }}
            style={{
              height: 44,
              width: 230,
            }}
            disabled={!isSendInvoice && createInvoiceMn.isLoading}
            loading={
              isSendInvoice &&
              (updateInvoiceMutation.isLoading ||
                createInvoiceMn.isLoading ||
                shareInvoiceMn.isLoading)
            }
          >
            Send Invoice
          </CTAButton>
          <CTAButton
            htmlType="submit"
            type="primary"
            key="submit"
            disabled={isSendInvoice && createInvoiceMn.isLoading}
            onClick={() => form.submit()}
            style={{
              height: 44,
              width: 230,
            }}
            loading={!isSendInvoice && createInvoiceMn.isLoading}
          >
            Save as Draft
          </CTAButton>
        </>
      }
      centered
    >
      <CreateNewInoviceForm
        form={form}
        onFinish={onFinish}
        customers={customerQuery?.data}
        productData={productData}
        invoiceTemplateSettings={invoiceTemplateSettings}
        taxRates={taxQuery?.data}
        summary={summary}
        setSummary={setSummary}
        discount={discount}
        setDiscount={setDiscount}
      />
    </Modal>
  );
}
