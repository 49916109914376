import endpoints from "../../../endpoints";

export default async function userData(email){
    let env = process.env.REACT_APP_ENV?.trim();
    
    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
        email_id: email,
    }).toString();

    const url = endpoints.SERVICES.AUTH.USER_DATA[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${url}?${queryParams}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
