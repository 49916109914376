import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Button, Menu, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import { ErrorFallback } from "../../common";
import { ROUTES } from "../../constants";

import classNames from "./onboarding.module.scss";

const { Item } = Menu;

export default function Focused() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();
    navigate(ROUTES.PUBLIC.LOGIN);
  };

  return (
    <div className={classNames.container}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className={classNames.logoutContainer}>
          <Menu mode="horizontal" style={{background: "#181818", borderRadius: '8px'}}>
            <Item key="logout" onClick={handleLogout}>
              <Tooltip title="Logout">
                <Button
                  type="text"
                  icon={<LogoutOutlined style={{fill: 'white'}}/>}
                  className={classNames.logoutButton}
                />
              </Tooltip>
            </Item>
          </Menu>
        </div>
        <Outlet />
      </ErrorBoundary>
    </div>
  );
}
