import React, { useEffect, useState } from 'react';
import { 
  // Button, 
  Spin, 
  Table,
  Tooltip,
  // DatePicker 
} from 'antd';
import { Line, Bar } from 'react-chartjs-2';
import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from './Insights.module.scss';
import { useAccount, useFilter } from '../../../store/StoreProvider';
// import Rise from '../icons/Rise';
// import Fall from '../icons/Fall';
import { useMutation } from 'react-query';
import api from '../../API';
import { Message } from '@chatscope/chat-ui-kit-react';
import dayjs from 'dayjs';
import BeigeLegend from '../icons/BeigeLegend';
import GrayLegend from '../icons/GrayLegend';
// import { CTAButton } from '../../common';

// const { RangePicker } = DatePicker; // Destructure RangePicker from DatePicker

const ProfitAndLoss = () => {
  const { 
    // userData, 
    selectedBusiness 
  } = useAccount();
  const {
    insightFilter,
    // setInsightFilter,
    dateRange,
    setDateRange,
    // showCustomDatePicker,
    // setShowCustomDatePicker,
    applyClicked,
    // setApplyClicked,
    // inputDateRange,
    // setInputDataRange
  } = useFilter();
  const [loading, setLoading] = useState(true);
  // const [selectedFilter, setSelectedFilter] = useState('month');
  const [data, setData] = useState(null);
  // const [dateRange, setDateRange] = useState(null); // State variable to hold custom date range
  // const [showCustomDatePicker, setShowCustomDatePicker] = useState(false); // State variable to control visibility of custom date picker
  const [
    // dataRangeAvgMode
    ,setDateRangeAvgMode] = useState("MoM");
  // const [applyClicked, setApplyClicked] = useState(0);

  // Helper function to check if data is available
  const isDataAvailable = (data) => {
    return data !== null && data !== undefined && Object.keys(data).length > 0;
  };

  const getProfitLoss = useMutation(data => api.Services.Dashboard.BusinessOverview.v2.getProfitLossV2(data), {
    onSuccess: (responseData) => {
      if (!responseData.status) {
        Message({ type: "error", content: responseData.errors[0]?.message });
        return;
      }

      setData(responseData.data);
      setLoading(false);
    },
    onError: (error) => {
      Message({ type: "error", content: error.message });
      setLoading(false);
    },
  });

  useEffect(() => {
    if (selectedBusiness.business_id) {
      let filterValue = {};
      if (insightFilter === 'week') {
        setDateRangeAvgMode('WoW')
      } else if (insightFilter === "year") {
        setDateRangeAvgMode('YoY')
      } else {
        setDateRangeAvgMode('MoM')
      }
      if (insightFilter === 'custom' && dateRange && dateRange.length === 2) {
        filterValue = {
          filter_key: "custom",
          filter_value: {
            start_date: dateRange[0].format("YYYY-MM"),
            end_date: dateRange[1].format("YYYY-MM")
          }
        };
      } else {
        if (insightFilter === 'custom') {
          // filterValue = {
          //   filter_key: "month",
          //   filter_value: {}
          // };
        } else {
          setDateRange(null);
          filterValue = {
            filter_key: insightFilter,
            filter_value: {}
          };
        }
      }
  
      // Fetch data using the selected filter value

      if (Object.keys(filterValue).length > 0) {
        getProfitLoss.mutateAsync({
          business_id: selectedBusiness.business_id,
          body: filterValue,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness, dateRange, insightFilter, applyClicked]);

  const getWeekNumber = (dateStr) => {
    const date = new Date(dateStr);
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  };

  const convertLabel = (label) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (/^\d{4}-\d{2}$/.test(label)) {
      const [year, month] = label.split("-");
      return `${monthNames[parseInt(month) - 1]}'${year.slice(2)}`;
    }

    if (/^\d{4}-\d{2}-\d{2}$/.test(label)) {
      const weekNumber = getWeekNumber(label);
      return `W${weekNumber}`;
    }

    return label; // Return the label as is if it doesn't match any format
  };

  const chartDataRevenue = {
    labels: Object.keys(data?.revenue_chart || {}).map(convertLabel),
    datasets: [
      {
        label: 'Revenue',
        data: Object.values(data?.revenue_chart || {}),
        // backgroundColor: '#E1BB80',
        backgroundColor: function(context) {
          const chart = context.chart;
          const {ctx} = chart;

          var gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, '#E1BB80');   
          gradient.addColorStop(1, '#BFB1A1');

          return gradient
        },
        // borderColor: customColors,
        borderRadius: 5,
        borderWidth: 1,
      },
    ],
  };

  const chartDataProfitMargin = {
    labels: Object.keys(data?.profit_and_loss_chart || {}).map(convertLabel),
    datasets: [
      {
        label: 'Profit / Loss',
        data: Object.values(data?.profit_and_loss_chart || {}),
        // backgroundColor: customColors,
        borderColor: '#E1BB80',
        borderWidth: 2,
        lineTension: 0.3,
      },
    ],
  };

  const stackedBarChartData = {
    labels: Object.keys(data?.total_expenses_chart || {}).map(convertLabel),
    datasets: [
      {
        label: 'Operating Expenses',
        data: Object.values(data?.total_expenses_chart || {}).map((values) => values.operating_expenses),
        backgroundColor: "#E1BB80", // Set custom color for operating expenses
        borderRadius: 5,
      },
      {
        label: 'Cost of Sales',
        data: Object.values(data?.total_expenses_chart || {}).map((values) => values.cost_of_sales),
        backgroundColor: "#BFB1A1D6", // Set custom color for cost of sales
        borderRadius: 5,
      },
    ],
  };

  const stackedBarChartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
          },
        },
      },
      y: {
        stacked: true,
        suggestedMin: 0,
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 16,
          },
          autoSkip: false,
          maxTicksLimit: 8,
          callback: function (value, index, values) {
            return `$${value.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`;
          },
          stepSize: 3000
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  }

  const footer = (tooltipItems) => {
    let sum = 0;
    tooltipItems.forEach(function(tooltipItem) {
      sum = tooltipItem?.parsed?._stacks?.y?._visualValues[0] + tooltipItem?.parsed?._stacks?.y?._visualValues[1];
    });

    return 'Total Expense: ' + sum?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const totalExpenseOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
          },
        },
      },
      y: {
        stacked: true,
        suggestedMin: 0,
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 15,
          },
          autoSkip: false,
          maxTicksLimit: 8,
          callback: function (value, index, values) {
            return `$${value.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`;
          },
          stepSize: 2000
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          footer: footer,
        }
      },
    },
  }

  // Define columns for the table
  const columns = [
    {
      title: '',
      dataIndex: 'metric',
      key: 'metric',
    },
    {
      title: () => (
        <div style={{ display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px'
        }}>
          <span>YTD ($)</span>
          <Tooltip title={<span>Profit/Loss (YTD $): Year-to-date profit/loss amount</span>}>
            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem'}} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: () => (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px'
        }}>
          <span>YTD (%)</span>
          <Tooltip title={<span>Profit/Loss (YTD %): Year-to-date profit/loss amount as a percentage of total revenue</span>}>
            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem'}} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'vsPrev',
      key: 'vsPrev',
    },
  ];

  // const convertToAbsolutePercentage = (percentageString) => {
  //   if (percentageString === null || percentageString === undefined) {
  //     return '0%';
  //   }
  //   return percentageString.replace('-', '');
  // };

  // const expenseCategories = data?.expense_categories
  //   ? data.expense_categories.map((item, index) => ({
  //       key: `${index + 1}`,
  //       metric: item.category,
  //       value: `$${item.value.toLocaleString()}`,
  //       vsPrev: <><span style={{color: parseFloat(item.percentage) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(item.percentage)}</span></>,
  //     }))
  //   : [];

  const expenseCategories = [
    {
      key: '1',
      metric: <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {/* <GrayLegend /> */}
          <span>Gross Profit/Loss</span>
          <Tooltip title="Gross Profit/Loss: Year-to-date revenue minus the total cost of sales.">
            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem'}} />
          </Tooltip>
        </div>
      </>,
      value: `$ ${data?.margins?.gross_profit?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`,
      vsPrev: <><span style={{color: '#2E9A53', fontWeight: 'bold'}}>{data?.margins?.gross_profit_percentage?.toFixed(2)} %</span></>,
    },
    {
      key: '2',
      metric: <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {/* <GrayLegend /> */}
          <span>Operating/Net Profit/Loss</span>
          <Tooltip title="Operating/Net Profit/Loss (YTD): Year-to-date revenue minus the total expenses.">
            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem'}} />
          </Tooltip>
        </div>
      </>,
      value: `$ ${data?.margins?.net_profit?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`,
      vsPrev: <><span style={{color: '#99161680', fontWeight: 'bold'}}>{data?.margins?.net_profit_percentage?.toFixed(2)} %</span></>,
    },
  ];

    return (
      <div className={classNames.container}>
        {/* <section className={classNames.greetingsSection}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 style={{ color: '#82898F', margin: 0 }}>Hello, {userData?.first_name}</h1>
          </div>
        </section> */}
  
        {loading ? (
          <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }} />
        ) : (
          <>
            <div style={{ width: '100%'}}>
              <section className={classNames.innerContent} style={{ background: 'white', margin: '0.5rem', gap: '0' }}>
                {/* <div style={{ width: '100%', display: 'flex', gap: '30px', justifyContent: 'flex-end', padding: '1vw 1vw 0 1vw' }}>
                  {['Week', 'Month', 'Year', 'Custom'].map((filter, index) => (
                    <Button 
                      key={index}
                      type='link' 
                      style={{ 
                        backgroundColor: insightFilter === filter.toLowerCase() ? '#616161' : 'white', 
                        color: insightFilter === filter.toLowerCase() ? 'white' : 'black',
                        borderRadius: '20px',
                        transition: 'background-color 0.3s, color 0.3s', // Add transition for smooth hover effect
                      }} 
                      onClick={() => {
                        setInsightFilter(filter.toLowerCase());
                        setShowCustomDatePicker(filter.toLowerCase() === 'custom');
                      }}
                    >
                      {filter}
                    </Button>
                  ))}

                  {showCustomDatePicker && renderCustomDateRangePicker()}
                </div> */}
                
                <div className={classNames.card} style={{
                    border: 'none',
                    boxShadow: 'none',
                    padding: '0 20px',
                    // filter: Object.entries(cashflowData).length === 0 ? "blur(5px)" : null
                }}>
                  <div className={classNames.gridCashflow}>
                    {/* First Row */}
                    <div className={classNames.card} style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'relative'
                    }}>
                      {getProfitLoss.isLoading && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                          <Spin />
                        </div>
                      )}
                      {!data?.average_revenue && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'end', paddingBottom: '15px'}}>
                          No data available yet! <br />
                          {/* We're currently reconciling your books. <br />
                          The data will be displayed here soon. */}
                        </div>
                      )}
                      <div className={classNames.cardText}>
                        {/* <h3 style={{fontSize: '1rem'}}>30 days Cash In <span style={{color: 'gray', fontSize: '0.8rem'}}>(rolling)</span></h3> */}
                        <h3 style={{fontSize: '1rem'}}>
                          Average Revenue
                          {/* <Tooltip title="Average Cash In (Per Month): Average cash received during a certain period.">
                            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                          </Tooltip> */}
                        </h3>
                        <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !data?.average_revenue ? 0 : 1}}>As of bookkeeping on {dayjs(data?.revenue_last_updated).format('DD MMMM, YYYY')}</p>
                      </div>
                      <div className={classNames.cardText} style={{opacity: !data?.average_revenue ? 0 : 1, alignItems: 'flex-end'}}>
                        <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{data && data?.average_revenue?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</p>
                        {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData && cashflowData['30_day_cash_in_improvement']) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData['30_day_cash_in_improvement']) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{cashflowData && convertToAbsolutePercentage(cashflowData['30_day_cash_in_improvement'])}</span> vs last month</p> */}
                        <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p>
                      </div>
                    </div>
                    
                    <div className={classNames.card} style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'relative'
                    }}>
                      {getProfitLoss.isLoading && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                          <Spin />
                        </div>
                      )}
                      {!data?.average_burn && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'end', paddingBottom: '15px'}}>
                          No data available yet! <br />
                          {/* We're currently reconciling your books. <br />
                          The data will be displayed here soon. */}
                        </div>
                      )}
                      <div className={classNames.cardText}>
                        {/* <h3 style={{fontSize: '1rem'}}>30 days Cash Burn <span style={{color: 'gray', fontSize: '0.8rem'}}>(rolling)</span></h3> */}
                        <h3 style={{fontSize: '1rem'}}>
                          Average Expenses
                          {/* <Tooltip title="Average Cash Burn: Average cash spent during a certain period.">
                            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                          </Tooltip> */}
                        </h3>
                        <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !data?.average_burn ? 0 : 1}}>As of bookkeeping on {dayjs(data?.revenue_last_updated).format('DD MMMM, YYYY')}</p>
                      </div>
                      <div className={classNames.cardText} style={{opacity: !data?.average_burn ? 0 : 1, alignItems: 'flex-end'}}>
                        <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{data && data?.average_burn?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</p>
                        {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData && cashflowData['30_day_cash_burn_improvement']) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData['30_day_cash_burn_improvement']) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{cashflowData && convertToAbsolutePercentage(cashflowData['30_day_cash_burn_improvement'])}</span> vs last month</p> */}
                        <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p>
                      </div>
                    </div>
                    
                    <div className={classNames.card} style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'relative'
                    }}>
                      {getProfitLoss.isLoading && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                          <Spin />
                        </div>
                      )}
                      {!data?.average_profit_loss && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'end', paddingBottom: '15px'}}>
                          No data available yet! <br />
                          {/* We're currently reconciling your books. <br />
                          The data will be displayed here soon. */}
                        </div>
                      )}
                      <div className={classNames.cardText}>
                        {/* <h3 style={{fontSize: '1rem'}}>Cash on Hand</h3> */}
                        <h3 style={{fontSize: '1rem'}}>
                          Average Profit/Loss
                          {/* <Tooltip title="Average Cash Balance: Average cash balance during a certain period.">
                            <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                          </Tooltip> */}
                        </h3>
                        <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !data?.average_profit_loss ? 0 : 1}}>As of bookkeeping on {dayjs(data?.revenue_last_updated).format('DD MMMM, YYYY')}</p>
                      </div>
                      <div className={classNames.cardText} style={{opacity: !data?.average_profit_loss ? 0 : 1, alignItems: 'flex-end'}}>
                        <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{data && data?.average_profit_loss?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</p>
                        {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData?.cash_on_hand_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData?.cash_on_hand_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(cashflowData?.cash_on_hand_improvement)}</span> vs last month</p> */}
                        <p style={{color: '#313D44', fontSize: '0.8rem'}}>Per Month</p>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className={classNames.card} style={{ 
                  border: 'none', boxShadow: 'none', 
                  // filter: Object.entries(data).length === 0 ? "blur(5px)" : null, 
                  padding: '0 20px',
                  margin: '0.1rem 0.5rem'
                }}>
                  <div className={classNames.gridPl}>
                    
                    {/* Chart First - Revenue */}
                    <div className={classNames.card} style={{ position: 'relative'}}>
                      {getProfitLoss.isLoading && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                          <Spin />
                        </div>
                      )}
                      {!isDataAvailable(data?.revenue_chart) && (
                        <div className={classNames.noDataOverlay}>
                          No data available yet! <br />
                          We're currently reconciling your books. <br />
                          The data will be displayed here soon.
                        </div>
                      )}
                      <div style={{width: '100%', height: '100%'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div className={classNames.cardText}>
                            <h3 style={{fontSize: '1rem'}}>Revenue</h3>
                            <p style={{ color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(data?.revenue_chart) ? 0 : 1 }}>As of bookkeeping on {data?.revenue_last_updated ? dayjs(data.revenue_last_updated.split(' ')[0]).format('DD MMMM, YYYY') : ''}</p>
                          </div>
                          <div className={classNames.cardText} style={{alignItems: "flex-end", opacity: !isDataAvailable(data?.revenue_chart) ? 0 : 1}}>
                            <p style={{ fontSize: '1.8rem' }}><sup style={{top: '-0.4em'}}>$</sup>{data?.revenue?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}</p>
                            {/* <p style={{ color: '#313D44' }}>{parseFloat(data?.revenue_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(data?.revenue_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(data?.revenue_improvement)}</span> {dataRangeAvgMode}</p> */}
                            <h3 style={{color: '#313D44', fontSize: '0.8rem'}}>
                              YTD
                              <Tooltip title="Revenue YTD: Year-to-date total revenue generated.">
                                <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                              </Tooltip>
                            </h3>
                          </div>
                        </div>
                        <div style={{ margin: '3rem 0 1rem 0', opacity: !isDataAvailable(data?.revenue_chart) ? 0 : 1 }} className={classNames.chartContainer}>
                          <Bar data={chartDataRevenue} options={stackedBarChartOptions} />
                        </div>
                      </div>
                    </div>
  
                    {/* Second Chart - Profit / Loss */}
                    <div className={classNames.card} style={{ position: 'relative'}}>
                      {getProfitLoss.isLoading && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                          <Spin />
                        </div>
                      )}
                      {!isDataAvailable(data?.profit_and_loss_chart) && (
                        <div className={classNames.noDataOverlay}>
                          No data available yet! <br />
                          We're currently reconciling your books. <br />
                          The data will be displayed here soon.
                        </div>
                      )}
                      <div style={{width: '100%', height: '100%'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div className={classNames.cardText}>
                            <h3 style={{fontSize: '1rem'}}>Profit / Loss</h3>
                            <p style={{ color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(data?.profit_and_loss_chart) ? 0 : 1 }}>As of bookkeeping on {data?.profit_and_loss_last_updated ? dayjs(data.profit_and_loss_last_updated.split(' ')[0]).format('DD MMMM, YYYY') : ''}</p>
                          </div>
                          <div className={classNames.cardText} style={{alignItems: "flex-end", opacity: !isDataAvailable(data?.profit_and_loss_chart) ? 0 : 1}}>
                            <p style={{ fontSize: '1.8rem' }}><sup style={{top: '-0.4em'}}>$</sup>{data?.profit_and_loss?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}</p>
                            {/* <p style={{ color: '#313D44' }}>{parseFloat(data?.profit_and_loss_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(data?.profit_and_loss_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(data?.profit_and_loss_improvement)}</span> {dataRangeAvgMode}</p> */}
                            <h3 style={{color: '#313D44', fontSize: '0.8rem'}}>
                              YTD
                              <Tooltip title="Profit/Loss YTD: Year-to-date total profit or loss.">
                                <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                              </Tooltip>
                            </h3>
                          </div>
                        </div>
                        <div style={{ margin: '3rem 0 1rem 0', opacity: !isDataAvailable(data?.profit_and_loss_chart) ? 0 : 1 }} className={classNames.chartContainer}>
                          <Line data={chartDataProfitMargin} options={{
                              maintainAspectRatio: false, // Set to false to allow custom height
                              responsive: true, // Set to true for responsiveness
                              height: 200, // Set the desired height
                              plugins: {
                                legend: {
                                  display: false,
                                },
                                tooltip: {
                                  enabled: true,
                                },
                              },
                              scales: {
                                x: {
                                  grid: {
                                    display: false,
                                  },
                                  ticks: {
                                    font: {
                                      size: 16,
                                    },
                                  },
                                },
                                y: {
                                  suggestedMin: 0,
                                  grid: {
                                    display: true,
                                  },
                                  ticks: {
                                    font: {
                                      size: 16,
                                    },
                                    autoSkip: false,
                                    maxTicksLimit: 8,
                                    callback: function (value, index, values) {
                                      return `$${value.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })}`;
                                    },
                                    // stepSize: 1500
                                  },
                                }
                              },
                            }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className={classNames.card} style={{ 
                  border: 'none', boxShadow: 'none', 
                  // filter: Object.entries(data).length === 0 ? "blur(5px)" : null, 
                  padding: '0 20px',
                  margin: '0.1rem 0.5rem'
                }}>
                  <div className={classNames.gridPl}>
                    
                    {/* Third Chart - Total Expenses */}
                    <div className={classNames.card} style={{ height: '100%', position: 'relative' }}>
                      {getProfitLoss.isLoading && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                          <Spin />
                        </div>
                      )}
                      {!isDataAvailable(data?.total_expenses_chart) && (
                        <div className={classNames.noDataOverlay}>
                          No data available yet! <br />
                          We're currently reconciling your books. <br />
                          The data will be displayed here soon.
                        </div>
                      )}
                      <div style={{width: '100%', height: '100%'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div className={classNames.cardText}>
                            <h3 style={{fontSize: '1rem'}}>Total Expenses</h3>
                            <p style={{ color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(data?.total_expenses_chart) ? 0 : 1 }}>As of bookkeeping on {data?.total_expenses_last_updated ? dayjs(data.total_expenses_last_updated.split(' ')[0]).format('DD MMMM, YYYY') : ''}</p>
                          </div>
                          <div className={classNames.cardText} style={{alignItems: "flex-end", opacity: !isDataAvailable(data?.total_expenses_chart) ? 0 : 1}}>
                            <p style={{ fontSize: '1.8rem' }}><sup style={{top: '-0.4em'}}>$</sup>{data?.total_expenses?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}</p>
                            {/* <p style={{ color: '#313D44' }}>{parseFloat(data?.total_expenses_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(data?.total_expenses_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(data?.total_expenses_improvement)}</span> {dataRangeAvgMode}</p> */}
                            <h3 style={{color: '#313D44', fontSize: '0.8rem'}}>
                              YTD
                              <Tooltip title="Expense YTD: Year-to-date total expenses.">
                                <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                              </Tooltip>
                            </h3>
                          </div>
                        </div>
                        <div style={{ 
                          margin: '3rem 0 1rem 0', 
                          display: 'flex', 
                          flexDirection: 'column', 
                          opacity: !isDataAvailable(data?.total_expenses_chart) ? 0 : 1 
                        }} className={classNames.chartContainer}>
                          <Bar data={stackedBarChartData} options={totalExpenseOptions} />
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '60px', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '0.9rem' }}>
                              <BeigeLegend />
                              <span style={{fontWeight: '500'}}>Operating Expenses</span>
                              <Tooltip title="Operating Expenses: The total costs required to run the business, excluding the cost of sales.">
                                <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '5px'}} />
                              </Tooltip>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '0.9rem' }}>
                              <GrayLegend />
                              <span style={{fontWeight: '500'}}>Cost of Sales</span>
                              <Tooltip title="Cost of Sales: The direct costs associated with producing the goods or services sold.">
                                <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '5px'}} />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
  
                    <div className={classNames.card} style={{ height: '100%', width: '100%', position: 'relative' }}>
                      {getProfitLoss.isLoading && (
                        <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                          <Spin />
                        </div>
                      )}
                      {!isDataAvailable(data?.expense_categories) && (
                        <div className={classNames.noDataOverlay}>
                          No data available yet! <br />
                          We're currently reconciling your books. <br />
                          The data will be displayed here soon.
                        </div>
                      )}
                      <div style={{width: '100%', height: '100%'}}>
                        <h3 style={{fontSize: '1rem'}}>Margins</h3>
                        <p style={{ color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(data?.expense_categories) ? 0 : 1 }}>As of bookkeeping on {data?.expense_categories_last_updated ? dayjs(data.expense_categories_last_updated.split(' ')[0]).format('DD MMMM, YYYY') : ''}</p>
                        <div style={{ margin: '3rem 0 0 0', opacity: !isDataAvailable(data?.expense_categories) ? 0 : 1 }} className={classNames.tableContainer}>
                          <Table dataSource={expenseCategories} columns={columns} pagination={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    );
  };
  
  export default ProfitAndLoss;
  