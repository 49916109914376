import endpoints from "../../../endpoints";

export default async function uploadFileChat(data){
    let env = process.env.REACT_APP_ENV?.trim();
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const formData = new FormData();
    formData.append('file', data.file);

    console.log(data.file);

    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
        business_id: data.business_id,
        otterz_id: data.otterz_id,
    }).toString();
    
    const baseURL = endpoints.SERVICES.DASHBOARD.DOCUMENTS_VAULT.FILE_CHAT[env];
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}?${queryParams}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: formData
        })
    ).json();
}
