import endpoints from "../../../endpoints";

export default async function getProfitLossV2(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const baseURL = endpoints.SERVICES.ONBOARDING.PLAID[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    const route = data.account_id ? `${baseURL}/v2/get/transactions/${data.business_id}/${data.account_id}` : `${baseURL}/v2/get/transactions/${data.business_id}`;

    return await(
        await fetch(route, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify(data.body)
        })
    ).json();
}
