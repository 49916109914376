import React, { useState, useRef, useEffect } from 'react';
import { Typography, Card } from 'antd';
// import { CloseCircleOutlined } from '@ant-design/icons';
import classNames from './OwnersInvite.module.scss';
import { CTAButton } from '../../common';
import gsap from 'gsap';
// import { useNavigate } from 'react-router-dom';
// import { useMutation } from 'react-query';
// import api from '../../API';
// import { ROUTES } from '../../constants';

const { Title, Text } = Typography;

const OwnersInvite = () => {
  // const navigate = useNavigate();
  const [owners, ] = useState([{ name: '', email: '' }]);
  const ownerRefs = useRef([]);

  useEffect(() => {
    // Animate new field addition
    if (ownerRefs.current.length > 0) {
      const lastElement = ownerRefs.current[ownerRefs.current.length - 1];
      gsap.fromTo(lastElement, { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 0.3 });
    }
  }, [owners.length]);

  // const handleAddOwner = () => {
  //   setOwners([...owners, { name: '', email: '' }]);
  // };

  // const handleRemoveOwner = (index) => {
  //   const element = ownerRefs.current[index];

  //   // Ensure the element exists before attempting to animate it
  //   if (element) {
  //     gsap.to(element, { opacity: 0, y: -20, duration: 0.3 }).then(() => {
  //       const newOwners = owners.filter((_, i) => i !== index);
  //       setOwners(newOwners);
  //     });
  //   } else {
  //     const newOwners = owners.filter((_, i) => i !== index);
  //     setOwners(newOwners);
  //   }
  // };

  // const handleChange = (value, index, field) => {
  //   const newOwners = [...owners];
  //   newOwners[index][field] = value;
  //   setOwners(newOwners);
  // };

  // /* Mutation definition for API calls */
  // // Owner Invite mutation
  // const ownerInvite = useMutation(data => api.Services.Onboarding.inviteOwner(data), {
  //   onSuccess: (data) => {
  //     // Handle errors from the API call
  //     if (!data.status) {
  //       Message({ type: "error", content: data.errors[0].message });
  //     }
      
  //     // Handle successful registration
  //     if (data && data.data) {
  //       console.log("Invite Sent to Owner", data);
  //       Message({ type: "success", content: "Invite Sent to Owner !" });
        
  //       // Navigate or update state as needed
  //       // navigate(ROUTES.VERIFICATION.EMAIL_VERIFY);
  //     }
  //   },
  //   onError: (error) => {
  //     // Handle any errors from the API call
  //     console.error("Signup error: ", error);
  //     Message({ type: "error", content: error.message });
  //   },
  // });  

  // const handleSubmit = () => {
  //   console.log('Owners: ', owners);
  //   // Extract email addresses from the owners array
  //   const emailAddresses = owners.map(owner => owner.email);

  //   console.log(emailAddresses);
  //   // Handle the submit action
  //   ownerInvite.mutat333333333333e({
  //       email_id: owners[0].email
  //   });
  // };

    const handleSubmit = () => {
    console.log('Owners: ', owners);
    const emailAddresses = owners.map(owner => owner.email);

    console.log(emailAddresses);

    // Construct email body
    const emailBody = encodeURIComponent(`Hello,

I came across Otterz, and I believe it could be a game-changer for our financial management.
Could you spare a moment to sign up? Here's the link: <link>

Thank you,
<Name>`);

    // Construct mailto link
    const mailtoLink = `mailto:${emailAddresses.join(',')}?subject=Invitation to Otterz&body=${emailBody}`;

    // Open email client
    window.location.href = mailtoLink;
  };

  return (
    <div className={classNames.setupOtterzComponentContainer}>
      <div className={classNames.setupOtterzComponent}>
        {/* <svg onClick={() => {navigate(-1)}} style={{position: "relative", left: '-70px', top: "45px"}} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_8949_97551)">
          <path d="M20.4974 3.41406C29.9274 3.41406 37.5807 11.0674 37.5807 20.4974C37.5807 29.9274 29.9274 37.5807 20.4974 37.5807C11.0674 37.5807 3.41406 29.9274 3.41406 20.4974C3.41406 11.0674 11.0674 3.41406 20.4974 3.41406ZM20.4974 34.1641C28.0482 34.1641 34.1641 28.0482 34.1641 20.4974C34.1641 12.9466 28.0482 6.83073 20.4974 6.83073C12.9466 6.83073 6.83073 12.9466 6.83073 20.4974C6.83073 28.0482 12.9466 34.1641 20.4974 34.1641ZM20.4974 18.7891H27.3307V22.2057H20.4974V27.3307L13.6641 20.4974L20.4974 13.6641V18.7891Z" fill="black"/>
          </g>
          <defs>
          <clipPath id="clip0_8949_97551">
          <rect width="41" height="41" fill="white"/>
          </clipPath>
          </defs>
        </svg> */}
        <Title level={2}>
          Invite the business owner
        </Title>

        {/* Card Component with Email Format Text */}
        <Card className={classNames.emailCard}>
          <Text style={{color: '#82898F'}}>
            Hello,
            <br/><br/>
            I came across Otterz, and I believe it could be a game-changer for our financial management.
            Could you spare a moment to sign up? Here's the link: &lt;link&gt;
            <br/><br/>
            Thank you,
            <br/>
            &lt;Name&gt;
          </Text>
        </Card>

        {/* <Text className={classNames.subtitle}>
          In the next steps, we’ll require the business bank and QuickBooks accounts to be connected.
        </Text> */}

        {/* <div style={{ margin: '30px 0 0' }}>
          {owners.map((owner, index) => (
            <div ref={(el) => (ownerRefs.current[index] = el)} key={index} className={classNames.ownerRow}>
              <Row gutter={16}>
                <Col span={11}>
                  <Input
                    placeholder="Name"
                    value={owner.name}
                    onChange={(e) => handleChange(e.target.value, index, 'name')}
                  />
                </Col>
                <Col span={11}>
                  <Input
                    placeholder="Email ID"
                    value={owner.email}
                    onChange={(e) => handleChange(e.target.value, index, 'email')}
                  />
                </Col>
                <Col span={2}>
                  {owners.length > 1 && (
                    <CloseCircleOutlined
                      className={classNames.removeIcon}
                      onClick={() => handleRemoveOwner(index)}
                    />
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </div> */}

        {/* <Button type="link" onClick={handleAddOwner} className={classNames.addOwnerButton}>
          + Add Owner
        </Button> */}

        <CTAButton onClick={handleSubmit} className={classNames.continueButton}>
          Draft Email
        </CTAButton>
      </div>
    </div>
  );
};

export default OwnersInvite;
