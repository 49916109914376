import React, { useState } from 'react';
import { Checkbox, Typography } from 'antd';
import classNames from './UsePreference.module.scss';
import { CTAButton, Message } from '../../common';
import Heading from '../Heading';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants';
import { useMutation } from 'react-query';
import api from '../../API';

const { Title, Text } = Typography;

const UsePreference = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const secureLocalStore = window.localStorage || window.sessionStorage;

  const navigate = useNavigate();

  /* Mutation definition for API calls */
  // Create User mutation - Here we are updating the user preferences
  const createUser = useMutation(data => api.Services.Onboarding.createUser(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
        
        let nextRoute;
        if (isOwner === 'Y') {
          if (selectedOptions.includes('coPilot')) {
            nextRoute = "SETUP_COPILOT";
          } else if (selectedOptions.includes('acceptPayments')) {
            nextRoute = "SETUP_PAYMENTS";
          } else {
            Message({ type: "error", content: "Please select at least one preference" });
            return; // Exit early if no preference is selected
          }
        } else if (isOwner === 'N') {
          nextRoute = "INVITE_OWNERS";
        }
        updateOnboardingStatus.mutate({otterz_id: secureLocalStore.getItem('otterz_id'), status: nextRoute});
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // update status mutation
  const updateOnboardingStatus = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return; // Exit early if there's an error
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
        Message({ type: "success", content: "Preferences added successfully" });

        // Determine the next route based on the preferences
        let nextRoute;
        if (isOwner === 'Y') {
          const onboardingData = data.data.pop()
          if (onboardingData.status === "SETUP_COPILOT") {
            nextRoute = ROUTES.ONBOARDING.SETUP_COPILOT;
          } else if (onboardingData.status === "SETUP_PAYMENTS") {
            nextRoute = ROUTES.ONBOARDING.SETUP_PAYMENTS;
          } else {
            Message({ type: "error", content: "Please select at least one preference" });
            return; // Exit early if no preference is selected
          }
        } else if (isOwner === 'N') {
          nextRoute = ROUTES.ONBOARDING.INVITE_OWNERS;
        }

        // Navigate to the next route
        navigate(nextRoute);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // View user's owner status
  const viewUser = useMutation(data => api.Services.Onboarding.viewUserByID(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses added successful", data);
        
        // Navigate or update state as needed
        setIsOwner(data.data.pop().is_owner)
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  const onChange = (checkedValues) => {
    setSelectedOptions(checkedValues);
  };

  React.useEffect(() => {
    viewUser.mutate({ otterz_id: secureLocalStore.getItem('otterz_id') });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleOnFinish = (selectedOptions) => {
    console.log('Selected Options: ', selectedOptions);

    // Handle the submit action
    createUser.mutate({ 
      otterz_id: secureLocalStore.getItem('otterz_id'), 
      userData: {
        services: {
            bookeeping_insights: selectedOptions.includes('coPilot') ? "Y" : "N",
            accept_payments: selectedOptions.includes('acceptPayments') ? "Y" : "N"
        }
      } 
    });

    // if (selectedOptions.includes('coPilot')) {
    //   navigate(ROUTES.ONBOARDING.SETUP_COPILOT)
    // } else if (selectedOptions.includes('acceptPayments')) {
    //   // Handle Accept Payments selection
    // }
  };

  return (
    <div className={classNames.otterzUsageComponentContainer}>
        <div className={classNames.otterzUsageComponent}>
            <Heading
              style={{margin: "0"}}
              classNames={classNames}
              title="What will you use Otterz for?"
            />
            <Title level={2} className={classNames.title}></Title>
            <Text style={{color: "rgba(130, 137, 143)"}} className={classNames.subText}>You can change your preferences under settings</Text>
            <Checkbox.Group style={{ width: '100% !imprtant' }} onChange={onChange} className={classNames.checkboxGroup}>
              <Checkbox value="coPilot" className={classNames.checkbox}>Otterz Co-Pilot (Bookkeeping & Insights)</Checkbox>
              <Checkbox value="acceptPayments" className={classNames.checkbox}>Accept Payments</Checkbox>
            </Checkbox.Group>
            <CTAButton
                htmlType="submit"
                type="primary"
                onClick={()=>{handleOnFinish(selectedOptions)}}
                className={classNames.signupFormInputs}
                disabled={selectedOptions.length === 0 || createUser.isLoading || updateOnboardingStatus.isLoading } // Disable button if no options are selected
                loading={createUser.isLoading || updateOnboardingStatus.isLoading }
            >
                Continue
            </CTAButton>
        </div>
    </div>
  );
};

export default UsePreference;
