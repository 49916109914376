import React, { useState, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import mixpanel from "mixpanel-browser";
import { Button } from 'antd';

import SubscriptionPlanCard from './SubscriptionCard';
import classNames from './styles.module.scss';
import api from "../../API";
// import { AppContext } from "../../../context/AppState";
import { tempUrls } from "../../../apiEndpoints";
// import { Message } from "../../common";
// import Bugsnag from "@bugsnag/js";
import TaxCard from "./TaxCard";
import { useAccount, useBusiness } from "../../../store/StoreProvider";
import endpoints from "../../API/endpoints";

const env = process.env.REACT_APP_ENV?.trim();
const ProLink = tempUrls.PAYMENT_LINK_PRO[env];
const EliteLink = tempUrls.PAYMENT_LINK_ELITE[env];
const EnterpriseLink = tempUrls.PAYMENT_LINK_ENTERPRISE[env];
const ProLinkAnnual = tempUrls.PAYMENT_LINK_PRO_ANNUAL[env];
const EliteLinkAnnual = tempUrls.PAYMENT_LINK_ELITE_ANNUAL[env];
const EnterpriseLinkAnnual = tempUrls.PAYMENT_LINK_ENTERPRISE_ANNUAL[env];

const subscriptionPlans = {
    MONTHLY: {
        MONTHLY_STARTER: {
            planName: 'Starter',
            price: 399,
            description: 'per month',
            planInclude: 'Monthly Transaction Volume: < $10k',
            // subDescription: 'High Sales Volume',
            link: `${ProLink}`,
            paymentRate: "2.6% + 30c",
            // interchangeRate: "2.6% + 30c",
            corporateTaxFile: false,
            personalTaxFile: false,
            corporateTaxFileCost: 1000,
            personalTaxFileCost: 750,
            addOn: ' $1300',
            info: "Bookkeeping and tax filing for businesses with up to $30k in monthly expenses",
            features: [
                {
                    name: "Access to Otterz's all-in-one financial management platform",
                    isAvailable: true,
                },
                {
                    name: "Dedicated bookkeeping expert",
                    isAvailable: true,
                },
                {
                    name: "Clean, accurate books and up-to-date financials",
                    isAvailable: true,
                },
                {
                    name: "Profit & loss and balance sheet statements",
                    isAvailable: true,
                },
                {
                    name: "Instant, real-time financial insights",
                    isAvailable: true,
                },
                {
                    name: "Setting up your business' chart of accounts",
                    isAvailable: true,
                },
                {
                    name: "Federal and state tax filing for up to two states",
                    isAvailable: true,
                },
                {
                    name: "Up to five 1099 filings",
                    isAvailable: true,
                },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        MONTHLY_GROWTH: {
            planName: 'Growth',
            price: 599,
            description: 'per month',
            planInclude: 'Monthly Transaction Volume: $10k - $50k',
            // subDescription: 'Lowest Processing Fees',
            paymentRate: "1.90% + 30c",
            interchangeRate: "0.10% + 30c",
            corporateTaxFile: false,
            personalTaxFile: false,
            corporateTaxFileCost: 1000,
            personalTaxFileCost: 500,
            link: `${EliteLink}`,
            info: "Advanced bookkeeping and tax filing for businesses with up to $80k in monthly expenses.",
            addOn: ' $1000',
            tag: "Everything in the starter financial suite, plus:",
            features: [
                {
                    name: "Initial BOIR report filing",
                    isAvailable: true,
                },
                {
                    name: "Faster book closings",
                    isAvailable: true,
                },
                {
                    name: "Semi-annual tax consultations",
                    isAvailable: true,
                },
                {
                    name: "Profit & loss and balance sheet statements",
                    isAvailable: true,
                },
                {
                    name: "Business income tax filing for multiple states",
                    isAvailable: true,
                },
                {
                    name: "Swift payment processing with Clover or/and Cardpointe at competitive rates (approx. 2.30% +30¢).",
                    isAvailable: true,
                },
                {
                    name: "Multiple Revenue Systems",
                    isAvailable: false,
                },
                {
                    name: "Unlimited Chart of Accounts",
                    isAvailable: false,
                },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        MONTHLY_ENTERPRISE: {
            // save: "Free Tax Filing worth $2,000",
            planName: 'Enterprise',
            price: 999,
            description: 'per month',
            planInclude: 'Monthly Transaction Volume: > $50k',
            // subDescription: 'Lowest Processing Fees',
            paymentRate: "1.90% + 30c",
            interchangeRate: "0.10% + 30c",
            corporateTaxFile: false,
            personalTaxFile: false,
            corporateTaxFileCost: 1000,
            personalTaxFileCost: 500,
            link: `${EnterpriseLink}`,
            addOn: ' $1000',
            tag: "Everything in the growth financial suite, plus:",
            info: "Unparalleled bookkeeping and tax filing for multi-entity businesses with expenses over $80k in monthly expenses.",
            features: [
                {
                    name: "Unlimited chart of accounts for boundless financial flexibility",
                    isAvailable: true,
                },
                {
                    name: "Federal and state tax filing for multiple entities",
                    isAvailable: true,
                },
                {
                    name: "IRS tax extensions filing",
                    isAvailable: true,
                },
                {
                    name: "Sales and payroll tax filing",
                    isAvailable: true,
                },
                {
                    name: "Handling full process of R&D tax credit claim",
                    isAvailable: true,
                },
                {
                    name: "Versatile revenue systems to fit every size and need",
                    isAvailable: true,
                },
                {
                    name: "Streamlined support for multiple business entities",
                    isAvailable: true,
                },
                {
                    name: "Swift payment processing with Clover or/and Cardpointe at competitive rates (approx. 1.90% +30¢)",
                    isAvailable: true,
                },
            ],
            transactionVol: '*Covers high volume of transactions'
        },
    },
    ANNUALLY: {
        ANNUAL_STARTER: {
            // save: "Free Books Cleanup worth $3,500",
            planName: 'Starter',
            price: 399,
            description: 'per month billed annually',
            planInclude: 'Monthly Transaction Volume: < $10k',
            // subDescription: 'High Sales Volume',
            paymentRate: "2.6% + 30c",
            corporateTaxFile: false,
            personalTaxFile: true,
            corporateTaxFileCost: 1000,
            link: `${ProLinkAnnual}`,
            info: "Bookkeeping and tax filing for businesses with up to $30k in monthly expenses",
            addOn: ' $1000',
            features: [
                {
                    name: "Access to Otterz's all-in-one financial management platform",
                    isAvailable: true,
                },
                {
                    name: "Dedicated bookkeeping expert",
                    isAvailable: true,
                },
                {
                    name: "Clean, accurate books and up-to-date financials",
                    isAvailable: true,
                },
                {
                    name: "Profit & loss and balance sheet statements",
                    isAvailable: true,
                },
                {
                    name: "Instant, real-time financial insights",
                    isAvailable: true,
                },
                {
                    name: "Setting up your business' chart of accounts",
                    isAvailable: true,
                },
                {
                    name: "Federal and state tax filing for up to two states",
                    isAvailable: true,
                },
                {
                    name: "Up to five 1099 filings",
                    isAvailable: true,
                },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        ANNUAL_GROWTH: {
            save: "Free Tax Filing",
            planName: 'Growth',
            price: 599,
            description: 'per month billed annually',
            planInclude: 'Monthly Transaction Volume: $10k - $50k',
            paymentRate: "1.90% + 30c",
            interchangeRate: "0.10% + 30c",
            corporateTaxFile: true,
            personalTaxFile: true,
            // subDescription: 'Lowest Processing Fees',
            link: `${EliteLinkAnnual}`,
            tag: "Everything in the starter financial suite, plus:",
            info: "Advanced bookkeeping and tax filing for businesses with up to $80k in monthly expenses.",
            features: [
                // {
                //     name: "Business Checking Account",
                //     isAvailable: true,
                // },
                // {
                //     name: "Free ATM Withdrawals",
                //     isAvailable: true,
                // },
                // {
                //     name: "ACH, Wires",
                //     isAvailable: true,
                // },
                // {
                //     name: "Unlimited Invoices",
                //     isAvailable: true,
                // },
                // {
                //     name: "Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "eCommerce Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                // {
                //     name: "Invoice Payments",
                //     extraInfo: "2.3% + 30c",
                //     isAvailable: true,
                // },
                {
                    name: "Initial BOIR report filing",
                    isAvailable: true,
                },
                {
                    name: "Faster book closings",
                    isAvailable: true,
                },
                {
                    name: "Semi-annual tax consultations",
                    isAvailable: true,
                },
                {
                    name: "Profit & loss and balance sheet statements",
                    isAvailable: true,
                },
                {
                    name: "Business income tax filing for multiple states",
                    isAvailable: true,
                },
                {
                    name: "Swift payment processing with Clover or/and Cardpointe at competitive rates (approx. 2.30% +30¢).",
                    isAvailable: true,
                },
                {
                    name: "Multiple Revenue Systems",
                    isAvailable: false,
                },
                {
                    name: "Unlimited Chart of Accounts",
                    isAvailable: false,
                },
                // {
                //     name: "AI Insights",
                //     isAvailable: true,
                // },
                // {
                //     name: "Bookkeeping & Tax Filing",
                //     isAvailable: true,
                // },
                // {
                //     name: "Customer Support",
                //     extraInfo: "24 x 7",
                //     isAvailable: true,
                // },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        ANNUAL_ENTERPRISE: {
            save: "Free Tax Filing",
            planName: 'Enterprise',
            price: 999,
            description: 'per month billed annually',
            planInclude: 'Monthly Transaction Volume: > $50k',
            // subDescription: 'Lowest Processing Fees',
            paymentRate: "1.90% + 30c",
            interchangeRate: "0.10% + 30c",
            corporateTaxFile: true,
            personalTaxFile: true,
            link: `${EnterpriseLinkAnnual}`,
            info: "Unparalleled bookkeeping and tax filing for multi-entity businesses with expenses over $80k in monthly expenses.",
            tag: "Everything in the growth financial suite, plus:",
            features: [
                {
                    name: "Unlimited chart of accounts for boundless financial flexibility",
                    isAvailable: true,
                },
                {
                    name: "Federal and state tax filing for multiple entities",
                    isAvailable: true,
                },
                {
                    name: "IRS tax extensions filing",
                    isAvailable: true,
                },
                {
                    name: "Sales and payroll tax filing",
                    isAvailable: true,
                },
                {
                    name: "Handling full process of R&D tax credit claim",
                    isAvailable: true,
                },
                {
                    name: "Versatile revenue systems to fit every size and need",
                    isAvailable: true,
                },
                {
                    name: "Streamlined support for multiple business entities",
                    isAvailable: true,
                },
                {
                    name: "Swift payment processing with Clover or/and Cardpointe at competitive rates (approx. 1.90% +30¢)",
                    isAvailable: true,
                },
            ],
            transactionVol: '*Covers high volume of transactions'
        },
    }, 
    FREE_PLAN_BOOKS_TAX: {
        ANNUAL_TAX_FILLING: {
            planName: 'Tax filing and advisory',
            price: 399,
            description: 'per month',
            planInclude: 'Monthly Transaction Volume: < $10k',
            // subDescription: 'High Sales Volume',
            paymentRate: "2.6% + 30c",
            corporateTaxFile: false,
            personalTaxFile: true,
            corporateTaxFileCost: 1000,
            // link: `${ProLinkAnnual}`,
            info: "Tax filing and advisory services to manage your financials seamlessly.",
            addOn: ' $1000',
            features: [
                {
                    name: "Access to Otterz financial management platform",
                    isAvailable: true,
                },
                {
                    name: "Dedicated tax expert, always on call",
                    isAvailable: true,
                },
                {
                    name: "Complete tax handling: federal, state & franchise",
                    isAvailable: true,
                },
                {
                    name: "Hassle-free annual filings, 1099 forms & more",
                    isAvailable: true,
                },
                {
                    name: "Tailored tax advice for optimal savings",
                    isAvailable: true,
                },
                {
                    name: "Proactive tax planning",
                    isAvailable: true,
                },
                // {
                //     name: "Federal and state tax filing for up to two states",
                //     isAvailable: true,
                // },
                // {
                //     name: "Up to five 1099 filings",
                //     isAvailable: true,
                // },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
        ANNUAL_BOOKKEEPING: {
            save: "Free Tax Filing",
            planName: 'Bookkeeping',
            price: 259,
            description: 'per month',
            planInclude: 'Monthly Transaction Volume: $10k - $50k',
            paymentRate: "1.90% + 30c",
            interchangeRate: "0.10% + 30c",
            corporateTaxFile: true,
            personalTaxFile: true,
            // subDescription: 'Lowest Processing Fees',
            // link: `${EliteLinkAnnual}`,
            info: "Fast and smart bookkeeping to cover your basic financial reporting needs.",
            features: [
                {
                    name: "Dedicated bookkeeping expert",
                    isAvailable: true,
                },
                {
                    name: "Clean, accurate books and up-to-date financials",
                    isAvailable: true,
                },
                {
                    name: "Profit & loss and balance sheet statements",
                    isAvailable: true,
                },
                {
                    name: "Daily tracking & categorization",
                    isAvailable: true,
                },
                {
                    name: "Instant, real-time financial insights",
                    isAvailable: true,
                },
                {
                    name: "Monthly reconciliation",
                    isAvailable: true,
                },
                // {
                //     name: "Multiple Revenue Systems",
                //     isAvailable: false,
                // },
                // {
                //     name: "Unlimited Chart of Accounts",
                //     isAvailable: false,
                // },
            ],
            transactionVol: '*Covers only low volume of transactions'
        },
    }
}

const Subscription = ({onSubmit, selectTaxFiling, loading, selectedSubscriptionPlan}) => {
    const { selectedBusiness } = useBusiness();
    const [, setSubscriptionPlan] = useState([]);

    const [duration, setDuration] = useState('ANNUALLY'); // New state for duration
    // const { userData } = useContext(AppContext);
    const {userData} = useAccount();
    const queryClient = useQueryClient();
    const currentPlanName = useMemo(()=> {
        if (!selectedSubscriptionPlan ||!selectedSubscriptionPlan.data) {
            return null;
        }

        switch (selectedSubscriptionPlan.data.subscription_type) {
            case 'starter':
            case 'custom_starter':
                return 'starter';
            default:
                return selectedSubscriptionPlan.data.subscription_type;
        }
    }, [selectedSubscriptionPlan])

    // Custom switch styling
    // const switchStyle = {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     background: 'rgb(255 255 255)',
    //     borderRadius: '22px',
    //     position: 'relative',
    // };

    // const switchButtonStyle = {
    //     background: 'white',
    //     borderRadius: '1.5px',
    //     transform: 'scaleX(5) scaleY(2.5)',
    //     boxShadow: '0px 4px 4px 0px #0000000D',
    //     border: '1px solid #EEF1F1'
    // };

    // const currentPlanID = useMemo(()=> selectedSubscriptionPlan?.data?.subscription_type ?? null, [selectedSubscriptionPlan])
    // const isCardInfoReceived = useMemo(()=> currentPlanID && selectedSubscriptionPlan?.data?.subscription_type !=='custom_starter', [selectedSubscriptionPlan, currentPlanID])

    useQuery(
        "getSubscriptions",
        () => api.Services.Dashboard.Subscription.getSubscriptionDetails(),
        {
          refetchOnWindowFocus: false,
          onSuccess: (response) => {
            setSubscriptionPlan(response?.data);
          },
        }
      );

    // const onSelectTaxFiling = (planName, duration) => {
    //     selectTaxFiling(selectedBusiness.business_id);
    // }

    const onSelectSubscription = (planName, duration, link) => {
        queryClient.invalidateQueries("getCurrentSubscriptionPlanId");

        console.log("Plan", planName, duration, link);

        mixpanel.track("User selects a subscription plan", {
            "Email ID": userData.email,
            "Phone Number": userData.phone_number,
            "Plan name": planName,
          });

        onSubmit({
            business_id: selectedBusiness.business_id,
            subscriptionType: {
                subscription_type: planName,
                subscription_cycle: duration
            }
        })
    }

    // const startPollingForSubscriptionStatus = () => {
    //     const intervalId = setInterval(async () => {
    //         queryClient.invalidateQueries("getCurrentSubscriptionPlanId");
    //         if (currentPlanName !== null && currentPlanName !== "starter") {
    //             clearInterval(intervalId);
    //         }
    //     }, 5000); // Poll every 5 seconds, adjust as needed
    // }

    // const sendEmailInternal = useMutation(
    //     (data) => api.Receivables.PaymentAcceptOnboard.sendInternalMail(data, false),
    //     {
    //       onSuccess: (data, variables) => {
    //         // This function will be called when the mutation is successful
    //         Bugsnag.notify(data)
    //         Message({ type: "success", content: `Your request to enable ${variables.formData.tax_filling_type.toLowerCase()} has been captured.` });
    //       },
    //       // You can also handle errors using the onError callback if needed
    //       onError: (error) => {
    //         // Handle error
    //         Message({ type: "error", content: "Tax filling Request Failed" });
    //       },
    //     }
    //   );
      
      // Event handlers
    //   const sendTaxFillingMail = (data) => {
    //     const reqBody = {
    //       templateName: "TAXFILLING",
    //       tenantId: userData?.tenantId,
    //       formData: {
    //         tax_filling_type: data,
    //         email: userData?.email,
    //       },
    //     };
    //     sendEmailInternal.mutate(reqBody);
    //   };

    return (
        <>
            {/* <section className={classNames.greetingsSection}>
                <h1 style={{ color: '#82898F', margin: 0 }}>Hello{userData?.first_name ? `, ${userData?.first_name}` : ''}</h1>
            </section> */}
            <div className={classNames.container} style={{
                flexDirection: 'column',
                // height: '100%',
                flexGrow: '1',
            }}>
                {/* AntD Switch for selecting duration */}
                {/* <div className={classNames.switchContainer} style={switchStyle}>
                    <span style={{
                        fontWeight: '600',
                        position: 'relative',
                        zIndex: '1',
                        pointerEvents: 'none',
                        color: duration === 'MONTHLY' ? '#ffffff' : '#82898FC2',
                        right: '5px'
                    }}>Monthly</span>
                    <Switch
                        style={switchButtonStyle}
                        checked={duration === 'ANNUALLY'}
                        onChange={(checked) => setDuration(checked ? 'ANNUALLY' : 'MONTHLY')}
                        trackColor={{ true: '#7372FF', false: '#7372FF' }} // Always color the track
                        thumbColor={duration === 'ANNUALLY' ? '#f5f5f5' : '#7372FF'} // Change thumb color based on selection
                    />
                    <span style={{ 
                        fontWeight: '600',
                        position: 'relative',
                        pointerEvents: 'none',
                        color: duration === 'ANNUALLY' ? '#ffffff' : '#82898FC2',
                        left: '5px'
                    }}>Annually</span>
                </div> */}
                <div style={{
                    width: '100%',
                    display: 'flex',
                    // height: '100vh',
                    // alignItems: 'center',
                    alignItems: 'stretch',
                    justifyContent: 'center'
                }}>
                {/* Render subscription cards based on selected duration */}
                {Object.keys(subscriptionPlans[duration]).map((planName, index) => {
                    const data = subscriptionPlans[duration][planName];

                    if (!data) {
                        return null;
                    }

                    return (
                        <SubscriptionPlanCard
                            key={index}
                            planName={data.planName}
                            price={data.price}
                            description={data.description}
                            subDescription={data.subDescription}
                            planInclude={data.planInclude}
                            paymentRate={data.paymentRate}
                            features={data.features}
                            onSubscribe={() => {
                                if (planName === "ANNUAL_TAX_FILLING" || planName === "ANNUAL_BOOKKEEPING") {
                                    onSelectSubscription(planName, "ANNUALLY", data.link)
                                    // onSelectTaxFiling(planName, duration)
                                } else {
                                    onSelectSubscription(planName, duration, data.link)
                                }
                                
                            }}
                            selected={currentPlanName === planName}
                            save={data?.save}
                            tag={data?.tag}
                            info={data?.info}
                            interchangeRate={data.interchangeRate}
                            transactionVol={data.transactionVol}
                            addOn={data?.addOn}
                            isLoading={loading}
                        />
                    );
                })}
                </div>
                {/* <div className={classNames.taxContainer} style={{
                    display: 'flex'
                }}>
                    {Object.keys(subscriptionPlans[duration]).map((planName, index) => {
                        const data = subscriptionPlans[duration][planName];

                        if (!data) {
                            return null;
                        }

                        return (                        
                            <TaxCard
                                key={index}
                                corporateTaxFile={data.corporateTaxFile}
                                planName={data.planName}
                                display={planName === 'ANNUAL_STARTER' || planName === 'MONTHLY_STARTER'}
                                personalTaxFile={data.personalTaxFile}
                                corporateTaxFileCost={data.corporateTaxFileCost}
                                personalTaxFileCost={data.personalTaxFileCost}
                                selected={selectedSubscriptionPlan && selectedSubscriptionPlan.data && selectedSubscriptionPlan.data.has_ctf}
                                onSubscribe={() => {
                                    window.open(endpoints.SERVICES.DASHBOARD.PAYMENT_DETAILS_SUBSCRIPTION[env], '_blank');
                                }}
                            />
                        );
                    })}
                </div> */}

                <div style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    display: 'flex',
                    padding: '0px 4vw'
                }}>
                    <TaxCard
                        key={0}
                        corporateTaxFile={false}
                        // planName={data.planName}
                        display={true}
                        // personalTaxFile={data.personalTaxFile}
                        // corporateTaxFileCost={data.corporateTaxFileCost}
                        // personalTaxFileCost={data.personalTaxFileCost}
                        selected={selectedSubscriptionPlan && selectedSubscriptionPlan.data && selectedSubscriptionPlan.data.has_ctf}
                        onSubscribe={() => {
                            window.open(endpoints.SERVICES.DASHBOARD.PAYMENT_DETAILS_SUBSCRIPTION[env], '_blank');
                        }}
                    />

                    <div className={classNames.switchLink}>
                        <p>Prefer {
                            duration === 'ANNUALLY' ? (
                                <>a Monthly Plan? Click<Button style={{padding: '0 0 0 5px'}} type="link" onClick={() => {setDuration("MONTHLY")}}>Here</Button></>
                            ) : (
                                <>an Annual Plan? Click<Button style={{padding: '0 0 0 5px'}} type="link" onClick={() => {setDuration("ANNUALLY")}}>Here</Button></>
                            )
                        }
                        </p>

                        <p>
                        Prefer {
                            duration === 'FREE_PLAN_BOOKS_TAX'  ? (
                                <>an Monthly Plan? Click<Button style={{padding: '0 0 0 5px'}} type="link" onClick={() => {setDuration("MONTHLY")}}>Here</Button></>
                            ) : (
                                <>a Custom Plan? Click<Button style={{padding: '0 0 0 5px'}} type="link" onClick={() => {setDuration("FREE_PLAN_BOOKS_TAX")}}>Here</Button></>
                            )
                        }
                        </p>
                    </div>
                </div>


                
                {/* <div className={classNames.taxContainer}>
                    <div className={classNames.taxCard}>
                        <p className={classNames.taxPara}><strong>Enable Tax Filing to enjoy a Stress Free Tax Season <br/></strong>(rates are subject to your active subscription plan)</p>
                        <div style={{display: 'flex', gap: '2vw'}}>
                            <Button type="primary" disabled={currentPlanName === null} onClick={() => { sendTaxFillingMail("Corporate Tax filling")}} style={{width: 'fit-content'}} className={[classNames.taxButton]}>Enable Corporate Tax Filing</Button>
                            <Button type="primary" disabled={currentPlanName === null} onClick={() => { sendTaxFillingMail("Personal Tax filling")}} style={{width: 'fit-content'}} className={[classNames.taxButton]}>Enable Personal Tax Filing</Button>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default Subscription;
