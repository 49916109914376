import endpoints from "../../../endpoints";

export default async function sendConfirmedMessages(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = endpoints.SERVICES.DASHBOARD.QUERIES.BOOKKEEPING.SEND_CONFIREMD_MESSAGE[env]
    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${url}${data?.business_id}/${data?.transactionId}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify(data.data)
        })
    ).json();
}
