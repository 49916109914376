import endpoints from "../../../endpoints";

export default async function requestDocuments(data){
    let env = process.env.REACT_APP_ENV?.trim();

    // Construct the URL with query parameters

    const secureLocalStore = window.localStorage || window.sessionStorage;
    const baseURL = endpoints.SERVICES.ACCOUNTANTS_DASHBOARD.REQUEST_DOCUMENTS[env];
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}?${data.docID}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: {
                document_name: data.document_name,
                folder_id: data.folder_id
            }
        })
    ).json();
}
