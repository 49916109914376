import endpoints from "../../../endpoints";

export default async function getBusinessForAccountant(otterz_id){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = endpoints.SERVICES.ACCOUNTANTS_DASHBOARD.GET_BUSINESS_FOR_ACCOUNTANTS[env] + otterz_id;
    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
