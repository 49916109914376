import { Form } from "antd";
import { useMutation } from "react-query";

import { CTAButton, Input, Message } from "../../common";
import api from "../../API";

import { validation } from "../config";
import Heading from "../Heading";
import classNames from "./ForgotPassword.module.scss";

export default function OTPMessage() {
  const [form] = Form.useForm();

  const resetPassword = useMutation(data => api.Services.Auth.resetPassword(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Password recovery started", data);
        Message({ type: "success", content: "Password reset link sent to email" });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  const handleOnFinish = async ({ email }) => {
    try {
      console.log(form.getFieldsValue().email);
      resetPassword.mutate({ email: form.getFieldsValue().email });
    } catch (error) {
      return;
    }

  };

  return (
    <div className={classNames.wrapper}>
      {/* <div className={classNames.backButtonWrapper}>
        <BackButton variant="tail" onClick={() => navigate(-1)} />
      </div> */}
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Forgot Password"
          // description="Enter your email address registered with Otterz"
        />
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={handleOnFinish}
          autoComplete="off"
        >
          <Form.Item
            // label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                message: validation.email.valid,
              },
              { required: true, message: validation.email.required },
            ]}
          >
            <Input placeholder={validation.email.placeholder} />
          </Form.Item>
          <CTAButton
            htmlType="submit"
            type="primary"
            loading={resetPassword.isLoading}
          >
            Reset Password
          </CTAButton>
        </Form>
      </div>
    </div>
  );
}
