import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table, Space, Empty, Button as AntButton } from "antd";
import Search2LineIcon from "remixicon-react/Search2LineIcon";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
// TODO: Edit/Delete currently not possible.
import EditLineIcon from "remixicon-react/EditLineIcon";
import { useQuery, useQueryClient } from "react-query";
// import DeleteBin7LineIcon from "remixicon-react/DeleteBin7LineIcon";
import { Input, Header, TypeTag } from "../../../common";
import AddNewPayee from "./AddNewPayee/AddNewPayee";
import DeletePayee from "./DeletePayee";
import EditPayee from "./EditPayee/EditPayee";
import { AppContext } from "../../../../context/AppState";
import api from "../../../API";
import generateErrorMessage from "../../../util/functions/customError";
import { isBusinessType } from "../../../util/functions/onboarding";
import { PAGINATION, PAGINATION_DEFAULT } from "../../../util/constants";
import classNames from "./Payees.module.scss";

export default function Payees() {
  //Hooks
  const [modalVisibility, setModalVisibility] = useState(false);
  const [editPayeeModalVisibility, setEditPayeeModalVisibility] = useState(false);
  const [formattedData, setFormattedData] = useState();
  const [searchResults, setSearchResults] = useState();
  const queryClient = useQueryClient();

  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [selectedPayees, setSelectedPayees] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { application } = useContext(AppContext);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState(PAGINATION);

  //Api calls
  const {
    isLoading,
    data: payeeList,
    refetch: refetchPayee,
  } = useQuery(
    "getPayees",
    () =>
      api.BusinessBanking.listPayees({
        ...(isBusinessType(application?.type)
          ? { otz_business_id: application?.otz_business_id }
          : { otz_person_id: application?.otz_person_id }),
      }), //TODO: currently getting maximum records and planing to manage pagination from backend later
    {
      onSuccess: (data) => {
        setPagination(PAGINATION);
        setRefresh((refresh) => !refresh);
      },
      onError: (error) => generateErrorMessage(error),
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const transformData = (accounts) => {
    const grouped = {};
  
    accounts.forEach((account) => {
      const name = account.account_owner_name;
      if (!grouped[name]) {
        grouped[name] = {
          name,
          email: account.is_primary ? account.email : '',
          accounts: [],
        };
      }
      grouped[name].accounts.push({
        ...account,
        key: account?.otz_payee_id,
        accountNumber: account?.account_number_masked,
        accountType: account?.account_type,
        routingNumber: account?.routing_no,
        payeeName: account.is_primary ? name : '',
        email: account.is_primary ? account.email : '',
      });
    });
  
    return Object.values(grouped).flatMap(group => group.accounts);
  };

  // useEffect(() => {
  //   Boolean(payeeList?.payload) &&
  //     setFormattedData(
  //       payeeList?.payload.map((payee) => {
  //         return {
  //           key: payee?.otz_payee_id,
  //           payeeName: payee?.account_owner_name,
  //           accountNumber: payee?.account_number_masked,
  //           accountType: payee?.account_type,
  //           routingNumber: payee?.routing_no,
  //           email: payee?.email,
  //           // wire_routing_number: payee?.wire_routing_number,
  //         };
  //       })
  //     );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [payeeList, refresh]);

  useEffect(() => {
    if (payeeList?.payload) {
      const transformedData = transformData(payeeList.payload);
      setFormattedData(transformedData);
    }
  }, [payeeList, refresh]);

  //Event handlers
  const handleDeleteModalCancelClick = () => {
    setDeleteModalVisibility(false);
    setSelectedPayees([]);
  };

  const handleModalCancelClick = () => {
    setModalVisibility(false);
  };

  const handleEditPayee = (selectedRecord) => {
    if(selectedRecord.same_payee_id) {
      const relatedPayees = payeeList?.payload.filter(
        (payee) => payee.same_payee_id === selectedRecord.same_payee_id
      );
      setSelectedPayees(relatedPayees);
    } else {
      setSelectedPayees([selectedRecord]);
    }
    setEditPayeeModalVisibility(true);
  };  

  //JSX Element
  //Other variables
  const columns = [
    {
      title: "Payee Name",
      dataIndex: "payeeName",
      key: "payeeName",
      render: (text, record) => record.is_primary ? text : '',
    },
    {
      title: "Bank",
      dataIndex: "bank_name",
      key: "bank_name",
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      key: "accountType",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "Routing Number",
      dataIndex: "routingNumber",
      key: "routingNumber",
    },
   /*  {
      title: "Wire Routing Number",
      dataIndex: "wire_routing_number",
      key: "wire_routing_number",
    }, */
    {
      title: "Default Payment",
      dataIndex: "defaultPayment",
      key: "defaultPayment",
      render: (type, index) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            width: "80%",
          }}
        >
          {/* NOTE: Type hardcoded to 'ACH' until options made available post MVP */}
          <TypeTag type="ACH" />
        </Space>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      dataIndex: "actions",
      key: "actions",
      // width: "25%",
      render: (type, record) => (
        <Space
          size="middle"
          className={classNames.payeeActionColumns}
          // disabled={!(selectedPayees && selectedPayees.length === 0)}
        >
        {record.is_primary && (
          <EditLineIcon
            size={14}
            color="#9BA3AB"
            className={classNames.actionIcon}
            onClick={() => {
              handleEditPayee(record);
            }}
          />
        )}
          {/* <DeleteBin7LineIcon
            size={14}
            color="#9BA3AB"
            className={classNames.actionIcon}
            onClick={() => {
              setSelectedPayees([index]);
              setDeleteModalVisibility(true);
            }}
          /> */}
        </Space>
      ),
    },
  ];

  //Helper functions
  const searchItems = (searchValue) => {
    setPagination(PAGINATION);
    const filtered = formattedData?.filter((payee) => {
      const filterObj = {
        key: payee?.key,
        payeeName: payee.payeeName,
        accountNumber: payee.accountNumber,
        accountType: payee.accountType,
        routingNumber: payee.routingNumber,
        email: payee.email,
        bank: payee.bank_name,
        // wire_routing_number: payee?.wire_routing_number,
      };
      return Object.values(filterObj).some(value => {
        return value?.toString().toLowerCase().includes(searchValue.toLowerCase())
      }
      );
    });
    setSearchResults(filtered);
  };
  
  const editPayeeModalCancel = () => {
    setEditPayeeModalVisibility(false);
    setSelectedPayees([]);
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header title="Payees" back onClick={() => navigate(-1)} />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.topRow}>
            <div className={classNames.payeeFilters}>
              <Input
                placeholder="Search payee"
                prefix={<Search2LineIcon />}
                className={classNames.searchPayee}
                onChange={(e) => searchItems(e.target.value)}
              />
              {
                //#region
              }
              {/* Do not remove the below code. Commenting below code according to Mukund lahot's guidance on 2/22/2022 - Sagara */}
              {/* <Card className={classNames.filtersCard}>
                <div className={classNames.filtersGroup}>
                  <Radio.Group defaultValue="a" buttonStyle="solid">
                    <Space size={0}>
                      <Radio.Button value="a">All</Radio.Button>
                      <Radio.Button value="b">1D</Radio.Button>
                      <Radio.Button value="c">1W</Radio.Button>
                      <Radio.Button value="d">1M</Radio.Button>
                      <Radio.Button value="e">6M</Radio.Button>
                      <Radio.Button value="f">Y</Radio.Button>
                      <Radio.Button value="g">
                        <CalendarLineIcon size={12} />
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </div>
              </Card> */}
              {
                //#endregion
              }
            </div>
            <Card
              className={classNames.optionCard}
              onClick={() => setModalVisibility(true)}
            >
              <div className={classNames.cardAction}>
                <span>Add New Payee</span>
                <ArrowRightLineIcon className={classNames.arrowBtnWithBg} />
              </div>
            </Card>
          </div>
          <div className={classNames.body}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "350px",
                margin: "26px 0",
              }}
            />

            <Table
              size="large"
              loading={isLoading}
              dataSource={!searchResults ? formattedData : searchResults}
              columns={columns}
              className={classNames.table}
              pagination={{
                ...PAGINATION_DEFAULT,
                current: pagination.page,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  setPagination({ page: page, pageSize: pageSize });
                },
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <AntButton style={{ color: "#9f9cff" }}>Prev</AntButton>
                    );
                  } else if (type === "next") {
                    return (
                      <AntButton
                        style={{ color: "#9f9cff", marginLeft: "32px" }}
                      >
                        Next
                      </AntButton>
                    );
                  }
                },
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data found"
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <AddNewPayee
        visible={modalVisibility}
        onCancel={handleModalCancelClick}
        refetch={refetchPayee}
      />
      <DeletePayee
        dataId={selectedPayees[0]?.key}
        visible={deleteModalVisibility}
        onCancel={handleDeleteModalCancelClick}
        queryClient={queryClient}
        refetch={refetchPayee}
      />
      <EditPayee
        visible={editPayeeModalVisibility}
        onCancel={editPayeeModalCancel}
        payee={selectedPayees}
        refetch={refetchPayee}
      />
    </div>
  );
}
