import endpoints from "../../../endpoints";

export default async function deleteFolder(data){
    let env = process.env.REACT_APP_ENV?.trim();

    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
        folder_id: data.folder_id,
        business_id: data.business_id,
    }).toString();    
    
    const baseURL = endpoints.SERVICES.DASHBOARD.DOCUMENTS_VAULT.FOLDER[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${baseURL}?${queryParams}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
