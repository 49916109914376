import endpoints from "../../../endpoints";

export default async function viewLinkedAccounts(business_id){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');
    // const url = endpoints.SERVICES.ONBOARDING.BUSINESS[env] + "/" + otterz_id;
    const url = endpoints.SERVICES.ONBOARDING.BUSINESS[env];

    return await(
        await fetch(`${url}/${business_id}/accounts`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
