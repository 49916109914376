import React, { useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CTAButton, Message } from '../../common';
import api from '../../API';
import { useMutation } from 'react-query';

const { Title, Paragraph } = Typography;

const SetupPaymentAcceptance = () => {
  const navigate = useNavigate();
  const [choosePaymentAcceptance, setChoosePaymentAcceptance] = useState(false)

  console.log("Payment: ", choosePaymentAcceptance);

  const wrapperStyle = {
    width: '500px',
    border: '1px solid rgb(255 255 255)',
    boxShadow: '0px 0px 8px 0px #c2c2c2',
    borderRadius: '3px'
  };

  const secureLocalStore = window.localStorage || window.sessionStorage;

  const updateOnboardingStatusMutation = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data));

  const updateOnboardingStatus = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data), {
    onSuccess: () => {
      if(choosePaymentAcceptance) {
        Message({ type: "success", content: "Payment Acceptance Scheduled !" });
      }
      // Redirect or do any further action upon successful onboarding status update
      navigate("/onboarding/subscription");
    },
    onError: (error) => {
      console.error("Error updating onboarding status: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  const handleContinueButtonClick = async () => {
    try {
      setChoosePaymentAcceptance(true);
      updateOnboardingStatus.mutate({ otterz_id: secureLocalStore.getItem('otterz_id'), status: "SUBSCRIPTION_ONBOARD" });
      // If mutation is successful
    } catch (error) {
      console.error("Error updating onboarding status: ", error);
      Message({ type: "error", content: error.message });
    }
  };

  const handleMaybeLaterButtonClick = async () => {
    try {
      // navigate("/app");
      // // If mutation is successful
      // await updateOnboardingStatusMutation.mutateAsync({ otterz_id: secureLocalStore.getItem('otterz_id'), status: "ONBOARDED" });
      updateOnboardingStatus.mutate({ otterz_id: secureLocalStore.getItem('otterz_id'), status: "SUBSCRIPTION_ONBOARD" });

    } catch (error) {
      console.error("Error updating onboarding status: ", error);
      Message({ type: "error", content: error.message });
    }
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col>
        <div style={{ textAlign: 'center' }}>
          <Title level={2}>Setup Payment Acceptance</Title>
          <Paragraph>
            Book a call with our account manager to enable payment acceptance
          </Paragraph>
          <div style={wrapperStyle}>
            <iframe
              title='Schedule a meeting with Otterz'
              src="https://meetings.hubspot.com/tapan-r?embed=true"
              width="100%"
              height="600px"
              frameBorder="0"
              style={{ border: 'none' }}
              allowFullScreen
            ></iframe>
          </div>
          <Row style={{justifyContent: 'space-around', marginTop: '20px'}}>
            <CTAButton style={{width: '40%'}} onClick={handleContinueButtonClick} loading={updateOnboardingStatusMutation.isLoading}>Continue</CTAButton>
            <CTAButton style={{background: 'white', color: 'black', width: '40%'}} onClick={handleMaybeLaterButtonClick}>Maybe Later</CTAButton>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default SetupPaymentAcceptance;
