import moment from "moment";
import { useState } from "react";
import { Form } from "antd";

import classNames from "./AddNewRecurringInvoices.module.scss";
import { CTAButton, Modal, Message } from "../../../../common";
import AddNewRecurringFrom from "./AddNewRecurringFrom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
import messages from "../../../config/messages.const";
import useLanguage from "../../../../../hooks/useLanguage";
import { FORMATS } from "../../../../constants";
import mixpanel from "mixpanel-browser";
import Bugsnag from "@bugsnag/js";

export default function AddNewRecurringInvoices({
  setCreateNewRecurringInvoiceModal,
  visible,
  onCancel,
  invoiceTemplateSettings,
}) {
  const language = useLanguage(messages);

  const [summary, setSummary] = useState({ subTotal: 0, tax: 0 });
  const [discount, setDiscount] = useState(0);

  const [form] = Form.useForm();
  const onOk = () => {
    form.submit();
  };
  const queryClient = useQueryClient();

  const { data: currentUserData } = useQuery(
    "getCurrentUser",
    () => api.Auth.fetchCurrentUser(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: customerQuery } = useQuery(
    "getCustomers",
    () =>
      api.Receivables.Customers.getCustomers(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: productQuery } = useQuery(
    "getProducts",
    () =>
      api.Receivables.Product.list(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  const { data: taxQuery } = useQuery(
    "getAllTaxes",
    () =>
      api.Receivables.Taxes.getAllTaxRates(
        currentUserData?.signInUserSession?.idToken?.payload?.icid
      ),
    {
      enabled: !!currentUserData,
      refetchOnWindowFocus: false,
    }
  );

  function createRecurringInvoiceFn(data) {
    let itemDto =
      data.salesItemsRecurring &&
      data.salesItemsRecurring.length > 0 &&
      data.salesItemsRecurring.map((item, index) => {
        return {
          description: item.description,
          quantity: item.quantity ? Number(item.quantity) : 1,
          taxRate: item.taxRate ? Number(item.taxRate) : 0,
          unitCost: Number(item.unitCost),
        };
      });

    let input = {
      companyId: currentUserData?.signInUserSession?.idToken?.payload?.icid,
      clientId: data.ReceiverName,
      startDate: moment(data.StartDate).format(FORMATS.API_OTHER_FORMAT),
      timing: data.ScheduledTiming && Number(data.ScheduledTiming),
      period: data.ScheduledPeriod,
      itemDto: data.salesItemsRecurring ? itemDto : [],
      discount: data.Discount && Number(data.Discount),
      reference: data.OtherInformation,
    };
    return api.Receivables.Recurring.createRecurringInvoice(input);
  }

  const createRecurringInvoice = useMutation((event) =>
    createRecurringInvoiceFn(event)
  );

  const onFinish = (data) => {
    createRecurringInvoice.mutate(data, {
      onSuccess: async (response) => {
        queryClient.invalidateQueries("getRecurringData");
        setCreateNewRecurringInvoiceModal(false);
        Message({
          type: "success",
          content: language.RECURRING_INVOICE_CREATE_SUCCESS,
        });

        try {
          // Extracting values from data
          const totalItems = data.salesItemsRecurring.length;
          const totalPrice = data.salesItemsRecurring.reduce((acc, item) => acc + item.subTotal, 0);
          const totalTax = data.salesItemsRecurring.reduce((acc, item) => acc + item.taxAmount, 0);

          const recur = await api.Receivables.Recurring.getRecurringInvoiceById(response.data.id)

          mixpanel.track("Recurring Invoice Creation Successful", {
            "Recurring Invoice Number": recur?.data?.invoiceNumber,
            "No. of items": totalItems,
            "Total Price": totalPrice,
            "Discount": data?.Discount,
            "Tax": totalTax,
            "Status": recur?.data?.invoiceStatus,
            "Payable Amount": recur?.data?.total,
            "Start Date": recur?.data?.startDate,
            "Last Run Date": recur?.data?.scheduledLastRun,
            "Next Run Date": recur?.data?.scheduledNextRun,
          });
        } catch (error) {
          Bugsnag.notify("mixpanel error", error);
        }

        form.resetFields();
        setSummary({ subTotal: 0, tax: 0 });
      },
      onError: (error) => {
        try {
          mixpanel.track("Recurring Invoice Creation Failed", {
            "Invoice Number": data?.reference,
            "Failure Message": generateErrorMessage(error),
        });
        } catch (error) {
          Bugsnag.notify("mixpanel error", error);
        }
      },
    });
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={() => {
        form.resetFields();
        setDiscount(0);
        onCancel();
        setSummary({ subTotal: 0, tax: 0 });
      }}
      onCancel={() => {
        form.resetFields();
        setDiscount(0);
        onCancel();
        setSummary({ subTotal: 0, tax: 0 });
      }}
      title="Create Recurring Invoice"
      className={classNames.modal}
      bodyStyle={{
        height: "70vh",
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      footer={
        <CTAButton
          htmlType="submit"
          type="primary"
          key="submit"
          onClick={onOk}
          style={{
            height: 44,
            width: 248,
          }}
          loading={createRecurringInvoice.isLoading}
        >
          Save
        </CTAButton>
      }
      width={872}
      onOk={onOk}
    >
      <AddNewRecurringFrom
        form={form}
        onFinish={onFinish}
        customers={customerQuery?.data}
        products={productQuery?.data}
        taxRates={taxQuery?.data}
        invoiceTemplateSettings={invoiceTemplateSettings}
        setSummary={setSummary}
        summary={summary}
        discount={discount}
        setDiscount={setDiscount}
      />
    </Modal>
  );
}
