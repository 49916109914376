import React, { useEffect, useState } from 'react';
import { 
  Spin,
  // Table,
  Tag,
  Tooltip, 
  // DatePicker, 
  // Button 
} from 'antd';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import dayjs from 'dayjs';
import classNames from './Insights.module.scss';
import { useAccount, useFilter } from '../../../store/StoreProvider';
import api from '../../API';
import { 
  // CTAButton, 
  Message 
} from '../../common';
import { useMutation } from 'react-query';
// import Rise from '../icons/Rise';
// import Fall from '../icons/Fall';
import BeigeLegend from '../icons/BeigeLegend';
import GrayLegend from '../icons/GrayLegend';
import { InfoCircleOutlined } from '@ant-design/icons';

// const { RangePicker } = DatePicker; // Destructure RangePicker from DatePicker

const Insights = () => {
  const {
    selectedBusiness 
  } = useAccount();
  const {
    insightFilter,
    dateRange,
    setDateRange,
    applyClicked,
  } = useFilter();
  const [loading, setLoading] = useState(true);
  const [cashflowData, setCashflowData] = useState(null);
  // const [categoryData, ] = useState([
  //   "PERSONAL_CARE",
  //   "ENTERTAINMENT",
  //   "FOOD_AND_DRINK",
  //   "TRAVEL",
  //   "GENERAL_MERCHANDISE"
  // ]); // [{}
  const [
    // dataRangeAvgMode
    ,setDateRangeAvgMode] = useState("MoM");

  /*
  
  State variables required for the date range picker:
  - dateRange: to store the selected date range
  - showCustomDatePicker: to toggle the visibility of the date picker
  - applyClicked: to force re-render the chart when the apply button is clicked
  
  Display this when there's no data available yet: (and also blur the chart)

  No data available yet! 
  We're currently reconciling your books. 
  The data will be displayed here soon.
  
  */

  // Helper function to check if data is available
  const isDataAvailable = (data) => {
    console.log("Data", data);
    return data !== null && data !== undefined && Object.keys(data).length > 0;
  };

  /* API calls to fetch business data */
  const getBusinessOverview = useMutation(data => api.Services.Dashboard.BusinessOverview.v2.getBusinessOverviewV2(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
        return; // Exit early if there's an error
      }

      if (data && data.data) {
        console.log("Business Overview Fetched", data.data);
        setCashflowData(data.data);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
      setLoading(false);
    },
  });

  useEffect(() => {
    if (selectedBusiness.business_id) {
      let filterValue = {};

      if (insightFilter === 'week') {
        setDateRangeAvgMode('WoW')
      } else if (insightFilter === "year") {
        setDateRangeAvgMode('YoY')
      } else {
        setDateRangeAvgMode('MoM')
      }

      console.log("Range", dateRange, insightFilter);

      if (insightFilter === 'custom' && dateRange && dateRange.length === 2) {
        filterValue = {
          filter_key: "custom",
          filter_value: {
            start_date: dateRange[0].format("YYYY-MM"),
            end_date: dateRange[1].format("YYYY-MM")
          }
        };
      } else {
        if (insightFilter === 'custom') {
          // filterValue = {
          //   filter_key: "month",
          //   filter_value: {}
          // };
        } else {
          setDateRange(null);
          filterValue = {
            filter_key: insightFilter,
            filter_value: {}
          };
        }
      }
      
      // don't execute the api call if filterValue is {}
      if (Object.keys(filterValue).length > 0) {
        getBusinessOverview.mutateAsync({
          business_id: selectedBusiness.business_id,
          body: filterValue,
        });
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness, insightFilter, dateRange, applyClicked]);

  const lineChartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
          },
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 16,
          },
          autoSkip: false,
          maxTicksLimit: 8,
          callback: function (value, index, values) {
            return `$${value.toLocaleString()}`;
          },
          stepSize: 5000,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  /* Chart definition */
  // Chart data and options
  // const customColors = ['#E1BB80', '#806443', '#7B6B43', '#352208', '#EBE5D4', '#E1BB80CC'];

  // const convertToAbsolutePercentage = (percentageString) => {
  //   if (percentageString === null || percentageString === undefined) {
  //     return '0%';
  //   }
  //   return percentageString.replace('-', '');
  // };

  const getWeekNumber = (dateStr) => {
    const date = new Date(dateStr);
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  };

  const convertLabel = (label) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (/^\d{4}-\d{2}$/.test(label)) {
      const [year, month] = label.split("-");
      return `${monthNames[parseInt(month) - 1]}'${year.slice(2)}`;
    }

    if (/^\d{4}-\d{2}-\d{2}$/.test(label)) {
      const weekNumber = getWeekNumber(label);
      return `W${weekNumber}`;
    }

    return label; // Return the label as is if it doesn't match any format
  };

  // Update chart data and options
  const updatedChartData = {
    revenue_vs_burn: {
      labels: Object.keys(cashflowData?.revenue_vs_burn ?? {}).map(convertLabel),
      datasets: [
        {
          label: 'Revenue',
          data: Object.values(cashflowData?.revenue_vs_burn ?? {}).map(data => data.revenue),
          // backgroundColor: '#E1BB8050',
          fill: false,
          borderColor: '#E1BB80',
          borderWidth: 1,
          lineTension: 0.3,
          // yAxisID: 'y',
        },
        {
          label: 'Burn',
          data: Object.values(cashflowData?.revenue_vs_burn ?? {}).map(data => data.burn),
          // backgroundColor: '#80644350',
          borderColor: '#806443',
          fill: false,
          borderWidth: 1,
          lineTension: 0.3,
          // yAxisID: 'y',
        },
      ],
    },
    cash_in_bank: {
      labels: Object.keys(cashflowData?.cash_in_bank_chart ?? {}).map(convertLabel),
      datasets: [
        {
          label: 'Cash Balance',
          data: Object.values(cashflowData?.cash_in_bank_chart ?? {}),
          // backgroundColor: '#E1BB80',
          backgroundColor: function(context) {
            const chart = context.chart;
            const {ctx} = chart;

            var gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, '#E1BB80');   
            gradient.addColorStop(1, '#BFB1A1');

            return gradient
          },
          // borderColor: '#806443',
          borderRadius: 5,
          borderSkipped: false,
          borderWidth: 1,
          fill: false,
        },
      ],
    },
    credit_spends: {
      labels: ['Available Credit', 'Credit Utilization'],
      datasets: [
        {
          data: [cashflowData?.credit_spends?.available_credit ?? 0, cashflowData?.credit_spends?.credit_utilization ?? 0],
          backgroundColor: ['#3522081A', '#E1BB80'],
          borderWidth: 0,
        },
      ],
    },
  };

  function getPriorityColor(lastUpdated) {
    const currentDate = dayjs();
    const lastUpdatedDate = dayjs(lastUpdated);
    const monthsDifference = lastUpdatedDate.diff(currentDate, 'month'); // Difference between future date and current date
  
    if (monthsDifference < 0) {
      return 'red'; // If the date has passed
    } else if (monthsDifference <= 1) {
      return 'red'; // Urgent (<= 1 month from now)
    } else if (monthsDifference <= 3) {
      return 'gold'; // Important (1-3 months from now)
    } else {
      return 'green'; // Normal (> 3 months from now)
    }
  }

  // Update gauge chart data and options
  const gaugeChartData = {
    labels: ['Runway', 'Remaining'],
    datasets: [
      {
        data: [parseInt(cashflowData?.runway) ?? 0, 36 - (parseInt(cashflowData?.runway) ?? 0)], // 36 months total
        // backgroundColor: ['#E1BB80', '#3522081A'],
        backgroundColor: function(context) {
          const chart = context.chart;
          const {ctx} = chart;

          var gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, getPriorityColor(cashflowData?.cash_zero_date));   
          gradient.addColorStop(1, '#BFB1A1');
          // gradient.addColorStop(0, priorityColor);

          return [gradient, '#3522081A'];
        },
        borderWidth: 0,
      },
    ],
  };

  // Update credit spends line chart data and options
  // const creditSpendsLineChartData = {
  //   labels: Object.keys(cashflowData?.credit_spends_chart ?? {}).map(convertLabel),
  //   datasets: [
  //     {
  //       label: 'Credit Spends',
  //       data: Object.values(cashflowData?.credit_spends_chart ?? {}),
  //       borderColor: '#E1BB80',
  //       // backgroundColor: '#E1BB8050',
  //       fill: false,
  //       borderWidth: 2,
  //       lineTension: 0.3,
  //     },
  //   ],
  // };

  // const options = {
  //   responsive: true, // Make the chart responsive
  //   maintainAspectRatio: false, 
  //   // borderRadius: 10,
  //   plugins: {
  //     legend: {
  //       position: 'right',
  //       display: false,
  //     },
  //   },
  // };


  const gaugeChartOptions = {
    cutout: '70%', // Make the chart a semicircle
    rotation: -90, // Rotate to make it a semicircle
    circumference: 180, // Set the circumference to half of the circle
    // borderRadius: 10, // Add border radius to the gauge
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  // Calculate the total amount and percentages
  // const totalCategoryAmount = cashflowData?.expense_categories?.reduce((acc, amount) => acc + amount.value, 0);
  // const categoryPercentages = cashflowData?.expense_categories?.map(amount => ((amount.value / totalCategoryAmount) * 100).toFixed(2));
  // const categoryAmounts = cashflowData?.expense_categories?.map(amount => amount.value);
  // const expense_labels = cashflowData?.expense_categories?.map(category => category.category) ?? [];

  // const chartData = {
  //   labels: cashflowData?.expense_categories?.map(category => category.category) ?? [],
  //   datasets: [
  //     {
  //       data: cashflowData?.expense_categories?.map(category => category.value) ?? [],
  //       backgroundColor: ['#EF476F', '#F78C6B', '#FFD166', '#06D6A0', '#118AB2', '#073B4C'],
  //     },
  //   ],
  // };

  // const columns = [
  //   {
  //     title: 'Category',
  //     dataIndex: 'metric',
  //     key: 'metric',
  //   },
  //   {
  //     title: 'Value',
  //     dataIndex: 'value',
  //     key: 'value',
  //   },
  // ];

  // const expenseCategories = cashflowData?.expense_categories
  // ? cashflowData.expense_categories.map((item, index) => ({
  //     key: `${index + 1}`,
  //     metric: item.category,
  //     value: `$${item.value.toLocaleString()}`,
  //   }))
  // : [];

  return (
    <div className={classNames.container}>

      {(loading) ? (
        <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }} />
      ) : (
        <>
          <section className={classNames.innerContent} style={{background: 'white', margin: '0.5rem'}}>
            <div className={classNames.grid} style={{
              // filter: Object.entries(cashflowData).length === 0 ? "blur(5px)" : null, 
              padding: '0 20px', gridTemplateColumns: 'repeat(2, 1fr) !important', width: '100%'}}>
              
              {/* First card - Cash burn vs Revenue */}
              <div className={classNames.card} style={{ position: 'relative', padding: '40px 20px 20px 20px'}}>
                {getBusinessOverview.isLoading && (
                  <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                    <Spin />
                  </div>
                )}
                {!isDataAvailable(cashflowData?.revenue_vs_burn) && (
                  <div className={classNames.noDataOverlay}>
                    No data available yet! <br />
                    We're currently reconciling your books. <br />
                    The data will be displayed here soon.
                  </div>
                )}
                <div style={{width: '100%', height: '100%'}}> 
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}>
                    <div className={classNames.cardText}>
                      <h1 style={{marginBottom: '5px', fontSize: '1rem'}}>
                      Revenue vs Burn
                        <Tooltip title="Revenue vs Burn: Compares the total revenue generated against the total expenses.">
                          <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                        </Tooltip>
                      </h1>
                      <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData?.revenue_vs_burn) ? 0 : 1}}>As of bookkeeping on {cashflowData?.revenue_vs_burn_last_updated ? dayjs(cashflowData.revenue_vs_burn_last_updated.split(' ')[0]).format('DD MMMM, YYYY')  : ''}</p>
                    </div>

                    <div style={{fontWeight: '500', display: 'flex', gap: '30px', opacity: !isDataAvailable(cashflowData?.revenue_vs_burn) ? 0 : 1}}>
                      <div style={{textAlign: 'right'}}>
                        <p style={{fontSize: '1.8rem', textAlign: 'right'}}><sup style={{top: '-0.4em'}}>$</sup>{parseFloat(cashflowData?.revenue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                        <span>
                          {/* {parseFloat(cashflowData?.revenue_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData?.revenue_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(cashflowData?.revenue_improvement)}</span> {dataRangeAvgMode} */}
                          <h3 style={{color: '#313D44', fontSize: '0.8rem', textAlign: 'right'}}>
                            YTD
                            <Tooltip title="Revenue YTD: Year-to-date total revenue generated.">
                              <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                            </Tooltip>
                          </h3>
                        </span>
                        <div style={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'flex-end'}}>
                          <BeigeLegend />
                          <span style={{color: '#1D1C1C75'}}>
                            Revenue
                          </span>
                        </div>
                      </div>

                      <div style={{textAlign: 'right'}}>
                        <p style={{fontSize: '1.8rem', textAlign: 'right'}}><sup style={{top: '-0.4em'}}>$</sup>{parseFloat(cashflowData?.burn).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                        <span>
                          {/* {parseFloat(cashflowData?.burn_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData?.burn_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(cashflowData?.burn_improvement)}</span> {dataRangeAvgMode} */}
                          <h3 style={{color: '#313D44', fontSize: '0.8rem', textAlign: 'right'}}>
                            YTD
                            <Tooltip title="Burn YTD: Year-to-date total expenses.">
                              <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                            </Tooltip>
                          </h3>
                        </span>
                        <div style={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'flex-end'}}>
                          <GrayLegend />
                          <span style={{color: '#1D1C1C75'}}>Burn</span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div style={{margin: '3rem 0 1rem 0', flexDirection: 'column-reverse', justifyContent: 'flex-start', gap: '40px', opacity: !isDataAvailable(cashflowData?.revenue_vs_burn) ? 0 : 1}} className={[classNames.chartContainer, classNames.revenueChart]}>
                    <Line data={updatedChartData.revenue_vs_burn} options={lineChartOptions}/>
                  </div>
                </div>
              </div>

              {/* Second Chart - Cash Zero Date */}
              <div className={classNames.card} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
                // width: '40%'
              }}>
                {getBusinessOverview.isLoading && (
                  <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                    <Spin />
                  </div>
                )}
                {!isDataAvailable(cashflowData?.revenue_vs_burn) && (
                  <div className={classNames.noDataOverlay}>
                    No data available yet! <br />
                    We're currently reconciling your books. <br />
                    The data will be displayed here soon.
                  </div>
                )}
                <div style={{
                  width: '100%', 
                  height: '100%', 
                  // filter: !isDataAvailable(cashflowData?.revenue_vs_burn) ? 'blur(5px)' : 'none', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  position: 'relative'
                }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                    <div className={classNames.cardText}>
                      <h1 style={{marginBottom: '5px', fontSize: '1rem'}}>
                        Cash Zero Date
                        <Tooltip title="Cash Zero Date: The projected date when cash reserves will be depleted based on average monthly expenses">
                          <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                        </Tooltip>
                      </h1>
                      <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData?.runway) ? 0 : 1}}>As of bookkeeping on {cashflowData?.runway_last_updated ? dayjs(cashflowData.runway_last_updated.split(' ')[0]).format('DD MMMM, YYYY')  : ''}</p>
                      {/* <p style={{ color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData?.runway) ? 0 : 1 }}>
                        <Tag color={getPriorityColor(cashflowData?.runway_last_updated)}>
                          As of bookkeeping on {cashflowData?.runway_last_updated ? dayjs(cashflowData.runway_last_updated.split(' ')[0]).format('DD MMMM, YYYY') : ''}
                        </Tag>
                      </p> */}
                    </div>
                    <div className={classNames.cardText} style={{opacity: !isDataAvailable(cashflowData?.runway) ? 0 : 1}}>
                      {/* <p style={{fontSize: '1.5rem', textAlign: 'right'}}>{dayjs(cashflowData?.cash_zero_date).format('DD MMMM, YYYY')}</p> */}
                      <p style={{fontSize: '1.5rem', textAlign: 'right'}}>
                        <Tag style={{fontSize: '1.5rem', textAlign: 'right', padding: '1rem'}} color={getPriorityColor(cashflowData?.cash_zero_date)}>
                          {dayjs(cashflowData?.cash_zero_date).format('DD MMMM, YYYY')}
                        </Tag>
                      </p>
                    </div>
                  </div>
                  <div style={{margin: '3rem 0 0 0', width: '75%', position: 'relative', opacity: !isDataAvailable(cashflowData?.runway) ? 0 : 1}} className={classNames.chartContainer}>
                    <Doughnut data={gaugeChartData} options={gaugeChartOptions} />
                    <div style={{
                      position: 'absolute',
                      bottom: '40px',
                      textAlign: 'center'
                    }}>
                      <p style={{fontSize: '1rem', color: '#313D44', fontWeight: '500'}}>
                        Runway
                        <Tooltip title="Runway (Months): The number of months your cash reserves can cover current expenses.">
                          <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                        </Tooltip>
                      </p>
                      <p style={{fontSize: '1.5rem', color: '#313D44', fontWeight: '500'}}>{cashflowData?.runway}</p>
                      {/* <p style={{color: '#313D44', fontWeight: '500'}}>{parseFloat(cashflowData?.runway_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData?.runway_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(cashflowData?.runway_improvement)}</span> {dataRangeAvgMode}</p> */}
                    </div>
                    <p style={{position: 'absolute', left: '0', bottom: '0', color: '#1d1c1c80', fontSize: '0.8rem'}}>0 months</p>
                    <p style={{position: 'absolute', right: '0', bottom: '0', color: '#1d1c1c80', fontSize: '0.8rem'}}>36 months</p>
                  </div>
                </div>
              </div>
            </div>

            
            <div className={classNames.grid} style={{
              // filter: Object.entries(cashflowData).length === 0 ? "blur(5px)" : null, 
              padding: '0 20px'}}>
              
              {/* Third card - cash in bank */}
              <div className={classNames.card} style={{ position: 'relative'}}>
                {getBusinessOverview.isLoading && (
                  <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                    <Spin />
                  </div>
                )}
                {!isDataAvailable(cashflowData?.cash_in_bank_chart) && (
                  <div className={classNames.noDataOverlay}>
                    No data available yet! <br />
                    We're currently reconciling your books. <br />
                    The data will be displayed here soon.
                  </div>
                )}
                <div style={{width: '100%', height: '100%'}}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <div className={classNames.cardText}>
                      <h1 style={{marginBottom: '5px', fontSize: '1rem'}}>Cash Balance</h1>
                      <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData?.cash_in_bank_chart) ? 0 : 1}}>As of bookkeeping on {cashflowData?.cash_in_bank_chart_last_updated ? dayjs(cashflowData.cash_in_bank_chart_last_updated.split(' ')[0]).format('DD MMMM, YYYY')  : ''}</p>
                    </div>
                    <div className={classNames.cardText} style={{opacity: !isDataAvailable(cashflowData?.cash_in_bank_chart) ? 0 : 1}}>
                      <p style={{fontSize: '1.8rem'}}><sup style={{top: '-0.4em'}}>$</sup>{cashflowData?.cash_in_bank?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</p>
                      {/* <p style={{color: '#313D44'}}>{parseFloat(cashflowData?.cash_in_bank_improvement) < 0 ? <Fall /> : <Rise />} <span style={{color: parseFloat(cashflowData?.cash_in_bank_improvement) < 0 ? "#99161680" : '#2E9A53', fontWeight: 'bold'}}>{convertToAbsolutePercentage(cashflowData?.cash_in_bank_improvement)}</span> {dataRangeAvgMode}</p> */}
                    </div>
                  </div>
                  <div style={{margin: '3rem 0 0 0', opacity: !isDataAvailable(cashflowData?.cash_in_bank_chart) ? 0 : 1}} className={classNames.chartContainer}>
                    <Bar data={updatedChartData.cash_in_bank} options={{
                        maintainAspectRatio: false, // Set to false to allow custom height
                        responsive: true, // Set to true for responsiveness
                        height: 200, // Set the desired height
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                            ticks: {
                              font: {
                                size: 16,
                              },
                            },
                          },
                          y: {
                            grid: {
                              display: true,
                            },
                            ticks: {
                              font: {
                                size: 16,
                              },
                              autoSkip: false,
                              maxTicksLimit: 8,
                              // stepSize: 4000
                              callback: function (value, index, values) {
                                return `$${value.toLocaleString()}`;
                              },
                            },
                          }
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            enabled: true,
                          },
                        },
                      }
                    } />
                  </div>

                </div>

              </div>

              {/* Fourth card - Top expenses */}
              {/* <div className={classNames.card} style={{ position: 'relative'}}>
                {getBusinessOverview.isLoading && (
                  <div className={classNames.noDataOverlay} style={{fontSize: '1vw', alignItems: 'center', paddingBottom: '15px', filter: 'blur(2px)', background: '#ffffffbf', zIndex: '1000'}}>
                    <Spin />
                  </div>
                )}
                {!isDataAvailable(cashflowData?.credit_spends_chart) && (
                  <div className={classNames.noDataOverlay}>
                    No data available yet! <br />
                    We're currently reconciling your books. <br />
                    The data will be displayed here soon.
                  </div>
                )}
                <div style={{width: '100%', height: '100%'}}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <div className={classNames.cardText}>
                      <h1 style={{marginBottom: '5px', fontSize: '1rem'}}>
                      Top Expenses (YTD)
                        <Tooltip title="Top 5 Expenses YTD: The five highest costs incurred year-to-date. All other expenses have been grouped under Miscellaneous.">
                          <InfoCircleOutlined style={{color: '#1D1C1C80', fontSize: '0.8rem', marginLeft: '10px'}} />
                        </Tooltip>
                      </h1>
                      <p style={{color: '#1D1C1C80', fontSize: '0.75rem', opacity: !isDataAvailable(cashflowData?.credit_spends_chart) ? 0 : 1}}>As of bookkeeping on {cashflowData?.cash_in_bank_chart_last_updated ? dayjs(cashflowData.cash_in_bank_chart_last_updated.split(' ')[0]).format('DD MMMM, YYYY')  : ''}</p>
                    </div>
                  </div>

                  <div style={{ margin: '3rem 0 0 0', opacity: !isDataAvailable(cashflowData?.credit_spends_chart) ? 0 : 1 }} className={classNames.tableContainer}>
                    <Table dataSource={expenseCategories} columns={columns} pagination={false} />
                  </div>

                </div>

              </div> */}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default Insights;
