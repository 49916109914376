import endpoints from "../../../endpoints";

export default async function getListOfContractors({business_id, form_type}){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = `${endpoints.SERVICES.ACCOUNTANTS_DASHBOARD.CONTRACTORS.GET_LIST_OF_CONTRACTORS[env]}${business_id}${form_type ? `?form_type=${form_type}` : ""}`;
    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
