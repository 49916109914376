import React, { useState, useEffect } from 'react';
import { Radio, Upload, Button, Spin, Row, Col, Tooltip, Form } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import classNames from './UploadTaxReturns.module.scss'; 
import { CTAButton, Message } from '../../../../common';
import { useMutation } from 'react-query';
import api from '../../../../API';
import { openDB } from 'idb'
import { useBusiness } from '../../../../../store/StoreProvider';
import taxFillingDataCollection from '../../../../API/Services/Onboarding/Business/taxFillingDataCollection.api';
import { uploadTaxFile } from '../../../../API/Services';

const UploadTaxReturns = ({ setStatus, setTaxOnboarded }) => {
  const { selectedBusiness } = useBusiness();
  const [form] = Form.useForm();
  const [taxfileList, setTaxFileList] = useState([]);
  const [extensionFileList, setExtensionFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taxFiled, setTaxFiled] = useState(null);

  const saveFileToIndexedDB = async (file, fileType) => {
    const db = await openDB('file-storage', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files');
        }
      },
    });
  
    // Save the file object
    await db.put('files', file, fileType);
  };

  const getFileFromIndexedDB = async (fileType) => {
    const db = await openDB('file-storage', 1);
    const file = await db.get('files', fileType);
    return file;
  };

  // Load form data from localStorage when the component mounts
  useEffect(() => {

    const fetchSavedData = async () => {
      const savedData = JSON.parse(localStorage.getItem('uploadTaxReturnsFormData'));
  
      if (savedData) {
        form.setFieldsValue(savedData);
        setTaxFiled(savedData.taxFiled);
        
        // Fetch the file from IndexedDB
        const extensionFile = await getFileFromIndexedDB('extensionFile');
        if (extensionFile) {
          setExtensionFileList([{ 
            uid: extensionFile.uid, 
            name: extensionFile.name, 
            originFileObj: extensionFile, 
            status: 'done' 
          }]);
        } else {
          setExtensionFileList(savedData.extensionFileList || []);
        }

        const taxFile = await getFileFromIndexedDB('taxFile');
        if (taxFile) {
          setTaxFileList([{ 
            uid: taxFile.uid, 
            name: taxFile.name, 
            originFileObj: taxFile, 
            status: 'done' 
          }]);
        } else {
          setTaxFileList(savedData.taxfileList || []);
        }
      }
    };
  
    fetchSavedData();
  }, [form]);  

  const updateTaxOnboardingStatus = useMutation(data => api.Services.Dashboard.TaxFilling.updateTaxOnboardingStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }
      if (data && data.data) {
        console.log("Registration successful", data);
        setStatus("UPLOAD_EXTENSION_FORM");
        if (data.data[0].tax_filling_status === "UPLOAD_EXTENSION_FORM") {
          setTaxOnboarded("UPLOAD_EXTENSION_FORM");
          Message({ type: "success", content: "Tax onboarding completed successfully!" });
        }
      }
    },
    onError: (error) => {
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  const handleTaxFileUpload = async  (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Allow only one file to be uploaded
    setTaxFileList(fileList);
    
    // Save the file to IndexedDB
    if (fileList[0] && fileList[0].originFileObj) {
      await saveFileToIndexedDB(fileList[0].originFileObj, "taxFile");
    }

    // Store other data in localStorage (if required)
    localStorage.setItem(
      'uploadTaxReturnsFormData',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('uploadTaxReturnsFormData')),
        taxfileList: fileList.map(file => ({ name: file.name, uid: file.uid })), // Save file metadata
      })
    );
  };

  const handleExtensionFileUpload = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Allow only one file to be uploaded
    setExtensionFileList(fileList);

    // localStorage.setItem('uploadTaxReturnsFormData', JSON.stringify({ ...JSON.parse(localStorage.getItem('uploadTaxReturnsFormData')), extensionFileList: fileList }));
  
    // Save the file to IndexedDB
    if (fileList[0] && fileList[0].originFileObj) {
      await saveFileToIndexedDB(fileList[0].originFileObj, "extensionFile");
    }

    // Store other data in localStorage (if required)
    localStorage.setItem(
      'uploadTaxReturnsFormData',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('uploadTaxReturnsFormData')),
        extensionFileList: fileList.map(file => ({ name: file.name, uid: file.uid })), // Save file metadata
      })
    );
  };

  return (
    <Form form={form}>
      <div className={classNames.profileComponent}>
        {loading ? (
          <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <h3 style={{ fontWeight: 'bold', margin: '0 0 1.5rem 0', fontSize: '0.95rem' }}>We are thoroughly checking previous tax return and business details.</h3>
            <Spin style={{margin: '2rem 0 5rem 0'}} size='large' />
          </div>
        ) : (
          <>
            <Form.Item>
              <h3 style={{ fontWeight: 'bold', margin: '0 0 1.5rem 0', fontSize: '0.95rem' }}>Have you filed taxes for your business before?</h3>
              <Radio.Group
                onChange={(e) => {
                  setTaxFiled(e.target.value);
                  form.setFieldsValue({ taxFiled: e.target.value });
                  localStorage.setItem('uploadTaxReturnsFormData', JSON.stringify({ ...JSON.parse(localStorage.getItem('uploadTaxReturnsFormData')), taxFiled: e.target.value }));
                }}
                value={taxFiled}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            
            {taxFiled && (
              <>
                <div className={classNames.uploadContainer}>
                  <Row className={classNames.uploadRow} gutter={[16, 16]}>
                    <Col xs={24} md={12} className={classNames.uploadColumn}>
                      <span className={classNames.uploadLabel}>
                        Previous Year’s Tax Returns 
                        <Tooltip title="Please upload your complete tax return from the previous year.">
                          <InfoCircleOutlined className={classNames.infoIcon} />
                        </Tooltip>
                      </span>
                    </Col>
                    <Col className={classNames.uploadButtonColumn}>
                      <Upload
                        fileList={taxfileList}
                        onChange={handleTaxFileUpload}
                        beforeUpload={() => false}
                      >
                        <Button icon={<UploadOutlined />} style={{ color: 'black', borderColor: 'black !important' }}>Upload</Button>
                      </Upload>
                    </Col>
                  </Row>

                  <Row className={classNames.uploadRow} gutter={[16, 16]}>
                    <Col xs={24} md={12} className={classNames.uploadColumn}>
                      <span className={classNames.uploadLabel}>
                        Current Year Extension Form (if applicable) 
                        <Tooltip title="If you've requested extra time to file your current year's taxes, please upload your extension form here to avoid late-filing penalties.">
                          <InfoCircleOutlined className={classNames.infoIcon} />
                        </Tooltip>
                      </span>
                    </Col>
                    <Col className={classNames.uploadButtonColumn}>
                      <Upload
                        fileList={extensionFileList}
                        onChange={handleExtensionFileUpload}
                        beforeUpload={() => false}
                      >
                        <Button icon={<UploadOutlined />} style={{ color: 'black', borderColor: 'black !important' }}>Upload</Button>
                      </Upload>
                    </Col>
                  </Row>
                </div>
                <p className={classNames.uploadInstruction}>
                  Uploaded documents will be available in the Documents Vault. If you don't have any of these documents, you can click “Next”.
                </p>
              </>
            )}

            <CTAButton
              loading={updateTaxOnboardingStatus.isLoading || loading}
              onClick={async () => {
                setLoading(true);
                let isDocumentUploaded = false; 
              
                try {
                  // Handle tax file upload if exists
                  if (taxfileList[0]) {
                    const taxFileResponse = await taxFillingDataCollection({
                      file: taxfileList[0].originFileObj ? taxfileList[0].originFileObj : taxfileList[0],
                      business_id: selectedBusiness.business_id,
                      key: 'tax-returns'
                    });
                  
                    console.log('upload response', taxFileResponse);
                  
                    if (taxFileResponse.status) {
                      isDocumentUploaded = true;
                    }
                  }
                
                  // Handle extension file upload if exists
                  if (extensionFileList[0]) {
                    const extensionFileResponse = await uploadTaxFile({
                      file: extensionFileList[0].originFileObj ? extensionFileList[0].originFileObj : extensionFileList[0],
                      business_id: selectedBusiness.business_id,
                      key: 'extension-form'
                    });
                  
                    if (extensionFileResponse.status) {
                      isDocumentUploaded = true;
                    }
                  }
                
                  // Only update the onboarding status if at least one document was uploaded successfully
                  if (isDocumentUploaded) {
                    await updateTaxOnboardingStatus.mutateAsync({
                      business_id: selectedBusiness.business_id,
                      body: {
                        tax_filling_status: "TAX_ONBOARDING_COMPLETE",
                        meta_data: { tax_documents_uploaded: true }
                      }
                    });
                    // localStorage.removeItem('uploadTaxReturnsFormData'); // Clear localStorage on successful submit
                  } else {
                    // If no document was uploaded, skip to the next step
                    setStatus("UPLOAD_EXTENSION_FORM");
                    setTaxOnboarded("UPLOAD_EXTENSION_FORM");
                  }
                } catch (error) {
                  console.error('Error occurred during file uploads or status update:', error);
                } finally {
                  setLoading(false);
                }
              }}
              style={{ marginTop: "4rem" }}
            >
              Next
            </CTAButton>
          </>
        )}
      </div>
    </Form>
  );
};

export default UploadTaxReturns;
