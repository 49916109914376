import { useEffect, useState, useRef } from "react";
import { Form, Button, Divider, Tooltip } from "antd";
import {
  Input,
  Title,
  DatePicker,
  Select,
  CountryPicker,
  Message,
} from "../../common";
import moment from "moment";
import { usStateList } from "../../util";
import { validation } from "../config";
import { MAX_MIN } from "../../util/constants";
import REGEX from "../../util/constants/regex";
import { FORMATS } from "../../constants";
import classNames from "../Signup/Signup.module.scss";

export default function BeneficialOwnerFormList({
  initialValues,
  form,
  availableEquity,
  setAvailableEquity,
}) {
  //Hooks
  const [, setCountrySelected] = useState(null);
  const [isInvalidEquity, setIsInvalidEquity] = useState(false);

  const [maxEquity, ] = useState(availableEquity);
  const hasAddedInitialForm = useRef(false);

  useEffect(() => {
    initialValues?.forEach((item) => {
      setCountrySelected(item.beneficialOwnerCountry);
    });
    calculateTotalEquity(initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  //Event handlers
  const handlePercentageChange = () => {
    const inputData = form.getFieldsValue();
    calculateTotalEquity(inputData?.beneficialOwners);
  };

  //Helper
  const calculateTotalEquity = (arrayName = []) => {
    const totalEquity = arrayName?.reduce(function (
      previousValue,
      currentValue
    ) {
      return (
        previousValue + Number(currentValue?.beneficialOwnerPercentage || 0)
      );
    },
    0);

    setAvailableEquity(maxEquity - Number(totalEquity || 0));

    if (maxEquity > 100) {
      setIsInvalidEquity(true);
    } else {
      setIsInvalidEquity(false);
    }
  };

  return (
    <Form.List
      name="beneficialOwners"
      rules={[{ required: true }]}
      initialValues={{
        beneficialOwnerCountry: 'US',
      }}
    >
      {(fields, { add, remove }, { errors }) => {
        // Check if the form has been added, if not, add it
        if (!hasAddedInitialForm.current && !fields.length) {
            add();
            hasAddedInitialForm.current = true;
        }
        return (
        <>
          {fields?.map(({ key, name, fieldKey, ...restField }) => {
            return (
              <Form.Item key={key}>
                <Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerFirstName"]}
                    label={
                      <Title as="h4" className={classNames.label}>
                        First Name <sup style={{ top: 1 }}>*</sup>
                      </Title>
                    }
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginBottom: "10px",
                      float: "left",
                    }}
                    rules={[
                      {
                        required: true,
                        message: validation.firstName.required,
                      },
                      {
                        max: MAX_MIN.name.max,
                        message: validation.firstName.maxLength,
                      },
                      {
                        pattern: new RegExp(REGEX.name),
                        message: validation.firstName.valid,
                      },
                    ]}
                  >
                    <Input placeholder={validation.firstName.placeholder} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerLastName"]}
                    fieldKey={[fieldKey, "beneficialOwnerLastName"]}
                    label={
                      <Title as="h4" className={classNames.label}>
                        Last Name <sup style={{ top: 1 }}>*</sup>
                      </Title>
                    }
                    rules={[
                      { required: true, message: validation.lastName.required },
                      {
                        max: MAX_MIN.name.max,
                        message: validation.lastName.maxLength,
                      },
                      {
                        pattern: new RegExp(REGEX.name),
                        message: validation.lastName.valid,
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px",
                      float: "right",
                    }}
                  >
                    <Input placeholder={validation.lastName.placeholder} />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  name={[name, "ssnInput"]}
                  fieldKey={[fieldKey, "ssnInput"]}
                  label={
                    <Title as="h4" className={classNames.label}>
                      SSN / ITIN <sup style={{ top: 1 }}>*</sup>{" "}
                    </Title>
                  }
                  rules={[
                    {
                      required: true,
                      message: validation.ssn.required,
                    },
                    {
                      pattern: new RegExp(REGEX.einNumber),
                      message: validation.ssn.valid,
                    },
                  ]}
                >
                  <Input placeholder={validation.ssn.placeholder} />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "beneficialOwnerNationality"]}
                  fieldKey={[fieldKey, "beneficialOwnerNationality"]}
                  label={
                    <Title as="h4" className={classNames.label}>
                      Nationality <sup style={{ top: 1 }}>*</sup>
                    </Title>
                  }
                  rules={[
                    {
                      required: true,
                      message: validation.nationality.required,
                    },
                  ]}
                >
                  <CountryPicker countries="all" />
                </Form.Item>

                <Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerDateOfBirth"]}
                    fieldKey={[fieldKey, "beneficialOwnerDateOfBirth"]}
                    label={
                      <Title as="h4" className={classNames.label}>
                        Date of Birth <sup style={{ top: 1 }}>*</sup>
                      </Title>
                    }
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginBottom: "10px",
                      float: "left",
                    }}
                    rules={[
                      {
                        required: true,
                        message: validation.dateOfBirth.required,
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={(date) =>
                        !date ||
                        date?.isAfter(
                          moment().format(FORMATS.DATEPICKER_FORMAT)
                        )
                      }
                      placeholder={validation.dateOfBirth.placeholder}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerEmail"]}
                    fieldKey={[fieldKey, "beneficialOwnerEmail"]}
                    label={
                      <Title as="h4" className={classNames.label}>
                        Email Address <sup style={{ top: 1 }}>*</sup>
                      </Title>
                    }
                    rules={[
                      { required: true, message: validation.email.required },
                      {
                        type: "email",
                        message: validation.email.valid,
                      },
                      {
                        min: MAX_MIN.email.min,
                        message: validation.email.minLength,
                      },
                      {
                        max: MAX_MIN.email.max,
                        message: validation.email.maxLength,
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px",
                      float: "right",
                    }}
                  >
                    <Input placeholder={validation.email.placeholder} />
                  </Form.Item>
                </Form.Item>

                {/* TODO: use <AddressForm/> with modifications */}
                <Form.Item>
                  <Title as="h4" className={classNames.label}>
                    Address <sup style={{ top: 1 }}>*</sup>
                  </Title>

                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerStreet"]}
                    fieldKey={[fieldKey, "beneficialOwnerStreet"]}
                    rules={[
                      {
                        required: true,
                        message: validation.street.required,
                      },
                      {
                        max: MAX_MIN.street.max,
                        message: validation.street.max,
                      },
                    ]}
                  >
                    <Input placeholder={validation.street.placeholder} />
                  </Form.Item>
                  
                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerStreet2"]}
                    fieldKey={[fieldKey, "beneficialOwnerStreet2"]}
                    rules={[
                      {
                        max: MAX_MIN.street.max,
                        message: validation.street2.max,
                      },
                    ]}
                  >
                    <Input placeholder={validation.street2.placeholder} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerCity"]}
                    fieldKey={[fieldKey, "beneficialOwnerCity"]}
                    rules={[
                      { required: true, message: validation.city.required },
                      { max: MAX_MIN.city.max, message: validation.city.max },
                    ]}
                  >
                    <Input placeholder={validation.city.placeholder} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    name={[name, "beneficialOwnerState"]}
                    fieldKey={[fieldKey, "beneficialOwnerState"]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      float: "left",
                    }}
                    rules={[
                      { required: true, message: validation.state.required },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={validation.state.placeholder}
                    >
                      {usStateList().map((value) => (
                        <Select.Option key={value.code} value={value.code}>
                          {value.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerPostalCode"]}
                    fieldKey={[fieldKey, "beneficialOwnerPostalCode"]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      float: "right",
                    }}
                    rules={[
                      {
                        required: true,
                        message: validation.postalCode.required,
                      },
                      {
                        pattern: new RegExp(REGEX.postalCode),
                        message: validation.postalCode.valid,
                      },
                    ]}
                  >
                    <Input placeholder={validation.postalCode.placeholder} />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "beneficialOwnerCountry"]}
                  fieldKey={[fieldKey, "beneficialOwnerCountry"]}
                  style={{
                    marginBottom: "10 px",
                  }}
                  rules={[
                    { required: true, message: validation.country.required },
                  ]}
                >
                  <CountryPicker
                    placeholder={validation.country.placeholder}
                    onChange={(code) => {
                      setCountrySelected(code);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "beneficialOwnerNumber"]}
                  fieldKey={[fieldKey, "beneficialOwnerNumber"]}
                  label={
                    <Title as="h4" className={classNames.label}>
                      Phone Number <sup style={{ top: 1 }}>*</sup>
                    </Title>
                  }
                  rules={[
                    {
                      required: true,
                      message: validation.phoneNumber.required,
                    },
                    {
                      pattern: new RegExp(REGEX.phoneNumber),
                      message: validation.phoneNumber.valid,
                    },
                  ]}
                >
                  <Input
                    placeholder={validation.phoneNumber.placeholder}
                    prefix="+1"
                  />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[name, "beneficialOwnerCountryCode"]}
                  fieldKey={[fieldKey, "beneficialOwnerCountryCode"]}
                  initialValue="1"
                >
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerPercentage"]}
                    fieldKey={[fieldKey, "beneficialOwnerPercentage"]}
                    label={
                      <Title as="h4" className={classNames.label}>
                        Percentage <sup style={{ top: 1 }}>*</sup>
                      </Title>
                    }
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 4px)",
                      margin: "0 0 0 4px",
                      float: "left",
                    }}
                    rules={[
                      {
                        required: true,
                        message: validation.percentage.required,
                      },
                      {
                        pattern: new RegExp(REGEX.percentage),
                        message: validation.percentage.valid,
                      },
                      {
                        max: MAX_MIN.percentage.max,
                        message: validation.percentage.max,
                      }
                    ]}
                    extra={
                      <>
                        <div style={{ color: "#ff4d4f", fontSize: "14px" }}>
                          {availableEquity < 0 ? validation.percentage.max : ""}
                        </div>
                        <sup
                          style={{
                            top: "5px",
                            color:
                              availableEquity < 0 ? "#ff0000" : "#00000073",
                          }}
                        >
                          {`Available Equity : ${parseInt(availableEquity)}`}
                        </sup>
                      </>
                    }
                  >
                    <Input
                      placeholder={validation.percentage.placeholder}
                      onChange={() => handlePercentageChange()}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "beneficialOwnerTitle"]}
                    fieldKey={[fieldKey, "beneficialOwnerTitle"]}
                    label={
                      <Title as="h4" className={classNames.label}>
                        Title <sup style={{ top: 1 }}>*</sup>
                      </Title>
                    }
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      marginBottom: "10px",
                      float: "right",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Title is required",
                      },
                      // Add any other validation rules if needed
                    ]}
                  >
                    <Select placeholder="Select title">
                      <Select.Option value="OFFICER">Officer</Select.Option>
                      <Select.Option value="DIRECTOR">Director</Select.Option>
                      <Select.Option value="FOUNDER">Founder</Select.Option>
                    </Select>
                  </Form.Item>

                </Form.Item>

                {key !== 0 && (
                  <Form.Item>
                    <Button
                      onClick={(e) => {
                        remove(name);
                        handlePercentageChange();
                      }}
                      className={classNames.removeButton}
                    >
                      Remove
                    </Button>
                  </Form.Item>
                )}
                <Divider />
              </Form.Item>
            );
          })}
          <Form.Item>
            <Form.Item>
              <Tooltip className={classNames.customDisabledButton} style={{width: "100% !important"}} title={fields.length >= 4 ? "Only 4 beneficial owners allowed" : ""}>
                <Button
                  type="dashed"
                  style={{ height: "56px", width: "100%" }}
                  onClick={() => {
                    if (isInvalidEquity) {
                      Message({
                        type: "warning",
                        content: validation.percentage.errorMessage,
                      });
                    } else {
                      add();
                    }
                  }}
                  disabled={fields.length >= 4}
                >
                  Add another beneficial owner
                </Button>
              </Tooltip>
            </Form.Item>
          </Form.Item>
        </>
      )}}
    </Form.List>
  );
}
