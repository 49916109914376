import endpoints from "../../../endpoints";

// Function to create subscription
export default async function sendEmailNotificationChat(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    const url = endpoints.SERVICES.DASHBOARD.CHATKITTY.SEND_EMAIL_NOTIFICATION[env];
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(url, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    ).json();
}
