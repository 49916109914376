import { Auth } from "aws-amplify";

import ENDPOINT_KEYS from "./endpointKeys";
import ISOLATED from "./apiEndpoints.isolated";
import DEV from "./apiEndpoints.dev";
import TTEN from "./apiEndpoints.tten";
import PROD from "./apiEndpoints.prod";

export const getEndpoints = (environment) => {
  const endpoints = { DEV, TTEN, PROD, ISOLATED }[environment];
  const config = [
    {
      name: ENDPOINT_KEYS.bankLetter,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.bankLetter],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.achPayments,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.achPayments], // TODO: Remove the duplication with 'createPayment'
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.businessRelationship,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.businessRelationship], // TODO: Remove the duplication with 'getApplicationByTenantId'
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.businessBanking,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.businessBanking],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.statements,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.statements],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.transactions,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.transactions], // TODO: Remove the duplication with 'businessBanking'
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.payees,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.payees],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.createApplication,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.createApplication], // TODO: Remove the duplication with 'getApplicationByTenantId'
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.account,
      endpoint: endpoints[ENDPOINT_KEYS.account],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.externalAccount,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.externalAccount],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.checkDeposits,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.checkDeposits],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.schedulePayments,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.schedulePayments],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.getApplicationByTenantId,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.getApplicationByTenantId],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.disclosure,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.disclosure],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.kyckyb,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.kyckyb],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.status,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.status],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.webhook,
      region: "us-west-2",
      endpoint: endpoints[ENDPOINT_KEYS.webhook],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.otzcards,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.otzcards],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.notification,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.notification],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.sendMail,
      region: "us-west-2",
      endpoint: endpoints[ENDPOINT_KEYS.sendMail],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.rewards,
      endpoint: endpoints[ENDPOINT_KEYS.rewards],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.subscription,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.subscription],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.updateBusinessOperationDetails,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.updateBusinessOperationDetails],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.paLink,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.paLink],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.cardknoxSync,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.cardknoxSync],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },

    {
      name: ENDPOINT_KEYS.tenant,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.tenant],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.taxRate,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.taxRate],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.product,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.product],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.invoices,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.invoices],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.getOverallInvoiceDetails,
      // region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.getOverallInvoiceDetails],
      // custom_header: async () => {
      //   return {
      //     Authorization: `Bearer ${(await Auth.currentSession())
      //       .getIdToken()
      //       .getJwtToken()}`,
      //   };
      // },
    },
    {
      name: ENDPOINT_KEYS.cards,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.cards],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.customPayment,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.customPayment],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS["general-info"],
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS["general-info"]],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS["temp-notifications"],
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS["temp-notifications"]],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.payments,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.payments], // TODO: Remove the duplication with 'createPayment'
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.notifications,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.notifications],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.authentication,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.authentication],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.subscriptionPlan,
      endpoint: endpoints[ENDPOINT_KEYS.subscriptionPlan],
    },
    {
      name: ENDPOINT_KEYS.subscriptionUser,
      endpoint: endpoints[ENDPOINT_KEYS.subscriptionUser], // TODO: Check whether path is correct. GET endpoint is defined under '/subscription-user' endpoint
    },
    {
      name: ENDPOINT_KEYS.cardknox,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.cardknox],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.paymentAcceptanceOnboard,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.paymentAcceptanceOnboard],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS.paymentAcceptanceTransaction,
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS.paymentAcceptanceTransaction],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS["pa-transaction"],
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS["pa-transaction"]], // TODO: Remove the duplication with 'paymentAcceptanceTransaction'
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS["pa-transaction-public"],
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS["pa-transaction-public"]], // TODO: Remove the duplication with 'paymentAcceptanceTransaction'
    },
    {
      name: ENDPOINT_KEYS["pa-settings"],
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS["pa-settings"]],
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: ENDPOINT_KEYS["pa-settings-public"],
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS["pa-settings-public"]],
    },
    {
      name: ENDPOINT_KEYS["cardknox-ifields"],
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS["cardknox-ifields"]],
    },
    {
      name: ENDPOINT_KEYS["subscription-public"], // TODO: This public endpoint should removed after fixing authorizer user pool issue
      region: process.env.REACT_APP_REGION,
      endpoint: endpoints[ENDPOINT_KEYS["subscription-public"]],
    },
  ];
  return config;
};

/**
 * Adding this here as authorizers are not supporting the file uploads at the moment.
 */
export const tempUrls = {
  UPLOAD_DOCUMENTS_FRONT: {
    TTEN: "https://v1.qaapi.otterz.co/applications/application/document-front",
    DEV: "https://1sh0crf01e.execute-api.us-west-2.amazonaws.com/prod/application/document-front",
    PROD: "https://v1.api.otterz.co/applications/application/document-front",
  },
  UPLOAD_DOCUMENTS_BACK: {
    TTEN: "https://v1.qaapi.otterz.co/applications/application/document-back",
    DEV: "https://1sh0crf01e.execute-api.us-west-2.amazonaws.com/prod/application/document-back",
    PROD: "https://v1.api.otterz.co/applications/application/document-back",
  },
  OTP_VERIFICATION: {
    TTEN: "https://apt.tten.app.otterz.co/api/v1/otpverification/verify",
    DEV: "http://dev.sync.app.otterz.co/api/v1/otpverification/verify",
    PROD: "http://dev.sync.app.otterz.co/api/v1/otpverification/verify",
    ISOLATED: "http://dev.sync.app.otterz.co/api/v1/otpverification/verify",
  },
  OTP_GENERATION: {
    TTEN: "https://apt.tten.app.otterz.co/api/v1/otpverification",
    DEV: "http://dev.sync.app.otterz.co/api/v1/otpverification",
    PROD: "https://apt.tten.app.otterz.co/api/v1/otpverification",
    ISOLATED: "http://dev.sync.app.otterz.co/api/v1/otpverification",
  },
  DOCV_INIT_KYC: {
    TTEN: "http://tten.sync.app.otterz.co/api/v1/kyckyb/docV/verify-kyc",
    DEV: "http://dev.sync.app.otterz.co/api/v1/kyckyb/docV/verify-kyc",
    PROD: "http://tten.sync.app.otterz.co/api/v1/kyckyb/docV/verify-kyc",
    ISOLATED: "http://dev.sync.app.otterz.co/api/v1/kyckyb/docV/verify-kyc",
  },
  DOCV_VERIFY_TOKEN: {
    TTEN: "http://tten.sync.app.otterz.co/api/v1/kyckyb/docV/token-validation",
    DEV: "http://dev.sync.app.otterz.co/api/v1/kyckyb/docV/token-validation",
    PROD: "http://tten.sync.app.otterz.co/api/v1/kyckyb/docV/token-validation",
    ISOLATED: "http://dev.sync.app.otterz.co/api/v1/kyckyb/docV/token-validation",
  },
  DOCV_SEND_LINK: {
    TTEN: "http://tten.sync.app.otterz.co/api/v1/kyckyb/docV/send-mail",
    DEV: "http://dev.sync.app.otterz.co/api/v1/kyckyb/docV/send-mail",
    PROD: "http://tten.sync.app.otterz.co/api/v1/kyckyb/docV/send-mail",
    ISOLATED: "http://dev.sync.app.otterz.co/api/v1/kyckyb/docV/send-mail",
  },
  PAYMENT_LINK_PRO: {
    TTEN: "https://buy.stripe.com/7sI9Bv4AF2db7tKdQR",
    DEV: "https://buy.stripe.com/test_eVa6rMaDp9nvc1ifZc",
    PROD: "https://buy.stripe.com/7sI9Bv4AF2db7tKdQR",
    ISOLATED: "https://buy.stripe.com/test_eVa6rMaDp9nvc1ifZc",
  },
  PAYMENT_LINK_ELITE: {
    TTEN: "https://buy.stripe.com/14k5lf0kp5pn9BS000",
    DEV: "https://buy.stripe.com/test_6oE5nIbHt1V3ghy14j",
    PROD: "https://buy.stripe.com/14k5lf0kp5pn9BS000",
    ISOLATED: "https://buy.stripe.com/test_6oE5nIbHt1V3ghy14j",
  },
  PAYMENT_LINK_ENTERPRISE: {
    TTEN: "https://buy.stripe.com/14k5lf0kp5pn9BS000",
    DEV: "https://buy.stripe.com/test_14k6rM4f1dDLd5mdR6",
    PROD: "https://buy.stripe.com/14k5lf0kp5pn9BS000",
    ISOLATED: "https://buy.stripe.com/test_14k6rM4f1dDLd5mdR6",
  },
  PAYMENT_LINK_PRO_ANNUAL: {
    TTEN: "",
    DEV: "https://buy.stripe.com/test_3cs2bwdPB1V3d5maEY",
    PROD: "",
    ISOLATED: "https://buy.stripe.com/test_3cs2bwdPB1V3d5maEY",
  },
  PAYMENT_LINK_ELITE_ANNUAL: {
    TTEN: "",
    DEV: "https://buy.stripe.com/test_fZe6rM9zl0QZ9TadRb",
    PROD: "",
    ISOLATED: "https://buy.stripe.com/test_fZe6rM9zl0QZ9TadRb",
  },
  PAYMENT_LINK_ENTERPRISE_ANNUAL: {
    TTEN: "",
    DEV: "https://buy.stripe.com/test_7sI3fA4f1arz0iA7sO",
    PROD: "",
    ISOLATED: "https://buy.stripe.com/test_7sI3fA4f1arz0iA7sO",
  },
  GET_SYNC_QB: {
    TTEN: "http://tten.sync.app.otterz.co/api/v1/ap/invoice/quickbook-sync/status",
    DEV: "http://dev.sync.app.otterz.co/api/v1/ap/invoice/quickbook-sync/status",
    PROD: "http://tten.sync.app.otterz.co/api/v1/ap/invoice/quickbook-sync/status",
    ISOLATED: "http://dev.sync.app.otterz.co/api/v1/ap/invoice/quickbook-sync/status",
  },
  INIT_SYNC_QB: {
    TTEN: "http://tten.sync.app.otterz.co/api/v1/ap/invoice/quickbook-sync/init",
    DEV: "http://dev.sync.app.otterz.co/api/v1/ap/invoice/quickbook-sync/init",
    PROD: "http://tten.sync.app.otterz.co/api/v1/ap/invoice/quickbook-sync/init",
    ISOLATED: "http://dev.sync.app.otterz.co/api/v1/ap/invoice/quickbook-sync/init",
  },
};
