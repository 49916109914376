import endpoints from "../../../endpoints";

export const sendTaxQueryMessage = async ({ business_id, vendor_id, messageData }) => {
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = `${endpoints.SERVICES.DASHBOARD.TAX_FILLING.SEND_TAX_QUERY_MESSAGE[env]}${business_id}/${vendor_id}`;
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(url, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(messageData)
        })
    ).json();
}
