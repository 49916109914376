import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Message } from "../../common";
import useLanguage from "../../../hooks/useLanguage";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import { messages } from "../util/messages.const";
import {Subscription} from "../../Auth/Subscription"
// import mixpanel from "mixpanel-browser";
import { useBusiness } from "../../../store/StoreProvider";
import { Spin } from "antd";

export default function PlansAndPreferences() {
  //Hooks
  const language = useLanguage(messages);
  const { selectedBusiness } = useBusiness();
  const [principlePlanId, ] = useState(null);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    useState(null);
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false)
  const [isTimerActive, setIsTimerActive] = useState(true);

  //Api calls
  const fetchSubscription = async () => {
    const response = await api.Services.Dashboard.Subscription.getSubscribedPlanDetails(selectedBusiness.business_id)
    console.log("Subscribed Plan", response);
    if (response && response.status && response.data.subscription_type) {
      // setSelectedSubscriptionPlan(response);

      if (response && response.status && response.data.status === 'complete' && response.data.webhook_processed) {
        setSelectedSubscriptionPlan(response);
        setIsLoading(false);
        setIsTimerActive(false);
      } else {
        setIsLoading(false);
      }
    } else {
      setSelectedSubscriptionPlan(null);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (selectedBusiness.business_id && isTimerActive) {
        fetchSubscription();
      }
    }, 3000); // Fetch subscription status every 5 seconds

    return () => clearInterval(timer); // Clear the interval when component unmounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness, isTimerActive]);

  const subscription = useMutation(
    (reqBody) => api.Services.Dashboard.Subscription.upsertSubscription(reqBody),
    {
      enabled: !!principlePlanId,
    }
  );
  const getTaxFilling = useMutation(
    (reqBody) => api.Services.Dashboard.Subscription.ctfAddons(reqBody),
    {
      enabled: !!principlePlanId,
    }
  );

  const onSubmit = async (reqBody) => {
    if (selectedSubscriptionPlan === null || selectedSubscriptionPlan === undefined || selectedSubscriptionPlan?.data?.status !== 'complete') {
      setChangeLoading(true)
      console.log("body", reqBody);
      const response = await api.Services.Dashboard.Subscription.createSubscription(reqBody)
      response.data.checkout_url && window.open(response.data.checkout_url);
      setChangeLoading(false)
    } else {
      setChangeLoading(true)
      console.log("body", reqBody);
      subscription.mutate(
        reqBody,
        {
          onSuccess: (resp) => {
            console.log("Response", resp);
            console.log("Selected Plan", selectedSubscriptionPlan);
            if (resp && resp.status) {
              console.log("Response", resp);

              Message({
                type: "success",
                content: resp?.data?.message || language.SUBSCRIPTION_ASSIGNMENT_SUCCESS,
              });
            }
            setSelectedSubscriptionPlan(undefined);
            fetchSubscription()
            setChangeLoading(false)
          },
          onError: (error) => {
            setChangeLoading(false)
            generateErrorMessage(error);
            queryClient.invalidateQueries("refreshSubscription");
          },
        }
      );
    }
  };

  const selectTaxFiling = (reqBody) => {
    getTaxFilling.mutate(
      reqBody,
      {
        onSuccess: (resp) => {
          console.log("Response", resp);
          console.log("Selected Plan", selectedSubscriptionPlan);
          if (resp && resp.status) {
            console.log("Response", resp);
            resp.data.checkout_url && window.open(resp.data.checkout_url);
          } else {
            Message({ type: "error", content: resp?.errors[0].reason });
            console.log("Error", resp?.errors[0].message);
          }
          // setSelectedSubscriptionPlan(undefined);
          queryClient.invalidateQueries("refreshSubscription");
        },
        onError: (error) => {
          console.log("Error", error);
          generateErrorMessage(error);
          queryClient.invalidateQueries("refreshSubscription");
        },
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Spin size="large" />
        </div>
      ) : (
        <Subscription onSubmit={onSubmit} selectTaxFiling={selectTaxFiling} loading={changeLoading} selectedSubscriptionPlan={selectedSubscriptionPlan} />
      )}
    </>
  )
}
