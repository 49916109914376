import { Auth } from 'aws-amplify';
import endpoints from '../../endpoints';

export default async function patchPayee(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    return await(
        await fetch(endpoints.BUSINESS_BANKING.PATCH_PAYEE[env] + data.payee_id, {
            method: "PATCH",
            headers: {
                'Authorization': `Bearer ${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify(data.updatedPayeeData),
        })
    ).json();
}
