import moment from "moment";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Form, Button, Divider, Checkbox } from "antd";
import {
  CTAButton,
  Input,
  Title,
  DatePicker,
  Select,
  CountryPicker,
  AddressForm,
} from "../../common";
import Heading from "../Heading";
import Steps from "../Steps";
// import AccountStatus from "../VerifyAccount/VerifyAccount";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { AppContext } from "../../../context/AppState";
import api from "../../API";
import { businessOfficerTitle } from "../../util";
import generateErrorMessage from "../../util/functions/customError";
import { useLocalStorageData } from "../../../hooks/useLocalStorage";
import { FORMATS, ROUTES/*, ROUTE_STATUS, USER_STATES*/ } from "../../constants";
import { validation } from "../config";
import { MAX_MIN } from "../../util/constants";
import REGEX from "../../util/constants/regex";
import classNames from "../Signup/Signup.module.scss";
import mixpanel from "mixpanel-browser";

export default function BAOfficer() {
  //Hooks
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [progress, setProgress] = useLocalStorage("OTTERZ_op_od");
  const businessData = useLocalStorageData("OTTERZ_op_bd");
  const { userData } = useContext(AppContext);
  const [countrySelected, setCountrySelected] = useState(null);
  // const [visible, setVisible] = useState(false);
  const [radioValue, setRadioValue] = useState({ 0: "No" });
  const [boData, setBOData] = useState([]);
  const [officerList] = useState([]);
  const [officerIDList, setOfficerIDList] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [selectedBeneficialOwner, setSelectedBeneficialOwner] = useState(null);

  const [isDisclosureAccepted, setIsDisclosureAccepted] = useState(false);

  try {
    mixpanel.identify(userData?.email);

    // Set user properties
    mixpanel.people.set({
      $email: userData?.email,
    });
  } catch (error) {
    console.error("error: ", error);
  }

  useEffect(() => {
    setCountrySelected(progress.officerCountry);
  }, [progress]);

  //Api calls
  const createOfficerBusiness = useMutation(
    (data) => {
      return api.onBoarding.createApplication(data);
    },
    {
      onSuccess: (res) => {
        const officerCount = Number(localStorage.getItem("officerCount") || 0);

        officerList?.push(res?.payload?.otz_person_id);
        localStorage.setItem("officerCount", Number(officerCount) + 1);

        setProgress((prevVal) => {
          return {
            ...prevVal,
            relationship: true,
          };
        });

        if (
          Number(localStorage.getItem("officerCount")) ===
          progress?.officers?.length
        ) {
          localStorage.removeItem("officerCount");

          callManageOwnerRelationship();
          updateApplication.mutate(
            {
              onBoardingStatus: "OFFICER_CREATED",
            },
            {
              onSuccess: () => {
                // setVisible(true);
                mixpanel.people.set({
                  "Sign Up": "Managing person listed",
                });
                mixpanel.track("Officer - Completed", {
                  "Email ID": userData?.email,
                  "Sign Up Date": new Date().toISOString(),
                  "Business name": businessData?.name,
                  "Company_ID": businessData?.id,
                  "Officer added?": Object.keys(progress).length !== 0,
                });
                navigate(ROUTES.ONBOARDING.ONBOARDING_DOCUMENT_UPLOAD)
              },
              onError: (err) => {
                throw new Error(err);
              },
            }
          );
        }
      },
      onError: (error) => {
        generateErrorMessage(error);
      },
    }
  );

  const updatePersonApplication = useMutation((reqBody) => {
    return api.onBoarding.updateApplication(reqBody, false);
  });

  const updateOfficerBusiness = useMutation(
    (data) => {
      return api.onBoarding.updateOfficerBusinessRelationship(
        data,
        progress?.officerId
      );
    },
    {
      onSuccess: () => {
        // setVisible(true);
      },
      onError: (error) => {
        generateErrorMessage(error);
      },
    }
  );

  const officerBusinessRelationship = useMutation((data) => {
    return api.onBoarding.createOfficerBusinessRelationship(data);
  });

  const beneficialOwnerDetailMutation = useMutation((id) => {
    return api.onBoarding.getBusinessPersonDetail({ otz_to_business_id: id });
  });

  const updateApplication = useMutation((data) => {
    return api.onBoarding.updateApplicationByTenantId(data);
  });

  const [isLoadingBOData, setIsLoadingBOData] = useState(true);
  const { data: application } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      onSuccess: (data) => {
        if (boData.length === 0) {
          beneficialOwnerDetailMutation.mutate(data?.payload?.otz_business_id, {
            onSuccess: (res) => {
              setBOData(res?.payload);
              setIsLoadingBOData(false);
              console.log("res: ", res, boData);
            },
          });
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  /* 
  useQuery(
    "getBusinessPersonDetail",
    () =>
      api.onBoarding.getBusinessPersonDetail({
        otz_to_business_id: businessID,
      }),
    {
      onSuccess: (data) => {
        setBOData(data?.payload);
      },
      refetchOnWindowFocus: true,
    }
  ); */

  const getSSN = (ssnNumber) => {
    return (
      ssnNumber?.slice(0, 3) +
      "-" +
      ssnNumber?.slice(3, 5) +
      "-" +
      ssnNumber?.slice(5)
    );
  };

  const addOfficers = async (data) => {
    return await Promise.all(
      data.forEach((element, index) => {
        setOfficerIDList((el) => {
          return [...el, element?.officerTitle];
        });
        if (radioValue[index] === "No") {
          let reqData = {
            tenant_id: userData?.tenantId,
            ssn: getSSN(element?.ssnInput),
            dob: moment(element?.officerDOB).format(FORMATS.API_SEND_FORMAT),
            email: element?.officerEmail,
            first_name: element?.officerFirstName,
            last_name: element?.officerLastName,
            state: element?.state,
            address_line_1: element?.street,
            address_line_2: element?.street2 ? element?.street2 : "",
            city: element?.city,
            country_code: element?.country,
            postal_code: element?.postalCode,
            phone_number: `+1${element?.officerNumber}`,
            nationality: element?.officerNationality,
            is_customer: true,
          };

          if (!progress?.officerId) {
            createOfficerBusiness.mutate(reqData, {
              onSuccess: (res) => {
                setProgress((prevVal) => {
                  return {
                    ...prevVal,
                  };
                });
              },
              onError: (error) => {
                generateErrorMessage(error);
              },
            });
          } else if (progress?.officerId && form.isFieldsTouched()) {
            const reqBody = {
              data: reqData,
              id: progress?.officerId,
            };
            updatePersonApplication.mutate(reqBody, {
              onSuccess: () => {
                updateManageOwnerRelationship(
                  progress?.officerId,
                  element?.officerTitle
                );
              },
              onError: (error) => {
                generateErrorMessage(error);
              },
            });
          } else {
            handleSave();
          }
        } else {
          if (element?.officerId) {
            setProgress({
              officerId: element?.officerId,
            });
            updateManageOwnerRelationship(
              element?.officerId,
              element?.officerTitle
            );
          }
        }
      })
    );
  };

  //Event handlers
  function handleOnFinish() {
    let inputData = form.getFieldsValue();

    if (selectedBeneficialOwner) {
      // Create a relationship with the selected beneficial owner
      const reqPayload = {
        to_business_id: application?.payload?.otz_business_id,
        from_person_id: selectedBeneficialOwner,
        title: inputData.officers[0].officerTitle, // Assuming title is in the officers field
      };
  
      // Call the API to create the relationship
      officerBusinessRelationship.mutate(reqPayload, {
        onSuccess: (data) => {
          handleSave()
        },
        onError: (error) => {
          generateErrorMessage(error);
        },
      });
    } else {
      setProgress({
        ...inputData,
      });
  
      addOfficers(inputData?.officers).then(() => {});
    }

    /* if (radioValue === "No") {
        let reqData = {
          tenant_id: userData?.tenantId,
          ssn: getSSN(inputData?.ssnInput),
          dob: moment(inputData?.officerDOB).format(FORMATS.API_SEND_FORMAT),
          email: inputData?.officerEmail,
          first_name: inputData?.officerFirstName,
          last_name: inputData?.officerLastName,
          state: inputData?.state,
          address_line_1: inputData?.street,
          address_line_2: inputData?.street2 ? inputData?.street2 : "",
          city: inputData?.city,
          country_code: inputData?.country,
          postal_code: inputData?.postalCode,
          phone_number: `+${inputData?.officerCountryCode}${inputData?.officerNumber}`,
          nationality: inputData?.officerNationality,
          is_customer: true,
        };

        if (!progress?.officerId) {
          createOfficerBusiness.mutate(reqData, {
            onSuccess: (data) => {
              if (data?.payload?.otz_person_id) {
                setProgress({
                  ...inputData,
                  officerId: data?.payload?.otz_person_id,
                });
              }

              callManageOwnerRelationship(
                data?.payload?.otz_person_id,
                inputData?.officerTitle
              );
            },
            onError: (error) => {
              generateErrorMessage(error);
            },
          });
        } else if (progress?.officerId && form.isFieldsTouched()) {
          const reqBody = {
            data: reqData,
            id: progress?.officerId,
          };
          updatePersonApplication.mutate(reqBody, {
            onSuccess: () => {
              callManageOwnerRelationship(
                progress?.officerId,
                inputData?.officerTitle
              );
            },
            onError: (error) => {
              generateErrorMessage(error);
            },
          });
        } else {
          handleSave();
        }
      } else {
        if (inputData?.officerId) {
          setProgress({
            officerId: inputData?.officerId,
          });

          callManageOwnerRelationship(
            inputData?.officerId,
            inputData?.officerTitle
          );
        }
      } */
  }

  // const handlerOnSuccess = () => {
  //   setVisible(false);

  //   try {
  //     mixpanel.people.set({
  //       $name: businessData?.name,
  //     });
  //     mixpanel.track("Officer - Completed", {
  //       "Email ID": userData?.email,
  //       "Sign Up Date": new Date().toISOString(),
  //       "Business name": businessData?.name,
  //       "Company_ID": businessData?.id,
  //       "Officer added?": Object.keys(progress).length !== 0,
  //     });
  //     mixpanel.people.set({
  //       "Sign Up": "Managing person listed",
  //     });
  //     mixpanel.track("Sign up - completed", {
  //       "Company_ID": businessData?.id,
  //       "Sign Up Date": new Date().toISOString(),
  //       "App permission status": userData?.approved,
  //       "Account type": userData?.planType,
  //     });
  //   } catch (error) {
  //     console.error("error: ", error);
  //   }

  //   navigate(
  //     `${ROUTES.ONBOARDING.THANK_YOU}${
  //       ROUTE_STATUS[USER_STATES.PENDING_REVIEW]
  //     }`
  //   );
  // };

  const handleSave = () => {
    // setVisible(true);
    updateApplication.mutate(
      {
        onBoardingStatus: "OFFICER_CREATED",
      },
      {
        onSuccess: () => {
          mixpanel.people.set({
            "Sign Up": "Managing person listed",
          });
          mixpanel.track("Officer - Completed", {
            "Email ID": userData?.email,
            "Sign Up Date": new Date().toISOString(),
            "Business name": businessData?.name,
            "Company_ID": businessData?.id,
            "Officer added?": Object.keys(progress).length !== 0,
          });
          navigate(ROUTES.ONBOARDING.ONBOARDING_DOCUMENT_UPLOAD)
        },
        onError: (err) => {
          throw new Error(err);
        },
      }
    );
  };

  //Handle responses
  const callManageOwnerRelationship = async () => {
    if (progress?.relationship) {
      return Promise.all(
        progress?.officer?.map((element) => {
          const reqBody = {
            title: element?.title,
          };
          return updateOfficerBusiness.mutate(reqBody, {
            onSuccess: (data) => {
              // setVisible(true);
            },
            onError: (error) => {
              generateErrorMessage(error);
            },
          });
        })
      );
    } else if (!progress?.relationship) {
      return Promise.all(
        officerList?.map((id, index) => {
          const reqPayload = {
            to_business_id: application?.payload?.otz_business_id,
            from_person_id: id,
            title: officerIDList[index],
          };
          return officerBusinessRelationship.mutate(reqPayload);
        })
      );
    }
  };

  const updateManageOwnerRelationship = (id, title) => {
    if (progress?.relationship) {
      const reqBody = {
        title: title,
      };
      updateOfficerBusiness.mutate(reqBody, {
        onSuccess: (data) => {
          // setVisible(true);
        },
        onError: (error) => {
          generateErrorMessage(error);
        },
      });
    }
  };
  
  const [useBeneficialOwner, setUseBeneficialOwner] = useState(false);
  const validateMessages = {}; // Initialize as an empty object

  useEffect(() => {
    console.log(boData, boData?.length > 0);
    if (boData.length > 0) {
      if (selectedBeneficialOwner) {
        const ownerData = boData.find(
          (owner) => owner.otz_person_id === selectedBeneficialOwner
        );
  
        console.log("ownerData: ", ownerData);

        if (ownerData) {
          form.setFieldsValue({
            officers: [
              {
                officerFirstName: ownerData.first_name,
                officerLastName: ownerData.last_name,
                officerNumber: ownerData.phone_number.replace('+1', ''),
                officerEmail: ownerData.email,
                street: ownerData.legal_address.address_line_1,
                street2: ownerData.legal_address.address_line_2,
                city: ownerData.legal_address.city,
                state: ownerData.legal_address.state,
                postalCode: ownerData.legal_address.postal_code,
                country: ownerData.legal_address.country_code,
                title: ownerData.title,
              },
            ],
          });
        }
  
        // Disable the form when a beneficial owner is selected
        setFormDisabled(true);
      } else {
        // Re-enable the form when no beneficial owner is selected
        setFormDisabled(false);
      }
      if(useBeneficialOwner) {
        setFormDisabled(true);
      } else {
        setFormDisabled(false);
        form.setFieldsValue({
          officers: [
            {
              officerFirstName: "",
              officerLastName: "",
              officerNumber: "",
              officerEmail: "",
              street: "",
              street2: "",
              city: "",
              state: null,
              postalCode: "",
              country: null,
              title: null,
            },
          ],
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBeneficialOwner, useBeneficialOwner, boData]);

  return (
    <>
      <div className={classNames.accountFormWrapper}>
        <Steps classNames={classNames} currentIndex={3} count={5} />
        <div className={classNames.formWrapper}>
          <Heading
            classNames={classNames}
            title="Open a Business Account"
            description="Fill in the details of one managing person who has significant control over the finances of the business"
          />

          <div className={classNames.formLabel}>
            Managing Person Details
            {/* (Optional) */}
            {/* <div className={classNames.warningSentence}>
              <span>
                Please be careful not to add the same person that you have
                already added as a Beneficial Owner, as an Officer.
              </span>
            </div> */}
          </div>
          { boData.length === 0 ? null : (
            <div>
              <Checkbox
                checked={useBeneficialOwner}
                onChange={(e) => setUseBeneficialOwner(e.target.checked)}
                style={{ marginBottom: "10px !important" }}
                // disabled={boData && boData?.length > 0}
              >
                Same as Beneficial Owner
              </Checkbox>
              {useBeneficialOwner && (
                isLoadingBOData ? (
                  <span>Loading Beneficial Owners...</span>
                ) : (
                  <Select
                    placeholder="Select a Beneficial Owner"
                    onChange={(value) => setSelectedBeneficialOwner(value)}
                    style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}
                  >
                    {boData.map((owner) => (
                      <Select.Option key={owner.otz_person_id} value={owner.otz_person_id}>
                        {owner.first_name} {owner.last_name}
                      </Select.Option>
                    ))}
                  </Select>
                )
              )}
            </div>
          )}

          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={handleOnFinish}
            form={form}
            initialValues={{
              ...progress,
              officerDOB: progress.officerDOB
                ? moment(progress.officerDOB)
                : null,
            }}
            autoComplete="off"
            scrollToFirstError={true}
            validateMessages={useBeneficialOwner ? {} : validateMessages} // Conditionally apply based on useBeneficialOwner
          >
            <Form.List
              name="officers"
              rules={[{ required: useBeneficialOwner ? false : true }]}
              initialValue={[{}]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields?.map(({ key, name, fieldKey, ...restFields }) => {
                    return (
                      <Form.Item key={key} >
                        {radioValue[key] === "No" ? (
                          <>
                            <Form.Item>
                              <Form.Item
                                {...restFields}
                                name={[name, "officerFirstName"]}
                                label={
                                  <Title as="h4" className={classNames.label}>
                                    First Name <sup style={{ top: 1 }}>*</sup>
                                  </Title>
                                }
                                style={{
                                  display: "inline-block",
                                  width: "calc(50% - 8px)",
                                  // marginBottom: "10px",
                                  float: "left",
                                }}
                                rules={[
                                  {
                                    required: useBeneficialOwner ? false : true,
                                    message: validation.firstName.required,
                                  },
                                  {
                                    max: MAX_MIN.name.max,
                                    message: validation.firstName.maxLength,
                                  },
                                  {
                                    pattern: new RegExp(REGEX.name),
                                    message: validation.firstName.valid,
                                  },
                                ]}
                              >
                                <Input
                                disabled={formDisabled}
                                placeholder={validation.firstName.placeholder}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restFields}
                                name={[name, "officerLastName"]}
                                label={
                                  <Title as="h4" className={classNames.label}>
                                    Last Name <sup style={{ top: 1 }}>*</sup>
                                  </Title>
                                }
                                style={{
                                  display: "inline-block",
                                  width: "calc(50% - 4px)",
                                  // margin: "66px 0 0 4px",
                                  float: "right",
                                  bottom: "0px",
                                }}
                                rules={[
                                  {
                                    required: useBeneficialOwner ? false : true,
                                    message: validation.lastName.required,
                                  },
                                  {
                                    max: MAX_MIN.name.max,
                                    message: validation.lastName.maxLength,
                                  },
                                  {
                                    pattern: new RegExp(REGEX.name),
                                    message: validation.lastName.valid,
                                  },
                                ]}
                              >
                                <Input
                                disabled={formDisabled}
                                placeholder={validation.lastName.placeholder}
                                />
                              </Form.Item>
                            </Form.Item>
                            <Form.Item
                              {...restFields}
                              name={[name, "officerTitle"]}
                              label={
                                <Title as="h4" className={classNames.label}>
                                  Title <sup style={{ top: 1 }}>*</sup>
                                </Title>
                              }
                              rules={[
                                {
                                  required: useBeneficialOwner ? false : true,
                                  message: validation.title.required,
                                },
                              ]}
                            >
                              <Select
                              disabled={formDisabled}
                              placeholder={validation.title.placeholder}
                              >
                                {businessOfficerTitle().map((value) => (
                                  <Select.Option
                                    key={value.value}
                                    value={value.value}
                                  >
                                    {value.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              {...restFields}
                              name={[name, "ssnInput"]}
                              label={
                                <Title as="h4" className={classNames.label}>
                                  SSN / ITIN
                                  <sup style={{ top: 1 }}>*</sup>{" "}
                                </Title>
                              }
                              rules={[
                                {
                                  required: useBeneficialOwner ? false : true,
                                  message: validation.ssn.required,
                                },
                                {
                                  pattern: new RegExp(REGEX.einNumber),
                                  message: validation.ssn.valid,
                                },
                              ]}
                            >
                              <Input placeholder={validation.ssn.placeholder} disabled={formDisabled} />
                            </Form.Item>

                            <Form.Item
                              {...restFields}
                              name={[name, "officerNationality"]}
                              label={
                                <Title as="h4" className={classNames.label}>
                                  Nationality <sup style={{ top: 1 }}>*</sup>
                                </Title>
                              }
                              rules={[
                                {
                                  required: useBeneficialOwner ? false : true,
                                  message: validation.nationality.required,
                                },
                              ]}
                            >
                              <CountryPicker disabled={formDisabled} countries="all" />
                            </Form.Item>
                            <Form.Item>
                              <Form.Item
                                {...restFields}
                                name={[name, "officerDOB"]}
                                label={
                                  <Title as="h4" className={classNames.label}>
                                    Date of Birth{" "}
                                    <sup style={{ top: 1 }}>*</sup>
                                  </Title>
                                }
                                style={{
                                  display: "inline-block",
                                  width: "calc(50% - 8px)",
                                  marginBottom: "10px",
                                  float: "left",
                                }}
                                rules={[
                                  {
                                    required: useBeneficialOwner ? false : true,
                                    message: validation.dateOfBirth.required,
                                  },
                                ]}
                              >
                                <DatePicker
                                  disabledDate={(date) =>
                                    !date ||
                                    date?.isAfter(
                                      moment().format(FORMATS.DATEPICKER_FORMAT)
                                    )
                                  }
                                  disabled={formDisabled}
                                // defaultPickerValue={moment().subtract(18, "years")}
                                  placeholder={
                                    validation.dateOfBirth.placeholder
                                  }
                                />
                              </Form.Item>

                              <Form.Item
                                name={[name, "officerEmail"]}
                                label={
                                  <Title as="h4" className={classNames.label}>
                                    Email Address{" "}
                                    <sup style={{ top: 1 }}>*</sup>
                                  </Title>
                                }
                                rules={[
                                  {
                                    required: useBeneficialOwner ? false : true,
                                    message: validation.email.required,
                                  },
                                  {
                                    type: "email",
                                    message: validation.email.valid,
                                  },
                                  {
                                    min: MAX_MIN.email.min,
                                    message: validation.email.minLength,
                                  },
                                  {
                                    max: MAX_MIN.email.max,
                                    message: validation.email.maxLength,
                                  },
                                ]}
                                style={{
                                  display: "inline-block",
                                  width: "calc(50% - 4px)",
                                  marginBottom: "10px",
                                  float: "right",
                                }}
                              >
                                <Input
                                disabled={formDisabled}
                                placeholder={validation.email.placeholder}
                                />
                              </Form.Item>
                            </Form.Item>

                            <Form.Item
                              label={
                                <Title as="h4" className={classNames.label}>
                                  Address <sup style={{ top: 1 }}>*</sup>
                                </Title>
                              }
                            >
                              <AddressForm
                                countrySelected={countrySelected}
                                onCountryChange={setCountrySelected}
                                validation={validation}
                                disabled={formDisabled}
                                name={name}
                                required={useBeneficialOwner ? false : true}
                              />
                            </Form.Item>

                            <Form.Item
                              {...restFields}
                              name={[name, "officerNumber"]}
                              label={
                                <Title as="h4" className={classNames.label}>
                                  Phone Number <sup style={{ top: 1 }}>*</sup>
                                </Title>
                              }
                              rules={[
                                {
                                  required: useBeneficialOwner ? false : true,
                                  message: validation.phoneNumber.required,
                                },
                                {
                                  pattern: new RegExp(REGEX.phoneNumber),
                                  message: validation.phoneNumber.valid,
                                },
                              ]}
                            >
                              <Input
                                placeholder={validation.phoneNumber.placeholder}
                              disabled={formDisabled}
                              prefix="+1"
                              />
                            </Form.Item>
                            <Form.Item
                              hidden
                              name={[name, "officerCountryCode"]}
                              initialValue="1"
                            >
                              <Input type="hidden" />
                            </Form.Item>
                            <Form.Item>

                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              {...restFields}
                              name={[name, "officerId"]}
                              label={
                                <Title as="h4" className={classNames.label}>
                                  Select person <sup style={{ top: 1 }}>*</sup>
                                </Title>
                              }
                              rules={[
                                { required: true, message: "Select person" },
                              ]}
                            >
                              <Select
                                size="small"
                                placeholder="Select person for officer"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .join("")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {boData &&
                                  boData.length > 0 &&
                                  boData?.map((person) => (
                                    <Select.Option
                                      key={person?.otz_person_id}
                                      value={person?.otz_person_id}
                                    >{`${person?.first_name} ${person?.last_name}`}</Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restFields}
                              name={[name, "officerTitle"]}
                              label={
                                <Title as="h4" className={classNames.label}>
                                  Title <sup style={{ top: 1 }}>*</sup>
                                </Title>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: validation.title.required,
                                },
                              ]}
                            >
                              <Select
                                placeholder={validation.title.placeholder}
                              >
                                {businessOfficerTitle().map((value) => (
                                  <Select.Option
                                    key={value.value}
                                    value={value.value}
                                  >
                                    {value.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        )}
                        <Divider />
                      </Form.Item>
                    );
                  })}
                  {Object.keys(radioValue).length > 1 && (
                    <Form.Item>
                      <Button
                        onClick={(e) => {
                          remove(Object.keys(radioValue).length - 1);
                          setRadioValue((prev) => {
                            delete radioValue[
                              Object.keys(radioValue).length - 1
                            ];
                            return { ...prev };
                          });
                        }}
                        className={classNames.removeButton}
                      >
                        Remove officer
                      </Button>
                    </Form.Item>
                  )}
                  {/* <Divider /> */}
                  {/* <Form.Item>
                    <Form.Item>
                      <Button
                        type="dashed"
                        style={{ height: "56px", width: "100%" }}
                        onClick={() => {
                          add();
                          setRadioValue((prev) => {
                            return {
                              ...prev,
                              [Object.keys(radioValue).length]: "No",
                            };
                          });
                        }}
                      >
                        Add another officer
                      </Button>
                    </Form.Item>
                  </Form.Item> */}
                </>
              )}
            </Form.List>

            <Form.Item style={{ marginTop: 25 }}>
              {/* <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
              >
                <CTAButton
                  htmlType="button"
                  onClick={handleSave}
                  // disabled={!isDisclosureAccepted}
                >
                  Skip
                </CTAButton>
              </Form.Item> */}
            <Form.Item className={classNames.links}>
              <Checkbox
                checked={isDisclosureAccepted}
                defaultChecked={false}
                onChange={() => setIsDisclosureAccepted(!isDisclosureAccepted)}
                style={{textAlign: "left"}}
              >
                <span>
                  I agree that I have read and accept all the
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      window.open(`https://www.otterz.co/disclosures`);
                    }}
                    style={{fontSize: "0.75rem"}}
                  >
                    disclosures{" "}
                  </Button>
                  provided by Otterz.
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
              >
                <CTAButton
                  htmlType="submit"
                  type="primary"
                  loading={createOfficerBusiness.isLoading}
                  disabled={!(isDisclosureAccepted )}
                >
                  Next
                </CTAButton>
              </Form.Item>
            </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
      {/* <AccountStatus
        visible={visible}
        onCancel={() => setVisible(false)}
        userData={userData}
        inputData={form.getFieldsValue()}
        onSuccess={handlerOnSuccess}
        isPerson={false}
      /> */}
    </>
  );
}
