import { 
    Form, 
    Checkbox,
    Input as AntdInput
  } from "antd";
  import { useContext, useState } from "react";
  import { useMutation, useQuery } from "react-query";
  import { useNavigate } from "react-router-dom";
  import {
    CTAButton,
    Input,
    Title,
    Select,
    Message,
    CountryPicker,
  } from "../../common";
  import Heading from "../Heading";
  import Steps from "../Steps";
  import { AppContext } from "../../../context/AppState";
  import api from "../../API";
  import generateErrorMessage from "../../util/functions/customError";
  import {
    industries,
    // revenueList
  } from "../../util";
  import { validation } from "../config";
  import { ROUTES } from "../../constants";
  import { MAX_MIN } from "../../util/constants";
  import REGEX from "../../util/constants/regex";
  import classNames from "../Signup/Signup.module.scss";
  import mixpanel from "mixpanel-browser";
  
  export default function BusinessOperationDetails() {
    //Hooks
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isDisclosureAccepted, setIsDisclosureAccepted] = useState(false);
    const { userData } = useContext(AppContext);
  

    const { data: application } = useQuery(
      "getApplicationByTenantId",
      () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
      {
        refetchOnWindowFocus: false,
      }
    );

    mixpanel.identify(userData.email);
  
    // Set user properties
    mixpanel.people.set({
      $email: userData.email,
    });
  
    try {
      mixpanel.identify(userData?.email);
  
      // Set user properties
      mixpanel.people.set({
        $email: userData?.email,
      });
    } catch (error) {
      console.error("error: ", error);
    }
  
    //Api calls
    const updateDetails = useMutation((data) =>
      api.onBoarding.updateBusinessOperationDetails(data)
    );

    const updateApplication = useMutation((data) => {
      return api.onBoarding.updateApplicationByTenantId(data);
    });

    //Event handlers
    const handleOnFinish = () => {
      let inputData = form.getFieldsValue();
      const data = {
        otz_business_id: application?.payload.otz_business_id,
        business_description: inputData?.businessDescription,
        industry: inputData?.industry,
        years_of_operation: inputData?.yearsOfOperation,
        purpose_of_the_account: inputData?.purposeOfAccount,
        product_services_offered: inputData?.serviceOffered,
        international_presence: inputData?.country,
        funding_source: inputData?.fundingSource,
        no_of_employees: inputData?.numberOfEmployees,
        // revenue: inputData?.revenue,
        // check_deposit_amount: inputData?.checkDepositAmount,
        // ACH_transactions_outgoing: inputData?.outgoingAchTransactions,
        // ACH_transactions_incoming: inputData?.incomingAchTransactions,
        // wire_transactions_outgoing: inputData?.outgoingWireTransfers
      }

      updateApplication.mutate(
        {
          onBoardingStatus: "BUSINESS_OPERATION_DETAILS_FILLED",
        },
        {
          onSuccess: () => {},
          onError: (err) => {
            throw new Error(err);
          },
        }
      );

      try {
        mixpanel.track("Business Operation Details", {
          "Industry": inputData?.industry,
          "Years of operation": inputData?.yearsOfOperation,
          "No. of employees": inputData?.numberOfEmployees,
        });
      } catch (error) {
        console.error("mixpanel-error: ", error)
      }
      
      updateDetails.mutate(data, {
          onSuccess: () => {
            Message({ type: "success", content: "Business details submitted successfully" });
  
            // try {
              // mixpanel.track("Business details - completed", {
              //   Company_id: data?.payload?.otz_business_id,
              //   "Sign Up Date": new Date().toISOString(),
              //   "Email ID": userData?.email,
              //   "Business Name": data?.payload?.entity_name,
              // });
            // } catch (error) {
              // console.error("error: ", error);
            // }


          },
          onError: (error) => {
            generateErrorMessage(error);
          },
        });

      navigate(ROUTES.ONBOARDING.ACCOUNT_ACTIVITY_DETAILS);
  
    };
 
    return (
      <div className={classNames.accountFormWrapper}>
        <Steps classNames={classNames} currentIndex={2} count={4} />
        <div className={classNames.formWrapper}>
          <Heading
            classNames={classNames}
            title="Sign up for business account"
            description="Fill in the form below and submit, we will review your details and get back to you"
          />
          <div className={classNames.formLabel}>Business Operations Details</div>
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={handleOnFinish}
            form={form}
            initialValues={{
              country: "US",
            }}
            autoComplete="off"
            scrollToFirstError={true}
          >
            <Form.Item>
                <Form.Item
                    name="businessDescription"
                    label={
                    <Title as="h4" className={classNames.label}>
                        Business Description <sup style={{ top: 1 }}>*</sup>
                    </Title>
                    }
                    rules={[
                    { required: true, message: validation.businessDescription.required },
                    {
                        max: MAX_MIN.businessDescription?.max,
                        message: validation.businessDescription?.max,
                    },
                    ]}
                >
                    <AntdInput.TextArea placeholder="Describe your business in 280 characters or less" rows={4} maxLength={280}/>
                </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="industry"
                label={
                  <Title as="h4" className={classNames.label}>
                    Industry <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  {
                    required: true,
                    message: validation.industry.required,
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Which industry does your company operate in?"
                >
                  {industries()?.map((value) => (
                    <Select.Option key={value.value} value={value.value}>
                      {value.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="yearsOfOperation"
                label={
                  <Title as="h4" className={classNames.label}>
                    Years of Operation <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                rules={[
                  {
                    pattern: new RegExp(REGEX.onlyNumbers),
                    message: validation.yearsOfOperation.onlyNumbers,
                  },
                ]}
              >
                <Input placeholder="How many years have you been in business for?" />
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="purposeOfAccount"
                label={
                  <Title as="h4" className={classNames.label}>
                    Purpose of Account <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                rules={[
                    { required: true, message: validation.purposeOfAccount.required }
                ]}
              >
                <Input placeholder={validation.purposeOfAccount.placeholder} maxLength={280}/>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="serviceOffered"
                label={
                  <Title as="h4" className={classNames.label}>
                    Product / Service Offered <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                rules={[
                    { required: true, message: validation.purposeOfAccount.required }
                ]}
              >
                <Input placeholder={validation.serviceOffered.placeholder} maxLength={280}/>
              </Form.Item>
            </Form.Item>
            <Form.Item>
                <Form.Item
                name={"country"}
                label={
                    <Title as="h4" className={classNames.label}>
                      International Presence <sup style={{ top: 1 }}>*</sup>
                    </Title>
                  }
                rules={[{ required: true, message: validation.country.required }]}
                >
                <CountryPicker
                    placeholder={validation.country.placeholder}
                    countries="all"
                    mode="multiple"
                    rows={1}
                    style={{overflow: 'hidden'}}
                />
                </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="fundingSource"
                label={
                  <Title as="h4" className={classNames.label}>
                    Funding Source <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                rules={[
                    { required: true, message: validation.fundingSource.required }
                ]}
              >
                <Input placeholder={validation.fundingSource.placeholder} maxLength={280}/>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="numberOfEmployees"
                label={
                  <Title as="h4" className={classNames.label}>
                    No. of employees <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                rules={[
                    { required: true, message: validation.numberOfEmployees.onlyNumbers }
                ]}
              >
                <Input placeholder={validation.numberOfEmployees.placeholder} type="number" min={0}  />
              </Form.Item>
            </Form.Item>

            <Form.Item className={classNames.links}>
            <Checkbox
                checked={isDisclosureAccepted}
                defaultChecked={false}
                onChange={() => setIsDisclosureAccepted(!isDisclosureAccepted)}
                style={{textAlign: "left"}}
              >
                <span style={{textAlign: "left"}}>
                  I certify to the best of my knowledge that the information provided is complete and correct.
                </span>
              </Checkbox>
              
            </Form.Item>
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                margin: "0 0 0 4px",
                float: "right",
              }}
            >
              <CTAButton
                htmlType="submit"
                type="primary"
                loading={updateDetails.isLoading}
                disabled={!isDisclosureAccepted}
              >
                Next
              </CTAButton>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
  