const CreateFolder = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12236_131407)">
    <path d="M9.3105 3.75H15.75C15.9489 3.75 16.1397 3.82902 16.2803 3.96967C16.421 4.11032 16.5 4.30109 16.5 4.5V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H7.8105L9.3105 3.75ZM3 3.75V14.25H15V5.25H8.6895L7.1895 3.75H3ZM8.25 9V6.75H9.75V9H12V10.5H9.75V12.75H8.25V10.5H6V9H8.25Z" fill="#3B3F43"/>
    </g>
    <defs>
    <clipPath id="clip0_12236_131407">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};

export default CreateFolder;
