import { useEffect, useReducer, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../../firebaseConfig";
import { 
  // FaMicrosoft, 
  FaGoogle, 
  // FaApple 
} from 'react-icons/fa';
import { Form, Button, Input as AntInput, Divider } from "antd";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  // CheckOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  // InfoCircleOutlined,
} from "@ant-design/icons";
import { CTAButton, Input, Message } from "../../common";
import Heading from "../Heading";
import signupReducer from "../stateManagement/signupReducer";
import { types } from "../stateManagement/types";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { removeValuesFromLocalStorage } from "../util";
// import REGEX from "../../util/constants/regex";
import { MAX_MIN } from "../../util/constants";
import lowerCase from "../../util/functions/lowerCase";
import { validatePassword } from "../../util/functions/onboarding";
import { ROUTES } from "../../constants";
// import { DISCLOSURES_TYPE } from "../../Disclosure/Disclosure.constant";
import { validation } from "../config";
import commonPasswords from "./util/CommonPasswords";
import classNames from "./Signup.module.scss";
import mixpanel from "mixpanel-browser";
import api from "../../API";
import gsap from "gsap";
import { useMutation } from "react-query";
import { DISCLOSURES_TYPE } from "../../Disclosure/Disclosure.constant";

export default function Signup() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [, setVerifyEmail] = useLocalStorage("OTTERZ.verifyEmail");
  const [, setRank] = useState(0);
  const [matcher, setMatcher] = useState(0);
  const [notCommonPwd, setNotCommonPwd] = useState(true);
  const [queryParams] = useSearchParams();
  const [{ status, error }, dispatch] = useReducer(signupReducer, { status: "idle" });
  const [showPasswordChecks, setShowPasswordChecks] = useState(false);
  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const passwordChecksRef = useRef(null); 
  const passwordWrapperRef = useRef(null); 

  const initialEmailAddress = queryParams.get("CTAemail");
  
  // Use secure local storage instead of sessionStorage
  const secureLocalStore = window.localStorage || window.sessionStorage;

  useEffect(() => {
    removeValuesFromLocalStorage(true, true);
  }, []);

  useEffect(() => {
    if (status === "success") {
      navigate(ROUTES.VERIFICATION.VERIFY_EMAIL);
    }
    if (error) {
      Message({ type: "error", content: error.message });
    }
  }, [status, error, navigate]);

  useEffect(() => {
    console.log("showPasswordChecks", showPasswordChecks);
    if (showPasswordChecks) {
      gsap.fromTo(passwordWrapperRef.current, {opacity: 0, height: 0}, { height: 'auto', opacity: 1, duration: 0.2 });
    } else {
      gsap.fromTo(passwordWrapperRef.current, { opacity: 1}, { height: 0, opacity: 0, duration: 0.2 });
    }
  }, [showPasswordChecks]);

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPasswordChecks({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[^A-Za-z0-9]/.test(value),
    });
  };

  const handlePasswordFocus = () => {
    setShowPasswordChecks(true);
  };
  
  const handlePasswordBlur = (e) => {
    if (e.target.value === '') {
      setShowPasswordChecks(false);
    }
  };

  /* Helper function to check if the email domain is valid and not in the excluded list */
  // Valid email domains
  const isValidEmailDomain = (email) => {
    const domain = email.split('@')[1];
    const excludedDomains = [
      'guerrillamail.com', 'yopmail.com', 'maildrop.cc', 'temp-mail.org',
      'getairmail.com', 'throwawaymail.com', 'discard.email', 'tempmailaddress.com',
      'mailnesia.com', 'mailmetrash.com', 'mailinator2.com', 'mailinator.net',
      'mailinator.org', 'mailinator2.net', 'mailinator.biz', 'mailinator.co.uk',
      'mailinator.info', 'mailinator.jp', 'mailinator.us'
    ];
    return !excludedDomains.includes(domain);
  };

  // Helper function to check if the password is in the common password list
  const isCommonPassword = (password) => {
    return commonPasswords.includes(password);
  };


  /* Password checks component */ 
  const PasswordChecks = () => (
    <div ref={passwordWrapperRef}>
      <div ref={passwordChecksRef} className={classNames.passwordChecks} >
        <div style={{margin: "5px 0"}}>Your Password must -</div>
        <div style={{ color: passwordChecks.length ? 'green' : 'grey', margin: "5px 0" }}>
          {passwordChecks.length ? <CheckCircleFilled /> : <CheckCircleOutlined />} Contain at least 8 characters
        </div>
        <div style={{ color: passwordChecks.uppercase ? 'green' : 'grey', margin: "5px 0" }}>
          {passwordChecks.uppercase ? <CheckCircleFilled /> : <CheckCircleOutlined />} Contain at least 1 uppercase letter
        </div>
        <div style={{ color: passwordChecks.lowercase ? 'green' : 'grey', margin: "5px 0" }}>
          {passwordChecks.lowercase ? <CheckCircleFilled /> : <CheckCircleOutlined />} Contain at least 1 lowercase letter
        </div>
        <div style={{ color: passwordChecks.number ? 'green' : 'grey', margin: "5px 0" }}>
          {passwordChecks.number ? <CheckCircleFilled /> : <CheckCircleOutlined />} Contain at least 1 number
        </div>
        <div style={{ color: passwordChecks.specialChar ? 'green' : 'grey', margin: "5px 0" }}>
          {passwordChecks.specialChar ? <CheckCircleFilled /> : <CheckCircleOutlined />} Contain at least 1 special character
        </div>
      </div>
    </div>
  );  

  /* Mutation definition for API calls */
  // Register mutation
  const registerMutation = useMutation(data => api.Services.Auth.registerToken(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }
      
      // Handle successful registration
      if (data && data.data && data.data.access_token) {
        console.log("Registration successful", data);
        Message({ type: "success", content: "Signup successful !" });
        
        // Store the access token in session storage
        secureLocalStore.setItem('accessToken', data.data.access_token);
        secureLocalStore.setItem('otterz_id', data.data.otterz_id);
        secureLocalStore.setItem('email', data.data.user.email_id);
        secureLocalStore.setItem('email_verification', false);
        secureLocalStore.setItem('is_accountant', false);
        
        // Navigate or update state as needed
        navigate(ROUTES.VERIFICATION.EMAIL_VERIFY);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });  

  // getch status mutation
  const getOnboardingStatus = useMutation(data => api.Services.Onboarding.getOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Onboarding status: ", data);

        // const email_verified = secureLocalStore.getItem('email_verification');
        const status = data.data?.status;
        console.log("Status: ", status, data);
        // Based on the onboarding status, navigate to the appropriate route
        switch (status) {
          case 'ADD_PREFERENCE':
            navigate(ROUTES.ONBOARDING.USE_PREFERENCE);
            break;
          case 'SETUP_COPILOT':
            navigate(ROUTES.ONBOARDING.SETUP_COPILOT);
            break;
          case 'CONNECT_PLAID':
            navigate(ROUTES.ONBOARDING.LINK_BANK);
            break;
          case 'LINK_BUSINESS':
            navigate(ROUTES.ONBOARDING.LINK_BUSINESS_BANK);
            break;
          case 'CONNECT_QUICKBOOKS':
            navigate(ROUTES.ONBOARDING.LINK_QUICKBOOKS);
            break;
          case 'SETUP_PAYMENTS':
            navigate(ROUTES.ONBOARDING.SETUP_PAYMENTS);
            break;
          case 'SUBSCRIPTION_ONBOARD':
            navigate(ROUTES.ONBOARDING.ONBOARD_SUBSCRIPTION);
            break;
          case 'INVITE_OWNERS':
            navigate(ROUTES.ONBOARDING.INVITE_OWNERS);
            break;
          case 'ONBOARDED':
            navigate(ROUTES.APP.INDEX);
            break;
          // Add more cases as needed
          default:
            navigate(ROUTES.ONBOARDING.CREATE_PROFILE);
        }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });  

  /* Google Sign in */
  // Initialize Google Auth Provider
  const googleProvider = new GoogleAuthProvider();
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // This gives you a Google Access Token. You can use it to access Google APIs.
      const credential = GoogleAuthProvider.credentialFromResult(result);

      // The signed-in user info.
      const user = result.user;
      const token = credential.accessToken;
      const accessToken = result._tokenResponse.idToken

      // Handle the signed-in user information (e.g., save to state, navigate)
      console.log("Google Sign in successful: ", result, token);
      Message({ type: "success", content: "Google sign in successful" });

      secureLocalStore.setItem('accessToken', accessToken);
      secureLocalStore.setItem('otterz_id', user.uid);
      secureLocalStore.setItem('email', result._tokenResponse.email);
      secureLocalStore.setItem('email_verification', true);
      secureLocalStore.setItem('is_accountant', false);

      // Navigate to the desired route after successful login
      getOnboardingStatus.mutate({ otterz_id: user.uid });
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;

      // The email of the user's account used.
      const email = error.email;

      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      
      // Show error message
      console.error("Google Sign in error: ", errorCode, errorMessage);
      console.log("MetaData: ", credential, email)
    }
  };

  /* Handle form submission */
  async function handleOnFinish({ email, password, mobile, countryCode }) {
    email = lowerCase(email);

    try {
      mixpanel.identify(email);

      // // Set user properties
      // mixpanel.people.set({
      //   $email: email,
      //   "Sign Up": "Sign Up started",
      // });

      // // Track the signup event
      // mixpanel.track("Sign up started", {
      //   "Email ID": email,
      //   "Sign Up Date": new Date().toISOString(),
      //   "Phone number": `+${countryCode + mobile}`
      //   // "Phone number": `+1 506 701 5696`,
      // });

      // dispatch({ type: types.PERFORMING_SIGNUP });
      // const user = await Auth.signUp({
      //   username: email,
      //   password,
      //   attributes: {
      //     email,
      //     phone_number: `+${countryCode + mobile}`,
      //     // phone_number: `+15067015696`,
      //   },
      // });

      // Trigger register mutation
      registerMutation.mutate({ email, password });

      // const emailTest = false;
      // if (emailTest) {
      //   navigate(ROUTES.VERIFICATION.EMAIL_VERIFY)
      // } else {
      //   navigate(ROUTES.ONBOARDING.CREATE_PROFILE)
      // }


      setVerifyEmail(email);
      // dispatch({ type: types.VERIFICATION_SENT, payload: user });
    } catch (error) {
      dispatch({ type: types.OPERATION_FAILED, error });
      mixpanel.track("Sign up failed", {
        "Email ID": email,
        "Sign Up Date": new Date().toISOString(),
        Reason: error?.name,
      });
    }
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.formWrapperSignup}>
        <Heading
          classNames={classNames}
          title="Create Account"
        />
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={handleOnFinish}
          autoComplete="off"
          scrollToFirstError={true}
          style={{marginBottom: "10vh"}}
          initialValues={initialEmailAddress ? {email: initialEmailAddress } : {}}
        >
          <Form.Item
            name="email"
            // label={
            //   <span className={classNames.label}>
            //     Email Address <sup style={{ top: 1 }}>*</sup>
            //   </span>
            // }
            className={classNames.signupFormInputs}
            rules={[
              { required: true, message: validation.email.required },
              {
                type: "email",
                message: validation.email.valid,
              },
              {
                min: MAX_MIN.email.min,
                message: validation.email.minLength,
              },
              {
                max: MAX_MIN.email.max,
                message: validation.email.maxLength,
              },
              { validator: (_, value) => isValidEmailDomain(value) ? Promise.resolve() : Promise.reject(new Error('The email domain name is invalid')) },
            ]}
          >
            <Input placeholder={validation.email.placeholder} />
          </Form.Item>

          <Form.Item
            name="password"
            // label={
            //   <span className={classNames.label}>
            //     Password <sup style={{ top: 1 }}>*</sup>
            //   </span>
            // }
            className={classNames.signupFormInputs}
            rules={[
              // { required: true, message: validation.password.required },
              // {
              //   min: MAX_MIN.password.min,
              //   message: validation.password.minLength,
              // },
              {
                max: MAX_MIN.password.max,
                message: validation.password.maxLength,
              },
              // {
              //   pattern: new RegExp(REGEX.password),
              //   message: validation.password.regex,
              // },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (isCommonPassword(value)) {
                    setNotCommonPwd(true)
                    return Promise.reject(new Error('Password is too common'));
                  }
                  if (value && value === getFieldValue('email')) {
                    setNotCommonPwd(true)
                    return Promise.reject(new Error('Password cannot be the same as the email address'));
                  }
                  setNotCommonPwd(false)
                  return Promise.resolve();
                },
              }),
              {
                validator: async (_, password, callback) => {
                  if (password) {
                    if (!validatePassword(password, setRank)) {
                      callback(validation.password.regex);
                    }
                  } else {
                    setRank(null);
                  }
                },
              },
            ]}
            // extra={<sup style={{ top: "5px" }}>{validation.password.hint}</sup>}
          >
            <AntInput.Password
              iconRender={(visible) =>
                visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
              }
              onChange={handlePasswordChange}
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
              placeholder={validation.password.placeholder}
            />
          </Form.Item>
          <PasswordChecks />
         
          <Form.Item
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  setMatcher(getFieldValue("password") === value);
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(validation.confirmPassword.match)
                  );
                },
              }),
              { required: true, message: validation.confirmPassword.required },
            ]}
            className={classNames.signupFormInputs}
            name="confirmPassword"
            
            // label={
            //   <span className={classNames.label}>
            //     Confirm Password <sup style={{ top: 1 }}>*</sup>
            //   </span>
            // }
          >
            <AntInput.Password
              iconRender={(visible) =>
                visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
              }
              placeholder={validation.confirmPassword.placeholder}
            />
          </Form.Item>

          <CTAButton
            htmlType="submit"
            type="primary"
            loading={registerMutation.isLoading }
            disabled={!matcher || notCommonPwd || !passwordChecks.length || !passwordChecks.uppercase || !passwordChecks.lowercase || !passwordChecks.number || !passwordChecks.specialChar}
            className={classNames.signupFormInputs}
          >
            Sign Up
          </CTAButton>

          <Form.Item
           className={classNames.links}
           >
            {" "}
            <span>
              By clicking on 'Sign Up', I agree that I have read and accepted
              the{" "}
              <Button
                type="link"
                size="small"
                style={{ padding: "0px 4px", fontSize: "0.8rem" }}
                onClick={() => {
                  // window.open(
                  //   `${
                  //     new URL(window.location.href).origin
                  //   }${ROUTES.DISCLOSURE_DETAIL?.replace(
                  //     ":param",
                  //     DISCLOSURES_TYPE[0].param
                  //   )}`
                  // );
                  window.open(`${DISCLOSURES_TYPE[0].link}`);
                }}
              >
                Terms and Conditions
              </Button>
              and the{""}
              <Button
                type="link"
                size="small"
                style={{ padding: "0px 4px", fontSize: "0.8rem" }}
                onClick={() => {
                  // window.open(
                  //   `${
                  //     new URL(window.location.href).origin
                  //   }${ROUTES.DISCLOSURE_DETAIL?.replace(
                  //     ":param",
                  //     DISCLOSURES_TYPE[2].param
                  //   )}`
                  // );
                  window.open(`${DISCLOSURES_TYPE[2].link}`);
                }}
              >
                Privacy Notice{" "}
              </Button>
              of Otterz.
            </span>
            <div>
              <span>
                Sign up requires you to opt-in receiving SMS messages from
                Otterz, Inc. Otterz uses SMS services to secure your login and
                access to platform. Message and data rates may apply. Message
                frequency varies.
              </span>
            </div>
          </Form.Item>

          {/* Divider with "OR" */}
          <Divider>OR</Divider>

          {/* Icons for Microsoft, Gmail, and Apple */}
          <div className={classNames.socialLogin}>
            {/* <FaMicrosoft className={classNames.socialIcon} onClick={() => {}} /> */}
            <FaGoogle className={classNames.socialIcon} onClick={() => {handleGoogleSignIn()}} />
            {/* <FaApple className={classNames.socialIcon} onClick={() => {}} /> */}
          </div>

          <Form.Item className={classNames.links}>
            <span>Already have an account?</span>

            <Button
              type="link"
              size="small"
              onClick={() => navigate(ROUTES.PUBLIC.LOGIN)}
            >
              Sign in here
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
