import endpoints from "../../../endpoints";

export default async function linkPermissions(data) {
    let env = process.env.REACT_APP_ENV?.trim();

    const baseURL = endpoints.SERVICES.AUTHORIZATION.ROLES_AND_PERMISSIONS[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${baseURL}/permissions/${data.business_id}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                business_id: data.business_id, // String
                permissions: data.permissions   // Array of permissions
            })
        })
    ).json();
}
