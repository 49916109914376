import { MAX_MIN } from "../../util/constants";

const config = {
  client: {
    required: "Please select customer ",
  },
  firstName: {
    required: "First name is required",
    placeholder: "Please enter",
    maxLength: `Max ${MAX_MIN.name.max} characters`,
    valid: "Enter a valid first name",
  },
  lastName: {
    required: "Last name is required",
    placeholder: "Please enter",
    maxLength: `Max ${MAX_MIN.name.max} characters`,
    valid: "Enter a valid last name",
  },
  email: {
    valid: "Enter a valid email address",
    required: "Email address is required",
    // placeholder: "Eg: john@mail.com",
    placeholder: "Business Email ID",
    maxLength: `Max ${MAX_MIN.email.max} characters`,
    minLength: `Min ${MAX_MIN.email.min} characters`,
  },
  phoneNumber: {
    valid: "Enter a valid phone number",
    required: "Phone number is required",
    placeholder: "Eg: 94xxxxxxxxx",
    maxLength: `Max ${MAX_MIN.phone.max} digits`,
  },
  password: {
    required: "Password is required",
    minLength: `Min ${MAX_MIN.password.min} characters`,
    maxLength: `Max ${MAX_MIN.password.max} characters`,
    regex: "Insecure password",
    // placeholder: "Enter your password",
    placeholder: "Password",
    hint: "Password should have 8+ characters, mix of cases, digits, and special characters. Avoid consecutive letters/digits.",
  },
  confirmPassword: {
    required: "Password confirmation is required",
    match: "Passwords do not match",
    placeholder: "Confirm Password",
  },
  businessName: {
    required: "Name of the business is required",
    placeholder: "Please enter name",
    max: `Max ${MAX_MIN.businessName.max} characters`,
    valid: "Enter a valid business name",
  },
  street: {
    required: "Street is required",
    placeholder: "Street 1*",
    max: `Max ${MAX_MIN.street.max} characters`,
  },
  street2: {
    placeholder: "Street 2",
    max: `Max ${MAX_MIN.street.max} characters`,
  },
  city: {
    required: "City is required",
    placeholder: "City *",
    max: `Max ${MAX_MIN.city.max} characters`,
  },
  state: {
    required: "State is required",
    placeholder: "State *",
    max: `Max ${MAX_MIN.state.max} characters`,
  },
  postalCode: {
    required: "Postal code is required",
    placeholder: "Postal Code *",
    // valid: "Only numbers allowed and count should be 5",
    valid: "Enter a valid postal code",
    pattern: "Only numbers allowed and count should be 5",
  },
  country: {
    required: "Country is required",
    placeholder: "Country *",
    max: `Max ${MAX_MIN.name.max} characters`,
  },
  ein: {
    required: "EIN is required",
    placeholder: "Plese enter EIN",
    // onlyNumbers: "Only numbers allowed and count should be 9",
    onlyNumbers: "Enter a valid EIN",
  },
  salesItems: {
    error: "At least one item is required",
  },
  countryCode: {
    required: "Country code is required",
    placeholder: "Please enter",
  },
  entityType: {
    required: "Entity type is required",
    placeholder: "Please select",
  },
  industry: {
    required: "Industry is required",
    placeholder: "Please select",
  },
  businessMeta: {
    required: "Business related information required",
    placeholder: "Please select",
  },
  identificationType: {
    required: "Identification type is required",
  },
  ssn: {
    required: "Social security number (SSN) or ITIN is required",
    placeholder: "Please enter SSN or ITIN",
    // valid: "Only numbers allowed and count should be 9",
    valid: "Must be 9 numbers without hyphens (-)",
    max: "Max 9 numbers",
  },
  passport: {
    // required: "Passport number is required",
    required: "Passport number is required",
    // valid: "Only numbers allowed and count should be 9",
    valid: "Enter a valid passport number",
    min: "Min 6 characters",
    max: "Max 9 characters",
  },
  nationality: {
    required: "Nationality is required",
  },
  dateOfBirth: {
    required: "Date of birth is required",
    placeholder: "MM-DD-YYYY",
  },
  title: { required: "Title is required", placeholder: "Please select" },
  website: {
    valid: "Enter a valid website",
    placeholder: "Enter website URL",
    maxLength: `Max ${MAX_MIN.website.max} characters`,
  },
  yearsOfOperation: {
    onlyNumbers: "Enter only digits",
    placeholder: "Enter years"
  },
  businessDescription: {
    placeholder: "Enter description",
    required: "Describe your business"
  },
  percentage: {
    required: "Percentage is required",
    range: "Only numbers allowed between 0 to 100",
    valid: "Enter a valid percentage",
    max: "Total percentage can not be greater than 100%",
    min: "Total percentage can not be lesser than 25%",
    placeholder: "Eg: 100%",
    errorMessage:
      "Total equity of all beneficial owners is equals to 100%, so modify above equity to add another beneficiary",
  },
  reference: {
    max: (number) => `Max ${Number(number)} characters`,
  },
  stateOfIncorporation: {
    required: "State of incorporation is required",
    placeholder: "Please select",
  },
  purposeOfAccount: {
    required: "Purpose of Account is required",
    placeholder: "What will you use this account for?",
  },
  serviceOffered: {
    required: "Products/services offered is required",
    placeholder: "What products/services does your company offer?",
    maxLength: `Max ${MAX_MIN.serviceOffered.max} characters`,
  },
  fundingSource: {
    required: "Funding source is required",
    placeholder: "What is your company’s main source of revenue/funding?",
  },
  numberOfEmployees: {
    onlyNumbers: "Enter only digits",
    placeholder: "How many people does your company employ?"
  },
};

export default config;
