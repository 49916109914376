import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select, InputNumber, Radio, Spin, Upload, Checkbox, DatePicker, Popover, Row, Col } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import classNames from './Profile.module.scss';
import { CTAButton, Message, Modal } from '../../common';
import { useAccount, useBusiness } from '../../../store/StoreProvider';
import { useMutation } from 'react-query';
import api from '../../API';
import { ROUTES } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getBusinessDataCollection, uploadToFolder } from '../../API/Services';
import FolderIcon from './Assets/FolderIcon';
import naicsData from './Assets/naics_codes.json'; 
import incorporationStatesData from './Assets/us_states_list.json'; 
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import { businessDataCollection } from '../../API/Services/Onboarding';

const { Option } = Select;

const Profile = ({accountData, rowData, isClient, isProfileView, handleContinue}) => {
  const navigate = useNavigate();
  const { userData, setUserData } = useAccount();
  let { selectedBusiness } = useBusiness();

  selectedBusiness = Object.keys(selectedBusiness).length === 0 ? accountData : selectedBusiness;

  const [form] = Form.useForm();
  const [formChanged, setFormChanged] = useState(false);
  const [isPaymentAcceptanceModalVisible, setIsPaymentAcceptanceModalVisible] = useState(false);
  const [shareholderType, setShareholderType] = useState("Both"); 
  const [subsidiariesExist, setSubsidiariesExist] = useState("Yes"); 
  const [businessQuestions, setBusinessQuestions] = useState(null);
  const secureLocalStore = window.localStorage || window.sessionStorage;
  const [formDisabled, setFormDisabled] = useState(true);
  const [, setContinueDisabled] = useState(true);
  const [entityType, setEntityType] = useState(null);
  const [tinType, setTINType] = useState(null); // State to store tin type
  const [naicsType, setnaicsType] = useState(null); // State to store tin type
  const [dateOfIncorporation, setDateOfIncorporation] = useState(null); // State to store tin type
  const [stateOfIncorporation, setStateOFIncorporation] = useState(null); // State to store tin type
  const [shareholders, setShareholders] = useState([{ ownerName: '', sharesOwned: '', email: '', isForeignOwner: false }]);
  const [businessShareholders, setBusinessShareholders] = useState([{ ownerName: '', businessType: '', contactName: '', email: '', sharesOwned: '', isForeignOwner: false }]);
  const [subsidiaries, setSubsidiaries] = useState([{ subsidiary: '', businessType: '', contactName: '', email: '', sharesOwned: '', isForeignOwner: false }]);
  const [loading, setLoading] = useState(true);
  const [
    , 
    setBookkeepingMethod
  ] = useState(null);

  useEffect(() => {
    if (userData && selectedBusiness && isClient) {
      console.log("RUNNNING ON CLIENT !!");

      getBusinessDataCollection({business_id: selectedBusiness.business_id}).then((data) => {
        if (data.status) {
          form.setFieldsValue({
            name: userData?.first_name === undefined ? '' : userData?.first_name+' '+userData?.last_name,
            email: userData?.email_id,
            phoneNumber: userData?.phone_no === undefined ? '' : userData?.phone_no,
            businessName: selectedBusiness?.name,
            businessDescription: data.data?.business_description,
            // Add other fields if needed
          });
          setLoading(false);
        } else {
          form.setFieldsValue({
            name: userData?.first_name === undefined ? '' : userData?.first_name+' '+userData?.last_name,
            email: userData?.email_id,
            phoneNumber: userData?.phone_no === undefined ? '' : userData?.phone_no,
            businessName: selectedBusiness?.name,
            // Add other fields if needed
          });
          setLoading(false);
        }
      });

      const type = form.getFieldValue("shareholder_type") || "Both";
      setShareholderType(type);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, selectedBusiness]);

  useEffect(() => {
    if (selectedBusiness && !isClient) {
      console.log("RUNNNING ON ACCOUNTANT!!");

      getBusinessDataCollection({business_id: selectedBusiness.business_id}).then((data) => {
        if (data.status) {
          form.setFieldsValue({
            name: rowData?.admin,
            email: rowData?.email,
            phoneNumber: rowData?.phone,
            businessName: selectedBusiness?.name,
            businessDescription: data.data?.business_description,
            // Add other fields if needed
          });
          setLoading(false);
        } else {
          form.setFieldsValue({
            name: rowData?.admin,
            email: rowData?.email,
            phoneNumber: rowData?.phone,
            businessName: selectedBusiness?.name,
            // Add other fields if needed
          });
          setLoading(false);
        }
      });

      const type = form.getFieldValue("shareholder_type") || "Both";
      setShareholderType(type);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Mutation definition for API calls */
  // Create User mutation - Here we are updating the user preferences
  const createUser = useMutation(data => api.Services.Onboarding.createUser(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        setUserData(data.data);
        setFormChanged(false)
        Message({ type: "success", content: "User details updated successfully" });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      Bugsnag.notify("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  const getBusinessQuestion = useMutation(data => api.Services.Dashboard.TaxFilling.getBusinessQuestion(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Bugsnag.notify("Business questions error - User still needs to add business info", data);
      }
      if (data && data.data) {
        let customQuestions = data.data.questions;
        setBusinessQuestions(data.data.questions);
      
        if (data.data.questions.find(q => q.key === "date_of_incorporation")?.value === '') {
          setDateOfIncorporation(moment());
        } else {
          setDateOfIncorporation(moment(data.data.questions.find(q => q.key === "date_of_incorporation")?.value));
        }

        console.log("Date: ", moment(data.data.questions.find(q => q.key === "date_of_incorporation")?.value), data.data.questions.find(q => q.key === "date_of_incorporation"));
        

        const stockLedgerQuestion = customQuestions.find(q => q.key === 'stock_ledger');
        const businessStockLedgerQuestion = customQuestions.find(q => q.key === 'business_stock_ledger');
        const subsidiaryStockLedgerQuestion = customQuestions.find(q => q.key === 'subsidiary_stock_ledger');

        // Set stock ledger breakdown
        if (stockLedgerQuestion && stockLedgerQuestion.value) {
          setShareholders(stockLedgerQuestion.value);
        }

        // Set business stock ledger breakdown
        if (businessStockLedgerQuestion && businessStockLedgerQuestion.value) {
          setBusinessShareholders(businessStockLedgerQuestion.value);
        }

        // Set subsidiary stock ledger breakdown
        if (subsidiaryStockLedgerQuestion && subsidiaryStockLedgerQuestion.value) {
          setSubsidiaries(subsidiaryStockLedgerQuestion.value);
        }
  
        // Set other form fields
        const otherFields = data.data.questions.reduce((acc, curr) => {
          if (curr.key !== 'stock_ledger' && curr.key !== 'business_stock_ledger' && curr.key !== 'subsidiary_stock_ledger') {
            acc[curr.key] = curr.value;
          }
          return acc;
        }, {});
  
        form.setFieldsValue(otherFields);
      }
    },
    onError: (error) => {
      Message({ type: "error", content: error.message });
    },
  });  

  useEffect(() => {
    getBusinessQuestion.mutate({business_id: selectedBusiness.business_id});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness])

  const handleFormChange = () => {
    setFormChanged(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setFormChanged(false);
    form.setFieldsValue({
      name: userData?.first_name+' '+userData?.last_name,
      email: userData?.email_id,
      phoneNumber: userData?.phone_no,
      businessName: selectedBusiness?.name,
      // Add other fields if needed
    });
  };

  const handleOnFinish = () => {
    form
      .validateFields()
      .then(() => {
        // Handle the submit action
        createUser.mutate({
          otterz_id: secureLocalStore.getItem('otterz_id'),
          userData: {
            first_name: form.getFieldValue('name').split(' ')[0],
            last_name: form.getFieldValue('name').split(' ')[1],
            phone_no: `${form.getFieldValue('phoneNumber')}`
          }
        });
      })
      .catch(errorInfo => {
        Message({ type: "error", content: "Please fill in all fields correctly" });
        Bugsnag.notify('Validation failed:', errorInfo);
      });
  };

  // Custom validator function for phone number
  // const validatePhoneNumber = (_, value) => {
  //   if (!value) {
  //     return Promise.reject('Phone number is required.');
  //   } else if (!/^\d{10}$/.test(value)) {
  //     return Promise.reject('Phone number must be a 10-digit number.');
  //   }
  //   return Promise.resolve();
  // };

  /* Related to the business details form */

  const updateBusinessQuestion = useMutation(data => api.Services.Dashboard.TaxFilling.updateBusinessQuestion(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Bugsnag.notify("Business questions error - Failed to update", data);
        // Handle error message if required
      }
      if (data && data.data) {
        Message({ type: "success", content: "Business questions updated successfully !" });
        setFormDisabled(true);
      }
    },
    onError: (error) => {
      Bugsnag.notify("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // Function to handle form field changes
  useEffect(() => {
    if (businessQuestions) {
      const isFormTouched = form.isFieldsTouched();
      setContinueDisabled(!isFormTouched); // Enable Continue button if form is touched
    }
  }, [businessQuestions, form]);

  const handleUpdate = () => {
    const formValues = form.getFieldsValue();
    const questions = [];

    // Reusable function to check if the summation of shares is 100%
    const checkTotalSharesOwned = (shareholders, message) => {
      if (shareholders.length === 1 && shareholders[0].sharesOwned === "") {
        return true;
      }
  
      const totalSharesOwned = shareholders.reduce(
        (sum, shareholder) => sum + (parseFloat(shareholder.sharesOwned) || 0),
        0
      );
  
      if (totalSharesOwned > 100) {
        Message({ type: "error", content: message });
        return false;
      }
  
      return true;
    };
  
    // Function to check combined ownership of individual and business shareholders
    const checkCombinedSharesOwned = (individualShareholders, businessShareholders) => {
      const totalIndividualShares = individualShareholders.reduce(
        (sum, shareholder) => sum + (parseFloat(shareholder.sharesOwned) || 0),
        0
      );
  
      const totalBusinessShares = businessShareholders.reduce(
        (sum, shareholder) => sum + (parseFloat(shareholder.sharesOwned) || 0),
        0
      );
  
      const combinedTotal = totalIndividualShares + totalBusinessShares;
  
      if (combinedTotal > 100) {
        Message({
          type: "error",
          content: "The combined ownership percentage of individual and business shareholders must not exceed 100%",
        });
        return false;
      }
  
      return true;
    };
  
    // Check ownership for different types of shareholders
    if (
      !checkTotalSharesOwned(shareholders, "Total ownership percentage for individual shareholders must not exceed 100%") ||
      !checkTotalSharesOwned(businessShareholders, "Total ownership percentage for business shareholders must not exceed 100%") ||
      // !checkTotalSharesOwned(subsidiaries, "Total ownership percentage for subsidiaries must not exceed 100%") ||
      !checkCombinedSharesOwned(shareholders, businessShareholders)
    ) {
      return;
    }
  
    // Helper function to add question if value is not empty
    const addQuestion = (key, questionText, value) => {
      if (value !== undefined && value !== null && value !== "") {
        questions.push({
          key,
          question: questionText,
          value,
          meta_data: {},
        });
      }
    };
  
    addQuestion("name", "What is the name?", formValues.name);
    addQuestion("email", "What is the email?", formValues.email);
    addQuestion("businessName", "What is the business name?", formValues.businessName);
    addQuestion("website", "What is the website?", formValues.website);
    addQuestion("entity_type", "What is your entity type?", formValues.entity_type);
    addQuestion("tin", "What is the TIN?", formValues.tin);
    addQuestion("tin_type", "What is the TIN type?", formValues.tin_type);
    addQuestion("business_address", "What is the business address?", formValues.business_address);
    addQuestion("mailing_address", "What is the mailing address?", formValues.mailing_address);
    addQuestion("state_of_incorporation", "What is the state of incorporation?", formValues.state_of_incorporation);
    addQuestion("state_payer_number", "What is the state payer number?", formValues.state_payer_number);
    addQuestion("date_of_incorporation", "What is the date of incorporation?", formValues.date_of_incorporation);
    addQuestion("naics_code", "What is the NAICS code?", formValues.naics_code);
    addQuestion("business_type", "What is the business type?", formValues.business_type);
    addQuestion("bookkeeping_method", "What is the bookkeeping method?", formValues.bookkeeping_method);
    addQuestion("shareholder_type", "What is the shareholder type?", formValues.shareholder_type);
    addQuestion("subsidiaries_exist", "Do subsidiaries exist?", formValues.subsidiaries_exist);
  
    // Only add stock ledger breakdown if there are shareholders
    if (shareholders.length > 0) {
      questions.push({
        key: "stock_ledger",
        question: "What is the stock share breakdown?",
        value: shareholders.map((s) => ({
          ownerName: s.ownerName,
          sharesOwned: s.sharesOwned,
          email: s.email,
          isForeignOwner: s.isForeignOwner,
        })),
        meta_data: {},
      });
    }
  
    // Only add business stock ledger breakdown if there are business shareholders
    if (businessShareholders.length > 0) {
      questions.push({
        key: "business_stock_ledger",
        question: "What is the business stock share breakdown?",
        value: businessShareholders.map((bs) => ({
          ownerName: bs.ownerName,
          businessType: bs.businessType,
          contactName: bs.contactName,
          email: bs.email,
          sharesOwned: bs.sharesOwned,
          isForeignOwner: bs.isForeignOwner,
        })),
        meta_data: {},
      });
    }
  
    // Only add subsidiary stock ledger breakdown if there are subsidiaries
    if (subsidiaries.length > 0) {
      questions.push({
        key: "subsidiary_stock_ledger",
        question: "What is the subsidiary stock share breakdown?",
        value: subsidiaries.map((s) => ({
          subsidiary: s.subsidiary,
          businessType: s.businessType,
          contactName: s.contactName,
          email: s.email,
          sharesOwned: s.sharesOwned,
          isForeignOwner: s.isForeignOwner,
        })),
        meta_data: {},
      });
    }
    
    businessDataCollection({
      business_id: selectedBusiness.business_id,
      business_name: formValues.businessName,
      business_description: formValues.businessDescription,
    }).then((response) => {
      console.log("Business data collection response: ", response);
    }).catch((error) => {
      console.error("Business data collection error: ", error);
      Message({ type: "error", content: error.message });
    });

    updateBusinessQuestion.mutate({
      business_id: selectedBusiness.business_id,
      body: questions,
    });
  };

  // Function to add and remove shareholders
  const handleAddShareholder = () => {
    setShareholders([...shareholders, { ownerName: '', sharesOwned: '', email: '', isForeignOwner: false }]);
  };
  const handleRemoveShareholder = (index) => {
    if (shareholders.length > 1) {
      setShareholders(shareholders.filter((_, i) => i !== index));
    } else {
      Message({ type: "error", content: "There must be at least one shareholder." });
    }
  };
  const handleShareholderChange = (index, field, value) => {
    const newShareholders = [...shareholders];
    newShareholders[index][field] = value;
    
    // Calculate the total shares owned by all shareholders
    const totalSharesOwned = newShareholders.reduce((sum, shareholder) => {
      return sum + (parseFloat(shareholder.sharesOwned) || 0);
    }, 0);
  
    // Check if the total shares exceed 100%
    if (totalSharesOwned > 100) {
      Message({ type: "error", content: "Total ownership percentages cannot exceed 100%." });
    }

    setShareholders(newShareholders);
  };

  // Functions to add and remove business shareholders
  const handleAddBusinessShareholder = () => {
    setBusinessShareholders([...businessShareholders, { ownerName: '', businessType: '', contactName: '', email: '', sharesOwned: '', isForeignOwner: false }]);
  };
  const handleRemoveBusinessShareholder = (index) => {
    if (businessShareholders.length > 1) {
      setBusinessShareholders(businessShareholders.filter((_, i) => i !== index));
    } else {
      Message({ type: "error", content: "There must be at least one business shareholder." });
    }
  };
  const handleBusinessShareholderChange = (index, field, value) => {
    const newBusinessShareholders = [...businessShareholders];
    newBusinessShareholders[index][field] = value;
  
    // Calculate the total shares owned by all shareholders
    const totalSharesOwned = newBusinessShareholders.reduce((sum, shareholder) => {
      return sum + (parseFloat(shareholder.sharesOwned) || 0);
    }, 0);
  
    // Check if the total shares exceed 100%
    if (totalSharesOwned > 100) {
      Message({ type: "error", content: "Total ownership percentages cannot exceed 100%." });
    }
    setBusinessShareholders(newBusinessShareholders);
  };

  // Functions to add and remove subsidiaries
  const handleAddSubsidiary = () => {
    setSubsidiaries([...subsidiaries, { subsidiary: '', businessType: '', contactName: '', email: '', sharesOwned: '', isForeignOwner: false }]);
  };
  const handleRemoveSubsidiary = (index) => {
    if (subsidiaries.length > 1) {
      setSubsidiaries(subsidiaries.filter((_, i) => i !== index));
    } else {
      Message({ type: "error", content: "There must be at least one subsidiary." });
    }
  };
  const handleSubsidiaryChange = (index, field, value) => {
    const newSubsidiaries = [...subsidiaries];
    newSubsidiaries[index][field] = value;

    // Calculate the total shares owned by all shareholders
    // const totalSharesOwned = newSubsidiaries.reduce((sum, shareholder) => {
    //   return sum + (parseFloat(shareholder.sharesOwned) || 0);
    // }, 0);
  
    // Check if the total shares exceed 100%
    // if (totalSharesOwned > 100) {
    //   Message({ type: "error", content: "Total ownership percentages cannot exceed 100%." });
    // }

    setSubsidiaries(newSubsidiaries);
  };

  // Process the NAICS data to create options
  const naicsOptions = naicsData.map((item) => {
    const optionText = `${item['2022_naics_code']}-${item['2022_naics_title']}`;
    return (
      <Option key={item['2022_naics_code']} value={optionText}>
        <Popover title={optionText} content={item.description}>
          <span>{optionText}</span>
        </Popover>
      </Option>
    );
  });

  // Process the NAICS data to create options
  const incorporationStatesOptions = incorporationStatesData.map((item, index) => {
    const optionText = item["state"];
    return (
      <Option key={index} value={optionText}>
        <span>{optionText}</span>
      </Option>
    );
  });

    // Function to handle file input change
  const handleFileInputChange = (event, folderName) => {
    // Execute file upload logic
    uploadToFolder({
      business_id: selectedBusiness.business_id,
      otterz_id: localStorage.getItem("otterz_id"),
      folder_name: folderName,
      file: event.fileList[0].originFileObj,
    }).then((data) => {
      if (data.status) {
        Message({
          type: "success",
          content: "File uploaded successfully",
        });
      } else {
        Message({
          type: "error",
          content: data.errors[0].message,
        });
      }
    });
  };

  return (
    <div className={classNames.rolesPermissions}>
      {/* {
      !isProfileView && <section className={classNames.greetingsSection}>
        <h1 style={{ color: '#82898F', margin: 0 }}>Hello, {isClient ? userData?.first_name : "Otterz Tax Support"}</h1>
      </section>
      } */}

      {
        isClient && <section className={classNames.extendedSection}>
        <Row gutter={20} style={{width: '100%'}}>
          {/* First Card */}
          <Col span={8} className={classNames.upgradePlan}>
            <Card className={classNames.extendedCard}>
              <div className={classNames.cardContent} >
                <h3 className={classNames.cardHeading} style={{
                  textAlign: 'left',
                  margin: '0 0 10px 30px',
                  width: '100%',
                  padding: '0px',
                  fontSize: '1rem'
                }}>
                  For access to:
                </h3>
                <div className={classNames.flexRow} style={{
                  gap: '30px'
                }}>
                  <div>
                    <p>Multiple Revenue Systems</p>
                  </div>
                  <div>
                    <p>Unlimited Charts of Accounts</p>
                  </div>
                </div>
                <CTAButton onClick={() => {navigate('/app/settings/plans-preferences')}} style={{ 
                  background: 'linear-gradient(92.72deg, #CF830E 8.37%, #C85103 94.73%)',
                  marginTop: '10px',
                  border: 'none'
                }} >Upgrade Plan</CTAButton>
              </div>
            </Card>
          </Col>

          {/* Second Card */}
          <Col span={8} className={classNames.upgradePlan}>
            <Card className={classNames.extendedCard}>
              <div className={classNames.cardContent} >
                <h3 className={classNames.cardHeading} style={{
                  textAlign: 'left',
                  margin: '0 0 10px 30px',
                  width: '100%',
                  padding: '0px',
                  fontSize: '1rem'
                }}>
                  Contact account manager to:
                </h3>
                <div className={classNames.flexRow} style={{
                  gap: '30px'
                }}>
                  <div>
                    <p>Discuss Payment Options</p>
                  </div>
                  <div>
                    <p>Enable payment acceptance</p>
                  </div>
                </div>
                <CTAButton onClick={() => {setIsPaymentAcceptanceModalVisible(true)}} style={{
                  marginTop: '10px',
                }}>Setup Payment Acceptance</CTAButton>
              </div>
            </Card>
          </Col>
        </Row>
      </section>
      }

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spin size={'large'} />
        </div>
      ) : (
      !isProfileView && <div style={{
        height: '100%',
        background: 'white',
        borderRadius: '8px',
        margin: '0.5rem',
        padding: '0px 0 0 2rem',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 4px 0px'
      }}>
        <Form
          loading={loading}
          form={form}
          layout="horizontal"
          className={classNames.profileForm}
          onValuesChange={handleFormChange}
          initialValues={{
            name: userData?.first_name === undefined ? '' : userData?.first_name+' '+userData?.last_name,
            email: userData?.email_id,
            phoneNumber: userData?.phone_no === undefined ? '' : userData?.phone_no,
            businessName: selectedBusiness?.name,
            businessDescription: selectedBusiness?.description,
            // Add other fields if needed
          }}
        >
          <div className={classNames.formLayout}>
            <div className={classNames.leftColumn}>
              <Form.Item label="Name" name="name">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Phone Number" 
                // name="phoneNumber"
                rules={[
                  // { required: true, message: 'Phone number is required' },
                  { pattern: /^\+?[1-9]\d{1,14}$/, message: 'Enter a valid phone number' }
                ]}
                className={classNames.formItem}
              >
                <PhoneInput
                  country={'us'}
                  value={isClient ? userData?.phone_no : rowData?.phone}
                  onChange={(phone) => {
                    form.setFieldsValue({ phoneNumber: phone })
                    setFormChanged(true);
                  }}
                  inputStyle={{ width: '100%', height: '50px' }}
                  containerStyle={{ width: '100%', height: '50px' }}
                  placeholder="Phone Number"
                />
              </Form.Item>
              <Form.Item label="Business" name="businessName">
                <Input disabled />
              </Form.Item>
              {/* <Form.Item label="Business Description" name="businessDescription">
                <Input disabled />
              </Form.Item> */}
              {/* <Form.Item label="Password" name="password">
                <Input.Password />
              </Form.Item> */}
              <Form.Item style={{textAlign: 'right'}}>
                <CTAButton className={classNames.saveButton} onClick={() => {
                  localStorage.clear();
                  navigate(ROUTES.PUBLIC.FORGOT_PASSWORD)
                }}>Reset Password</CTAButton>
              </Form.Item>
            </div>
          </div>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            {formChanged && (
              <CTAButton onClick={handleCancel} className={classNames.cancelButton}>
                Cancel
              </CTAButton>
            )}
            <CTAButton type="primary" htmlType="submit" loading={createUser.isLoading} onClick={()=>{handleOnFinish()}} className={classNames.saveButton} disabled={!formChanged}>
              Save Changes
            </CTAButton>
          </Form.Item>
        </Form>
      </div>)}
    

      {businessQuestions && businessQuestions.length > 0 ? (
        <Card className={classNames.card} style={{
          // height: '100%',
          background: 'white',
          borderRadius: '8px',
          margin: '0.5rem',
          padding: '0px 0 0 0rem',
          // boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 4px 0px'
        }}>
          <Form
            form={form}
            onFinish={() => {}} // You can handle this if needed
            scrollToFirstError
            onValuesChange={() => {}}
            layout="horizontal"
            labelWrap
            style={{width: isProfileView ? '100%' : "70%"}}
          >
            <h2 style={{fontWeight: '400'}}>Business Details</h2>
            <div style={{width: '50%'}}>
              <Form.Item
                key={"businessName"}
                name={"businessName"}
                label={"Business Name"}
                initialValue={selectedBusiness.name}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item label="Business Description" name="businessDescription">
                <Input disabled={formDisabled} />
              </Form.Item>

              <Form.Item
                key={"website"}
                name={"website"}
                label={"Website"}
                initialValue={businessQuestions.find(q => q.key === "website")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <Input disabled={formDisabled}/>
              </Form.Item>

              <Form.Item
                key={"entity_type"}
                name={"entity_type"}
                label={"Entity Type"}
                initialValue={businessQuestions.find(q => q.key === "entity_type")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <>
                  <Select
                    disabled={formDisabled}
                    name={"entity_type"}
                    value={entityType || businessQuestions.find(q => q.key === "entity_type")?.value || ""}
                    onChange={(value) => {
                      form.setFieldsValue({ entity_type: value });
                      setEntityType(value);
                    }}
                  >
                    <Option value="C Corp">C Corp</Option>
                    <Option value="LLC">LLC</Option>
                    <Option value="Partnership">Partnership</Option>
                    <Option value="S Corp">S Corp</Option>
                    <Option value="Sole Proprietorship">Sole Proprietorship</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.entity_type !== currentValues.entity_type}
                  >
                    {({ getFieldValue }) => {
                      return getFieldValue('entity_type') === 'Other' ? (
                        <Form.Item
                          style={{
                            margin: '1rem 0 0 0',
                            position: 'relative',
                            right: '200px',
                            width: '35vw'
                          }}
                          name='other_entity_type'
                          label="Other Entity Type"
                          rules={[{ required: true, message: 'Please input other entity type!' }]}
                        >
                          <Input />
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                </>
              </Form.Item>

              <Form.Item
                key={"tin"}
                name={"tin"}
                label={"TIN"}
                initialValue={businessQuestions.find(q => q.key === "tin")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <Input disabled={formDisabled}/>
              </Form.Item>

              <Form.Item
                key={"tin_type"}
                name={"tin_type"}
                label={"TIN Type"}
                initialValue={businessQuestions.find(q => q.key === "tin_type")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <>
                  <Select
                    disabled={formDisabled}
                    name={"tin_type"}
                    value={tinType || businessQuestions.find(q => q.key === "tin_type")?.value || ""}
                    onChange={(value) => {
                      form.setFieldsValue({ tin_type: value });
                      setTINType(value)
                    }}
                  >
                    <Option value="EIN">EIN</Option>
                    <Option value="SSN">SSN/ITIN</Option>
                    <Option value="Foreign">Foreign</Option>
                  </Select>
                </>
              </Form.Item>

              <Form.Item
                key={"business_address"}
                name={"business_address"}
                label={"Business Address"}
                initialValue={businessQuestions.find(q => q.key === "business_address")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <Input disabled={formDisabled}/>
              </Form.Item>

              <div style={{}}>
                <Form.Item
                  key={"mailing_address"}
                  name={"mailing_address"}
                  label={"Mailing Address"}
                  initialValue={businessQuestions.find(q => q.key === "mailing_address")?.value || ""}
                  rules={[{ required: true, message: 'Please input this field!' }]}
                >
                  <Input disabled={formDisabled}/>
                </Form.Item>

                <Form.Item style={{textAlign: 'right'}}>
                  <Checkbox
                    disabled={formDisabled}
                    onChange={(e) => {
                      if (e.target.checked) {
                        form.setFieldsValue({
                          mailing_address: form.getFieldValue("business_address")
                        });
                      }
                    }}
                  >
                    Mailing and business address are the same
                  </Checkbox>
                </Form.Item>
              </div>

              <Form.Item
                key={"state_of_incorporation"}
                name={"state_of_incorporation"}
                label={"State of Incorporation"}
                initialValue={businessQuestions.find(q => q.key === "state_of_incorporation")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                {/* <Input disabled={formDisabled}/> */}

                <Select
                    disabled={formDisabled}
                    name={"state_of_incorporation"}
                    value={stateOfIncorporation || businessQuestions.find(q => q.key === "state_of_incorporation")?.value || ""}
                    onChange={(value) => {
                      form.setFieldsValue({ state_of_incorporation: value });
                      setStateOFIncorporation(value);
                    }}
                    showSearch
                    placeholder="Select State of Incorporation"
                    // optionFilterProp="label" // We'll use this to filter the options
                    // filterOption={(input, option) =>
                    //   option?.label?.toLowerCase().includes(input.toLowerCase())
                    // }
                  >
                    {incorporationStatesOptions}
                  </Select>
              </Form.Item>

              <Form.Item
                key={"state_payer_number"}
                name={"state_payer_number"}
                label={"State/Payer's state no."}
                initialValue={businessQuestions.find(q => q.key === "state_payer_number")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <Input disabled={formDisabled}/>
              </Form.Item>

              <Form.Item
                key={"date_of_incorporation"}
                name={"date_of_incorporation"}
                label={"Date of Incorporation"}
                valuePropName={'date_of_incorporation'}
                initialValue={
                  businessQuestions.find(q => q.key === "date_of_incorporation")?.value
                    ? moment(businessQuestions.find(q => q.key === "date_of_incorporation")?.value, "MM-DD-YYYY")
                    : null
                }
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <DatePicker
                  disabled={formDisabled}
                  value={dateOfIncorporation !== "" ? moment(dateOfIncorporation, "MM-DD-YYYY") : null}
                  onChange={(date) => {
                    form.setFieldsValue({ date_of_incorporation: date });
                    setDateOfIncorporation(date);
                  }
                  }
                  format="MM-DD-YYYY" // Adjust format as needed
                />
              </Form.Item>

              <Form.Item
                key={"naics_code"}
                name={"naics_code"}
                label={"NAICS Code"}
                initialValue={naicsType || businessQuestions.find(q => q.key === "naics_code")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <>
                  <Select
                    disabled={formDisabled}
                    name={"naics_code"}
                    value={naicsType || businessQuestions.find(q => q.key === "naics_code")?.value || ""}
                    onChange={(value) => {
                      form.setFieldsValue({ naics_code: value });
                      setnaicsType(value);
                    }}
                    showSearch
                    placeholder="Select NAICS Code"
                    // optionFilterProp="label" // We'll use this to filter the options
                    // filterOption={(input, option) =>
                    //   option?.label?.toLowerCase().includes(input.toLowerCase())
                    // }
                  >
                    {naicsOptions}
                  </Select>
                </>
              </Form.Item>

              <Form.Item
                key={"business_type"}
                name={"business_type"}
                label={"Business Type"}
                initialValue={businessQuestions.find(q => q.key === "business_type")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <Input disabled={formDisabled}/>
              </Form.Item>

              <Form.Item
                key={"bookkeeping_method"}
                name={"bookkeeping_method"}
                label={"Bookkeeping Method"}
                initialValue={businessQuestions.find(q => q.key === "bookkeeping_method")?.value || "Cash"}
                style={{
                  alignItems: 'center',
                }}
              >
                <Radio.Group style={{display: 'flex', gap: '70px'}} onChange={(e) => {
                  form.setFieldsValue({ bookkeeping_method: e.target.value });
                  setBookkeepingMethod(e.target.value);
                }} value={"Cash"} disabled={formDisabled}>
                    <Radio value={"Cash"}>Cash</Radio>
                    <Radio value={"Accrual"}>Accrual</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <h2 style={{fontWeight: '400', margin: '2rem 0'}}>Shareholder Details</h2>

            <Form.Item
              key={"shareholder_type"}
              name={"shareholder_type"}
              label={"Shareholder Type"}
              initialValue={shareholderType}
              style={{ alignItems: 'center' }}
            >
              <Radio.Group style={{display: 'flex', gap: '70px'}} onChange={(e) => {
                form.setFieldsValue({ shareholder_type: e.target.value });
                setShareholderType(e.target.value);
              }} value={shareholderType} disabled={formDisabled}>
                <Radio value={"Individuals"}>Individuals</Radio>
                <Radio value={"Entities"}>Entities</Radio>
                <Radio value={"Both"}>Both</Radio>
              </Radio.Group>
            </Form.Item>
            
            {shareholderType === "Individuals" || shareholderType === "Both" ? (
              <Form.Item
                key={"stock_ledger"}
                label={null}
                initialValue={shareholders}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <div style={{width: '100%'}}>
                  <table className={classNames.table} style={{width: '100%'}}>
                    <thead>
                      <tr>
                        <th>Individual Shareholder Name</th>
                        <th>Ownership</th>
                        <th>Email Address</th>
                        <th>Foreign Owner</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {shareholders.map((owner, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Item initialValue={owner.ownerName}>
                              <Input
                                disabled={formDisabled}
                                value={owner.ownerName}
                                onChange={(e) => handleShareholderChange(index, 'ownerName', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item initialValue={owner.sharesOwned}>
                              <InputNumber
                                disabled={formDisabled}
                                min={0}
                                max={100}
                                addonAfter="%"
                                value={owner.sharesOwned}
                                onChange={(value) => handleShareholderChange(index, 'sharesOwned', value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item initialValue={owner.email}>
                              <Input
                                disabled={formDisabled}
                                value={owner.email}
                                onChange={(e) => handleShareholderChange(index, 'email', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item initialValue={"Yes"}>
                              <Select
                                disabled={formDisabled}
                                value={shareholders[index].isForeignOwner ? "Yes" : "No"}
                                onChange={(value) => handleShareholderChange(index, 'isForeignOwner', value)}
                              >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                              </Select>
                            </Form.Item>
                          </td>
                          <td>
                            <Upload
                              showUploadList={false}
                              beforeUpload={(file) => {
                                return false;
                              }}
                              // fileList={fileList}
                              onChange={(info) => handleFileInputChange(info, "Individual Shareholder Document")}
                            >
                              <Button style={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                              }} type="link" icon={<FolderIcon />} disabled={formDisabled}>
                                {/* <FolderIcon /> */}
                                Upload Files
                              </Button>
                            </Upload>
                          </td>
                          <td>
                            <Button
                              type="link"
                              onClick={() => handleRemoveShareholder(index)}
                              disabled={shareholders.length === 1 || formDisabled}
                            >
                              <MinusCircleOutlined className={classNames.removeIcon} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                    
                  <Button
                    type="link"
                    onClick={handleAddShareholder}
                    style={{ margin: '16px', color: 'black', textAlign: 'right' }}
                    block
                    disabled={formDisabled}
                    icon={<PlusOutlined />}
                  >
                    Add Individual Shareholders
                  </Button>
                </div>
              </Form.Item>
            ) : null}

            {(shareholderType === "Entities" || shareholderType === "Both") && !isProfileView ? (
              <Form.Item
                key={"business_shareholders"}
                // name={"business_shareholders"}
                label={null}
                initialValue={businessQuestions.find(q => q.key === "business_stock_ledger")?.value || ""}
                rules={[{ required: true, message: 'Please input this field!' }]}
                style={{width: '100%'}}
              >
                <>
                  <table className={classNames.table} style={{width: isProfileView ? '100%' : '72vw'}}>
                    <thead>
                      <tr>
                        <th>Business Shareholder Name</th>
                        <th>Ownership %</th>
                        <th>Business Entity</th>
                        {/* {
                          businessShareholders.some(sub => sub.businessType === "Other") &&
                          <th>Other Business Entity</th>
                        } */}
                        <th>Contact Name</th>
                        <th>Contact Email</th>
                        <th>Foreign Owner</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessShareholders.map((owner, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Item 
                            // name={`business_ownerName${index}`}
                             initialValue={owner.ownerName}>
                              <Input
                                disabled={formDisabled}
                                value={owner.ownerName}
                                onChange={(e) => handleBusinessShareholderChange(index, 'ownerName', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item 
                            // name={`business_sharesOwned${index}`}
                             initialValue={owner.sharesOwned}>
                              <InputNumber
                                disabled={formDisabled}
                                min={0}
                                max={100}
                                addonAfter="%"
                                value={owner.sharesOwned}
                                onChange={(value) => handleBusinessShareholderChange(index, 'sharesOwned', value)}
                              />
                            </Form.Item>
                          </td>

                          {/* <td>
                            <Form.Item 
                             initialValue={owner.businessType}>
                              <Input
                                disabled={formDisabled}
                                value={owner.businessType}
                                onChange={(e) => handleBusinessShareholderChange(index, 'businessType', e.target.value)}
                              />
                            </Form.Item>
                          </td> */}

                          <td>
                            <Form.Item
                              //  name={`subsidiaries_businessType${index}`}
                              initialValue={owner.businessType}
                            >
                              <Select
                                disabled={formDisabled}
                                value={businessShareholders[index].businessType}
                                onChange={(value) => handleBusinessShareholderChange(index, 'businessType', value)}
                              >
                                <Option value="C Corp">C Corp</Option>
                                <Option value="LLC">LLC</Option>
                                <Option value="Partnership">Partnership</Option>
                                <Option value="S Corp">S Corp</Option>
                                <Option value="Sole Proprietorship">Sole Proprietorship</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                              {/* <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.entity_type !== currentValues.entity_type}
                              >
                                {({ getFieldValue }) => {
                                  return getFieldValue('entity_type') === 'Other' ? (
                                    <Form.Item
                                      style={{
                                        margin: '1rem 0 0 0',
                                        position: 'relative',
                                        right: '200px',
                                        width: '35vw'
                                      }}
                                      name='other_entity_type'
                                      label="Other Entity Type"
                                      rules={[{ required: true, message: 'Please input other entity type!' }]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  ) : null;
                                }}
                              </Form.Item> */}
                            </Form.Item>
                          </td>

                          {/* {businessShareholders[index].businessType === "Other" &&
                            <td>
                              <Form.Item
                                initialValue={owner.businessType}>
                                  <Input
                                    disabled={formDisabled}
                                    onChange={(e) => handleBusinessShareholderChange(index, 'otherBusinessType', e.target.value)}
                                  />
                              </Form.Item>
                            </td>
                          } */}

                          <td>
                            <Form.Item 
                            // name={`business_contactName${index}`}
                             initialValue={owner.contactName}>
                              <Input
                                disabled={formDisabled}
                                value={owner.contactName}
                                onChange={(e) => handleBusinessShareholderChange(index, 'contactName', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item 
                            // name={`business_email${index}`}
                             initialValue={owner.email}>
                              <Input
                                disabled={formDisabled}
                                value={owner.email}
                                onChange={(e) => handleBusinessShareholderChange(index, 'email', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item 
                            // name={`business_isForeignOwner${index}`}
                             initialValue={"Yes"}>
                              <Select
                                disabled={formDisabled}
                                // name={"isForeignOwner"}
                                value={businessShareholders[index].isForeignOwner ? "Yes" : "No"}
                                onChange={(value) => handleBusinessShareholderChange(index, 'isForeignOwner', value)}
                              >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                              </Select>
                            </Form.Item>
                          </td>
                          <td>
                            <Upload
                              showUploadList={false}
                              beforeUpload={(file) => {
                                return false;
                              }}
                              // fileList={fileList}
                              onChange={(info) => handleFileInputChange(info, "Business Shareholder Document")}
                            >
                              <Button type="link" style={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center'
                              }} icon={<FolderIcon />} disabled={formDisabled}>
                                {/* <FolderIcon /> */}
                                Upload Files
                              </Button>
                            </Upload>
                          </td>
                          <td>
                            <Button
                              type="link"
                              onClick={() => handleRemoveBusinessShareholder(index)}
                              disabled={businessShareholders.length === 1 || formDisabled}
                            >
                              <MinusCircleOutlined className={classNames.removeIcon} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Button
                    type="link"
                    onClick={handleAddBusinessShareholder}
                    style={{ margin: '16px', color: 'black', textAlign: 'right', width: isProfileView ? '100%' : '72vw' }}
                    block
                    disabled={formDisabled}
                    icon={<PlusOutlined />}
                  >
                    Add Business Shareholders
                  </Button>
                </>
              </Form.Item>
            ) : null}

            {
              !isProfileView && <h2 style={{fontWeight: '400', margin: '2rem 0'}}>Subsidiaries</h2>
            }

            {
              !isProfileView && <Form.Item
                key={"subsidiaries_exist"}
                name={"subsidiaries_exist"}
                label={"Does the business have any subsidiaries?"}
                initialValue={businessQuestions.find(q => q.key === "subsidiaries_exist")?.value || ""}
                style={{
                  alignItems: 'center',
                }}
              >
                <Radio.Group style={{display: 'flex', gap: '70px'}} onChange={(e) => {
                  form.setFieldsValue({ subsidiaries_exist: e.target.value });
                  setSubsidiariesExist(e.target.value);
                }} value={"Both"} disabled={formDisabled}>
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                </Radio.Group>
              </Form.Item>
            }

            {subsidiariesExist === "Yes" && !isProfileView ? (
              <Form.Item
                key={"subsidiaries"}
                // name={"subsidiaries"}
                label={null}
                initialValue={businessQuestions.find(q => q.key === "subsidiary_stock_ledger")?.value || ""}
                style={{width: '100%'}}
                rules={[{ required: true, message: 'Please input this field!' }]}
              >
                <>
                  {/* <Button type="link" onClick={handleAddShareholder} >
                    + Add Shareholder
                  </Button> */}

                  <table className={classNames.table} style={{width: isProfileView ? '100%' : '72vw'}}>
                    <thead>
                      <tr>
                        <th>Subsidiary Name</th>
                        <th>Ownership %</th>
                        <th>Business Entity</th>
                        {/* {
                          subsidiaries.some(sub => sub.businessType === "Other") &&
                          <th>Other Business Entity</th>
                        } */}
                        <th>Contact Name</th>
                        <th>Contact Email</th>
                        <th>Foreign Owner</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {subsidiaries.map((owner, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Item 
                            // name={`subsidiaries_subsidiary${index}`}
                             initialValue={owner.subsidiary}>
                              <Input
                                disabled={formDisabled}
                                value={owner.subsidiary}
                                onChange={(e) => handleSubsidiaryChange(index, 'subsidiary', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item 
                            // name={`subsidiaries_sharesOwned${index}`}
                             initialValue={owner.sharesOwned}>
                              <InputNumber
                                disabled={formDisabled}
                                min={0}
                                max={100}
                                addonAfter="%"
                                value={owner.sharesOwned}
                                onChange={(value) => handleSubsidiaryChange(index, 'sharesOwned', value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              //  name={`subsidiaries_businessType${index}`}
                              initialValue={owner.businessType}
                            >
                              <Select
                                disabled={formDisabled}
                                value={subsidiaries[index].businessType}
                                onChange={(value) => handleSubsidiaryChange(index, 'businessType', value)}
                              >
                                <Option value="C Corp">C Corp</Option>
                                <Option value="LLC">LLC</Option>
                                <Option value="Partnership">Partnership</Option>
                                <Option value="S Corp">S Corp</Option>
                                <Option value="Sole Proprietorship">Sole Proprietorship</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                              {/* <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.entity_type !== currentValues.entity_type}
                              >
                                {({ getFieldValue }) => {
                                  return getFieldValue('entity_type') === 'Other' ? (
                                    <Form.Item
                                      style={{
                                        margin: '1rem 0 0 0',
                                        position: 'relative',
                                        right: '200px',
                                        width: '35vw'
                                      }}
                                      name='other_entity_type'
                                      label="Other Entity Type"
                                      rules={[{ required: true, message: 'Please input other entity type!' }]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  ) : null;
                                }}
                              </Form.Item> */}
                            </Form.Item>
                          </td>

                          {/* {subsidiaries[index].businessType === "Other" &&
                            <td>
                              <Form.Item
                                initialValue={owner.businessType}>
                                  <Input
                                    disabled={formDisabled}
                                    onChange={(e) => handleSubsidiaryChange(index, 'otherBusinessType', e.target.value)}
                                  />
                              </Form.Item>
                            </td>
                          } */}

                          <td>
                            <Form.Item 
                            // name={`subsidiaries_contactName${index}`}
                             initialValue={owner.contactName}>
                              <Input
                                disabled={formDisabled}
                                value={owner.contactName}
                                onChange={(e) => handleSubsidiaryChange(index, 'contactName', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item 
                            // name={`subsidiaries_email${index}`}
                             initialValue={owner.email}>
                              <Input
                                disabled={formDisabled}
                                value={owner.email}
                                onChange={(e) => handleSubsidiaryChange(index, 'email', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item 
                            // name={`subsidiaries_isForeignOwner${index}`}
                             initialValue={"Yes"}>
                              <Select
                                disabled={formDisabled}
                                // name={"isForeignOwner"}
                                value={subsidiaries[index].isForeignOwner ? "Yes" : "No"}
                                onChange={(value) => handleSubsidiaryChange(index, 'isForeignOwner', value)}
                              >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                              </Select>
                            </Form.Item>
                          </td>
                          <td>
                            <Upload
                              showUploadList={false}
                              beforeUpload={(file) => {
                                return false;
                              }}
                              // fileList={fileList}
                              onChange={(info) => handleFileInputChange(info, "Subsidiary Document")}
                            >
                              <Button type="link" style={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center'
                              }} icon={<FolderIcon />} disabled={formDisabled}>
                                {/* <FolderIcon /> */}
                                Upload Files
                              </Button>
                            </Upload>
                          </td>
                          <td>
                            <Button
                              type="link"
                              onClick={() => handleRemoveSubsidiary(index)}
                              disabled={subsidiaries.length === 1 || formDisabled}
                            >
                              <MinusCircleOutlined className={classNames.removeIcon} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Button
                    type="link"
                    onClick={handleAddSubsidiary}
                    style={{ margin: '16px', color: 'black', textAlign: 'right', width: isProfileView ? '100%' : '72vw' }}
                    block
                    disabled={formDisabled}
                    icon={<PlusOutlined />}
                  >
                    Add Subsidiary
                  </Button>
                </>
              </Form.Item>
            ) : null}
          </Form>

          <div style={{
            display: 'flex',
            width: isProfileView ? '100%' : '50%',
            gap: '1rem',
            justifyContent: 'flex-end'
          }}>
            {!formDisabled && <CTAButton
              style={{ marginTop: "1rem", width: '25%'}}
              loading={updateBusinessQuestion.isLoading}
              disabled={formDisabled}
              onClick={handleUpdate}
            >
              Update
            </CTAButton>}
            {formDisabled && <CTAButton
              // ghost
              style={{ marginTop: "1rem", width: '24%'}}
              // loading={updateTaxOnboardingStatus.isLoading}
              // disabled={continueDisabled}
              disabled={!formDisabled}
              onClick={() => {
                setFormDisabled(false);
              }}
            >
              Edit
            </CTAButton>}
            {
              isProfileView && <CTAButton
                style={{ marginTop: "1rem", width: '25%' }}
                // loading={null}
                onClick={handleContinue}
              >
                Approve
              </CTAButton>
            }
          </div>
        </Card>
      ) : null}
          
      <Modal
          title="Setup Payment Acceptance - Book a call with us"
          visible={isPaymentAcceptanceModalVisible}
          onCancel={()=>{setIsPaymentAcceptanceModalVisible(false)}}  // This line should be sufficient to close the modal when clicking outside the modal or pressing the escape key.
          footer={null}
          closable={true}
          onCloseClick={()=>{setIsPaymentAcceptanceModalVisible(false)}}
          width={800}
      >
        <Row justify="center" align="middle">
          <Col>
            <div style={{ textAlign: 'center' }}>
              <div style={{
                width: '500px',
                border: '1px solid rgb(255 255 255)',
                boxShadow: '0px 0px 8px 0px #c2c2c2',
                borderRadius: '3px'
              }}>
                <iframe
                  title='Schedule a meeting with Otterz'
                  src="https://meetings.hubspot.com/tapan-r?embed=true"
                  width="100%"
                  height="600px"
                  frameBorder="0"
                  style={{ border: 'none' }}
                  allowFullScreen
                ></iframe>
              </div>
              <Row style={{justifyContent: 'space-around', marginTop: '20px'}}>
                <CTAButton style={{width: '40%'}} onClick={() => {
                  Message({ type: "success", content: "Booking successful" });
                }}>Continue</CTAButton>
                <CTAButton style={{background: 'white', color: 'black', width: '40%'}} onClick={() => {setIsPaymentAcceptanceModalVisible(false)}}>Maybe Later</CTAButton>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>

    </div>
  );
};

export default Profile;
