import endpoints from "../../../endpoints";

export async function getTaxQueryMessages({ business_id, vendor_id}){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = `${endpoints.SERVICES.DASHBOARD.TAX_FILLING.GET_TAX_QUERIES_MESSAGES[env]}${business_id}/${vendor_id}`;
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
