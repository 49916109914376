import React, { useEffect, useState, useRef } from "react";
import classNames from "./Chatbot.module.scss";
// import ChatKitty from "@chatkitty/core";
import ChatKitty from 'chatkitty';
import { 
  Input,
  Upload, 
  Avatar, 
  Spin, 
  notification, 
  Button,
  Skeleton,
} from "antd";
import { InboxOutlined, 
  SendOutlined, 
  UploadOutlined, 
  UserOutlined
} from "@ant-design/icons";
import api from "../../API";
import { useBusiness } from "../../../store/StoreProvider";
import { Message } from "../../common";
import { useMutation } from "react-query";

function Chatbot({accountData, receiver}) {
  const secureLocalStore = window.localStorage || window.sessionStorage;
  // State variables initialization
  const [channel, setChannel] = useState(null); // Current chat channel
  const [sessionUser, setSessionUser] = useState(null); // Session user object
  const [messageInput, setMessageInput] = useState(""); // Input for typing messages
  const [, setFileInput] = useState(null); // Input for uploading files
  const [, setFileList] = useState([]);
  const [messages, setMessages] = useState([]); // Array to store chat messages
  const [, setContacts] = useState([]); // Array to store contacts
  const [typing, setTyping] = useState(null); // Typing indicator
  const [, setStyle] = useState('none'); // Styling for notifications
  const [fileLoading, setFileLoading] = useState(false); // Loading state for initial data fetching
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { TextArea } = Input;
  const messagesEndRef = useRef(null);

  // Fetching user data from context
  // const {userData} = useAccount();
  let { selectedBusiness } = useBusiness();

  selectedBusiness = Object.keys(selectedBusiness).length === 0 ? accountData : selectedBusiness;
  const [username, ] = useState(
    receiver === process.env.REACT_APP_ACCOUNTANT_EMAIL ? secureLocalStore.getItem('email') : process.env.REACT_APP_ACCOUNTANT_EMAIL
  );

  useEffect(() => {
    getMessageAccountant.mutate(selectedBusiness, {
      onSuccess: (response) => {
        setIsInitialLoad(false)
        const updatedMessages = response?.data?.map(item => {
          return {
            message: item.message,
            created_at: item.created_at,
            business_id: item.business_id,
            user: {
              displayName: item.sender
            }, 
            body: item.message
          }
        })
        setMessages(updatedMessages)
        scrollToBottom()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  // Initializing ChatKitty instance
  const chatkitty = ChatKitty.getInstance(process.env.REACT_APP_CHATKITTY_API_KEY);

  // Function to initialize session
  const initSession = async () => {
    const result = await chatkitty.startSession({
      username:  username
    });

    if (result.succeeded) {
      const session = result.session;
      setSessionUser(session);
      // Fetch contacts
      chatkitty.getUsers().then((result) => {
        setContacts(result.paginator.items);
      });
    }
    
    if (result.failed) {
      // const error = result.error;
      setIsInitialLoad(false)
    }
  }

  const sendMessageAccountant = useMutation((data) => api.Services.Dashboard.ChatKitty.sendMessageAccountant(data), {})
  const getMessageAccountant = useMutation((data) => api.Services.Dashboard.ChatKitty.getMessagesAccountant(data), {})

  // Function to initialize chat channel
  const initChannel = async () => {
    const result = await chatkitty.createChannel({
      type: 'DIRECT',
      members: [
        { username: receiver }, // Receiver's email
      ],
    });

    if (result.succeeded) {
      setChannel(result.channel);
    } else {
      setIsInitialLoad(false)
    }
  };

  // Function to notify user of new messages
  const notifyUser = (message) => {
    setStyle('initial');
    // Show Ant Design notification
    if(message?.user?.name?.toLowerCase()?.includes?.("nyra")){
      notification.open({
          message: getCurrentDate(),
          description: `New message from Nyra, ${message.body}`,
          duration: 2,
          onClick: () => {
            // Navigate to "documents-vault" when notification is clicked
        },
      });
    }
  };

  // Function to send message
  const sendMessage = async (messsage = messageInput?.trim?.()) => {
    if (!channel) return;
  
    const formattedMessage = messsage?.trim?.();
  
    if (!formattedMessage) {
      return; // Early exit if no message (including empty string after trim())
    }

    sendMessageAccountant.mutate({
      messageData: {
        sender: username,
        message: formattedMessage,
      },
      business_id: selectedBusiness?.business_id
    }, {onSuccess: async () =>{
      setMessageInput("");
      await getMessageAccountant.mutate(selectedBusiness, {
        onSuccess: (response) => {
          const updatedMessages = response?.data?.map(item => {
            return {
              message: item.message,
              created_at: item.created_at,
              business_id: item.business_id,
              user: {
                displayName: item.sender
              }, 
              body: item.message
            }
          })
          setMessages(updatedMessages)
          scrollToBottom()
        }
      })
      scrollToBottom()

      const bookkeeperUser = channel.members.find((member) => member.id !== sessionUser.id)

      if (receiver === "accountant@otterz.co" && bookkeeperUser.presence.online) {
        // Send email notification api call here
        await api.Services.Dashboard.ChatKitty.sendEmailNotificationChat({
          email: bookkeeperUser.name,
          message: `Hi, you have a new message from the User ${username}. Please login to your Otterz account to view the message.`,
          business_id: selectedBusiness.business_id
        })
      }
    }})
  };

  const uploadFileVault = async (file) => {
    try {
      setFileLoading(true);
      if (file === undefined) {
        Message({ type: "error", content: "Please select a file to upload" });
        setFileLoading(false);
        return;
      }

      const fileName = file.name;

      let  data = {} 
      data = {
        file: file,
        business_id: selectedBusiness.business_id,
        otterz_id: secureLocalStore.getItem('otterz_id'),
      }

      const response = await api.Services.Dashboard.DocumentsVault.uploadFileChat(data);
      if (response && response.data) {

        const message = `"${fileName}" uploaded. You can access the file in the "Uploaded Documents" folder of the Documents Vault or by clicking this link: <a href="https://ucarecdn.com/${response.data.file_id}/" target="_blank" rel="noopener noreferrer">here</a>.`;
        // setMessageInput(message);

        let completeMessage = "";
        if (messageInput?.trim?.() === "") {
          completeMessage = message;
        } else {
          completeMessage = messageInput + "<br/><br/>" + message;
        }

        // setMessages((prevMessages) => [...prevMessages, message]);
        scrollToBottom()

        sendMessage(completeMessage);
        // Clear the file input
        setFileInput(null);

        setFileLoading(false);
      } else {
        setFileLoading(false);
        Message({ type: "error", content: "Failed to create item" });
      }
    } catch (error) {
      setFileLoading(false);
      Message({ type: "error", content: `Failed to create item: ${error}` });
      return;
    }
  };

  // Function to handle file input change
  const handleFileInputChange = (event) => {
    setFileInput(event.fileList[0].originFileObj);
    // uploadFile(event.fileList[0].originFileObj);
    uploadFileVault(event.fileList[0].originFileObj);
  };

  // useEffect hook to initialize session on component mount
  useEffect(() => {
    chatkitty.endSession();
    initSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect hook to initialize chat channel when session user is set
  useEffect(() => {
    if (sessionUser) {
      initChannel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionUser]);

  // useEffect hook to start chat session and fetch messages when channel is set
  useEffect(() => {
    let chat = null;
    if (channel && sessionUser && selectedBusiness?.business_id) {
      try {
        chat = chatkitty.startChatSession({
          channel: channel,
          onReceivedMessage: (message) => {
            if (message.user.id === sessionUser.user.id) return;
            // setMessages((draft) => [...draft, message]);
            getMessageAccountant.mutate(selectedBusiness, {
              onSuccess: (response) => {
                const updatedMessages = response?.data?.map(item => {
                  return {
                    message: item.message,
                    created_at: item.created_at,
                    business_id: item.business_id,
                    user: {
                      displayName: item.sender
                    }, 
                    body: item.message
                  }
                })
                setMessages(updatedMessages)
                scrollToBottom()
              }
            })
            scrollToBottom()
            notifyUser(message);
          },
          onTypingStarted: (typingUser) => {
            if (typingUser.id !== sessionUser.user.id) {
              setTyping(typingUser);
            }
          },
          onTypingStopped: (typingUser) => {
            if (typingUser.id !== sessionUser.user.id) {
              setTyping(null);
            }
          },
        });
      } catch (error) {}
      

      if (chat.failed) {
        setIsInitialLoad(false)
      }
    }

    // Cleanup function
    return () => {
      // End chat session when component unmounts
      if (chat) {
        // chat.session.end();
        chatkitty.endSession();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, sessionUser, selectedBusiness?.business_id]);

  // Function to render styled text
  const renderStyledText = (text, message) => {

    // Check if the message type is FILE
    if (message.type === "FILE" && message.file) {
      text = `<a href="${message.file.url}" target="_blank" rel="noopener noreferrer">${message.file.name}</a>`;
    } else {
      // Replace <b> with <strong> and <i> with <em> for other text messages
      text = text.replace(/<b>(.*?)<\/b>/g, "<strong>$1</strong>");
      text = text.replace(/<i>(.*?)<\/i>/g, "<em>$1</em>");
    }

    // Render HTML
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  // Function to get current date
  function getCurrentDate() {
    const currentDate = new Date();
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  // Function to handle key press events in the text area
const handleKeyPress = (event) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault(); // Prevents new line in TextArea
    sendMessage(); // Calls the sendMessageAI function
  }
};

  // Function to handle notification click
  // const handleNotificationClick = () => {
  //   // Hide the circle icon
  //   setStyle('none')

  //   // Show Ant Design notification
  //   notification.open({
  //       message: getCurrentDate(),
  //       description: 'Nyra has a question regarding your Feb\'24 transactions.',
  //       duration: 2,
  //       onClick: () => {
  //         // Navigate to "documents-vault" when notification is clicked
  //     },
  //   });
  // };

  return (
    <div className={classNames.wrapper}>
      {(getMessageAccountant.isLoading && isInitialLoad) || isInitialLoad ? (
        <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} />
      ) : (
        <div ref={messagesEndRef} className={classNames.messagesContainer}>
          {messages?.length === 0 ? ( // Check if messages array is empty
            <div style={{ 
              textAlign: 'center',
              padding: '20px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              opacity: '0.5',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'center' 
            }}>
              <span>Your messages will appear here</span>
              <InboxOutlined style={{ marginLeft: '8px', fontSize: '24px', color: '#82898F' }} />
            </div>
          ) : (
            <>
              {
                messages?.map((message, index) => (
                  <div key={index} className={message?.user?.displayName === username ? classNames.message : classNames.receiver}>
                    <div className={message?.user?.displayName === username ? classNames.username : classNames.aiUsername}>
                      <div className={classNames.messageAuthor}>{message.user.displayName === process.env.REACT_APP_ACCOUNTANT_EMAIL ?  "accountant@otterz.co" :message?.user?.displayName}</div>
                    </div>
                    <div className={message?.user?.displayName === username ? classNames.messageContentSender : classNames.messageContent}>
                      <div className={classNames.messageAvatar}>
                        <Avatar icon={<UserOutlined />} />
                      </div>
                      <div className={classNames.messageBody}>
                        {message.isLoading ? (
                          <><Skeleton active /></>
                        ) : (
                          <>
                            {renderStyledText(message.body, message)}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              }
              {
                messages?.at(-1)?.user?.displayName === username &&
                <div style={{
                  textAlign: 'center',
                  fontSize: '0.8rem',
                  color: 'rgb(170, 170, 170)',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  alignItems: 'center',
                }}><hr style={{width: '20%', borderColor: 'rgb(255 255 255)'}}/>The {receiver === "accountant@otterz.co" ? "Accountant" : "User"} will get back to you shortly<hr style={{width: '20%', borderColor: 'rgb(255 255 255)'}}/></div>
              }
            </>

          )}
        </div>
      )}

      {typing && (
        <div className={classNames.typing}>
          {typing.displayName} is typing...
        </div>
      )}

      <div className={classNames.inputContainer}>
        {/* Input field for typing messages */}
        <TextArea
          placeholder="Ask a query"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          autoSize={{ minRows: 3 }}
          onKeyDown={handleKeyPress} // Add keydown event listener
        />

        {/* <SendOutlined className={classNames.submitBtn} disabled={fileLoading} onClick={() => sendMessage()} /> */}

        <Button
          type="primary"
          // icon={<SendOutlined />}
          className={classNames.submitBtn}
          onClick={() => sendMessage()}
          disabled={fileLoading || !messageInput?.trim?.() || sendMessageAccountant.isLoading || getMessageAccountant.isLoading}
        >{sendMessageAccountant.isLoading ? <Spin size="small" /> :<SendOutlined />}</Button>

        {/* Input field for uploading files */}
        <Upload
          className={classNames.fileInput}
          showUploadList={false}
          disabled={fileLoading}
          beforeUpload={(file) => {
            setFileList([file]);
            return false;
          }}
          // fileList={fileList}
          onChange={(info) => handleFileInputChange(info)}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </div>
      
      {fileLoading && (
        <div style={{margin: '0 1rem 1rem 1rem'}}>
          Uploading...
          <Spin size="small" style={{ marginLeft: '10px' }} />
        </div>
      )}
    </div>
  );
}

export default Chatbot;
