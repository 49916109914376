import endpoints from "../../../endpoints";

export default async function getBusinessStatus(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${endpoints.SERVICES.ONBOARDING.BUSINESS[env]}/${data.business_id}/status`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
