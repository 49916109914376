import { useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { CTAButton, Header, Select, Message, DatePicker } from "../../../common";
import { Upload, Input, Form, Table, Row, Col, Modal, Button as AntButton, Spin } from 'antd';
import moment from "moment";
import { 
  InboxOutlined, 
  CloseOutlined, 
  BankOutlined, 
  CreditCardOutlined, 
  ArrowRightOutlined, 
  // EditOutlined 
} from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";
import { isBusinessType } from "../../../util/functions/onboarding";
import { AppContext } from "../../../../context/AppState";

import endpoints from "../../../API/endpoints";

import { ROUTES } from "../../../constants";
import api from "../../../API";
import generateErrorMessage from "../../../util/functions/customError";
import PayViaBank from "./PayViaBank/ACHPayments";
import AddNewPayee from "../../../BusinessBanking/MakePayments/Payees/AddNewPayee/AddNewPayee";
import classNames from "./UploadInvoice.module.scss";
import Bugsnag from "@bugsnag/browser";

export default function UploadInvoice() {
  const navigate = useNavigate();

  const location = useLocation();
  const invoiceData = location.state?.invoiceData;
  const routeFrom = location.state?.routeFrom;

  const [isLoading, setIsLoading] = useState(false);
  const [ocrLoading, setOcrLoading] = useState(false);
  const [tableData, setTableData] = useState(invoiceData?.list_items.map((item, index) => ({
    key: (index + 1).toString(),
    description: item.description,
    price: item.price.toString(),
    qty: item.quantity.toString()
  })) || [{ key: '1', description: '', price: '', qty: '' }]);

  const [payee, setPayee] = useState();

  const addNewRow = () => {
    const key = (tableData.length + 1).toString();
    setTableData([...tableData, { key, description: '', price: '', qty: '' }]);
  };

  const removeRow = (key) => {
    const newData = tableData.filter(item => item.key !== key);
    setTableData(newData);
  };

  const columns = [
    {
      render: (text, record) => (
        <CloseOutlined style={{width: "50px"}} onClick={() => removeRow(record.key)} />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text, record) => (
        <Input
          style={{borderRadius: "0px !important"}}
          value={text}
          onChange={e => {
            const newData = [...tableData];
            const index = newData.findIndex(item => record.key === item.key);
            newData[index].description = e.target.value;
            setTableData(newData);
          }}
        />
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text, record) => (
        <Input
          style={{borderRadius: "0px !important"}}
          value={text}
          onChange={e => {
            const newData = [...tableData];
            const index = newData.findIndex(item => record.key === item.key);
            newData[index].price = e.target.value;
            setTableData(newData);
          }}
        />
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      render: (text, record) => (
        <Input
          style={{borderRadius: "0px !important"}}
          value={text}
          onChange={e => {
            const newData = [...tableData];
            const index = newData.findIndex(item => record.key === item.key);
            newData[index].qty = e.target.value;
            setTableData(newData);
          }}
        />
      ),
    },
  ];

  const [formData, setFormData] = useState({
    number: invoiceData?.receipt_id || "",
    otz_payee_id: invoiceData?.otz_payee_id || "",
    vendor_name: invoiceData?.vendor_name || null,
    receiver_address: invoiceData?.receiver_address || null,
    email: invoiceData?.email || "",
    total: invoiceData?.total || "",
    sub_total: invoiceData?.sub_total || null,
    tax: invoiceData?.tax || null,
    tax_payer_id: invoiceData?.tax_payer_id || null,
    receipt_id: invoiceData?.receipt_id || "",
    receipt_date: invoiceData?.receipt_date || "",
    due_date: invoiceData?.due_date ? moment(invoiceData?.due_date, "DD-MM-YYYY") : null, 
    payment_term: invoiceData?.payment_term || null,
    list_items: tableData
  });

  const [apiFile, setApiFile] = useState(null);
  // Invoice file upload
  const [fileList, setFileList] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewType, setPreviewType] = useState(null);
  
  useEffect(() => {  
    async function fetchInvoiceFile() {
      try {
        const response = await api.AccountsPayable.getAPInvoiceFile(invoiceData?.invoice_file_id);
        if (response?.status === 200) {
          const blob = await response.blob();

          // Check if the blob type is application/pdf, if not, set it manually
          let finalBlob;
          if (blob.type === "application/pdf") {
            finalBlob = new Blob([blob], { type: "application/pdf" });
          } else {
            finalBlob = new Blob([blob], { type: "image" });
          }
        
          // Create a File object from the blob
          const file = new File([finalBlob], "filename", { type: finalBlob.type });
          setPreviewType(file.type === 'application/pdf' ? 'pdf' : 'image')
          // Create an object URL for the file
          const url = URL.createObjectURL(file);
        
          // Set the object URL and the File object in the state
          setPreviewUrl(url);
          setApiFile(file);
        }
      } catch (error) {
        console.error("Error fetching the invoice file:", error);
      }
    }
  
    if (invoiceData?.invoice_file_id) {
      fetchInvoiceFile();
    }
  
    // Cleanup: Revoke the object URL when the component is unmounted or when a new file is fetched
    return () => {
      if (apiFile) {
        URL.revokeObjectURL(apiFile);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData]);


  const approveInvoice = useMutation(api.AccountsPayable.approveAPInvoice);
  const [approveModalVisible, setApproveModalVisible] = useState(false);
  const handleApproveInvoices = async () => {
    try {
        const responseData = await approveInvoice.mutateAsync({ invoice_id: invoiceData?.invoice_id });
        console.log("Invoice Approval Response:", responseData);

        if (responseData.status === 200) {
            setApproveModalVisible(false);
            Message({ type: "success", content: "Invoice approved" });
        } else {
            Message({ type: "error", content: responseData?.message || "Error approving the invoice." });
        }

        setApproveModalVisible(false);
        navigate(ROUTES.APP.PAYABLES.PENDING_INVOICES)
    } catch (error) {
        console.error("Error approving the invoice:", error);
        Message({ type: "error", content: "Error approving the invoice. Please try again." });
    }
  };
  const handleApproveModalClose = () => {
    setApproveModalVisible(false);
  };

  // Function to handle the marking of invoice
  const [isModalVisible, setIsModalVisible] = useState(false);
  const markAsPaidMutation = useMutation(api.AccountsPayable.updateAPInvoicePaymentStatus);
  const handleMarkAsPaid = async () => {
    try {
        // Assuming invoiceData contains the invoice_id
        const response = await markAsPaidMutation.mutateAsync({
            invoice_id: invoiceData?.invoice_id,
            updateToStatus: "paid"
        });

        if (response.status === 200) {
            Message({ type: "success", content: "Invoice marked as paid" });
        } else {
            Message({ type: "error", content: response?.message || "Something went wrong!" });
        }
    } catch (error) {
        Message({ type: "error", content: "Failed to mark the invoice as paid!" });
    }
    setIsModalVisible(false);
  };

  // Function to handle the closing of the modal
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  // Function to handle the rejection of invoices
  const rejectInvoice = useMutation(api.AccountsPayable.rejectAPInvoice);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [email, setEmail] = useState(null);
  const [rejectionReason, setRejectionReason] = useState('');
  
  const handleOpenRejectModal = () => {
    handleRejectInvoices()
    // setIsRejectModalVisible(true);
    setIsRejectModalVisible(false);
  };
  const handleCloseRejectModal = () => {
    setIsRejectModalVisible(false);
  };
  const handleRejectInvoices = async () => {
    try {
      // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

      // if (email && !emailRegex.test(email)) {
      //   // If the email is invalid, show an error message
      //   Message({ type: "error", content: "The input is not a valid email!" });
      //   return;
      // }
      // if (!rejectionReason) {
      //   Message({ type: "error", content: "Rejection reason is required!" });
      //   return;
      // }

      const data = await rejectInvoice.mutateAsync({
          invoice_id: invoiceData?.invoice_id,
          email: email,
          // reason: rejectionReason
      });
      console.log("Invoice Rejection Response:", data);

      Message({ type: "success", content: "Invoice rejected" });
      setIsRejectModalVisible(false);
      navigate(ROUTES.APP.PAYABLES.PENDING_INVOICES)
    } catch (error) {
        console.error("Error rejecting the invoice:", error);
        Message({ type: "error", content: "Error rejecting the invoice. Please try again." });
    }
  };

  const [isSchedulePaymentModalVisible, setIsSchedulePaymentModalVisible] = useState(false);
  const [payeeModalVisibility, setPayeeModalVisibility] = useState(false);

  const schedulePaymentStep = (payee) => {
    if(payee?.account_owner_name) {
      setIsSchedulePaymentModalVisible(true)
    } else {
      setPayeeModalVisibility(true)
    }
  }

  const uploadInvoiceAfterPayee = (payee) => {
    handleSaveClick(payee)
  }

  /* API Calls */
  const { application } = useContext(AppContext);
  const applicationData = application;

  const { data: payeeData, isLoading: isPayeeLoading } = useQuery(
    "getPayees",
    () =>
      api.BusinessBanking.listPayees({
        ...(isBusinessType(applicationData?.type)
          ? { otz_business_id: applicationData?.otz_business_id }
          : {
              otz_person_id: applicationData?.otz_person_id,
            }),
      }),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );
  
  const {
    data: accountPayload,
  } = useQuery(
    "getStatementDetail",
    () =>
      api.BusinessBanking.statementDetail({
        otz_account_id: applicationData?.otz_account_id,
      }),
    {
      onError: (error) => generateErrorMessage(error),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if(invoiceData?.otz_payee_id) {
      setPayee(payeeData?.payload?.find((entry) => entry?.otz_payee_id === invoiceData?.otz_payee_id))
    }
    const tempPayee = payeeData?.payload?.find((entry) => entry?.otz_payee_id === invoiceData?.vendor_name)
    if(tempPayee?.account_owner_name !== undefined) {
      setPayee(tempPayee)
    }
  }, [payeeData, invoiceData])
  
  const createAPInvoiceMutation = useMutation((invoiceData) =>
    api.AccountsPayable.createAPInvoice(invoiceData)
  );
  const uploadAPInvoiceMutation = useMutation(async (data) => {
    try {
      const response = await api.AccountsPayable.uploadAPInvoiceFile(data);
      return response; // Return the response data
    } catch (error) {
      console.error('Error in uploadAPInvoiceMutation:', error);
      throw error;
    }
  });

  const handleSaveClick = async (payee) => {
    try {
        setIsLoading(true);

        const { 
          number,
          receiver_address,
          ...restOfFormData 
        } = formData;
        
        // Check if otz_payee_id is undefined or an empty string
        if (payee?.otz_payee_id === undefined || payee?.otz_payee_id === "") {
          // Open the "AddNewPayee" modal here
          setPayeeModalVisibility(true);
          // You may also need to set some state to remember that the save action was triggered by the "Save" button.
        
          // Return or exit the function for now, as the save action will continue after selecting a payee.
          return;
        }
        
        // Filter out null values
        const filteredFormData = Object.fromEntries(
          Object.entries(restOfFormData).filter(([key, value]) => value !== null)
        );

        const invoiceDetails = {
            ...filteredFormData,
            receipt_date: new Date(),
            otz_payee_id: payee?.otz_payee_id,
            list_items: tableData.map(item => ({
                description: item.description,
                price: item.price,
                quantity: item.qty
            }))
        };

        var response = await createAPInvoiceMutation.mutateAsync(invoiceDetails);

        if(response.status === 200) {

          if (fileList.length > 0) {
            // Call the uploadAPInvoiceMutation
            const data = {
              invoice_id: response.payload?.invoice_id,
              file: fileList[0],
            }
            const uploadResponse = await uploadAPInvoiceMutation.mutateAsync(data);
            if (uploadResponse?.status === 200) {
              Message({ type: "success", content: "Invoice saved successfully !" });
              navigate(ROUTES.APP.PAYABLES.ACCOUNTS_PAYABLE)
            } else {
              Message({ type: "error", content: uploadResponse?.payload?.error[0]?.message || "Error uploading the file." });
            }
          } else {
            Message({ type: "success", content: "Invoice saved successfully !" });
            navigate(ROUTES.APP.PAYABLES.ACCOUNTS_PAYABLE)
          }
        } else {
          Message({ type: "error", content: response?.payload?.error[0]?.message || "Something went wrong!" });
        }
    } catch (error) {
      Message({ type: "error", content: response?.payload?.error[0]?.message || "Something went wrong!" });
    } finally {
      setIsLoading(false); // Set loading state back to false when the operation completes (whether it succeeds or fails)
    }
  };

  const updateAPInvoiceMutation = useMutation((invoiceData) =>
    api.AccountsPayable.updateAPInvoice(invoiceData)
  );
  const updateInvoiceSave = async () => {
    try {
        setIsLoading(true);

        const { 
          number,
          receiver_address,
          ...restOfFormData 
        } = formData;
        
        // Filter out null values
        const filteredFormData = Object.fromEntries(
          Object.entries(restOfFormData).filter(([key, value]) => value !== null)
        );

        const invoiceDetails = {
            ...filteredFormData,
            receipt_date: new Date(),
            otz_payee_id: payee?.otz_payee_id,
            list_items: tableData.map(item => ({
                description: item.description,
                price: item.price,
                quantity: item.qty
            }))
        };

        var response = await updateAPInvoiceMutation.mutateAsync({
          invoiceID: invoiceData?.invoice_id,
          update: invoiceDetails
        });

        if(response.status === 200) {
          if (fileList.length > 0) {
            // Call the uploadAPInvoiceMutation
            const data = {
              invoice_id: response.payload?.invoice_id,
              file: fileList[0],
            }
            const uploadResponse = await uploadAPInvoiceMutation.mutateAsync(data);
            console.log("uploadResponse: ", uploadResponse);
          } else {
            // Message({ type: "success", content: "Invoice saved successfully !" });
          }
        } else {
          console.log(response?.payload?.error);
          throw new Error(response?.payload?.error[0]?.message || "Something went wrong!");
          // Message({ type: "error", content: "Update failed: "+response?.payload?.error[0]?.message || "Something went wrong!" });
        }
    } catch (error) {
      // Message({ type: "error", content: "Update exception: " + (error.message || "Something went wrong!") });
      console.log("Update exception: ", error.message);
      throw new Error(error.message || "Something went wrong!");
    } finally {
      setIsLoading(false); // Set loading state back to false when the operation completes (whether it succeeds or fails)
    }
  }

  let env = process.env.REACT_APP_ENV?.trim();
  const props = {
    name: 'file',
    action: endpoints.PAYABLES.ACCOUNTS_PAYABLES.INVOICES[env]+formData?.invoice_id,
    accept: 'application/pdf,image/jpeg,image/png',
    headers: {
      'Content-Type': 'application/json'
    },
    maxCount: 1,
    fileList: apiFile ? [apiFile] : fileList,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setFileList([info.file]);
        
        setOcrLoading(true);
        Message({ type: "success", content: "Data read from invoice started" });

        // Assuming you have your credentials from the environment variables
        const credentials = `${process.env.REACT_APP_CRADL_CLIENT_ID}:${process.env.REACT_APP_CRADL_CLIENT_SECRET}`;
        const base64EncodedCredentials = btoa(credentials);
    
        // Step 1: Authenticate and get an access token
        fetch('https://auth.lucidtech.ai/oauth2/token?grant_type=client_credentials', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${base64EncodedCredentials}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            const accessToken = data.access_token;
    
            // Get the uploaded file
            const file = info.file;
    
            // Step 2: Read and convert the file to a base64-encoded string
            const reader = new FileReader();
            reader.onload = function () {
              const base64EncodedFile = reader.result.split(',')[1]; // Extract base64 part
              // Step 3: Use the access token to make a POST request to 'https://api.lucidtech.ai/v1/documents'
              fetch('https://api.lucidtech.ai/v1/documents', {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  content: base64EncodedFile,
                  contentType: file.type,
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  // Now you have the response data from the 'https://api.lucidtech.ai/v1/documents' route
                  // You can use it as needed in your application
    
                  // Step 4: Create predictions on your invoice
                  const documentId = data.documentId; // Replace with the actual document ID
                  const modelId = "las:organization:cradl/las:model:invoice-lines"; // Replace with the actual model ID
    
                  fetch('https://api.lucidtech.ai/v1/predictions', {
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ documentId, modelId }),
                  })
                    .then((response) => response.json())
                    .then((predictionData) => {
                      if (predictionData && predictionData.predictions && predictionData.predictions.length > 0) {
                        // Create an object with the highest confidence prediction for each label
                        const highestConfidencePredictions = {};

                        predictionData.predictions.forEach((prediction) => {
                          const label = prediction.label;
                          const confidence = prediction.confidence;
                          const value = prediction.value;
                        
                          if (!highestConfidencePredictions[label] || confidence > highestConfidencePredictions[label].confidence) {
                            highestConfidencePredictions[label] = {
                              confidence,
                              value,
                            };
                          }
                        });
                      
                        const updatedFormData = { ...formData }; // Create a copy of the existing formData

                        // Update formData with the highest confidence predictions
                        if (highestConfidencePredictions.invoice_id) {
                          updatedFormData.receipt_id = highestConfidencePredictions.invoice_id.value;
                        }
                        if (highestConfidencePredictions.vat_amount) {
                          updatedFormData.tax = highestConfidencePredictions.vat_amount.value;
                        }
                        if (highestConfidencePredictions.total_amount) {
                          updatedFormData.total = highestConfidencePredictions.total_amount.value;
                        }
                        if (highestConfidencePredictions.invoice_date) {
                          updatedFormData.receipt_date = moment(highestConfidencePredictions.invoice_date.value, "DD-MM-YYYY");
                        }
                        if (highestConfidencePredictions.due_date) {
                          updatedFormData.due_date = moment(highestConfidencePredictions.due_date.value, "DD-MM-YYYY");
                        }
                        if (highestConfidencePredictions.supplier_name) {
                          updatedFormData.vendor_name = highestConfidencePredictions.supplier_name.value;
                        }

                        // Assuming you have a function to update the list_items
                        if (highestConfidencePredictions.line_items) {
                          const tableData = predictionData.predictions
                            .find((prediction) => prediction.label === "line_items")
                            .value.map((lineItem, index) => {
                              const description = lineItem.find((item) => item.label === "description")?.value;
                              const totalPrice = lineItem.find((item) => item.label === "total_price")?.value;
                              const unitPrice = lineItem.find((item) => item.label === "unit_price")?.value;
                        
                              let qty = null;
                        
                              if (
                                totalPrice !== null &&
                                unitPrice !== null &&
                                unitPrice !== 0 &&
                                unitPrice !== 0.00 &&
                                !isNaN(totalPrice) &&
                                !isNaN(unitPrice)
                              ) {
                                qty = parseInt(totalPrice / unitPrice);
                              }
                        
                              return {
                                key: (index + 1).toString(),
                                description,
                                price: totalPrice,
                                qty: isNaN(qty) ? null : qty,
                              };
                            });
                        
                          // Set the tableData in your state
                          setTableData(tableData);
                        }                                           

                        // Set the updated formData in the state
                        setFormData(updatedFormData);
                        setOcrLoading(false);
                        Message({ type: "success", content: "Data read from invoice successful" });
                      }
                    })
                    .catch((error) => {
                      // Handle errors when creating predictions
                      Bugsnag.notify("Document Create Prediction OCR: ", error, error.response)
                    });
                })
                .catch((error) => {
                  // Handle errors when posting the document
                  Bugsnag.notify("Document Read OCR: ", error, error.response)
                });
            };
    
            // Read the file as a data URL (base64-encoded)
            reader.readAsDataURL(file);
          })
          .catch((error) => {
            // Handle errors during authentication
            Bugsnag.notify("Errors during authentication: ", error, error.response)
          });
      }
      if (info.file.status === 'done') {
        Message({ type: "success", content: `${info.file.name} file uploaded successfully` });
        setFileList([info.file]);
      } else if (info.file.status === 'error') {
        Message({ type: "error", content: `${info.file.name} file upload failed.` });
      }
    },
    onRemove(file) {
      setFileList([]);
      setPreviewUrl(null);
    },
    beforeUpload: file => {
      const isSupported = ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type);
      if (!isSupported) {
        Message({ type: "error", content: 'You can only upload PDF, JPG, or PNG files!' });
        return false;
      }

      // Check the file size here
      const maxSize = 1.5 * 1024 * 1024; // 1.5 MB in bytes
      if (file.size > maxSize) {
        Message({ type: "error", content: 'File size should be less than 1.5 MB!' });
        return false;
      }

      if (apiFile) {
        setPreviewUrl(URL.createObjectURL(apiFile));
      } else {
        const reader = new FileReader();
        reader.onload = e => {
          setPreviewUrl(e.target.result);
          if (file.type === 'application/pdf') {
            setPreviewType('pdf');
          } else {
            setPreviewType('image');
          }
        };
        reader.readAsDataURL(file);
      }
      
      return false;
    },
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.headerWrapper}>
        <Header
          title={invoiceData ? `Invoice : ${formData.number}` : `Upload Invoice`}
          back
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={classNames.layout}>
        <div className={classNames.bodyWrapper}>
          <div className={classNames.body}>
            <div style={{width: "85%", position: "relative" }} className={classNames.uploadContainer}>
              {previewUrl && (
                <div className={classNames.previewContainer}>
                  {previewType === 'image' && <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />}
                  {previewType === 'pdf' && <iframe title="invoicePdfPreview" src={previewUrl} style={{ width: '100%', height: '100%' }} />}
                  <AntButton onClick={() => { setFileList([]); setPreviewUrl(null); }} style={{ marginTop: '10px' }}>Delete</AntButton>
                </div>
              )}
              <Upload.Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Upload.Dragger>
            </div>
            <div className={classNames.formContainer}>
              <h2 style={{fontWeight: "bold", margin: "0 0 20px 0"}}>Invoice Details</h2>
              <Form layout="vertical">
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Vendor *">
                      <Select
                        isLoading={isPayeeLoading}
                        value={formData.vendor_name || payee?.account_owner_name} 
                        onChange={(value) => {
                          const splitNames = value.split('-')
                          setFormData({
                            ...formData,
                            vendor_name: value.split('-')[1],
                            email: splitNames[splitNames.length-1].includes('@') ? splitNames[splitNames.length-1] : null
                          });
                        }}
                        placeholder="Select a vendor"
                        onSelect={(e) => {
                          setPayee(payeeData?.payload?.find((entry) => entry?.otz_payee_id === e.split('-')[0]))
                        }
                        }
                        rules={[{ required: true, message: 'Please select a vendor!' }]}
                      >
                        {payeeData?.payload &&
                          payeeData?.payload.length > 0 &&
                          payeeData?.payload.map((value) => (
                            <Select.Option value={`${value?.otz_payee_id}-${value?.account_owner_name}-${value?.email}`}>
                              {`${value?.account_owner_name} - (${value?.account_number_masked})`}
                            </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Email Address *" rules={[{ required: true, message: 'Please enter email address!' }]}>
                      <Input value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})}  placeholder="Enter email address" />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Receiver Address *" rules={[{ required: true, message: 'Please enter receiver address!' }]}>
                  <Input value={formData.receiver_address} onChange={(e) => setFormData({...formData, receiver_address: e.target.value})}  placeholder="Enter receiver address" />
                </Form.Item> */}
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Invoice ID *" rules={[{ required: true, message: 'Please enter invoice/receipt ID!' }]}>
                      <Input value={formData.receipt_id} onChange={(e) => setFormData({...formData, receipt_id: e.target.value})}  placeholder="Enter invoice/receipt ID" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Invoice Total *" rules={[{ required: true, message: 'Please enter invoice total!' }]}>
                      <Input value={formData.total} onChange={(e) => setFormData({...formData, total: e.target.value})}  prefix="$" placeholder="Enter invoice total" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Due Date">
                    <DatePicker 
                      value={formData.due_date ? formData.due_date : null} 
                      onChange={(date) => {
                        if (date) {
                          setFormData({...formData, due_date: date});
                        } else {
                          setFormData({...formData, due_date: null});
                        }
                      }}
                    />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Tax">
                      <Input value={formData.tax} onChange={(e) => setFormData({...formData, tax: e.target.value})}  prefix="$" placeholder="Enter tax amount" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Invoice Tax Payer ID">
                      <Input value={formData.tax_payer_id} onChange={(e) => setFormData({...formData, tax_payer_id: e.target.value})}  placeholder="Enter tax payer ID" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item className={classNames.formLabel} style={{fontSize: "0.75rem"}} label="Payment Terms">
                      <Input value={formData.payment_term} onChange={(e) => setFormData({...formData, payment_term: e.target.value})}  placeholder="Enter payment terms" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <h2 style={{margin: "35px 0 0 0", fontWeight: "bold"}}>List Items *</h2>
              <Table className={classNames.formTable} dataSource={tableData} columns={columns} pagination={false} />
              <div onClick={addNewRow} style={{ cursor: 'pointer', marginTop: '10px', fontSize: "0.75rem" }}>
                + add new item
              </div>
              
              <div style={{display: "flex", justifyContent: "space-between", margin: "25px 0 0"}}>
                {invoiceData ? (
                  <>
                    {invoiceData.payment_status === "scheduled" ? (
                      <CTAButton onClick={() => setIsSchedulePaymentModalVisible(true)} type="primary" style={{ width: '35%', height: '40px', fontSize: '0.9rem' }}>
                        Pay now
                      </CTAButton>
                    ) : (
                      invoiceData.payment_status !== "paid" && (
                        <>
                          {
                            routeFrom ? (
                              <CTAButton type="danger" onClick={() => handleOpenRejectModal()} style={{ width: '35%', height: '40px', fontSize: '0.9rem' }}>
                                Reject
                              </CTAButton>
                            ) : (
                              <CTAButton onClick={async () => {
                                try {
                                  await updateInvoiceSave()
                                  setIsModalVisible(true)
                                } catch (error) {
                                  Message({ type: "error", content: "Update failed: " + (error.message || "Something went wrong!") });
                                  Bugsnag.notify("Error in Save & Mark as Paid: ", error)
                                }
                              }} style={{ width: 'fit-content', height: '40px', fontSize: '0.9rem' }}>
                                Save & Mark as Paid
                              </CTAButton>
                            )
                          }
                          {
                            routeFrom ? (
                              <CTAButton onClick={async () => {
                                try {
                                  await updateInvoiceSave()
                                  setApproveModalVisible(true)
                                } catch (error) {
                                  Message({ type: "error", content: "Update failed: " + (error.message || "Something went wrong!") });
                                  Bugsnag.notify("Error in Save & Approve: ", error)
                                }
                              }} type="primary" style={{ width: '35%', height: '40px', fontSize: '0.9rem' }}>
                                Save & Approve
                              </CTAButton>
                            ) : (
                              <CTAButton onClick={async () => {
                                try {
                                  await updateInvoiceSave()
                                  schedulePaymentStep(payee)
                                } catch (error) {
                                  Message({ type: "error", content: "Update failed: " + (error.message || "Something went wrong!") });
                                  Bugsnag.notify("Error in save and schedule payment: ", error)
                                }
                              }} type="primary" style={{ width: 'fit-content', height: '40px', fontSize: '0.9rem' }}>
                                Save & Schedule Payment
                              </CTAButton>
                            )
                          }
                        </>
                      )
                    )}
                  </>
                ) : (
                  <CTAButton
                    disabled={
                      isLoading || // Add isLoading check here
                      !formData.vendor_name ||
                      // !formData.receiver_address ||
                      !formData.total ||
                      !formData.receipt_id ||
                      (!formData.list_items.length >= 1 &&
                        formData.list_items[0].description === '' &&
                        formData.list_items[0].price === '' &&
                        formData.list_items[0].qty === '')
                    }
                    onClick={()=>{handleSaveClick(payee)}}
                    loading={isLoading}
                    style={{ width: '35%', height: '40px', fontSize: '0.9rem' }}>
                    Save
                  </CTAButton>
                )}
              </div>


            </div>
          </div>
        </div>
      </div>

      {/* Approve Invoices Modal component */}
      <Modal
        // title="Approve Invoices"
        centered
        visible={approveModalVisible}
        onCancel={handleApproveModalClose}
        footer={[
          <div className={classNames.centeredModalFooter}>
            <AntButton key="no" onClick={handleApproveModalClose}>
              Cancel
            </AntButton>
            <AntButton key="yes" type="primary" onClick={handleApproveInvoices}>
              Yes, Approve
            </AntButton>
          </div>
        ]}
      >
        <div className={classNames.centeredModalContent}>
          <h2>Approve Invoices</h2>
          <p>
            Are you sure you want to approve this invoice ?
          </p>
        </div>
      </Modal>


      {/* Mark as Paid Invoices Modal component */}
      <Modal
        // title="Approve Invoices"
        centered
        visible={isModalVisible}
        onCancel={handleCloseModal}
        width="25%"
        footer={[
          <div className={classNames.centeredModalFooter}>
            <AntButton key="no" onClick={handleCloseModal}>
              Cancel
            </AntButton>
            <AntButton key="yes" type="primary" onClick={handleMarkAsPaid}>
              Yes
            </AntButton>
          </div>
        ]}
      >
        <div className={classNames.centeredModalContent}>
          <h2>Mark as paid</h2>
          <p>
          Are you sure you want to mark this as paid?
          </p>
        </div>
      </Modal>

      {/* Reject Invoices Modal */}
      <Modal
        title={<span style={{ fontWeight: 'bold' }}>Reject Invoices</span>}
        visible={isRejectModalVisible}
        onCancel={handleCloseRejectModal}
        footer={[
          <AntButton 
            key="reject" 
            type="primary" 
            danger 
            style={{ width: '100%' }}
            onClick={handleRejectInvoices}
          >
            Reject
          </AntButton>
        ]}
      >
        <div>
          <label style={{ fontWeight: '500' }}>Email address:</label>
          <Input
            type="email"
            value={email}
            placeholder="Please enter email address"
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <label style={{ fontWeight: '500' }}>Reason for rejection:</label>
          <Input.TextArea
            value={rejectionReason}
            placeholder="Let us know why you are rejecting these invoices"
            onChange={(e) => setRejectionReason(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
      </Modal>

      {isSchedulePaymentModalVisible && (
        <SchedulePaymentModal 
          vendorName={formData?.vendor_name} // replace with actual vendor name
          totalAmount={formData?.total} // replace with actual total amount
          onClose={() => setIsSchedulePaymentModalVisible(false)}
          payee={payee}
          visible={isSchedulePaymentModalVisible}
          isPayeeLoading={isPayeeLoading}
          accountPayload={accountPayload}
          payeeData={!!payeeData?.payload && payeeData?.payload}
          invoiceId={invoiceData?.invoice_id}
          receiptId={invoiceData?.receipt_id}
        />
      )}

      <AddNewPayee
        visible={payeeModalVisibility}
        onCancel={()=> setPayeeModalVisibility(false)}
        isQBPayee={true}
        payeeSet={setPayee}
        payStart={schedulePaymentStep}
        reUpload={uploadInvoiceAfterPayee}
        invoiceID={invoiceData?.invoice_id}
        payeeName={invoiceData?.vendor_name || formData?.vendor_name}
        email={invoiceData?.email || formData?.email}
      />

      {ocrLoading && (
        // Render a loading overlay when isLoading is true
        <div className={classNames.loadingOverlay}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
}

function SchedulePaymentModal({ totalAmount, onClose, payee, isPayeeLoading, accountPayload, invoiceId, receiptId }) {
  const [isPayViaBank, setIsPayViaBank] = useState(false);

  const handlePayViaOtterzClick = () => {
    setIsPayViaBank(true);
  };

  if (isPayViaBank) {
    return (
      <PayViaBank
        visible={isPayViaBank}
        setVisible={setIsPayViaBank}
        incomingPayee={payee}
        isPaymentDataLoading={isPayeeLoading}
        accountBalance={accountPayload?.payload?.total_available_balance || 0}
        onCancel={() => setIsPayViaBank(!isPayViaBank)}
        amount={totalAmount}
        invoiceId={invoiceId}
        receiptId={receiptId}
      />
    )
  }

  return (
    <Modal
      title="Schedule Payment"
      centered
      visible={true}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: '1.5rem' }}>Pay {payee?.account_owner_name}</p>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <sup>$</sup>
          {/* <Input 
            style={{ border: 'none', outline: 'none', fontSize: '2rem', width: '40%' }} 
            readOnly
            defaultValue={totalAmount} 
          />
          <EditOutlined style={{ marginLeft: '5px' }} /> */}
          <span
            style={{ border: 'none', outline: 'none', fontSize: '2rem' }} 
            readOnly
            defaultValue={totalAmount} 
          >{totalAmount}</span>
        </div>
        <div onClick={handlePayViaOtterzClick} style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px', marginTop: '20px', alignItems: "center" }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10%', width: "100%", margin: "1rem", cursor: "pointer" }}>
            <BankOutlined />
            <div style={{ marginLeft: '10px', textAlign: "left" }}>
              <p style={{margin: 0, fontWeight: "bold"}}>Pay via Otterz</p>
              <p style={{margin: 0, color: "gray"}}>No additional charges</p>
            </div>
          </div>
          <ArrowRightOutlined />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px', marginTop: '10px', alignItems: "center", opacity: "0.5", cursor: "not-allowed" }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10%', width: "100%", margin: "1rem" }}>
            <CreditCardOutlined />
            <div style={{ marginLeft: '10px', textAlign: "left" }}>
              <p style={{margin: 0, fontWeight: "bold"}}>Pay with Card</p>
              {/* <p style={{margin: 0, color: "gray"}}>2.9% fee applicable</p> */}
              <p style={{margin: 0, color: "#b4730e"}}>Coming Soon</p>
            </div>
          </div>
          <ArrowRightOutlined />
        </div>
      </div>
    </Modal>
  );
}
