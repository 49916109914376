import endpoints from "../../../endpoints";

export default async function googleRegisterToken(data) {
    let env = process.env.REACT_APP_ENV?.trim();

    const baseURL = endpoints.SERVICES.AUTH.GOOGLE_SIGNUP[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    return await (
        await fetch(baseURL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                // Add other headers if necessary
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                idToken: data.idToken,
            })
        })
    ).json();
}
