import React, { useEffect, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import classNames from './CompleteProfile.module.scss'; 
import { CTAButton, Message } from '../../common';
import Heading from '../Heading';
import { useNavigate } from 'react-router';
// import { ROUTES } from '../../constants';
import { useMutation } from 'react-query';
import api from '../../API';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { businessDataCollection } from '../../API/Services/Onboarding';

const ProfileComponent = () => {
  const [form] = Form.useForm();
  const [ownerStatus, ] = useState('Y');
  const [loading, setLoading] = useState(false);
  const [isInvited, setInvited] = useState(false);
  const secureLocalStore = window.localStorage || window.sessionStorage;
  const navigate = useNavigate();

  /* Mutation definition for API calls */
  // Create User mutation
  const createUser = useMutation(data => api.Services.Onboarding.createUser(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // Create business mutation - Here we are updating the user preferences
  const addBusiness = useMutation(data => api.Services.Onboarding.addBusiness(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses added successful", data);
        
        setLoading(true)
        businessDataCollection({
          business_id: data.data.business_id,
          business_name: data.data.name,
          business_description: ""
        }).then((response) => {
          console.log("Business data collection response: ", response);
          setLoading(false)
        }).catch((error) => {
          console.error("Business data collection error: ", error);
          setLoading(false)
          Message({ type: "error", content: error.message });
        });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  // update status mutation
  const updateOnboardingStatus = useMutation(data => api.Services.Onboarding.updateOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
        Message({ type: "success", content: "User created successfully !" });
        
        // Navigate or update state as needed
        navigate("/app");
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });  

  const viewUser = useMutation(data => api.Services.Auth.userData(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      } else if (data.data && data.data[0].invitation_details) {
        const emailVerificationStatus = data.data[0].verifications.email;
        if (emailVerificationStatus === 'Y') {
          secureLocalStore.setItem('email_verification', true);

          if (data.data[0].invitation_details && data.data[0].invitation_details.invite_id) {
            console.log("invite data", data.data[0].invitation_details, data.data[0].invitation_details.invite_id);
            setInvited(true);
          }
        }
      }
    },
    onError: (error) => {
      console.error("Error fetching user details: ", error);
      Message({ type: "error", content: error.message });
    },
  }); 

  useEffect(() => {
    viewUser.mutate(secureLocalStore.getItem('email'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    console.log('Received values of form: ', values, ownerStatus);
  
    // Create a promise for createUser API call
    const createUserPromise = new Promise((resolve, reject) => {
      createUser.mutate({
        otterz_id: secureLocalStore.getItem('otterz_id'),
        userData: {
          first_name: values.firstName,
          last_name: values.lastName,
          email_id: secureLocalStore.getItem('email'),
          phone_no: values.phoneNumber,
          country: "US",
          uuid: "",
          is_owner: ownerStatus,
          verifications: {
            email: "Y",
            phone: "N"
          },
          services: {
            bookeeping_insights: "Y",
            accept_payments: "Y"
          }
        }
      }, {
        onSuccess: resolve,
        onError: reject
      });
    });
  
    // Chain the promises: first createUser, then (conditionally) addBusiness
    createUserPromise
      .then(() => {
        // Proceed to addBusiness only if the user is not invited
        if (!isInvited) {
          return new Promise((resolve, reject) => {
            addBusiness.mutate({
              name: values.businessName
            }, {
              onSuccess: resolve,
              onError: reject
            });
          });
        }
        return Promise.resolve(); // If invited, resolve immediately
      })
      .then(() => {
        // After both createUser and addBusiness (if applicable) succeed, update onboarding status
        updateOnboardingStatus.mutate({
          otterz_id: secureLocalStore.getItem('otterz_id'),
          status: "ONBOARDED"
        });
      })
      .catch((error) => {
        // Handle error if any API call fails
        console.error('Error in API calls: ', error);
      });
  };  

  // Function to force title case
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // Handler for input change to enforce title case
  const handleNameChange = (e, fieldName) => {
    const titleCasedValue = toTitleCase(e.target.value);
    form.setFieldsValue({ [fieldName]: titleCasedValue });
  };

  return (
    <div className={classNames.profileComponent}>
      {viewUser.isLoading ? (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spin></Spin>
        </div>
      ) : (
      <Form
        form={form}
        name="profileForm"
        onFinish={onFinish}
        layout="vertical"
        className={classNames.profileForm}
      >
        <Heading
          classNames={classNames}
          title="Create your profile"
        />
        <Form.Item>
            <Form.Item
              name="firstName"
              rules={[
                { required: true, message: 'First name is required' },
                // { pattern: /^[A-Za-z]+$/, message: 'First name only accepts letters' }
              ]}
              className={classNames.formItem}
              style={{ display: "inline-block", width: "calc(50% - 8px)", marginBottom: "10px", float: "left" }}
            >
              <Input placeholder="First Name" onChange={(e) => handleNameChange(e, 'firstName')} />
            </Form.Item>

            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: 'Last name is required' },
                // { pattern: /^[A-Za-z]+$/, message: 'Last name only accepts letters' }
              ]}
              className={classNames.formItem}
              style={{ display: "inline-block", width: "calc(50% - 4px)", margin: "0 0 0 4px", float: "right" }}
            >
              <Input placeholder="Last Name" onChange={(e) => handleNameChange(e, 'lastName')} />
            </Form.Item>
        </Form.Item>

        {/* {!isInvited && (
          <Form.Item
            name="ownerStatus"
            className={classNames.formItem}
            style={{padding: "0rem 3rem 0.7rem"}}
          >
            <span className={classNames.ownerLabel}>Are you the admin?</span>
            <Radio.Group
              onChange={(e) => setOwnerStatus(e.target.value)}
              value={ownerStatus}
              className={classNames.radioGroup}
            >
              <Radio value="Y">Yes</Radio>
              <Radio value="N">No</Radio>
            </Radio.Group>
          </Form.Item>
        )} */}

        <Form.Item
          name="phoneNumber"
          rules={[
            { required: true, message: 'Phone number is required' },
            { pattern: /^\+?[1-9]\d{1,14}$/, message: 'Enter a valid phone number' }
          ]}
          className={classNames.formItem}
        >
          <PhoneInput
            country={'us'}
            value=""
            onChange={(phone) => form.setFieldsValue({ phoneNumber: phone })}
            inputStyle={{ width: '100%', height: '50px' }}
            containerStyle={{ width: '100%', height: '50px' }}
            placeholder="Phone Number"
          />
        </Form.Item>

        {
          isInvited ? null : (
            <Form.Item
              name="businessName"
              rules={[
                { required: true, message: 'Business name is required' },
                // { pattern: /^[A-Za-z]+$/, message: 'First name only accepts letters' }
              ]}
              className={classNames.formItem}
              style={{ marginBottom: "10px" }}
            >
              <Input placeholder="Business Name" onChange={(e) => form.setFieldsValue({businessName: e.target.value })} />
            </Form.Item>
          )          
        }

        <Form.Item
          name="website"
          className={classNames.formItem}
          style={{ marginBottom: "10px" }}
        >
          <Input placeholder="Website (Optional)" onChange={(e) => form.setFieldsValue({website: e.target.value })} />
        </Form.Item>

        <CTAButton
            htmlType="submit"
            type="primary"
            className={classNames.signupFormInputs}
            loading={createUser.isLoading || updateOnboardingStatus.isLoading || loading}
            disabled={    
              createUser.isLoading || updateOnboardingStatus.isLoading
            }
          >
            Continue
          </CTAButton>
      </Form>
      )}
    </div>
  );
};

export default ProfileComponent;
