import endpoints from "../../../endpoints";

export default async function getMessagesBusiness(data){
    let env = process.env.REACT_APP_ENV?.trim();
    const url = endpoints.SERVICES.DASHBOARD.CHATKITTY.GET_MESSAGES_BUSINESS[env] + data.business_id;
    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');
    let transaction_url = '';

    if (data.transaction_id) {
        transaction_url = `${url}/${data.channel_id}?transaction_id=${data.transaction_id}`;
    } else if (data.vendor_id) {
        transaction_url = `${url}/${data.channel_id}?vendor_id=${data.vendor_id}`;
    } else {
        transaction_url = `${url}/${data.channel_id}`;
    }

    return await(
        await fetch(transaction_url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
    ).json();
}
