import React from 'react';
// import './index.scss';
import App from './App';

const BookKeeperChat = ({business_id, userName}) => {
  console.log('business_id', business_id);
  return (
    <App business_id={business_id} userName={userName} />
  );
}

export default BookKeeperChat;