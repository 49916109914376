import endpoints from "../../../endpoints";

export default async function inviteEmployee(data) {
    let env = process.env.REACT_APP_ENV?.trim();

    const baseURL = endpoints.SERVICES.ONBOARDING.INVITATION[env];
    const secureLocalStore = window.localStorage || window.sessionStorage;

    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${baseURL}/${data.business_id}/user`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                email_id: data.employee.email_id,
                role: data.employee.role,
                name: data.employee.name
            })
        })
    ).json();
}
