import endpoints from "../../../endpoints";

// Function to create subscription
export default async function sendMessageTaxes(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    const url = endpoints.SERVICES.DASHBOARD.CHATKITTY.SEND_MESSAGE_TAXES[env];
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    return await (
        await fetch(`${url}${data?.business_id}/${data.messageData.vendor_id}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                message: data.messageData.message,
                sender: data.messageData.sender,
            })
        })
    ).json();
}
