import endpoints from "../../../endpoints";

// Function to upsert subscription
export default async function ctfAddons(business_id) {
    let env = process.env.REACT_APP_ENV?.trim();
    const baseURL = endpoints.SERVICES.SUBSCRIPTIONS.CTF_ADDON[env];
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${baseURL}/${business_id}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        })
    ).json();
}
