import endpoints from "../../../endpoints";

export default async function updateDetailsForm1099(data){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');

    const url = endpoints.SERVICES.DASHBOARD.TAX_FILLING.UPDATE_TAXFORM_DETAILS_1099[env];

    return await(
        await fetch(`${url}/${data.business_id}/${data.shareholder_id}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify(data?.body)
        })
    ).json();
}
