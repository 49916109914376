import React from 'react';
import classNames from './OnboardingStart.module.scss'; 
import { CTAButton, Message } from '../../../../common';
// import Heading from '../../../../Auth/Heading';
import { useMutation } from 'react-query';
import api from '../../../../API';
import { useBusiness } from '../../../../../store/StoreProvider';

const OnboardingStart = ({setStatus}) => {
  const { selectedBusiness } = useBusiness();

  /* Mutation definition for API calls */
  // update status mutation
  const updateTaxOnboardingStatus = useMutation(data => api.Services.Dashboard.TaxFilling.updateTaxOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
        setStatus("UPLOAD_TAX_RETURNS");
        Message({ type: "success", content: "Tax onboarding started !" });
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });  

  return (
    <div className={classNames.profileComponent}>
      <h3 style={{ fontWeight: '500', margin: '0 0 1.5rem 0', fontSize: '0.95rem', textAlign: 'center' }}>We'll need some information/documents from you to get started. Ready to go?</h3>

      <CTAButton 
        loading={updateTaxOnboardingStatus.isLoading}
        style={{ marginTop: "3rem" }}
        onClick={() => {
          updateTaxOnboardingStatus.mutate({business_id: selectedBusiness.business_id, body: {
            tax_filling_status: "UPLOAD_TAX_RETURNS",
            meta_data: {
              "any_other_data_required_for_frontend": null
            }
          }});
        }}>Yes !</CTAButton>
    </div>
  );
};

export default OnboardingStart;
