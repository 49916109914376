import endpoints from "../../../endpoints";

export default async function undoTransaction(data){
    let env = process.env.REACT_APP_ENV?.trim();

    // Construct the URL with query parameters

    const secureLocalStore = window.localStorage || window.sessionStorage;
    const url = `${endpoints.SERVICES.ACCOUNTANTS_DASHBOARD.CATEGORIZATION.UNDO_TRANSACTION[env]}${data.business_id}`;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${url}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            },
            body: JSON.stringify({ transactions: data.transactions })
        })
    ).json();
}
