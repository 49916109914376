import React, { useEffect, useState, useRef } from "react";
import classNames from "./ChatPopUp.module.scss";
// import ChatKitty from "@chatkitty/core";
import ChatKitty from 'chatkitty';
import { Input, Avatar, Spin, notification } from "antd";
import { InboxOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { useAccount } from "../../../../../../store/StoreProvider";
import api from "../../../../../API";

const { TextArea } = Input;
export function ChatPopUp({vendorId, accountData}) {
  let { selectedBusiness } = useAccount();
  const [channel, setChannel] = useState(null);
  const [username] = useState(process.env.REACT_APP_ACCOUNTANT_EMAIL);
  const [
    // sessionUser
    , setSessionUser
  ] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [, setContacts] = useState([]);
  const [typing, setTyping] = useState(null);
  const [, setStyle] = useState('none');
  const [loading, setLoading] = useState(true);
  const [, setIsLoadingAIResponse] = useState(false);

  const messagesEndRef = useRef(null);

  const getTaxQueries = useMutation(({business_id, vendor_id})=>{
    return api.Services.AccountantsDashboard.Tax.getTaxQueryMessages({business_id, vendor_id})
  })

  const sendTaxQueryMessage = useMutation(({business_id, vendor_id, messageData})=>{
    return api.Services.AccountantsDashboard.Tax.sendTaxQueryMessage({business_id, vendor_id, messageData})
  })

  useEffect(() => {
    if(accountData?.business_id && vendorId){
      getTaxQueries.mutate({business_id: accountData?.business_id, vendor_id: vendorId}, {
        onSuccess: (response) => {
          // console.log("THIS IS THE RESPONSE >>>", response?.data)
          setMessages(response?.data);
          setLoading(false);
          scrollToBottom();
        }
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId, accountData?.business_id])

  // Assigning a default value to selectedBusiness if it's an empty object
  selectedBusiness = Object.keys(selectedBusiness).length === 0 ? accountData : selectedBusiness;

  const chatkitty = ChatKitty.getInstance(process.env.REACT_APP_CHATKITTY_API_KEY);

  const initSession = async () => {
    const result = await chatkitty.startSession({ username });
    if (result.succeeded) {
      const session = result.session;
      setSessionUser(session);
      chatkitty.getUsers().then((result) => {
        setContacts(result.paginator.items);
      });
      initChannel(session);
    } else {
      console.log("Failed to start session:", result.error);
    }
  };

  const initChannel = async (user) => {
    const result = await chatkitty.createChannel({
      type: 'DIRECT',
      members: [{ username: accountData.email }],
    });

    if (result.succeeded) {
      setChannel(result.channel);
      let chat = chatkitty.startChatSession({
        channel: result.channel,
        onReceivedMessage: (message) => {
          getTaxQueries.mutate({business_id: accountData?.business_id, vendor_id: vendorId}, {
            onSuccess: (response) => {
              // console.log("THIS IS THE RESPONSE >>>", response?.data)
              setMessages(response?.data)
              scrollToBottom();
            }
          })
          if (message.user.id === user.user.id) return;
          setMessages((draft) => [
            ...draft, 
            {
              sender: message.user.name,
              channel_id: result.channel.id,
              message: message.body,
              created_at: message.createdTime,
              business_id: selectedBusiness.business_id,
            }
          ]);
          scrollToBottom();
          notifyUser(message);
        },
        onTypingStarted: (typingUser) => {
          if (typingUser.id !== user.user.id) {
            setTyping(typingUser);
          }
        },
        onTypingStopped: (typingUser) => {
          if (typingUser.id !== user.user.id) {
            setTyping(null);
          }
        },
      });

      if (chat.failed) {
        console.error("Failed to start chat session:", chat.error);
        setLoading(false);
      }
      setLoading(false);
    } else {
      console.error("Failed to create channel:", result.error);
    }
  };

  const notifyUser = (message) => {
    setStyle('initial');
    notification.open({
      message: getCurrentDate(),
      description: `New message from Nyra, ${message.body}`,
      duration: 2,
      onClick: () => {},
    });
  };

  useEffect(() => {
    chatkitty.endSession();
    initSession();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);


  useEffect(() => {
    return () => {
      if (channel) {
        // channel.end();
      }
      chatkitty.endSession();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const sendMessage = async () => {
    if (!messageInput.trim()) return;

    const userMessage = {
      sender: username,
      message: messageInput.trim(),
      created_at: new Date().toISOString(),
      business_id: selectedBusiness.business_id,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsLoadingAIResponse(false);
    setMessageInput("");

    sendTaxQueryMessage.mutate({
      business_id: selectedBusiness.business_id,
      vendor_id: vendorId,
      messageData: {
        message: messageInput.trim(),
        sender: username,
      },
    });

    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const renderStyledText = (text) => {
    text = text.replace(/<b>(.*?)<\/b>/g, "<strong>$1</strong>");
    text = text.replace(/<i>(.*?)<\/i>/g, "<em>$1</em>");
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  function getCurrentDate() {
    const currentDate = new Date();
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return currentDate.toLocaleDateString('en-US', options);
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
  };

  return (
    <div className={classNames.wrapper}>
      {(loading) ? (
        <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} />
      ) : (
        <>
          <div ref={messagesEndRef} className={classNames.messagesContainer}>
            {messages.length === 0 ? (
              <div style={{ 
                textAlign: 'center',
                padding: '20px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                opacity: '0.5',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center' 
              }}>
                <span>Your messages will appear here</span>
                <InboxOutlined style={{ marginLeft: '8px', fontSize: '24px', color: '#82898F' }} />
              </div>
            ) : (
              messages.map((message, index) => (
                message.message !== "" && <div key={index} className={message.sender === username ? classNames.message : classNames.receiver}>
                  <div className={message.sender === username ? classNames.username : classNames.aiUsername}>
                    <div className={classNames.messageAuthor}>{message.sender}</div>
                  </div>
                  <div className={message.sender === username ? classNames.messageContentSender : classNames.messageContent}>
                    <div className={classNames.messageAvatar}>
                      <Avatar icon={<UserOutlined />} />
                    </div>
                    <div className={classNames.messageBody}>
                      {renderStyledText(message.message)}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          
          {typing && (
            <div className={classNames.typing}>
              {typing.displayName} is typing...
            </div>
          )}

          <div className={classNames.inputContainer}>
            <TextArea
              placeholder="Ask a query"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              autoSize={{ minRows: 3 }}
              onKeyDown={handleKeyPress}
            />
            <SendOutlined className={classNames.submitBtn} onClick={sendMessage} />
          </div>
        </>
      )}
    </div>
  );
}
