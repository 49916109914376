import { useMutation, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { Form, Switch } from "antd";
import {
  Modal,
  CTAButton,
  Input,
  Select,
  Message,
  CountryPicker,
} from "../../../../common";
// import { AppContext } from "../../../../../context/AppState";
import api from "../../../../API";
import generateErrorMessage from "../../../../util/functions/customError";
// import { isBusinessType } from "../../../../util/functions/onboarding";
import { validation } from "../../../../Auth/config";
import { validationPayee } from "../../../config";
import { MAX_MIN } from "../../../../util/constants";
import REGEX from "../../../../util/constants/regex";
import { usStateList } from "../../../../util";
import classNames from "../Payees.module.scss";
import Bugsnag from "@bugsnag/browser";

export default function EditPayee({ visible, onCancel, achModal, payee, refetch, isQBPayee, payeeSet, payStart, reUpload, invoiceID, payeeName, email }) {
  //Hooks
  const [form] = Form.useForm();
  const [type, setType] = useState("");
  const [wireChecked, setWireChecked] = useState([false]);
  // const wireChecked = false;
  const [countrySelected, setCountrySelected] = useState(null);
 
  // State to manage multiple accounts
  const [, setAccounts] = useState([{}]);

  // const { application } = useContext(AppContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    setType(null);
  }, [visible]);

  useEffect(() => {
    if (isQBPayee) {
      setType("business"); // Set Payee Type to "business" if isQBPayee is true
      form.setFieldsValue({
        customerType: "business",
        name: payeeName, // Set the Payee Name (Business Name) to the value of payeeName
        email: email, // Set the Email Address to the value of email
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isQBPayee, payeeName]);

  const { mutate, isLoading } = useMutation(api.BusinessBanking.patchPayee, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries("getPayees");
      form.resetFields();
      onCancel();
      refetch();
    },
    onError: (error) => {
      if (error.response.data.message === "duplicate account") {
        Message({ type: "error", content: "Payee is already exist" });
      } else {
        generateErrorMessage(error);
      }
      try {
        mixpanel.track("Add Payee Failure", {
          "Payee Type": form.getFieldValue("customerType"),
          "Payee Name": form.getFieldValue("customerType") === "business" ? form.getFieldValue("name") : form.getFieldValue("first") + " " + form.getFieldValue("last"),
          "Bank Name": form.getFieldValue("bank"),
          "Error": error?.response?.data?.message,
        });
      } catch (error) {
        Bugsnag.notify("Mixpanel error: ", error);
      }
      form.resetFields();
      onCancel();
    },
  });

  //Event handlers
  function handleSubmit(input) {
    input.customerType = input?.customerType ? input?.customerType : "personal";
  
    payee.map((account) => {
      return new Promise((resolve, reject) => {
        mutate({
          updatedPayeeData: {
            email: input?.email,
            // Include other data fields as necessary
          },
          payee_id: account.otz_payee_id,
        }, {
          onSuccess: () => {
            Message({ type: "success", content: "Payee updated successfully" });
            resolve();
          },
          onError: (error) => {
            Message({ type: "error", content: "Payee updated failed" });
            reject(error);
          },
        });
      });
    });
  }  

  const resetFormFieldsOnCancel = () => {
    onCancel();
    setWireChecked([false])
    setAccounts([{}])
    form.resetFields();
  };

  const [expandedAccount, setExpandedAccount] = useState(null);
  const [payeeData, setPayeeData] = useState(payee || [])

  useEffect(() => {
    setPayeeData(payee || [])
    if (payee && payee.length > 0) {
      // Set common fields
      form.setFieldsValue({
        email: payee[0].email, // Assuming all accounts have the same email
        // Set other common fields here
      });

      // Set account-specific fields
      const updatedAccounts = payee.map(account => ({
        accountNumber: account.account_number_masked,
        bank: account.bank_name,
        accountType: account.account_type,
        routingNumber: account.routing_no,
        wireroutingNumber: account.wire_routing_number,
        // Add other account-specific fields here
      }));

      setAccounts(updatedAccounts);

      payee.forEach((account, index) => {
        form.setFieldsValue({
          [`accountNumber_${index}`]: account.account_number_masked,
          [`bank_${index}`]: account.bank_name,
          [`accountType_${index}`]: account.account_type,
          [`routingNumber_${index}`]: account.routing_no,
          [`wireroutingNumber_${index}`]: account.wire_routing_number,
        });
      });

      // Set wireChecked based on whether wire routing number is present
      const wireChecks = updatedAccounts.map(account => !!account.wireroutingNumber);
      setWireChecked(wireChecks);
    }
  }, [payee, form]);

  const toggleAccountDetails = (accountId) => {
    setExpandedAccount(expandedAccount === accountId ? null : accountId);
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      maskClosable={false}
      onCloseClick={resetFormFieldsOnCancel}
      onCancel={resetFormFieldsOnCancel}
      title={achModal ? "Edit Payee for ACH Payment" : isQBPayee ? "Edit Vendor" : "Edit Payee"}
      className={classNames.modal}
      bodyStyle={{
        height: "auto",
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
      footer={
        <CTAButton
          onClick={() => form.submit()}
          type="primary"
          style={{ height: 44, width: 384 }}
          loading={isLoading}
        >
          {isQBPayee ? "Update Vendor" : "Update Payee"}
        </CTAButton>
      }
      width={450}
    >
      <div className={classNames.modalBody}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={handleSubmit}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name="customerType"
            label={
              <span className={classNames.label}>
                {isQBPayee ? "Vendor Type" : "Payee Type"} <sup style={{ top: 1 }}>*</sup>
              </span>
            } 
            rules={[
              { required: false, message: validationPayee.addPayee.accountType },
            ]}
          >
            <Select
              placeholder={validationPayee.addPayee.dropdown}
              size="small"
              style={{ width: "100%" }}
              onChange={(value) => setType(value)}
              disabled={true}
            >
              <Select.Option value="personal">Personal</Select.Option>
              <Select.Option value="business">Business</Select.Option>
            </Select>
          </Form.Item>

          {type === "business" ? (
            <Form.Item
              name="name"
              rules={[
                { required: false, message: validationPayee.addPayee.name },
                {
                  max: MAX_MIN.businessName.max,
                  message: validationPayee.addPayee.nameMax,
                },
                {
                  pattern: new RegExp(REGEX.payeeName),
                  message: validationPayee.addPayee.validPayeeName,
                },
              ]}
              label={
                <span className={classNames.label}>
                  {isQBPayee ? "Vendor Name" : "Payee Name (Business Name)"} <sup style={{ top: 1 }}>*</sup>
                </span>
              }
            >
              <Input
                placeholder={validationPayee.addPayee.payeePlaceholder}
                size="small"
                disabled={true}
              ></Input>
            </Form.Item>
          ) : (
            <Form.Item>
              <Form.Item
                name="first"
                label={
                  <span className={classNames.label}>
                    First Name <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginBottom: "10px",
                  float: "left",
                }}
                rules={[
                  {
                    required: false,
                    message: validation.firstName.required,
                  },
                  {
                    max: MAX_MIN.name.max,
                    message: validation.firstName.maxLength,
                  },
                  // {
                  //   pattern: new RegExp(REGEX.name),
                  //   message: validation.firstName.valid,
                  // },
                ]}
              >
                <Input placeholder={validation.firstName.placeholder} disabled={true} />
              </Form.Item>

              <Form.Item
                name="last"
                label={
                  <span className={classNames.label}>
                    Last Name <sup style={{ top: 1 }}>*</sup>
                  </span>
                }
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  margin: "0 0 0 4px",
                  float: "right",
                }}
                rules={[
                  { required: false, message: validation.lastName.required },
                  {
                    max: MAX_MIN.name.max,
                    message: validation.lastName.maxLength,
                  },
                  // {
                  //   pattern: new RegExp(REGEX.name),
                  //   message: validation.lastName.valid,
                  // },
                ]}
              >
                <Input placeholder={validation.lastName.placeholder} disabled={true} />
              </Form.Item>
            </Form.Item>
          )}
          <Form.Item
            name="email"
            type="email"
            rules={[
              {
                required: false,
                message: validationPayee.addPayee.email,
              }
            ]}
            label={
              <span className={classNames.label}>
                {isQBPayee ? "Vendor Email Address" : "Email Address"} <sup style={{ top: 1 }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder={validationPayee.addPayee.payeePlaceholder}
              size="small"
            ></Input>
          </Form.Item>

          
          <Form.Item style={{margin: "20px 0"}}>
            <span className={classNames.label} style={{margin: "10px 0", fontSize: "0.75rem"}}>Account</span>
            {payeeData.map((account, index) => (
              <div key={account.otz_payee_id} className="account-block" style={{ border: '1px solid #ccc', borderRadius: "6px", padding: '10px 20px', margin: '10px 0' }}>
                <div onClick={() => toggleAccountDetails(account.otz_payee_id)} style={{display: "flex", justifyContent: "space-between"}}>
                  <span>{account.bank_name}</span>
                  <span>xxxx xxxx xxxx {account.account_number_masked}</span>
                </div>
                {expandedAccount === account.otz_payee_id && (
                <div key={index} className="account-block">
                  <Form.Item
                  name={`accountNumber_${index}`}
                  rules={[
                    {
                      required: false,
                      message: validationPayee.addPayee.accountNumber,
                    },
                    {
                      max: MAX_MIN.accountNumber.max,
                      message: validationPayee.addPayee.accountMax,
                    },
                    // {
                    //   pattern: new RegExp(REGEX.accountNumber),
                    //   message: validationPayee.addPayee.accountNumberValid,
                    // },
                  ]}
                  type="number"
                  label={
                    <span className={classNames.label}>
                      Account Number <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <Input
                    placeholder={validationPayee.addPayee.payeePlaceholder}
                    size="small"
                    disabled={true}
                    value={account.account_number_masked}
                  ></Input>
                  </Form.Item>

                  <Form.Item
                  name={`bank_${index}`}

                  rules={[
                    { required: false, message: validationPayee.addPayee.bank },
                    {
                      max: MAX_MIN.bank.max,
                      message: validationPayee.addPayee.bankMax,
                    },
                    // {
                    //   pattern: new RegExp(REGEX.bankName),
                    //   message: validationPayee.addPayee.validBankName,
                    // },
                  ]}
                  type="text"
                  label={
                    <span className={classNames.label}>
                      Bank Name <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <Input
                    placeholder={validationPayee.addPayee.bankNamePlaceholder}
                    size="small"
                    disabled={true}
                    value={account.bank_name}
                  ></Input>
                  </Form.Item>

                  <Form.Item
                  name={`accountType_${index}`}

                  label={
                    <span className={classNames.label}>
                      Account Type <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                  rules={[
                    { required: false, message: validationPayee.addPayee.accountType },
                  ]}
                >
                  <Select
                    placeholder={validationPayee.addPayee.dropdown}
                    size="small"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={account.account_type}
                  >
                    <Select.Option value="SAVINGS">Savings</Select.Option>
                    <Select.Option value="CHECKING">Checking</Select.Option>
                  </Select>
                  </Form.Item>

                  <Form.Item
                  rules={[
                    {
                      required: false,
                      message: validationPayee.addPayee.routingNumber,
                    },
                    // {
                    //   pattern: new RegExp(REGEX.einNumber),
                    //   message: validationPayee.addPayee.routingNumberValid,
                    // },
                  ]}
                  name={`routingNumber_${index}`}

                  label={
                    <span className={classNames.label}>
                      Routing Number <sup style={{ top: 1 }}>*</sup>
                    </span>
                  }
                >
                  <Input
                    placeholder={validationPayee.addPayee.payeePlaceholder}
                    size="small"
                    disabled={true}
                    value={account.routing_no}
                  ></Input>
                  </Form.Item>

                  {!isQBPayee && <Form.Item
                  style={{margin: "20px 0"}}
                  name={`enableWire_${index}`}

                >
                  <span className={classNames.label} style={{fontSize: "0.75rem"}}>Enable Wire Payments</span>
                  <Switch
                  style={{float: "right"}}
                    checked={wireChecked[index]}
                    onChange={(checked) => {
                      const newWireChecked = [...wireChecked];
                      newWireChecked[index] = checked;
                      setWireChecked(newWireChecked);
                    }}
                    disabled={true}
                  />
                  </Form.Item>}
                  {wireChecked[index] && (
                  <>
                    <Form.Item
                      rules={[
                        {
                          required: false,
                          message: validationPayee.addPayee.routingNumber,
                        },
                        // {
                        //   pattern: new RegExp(REGEX.einNumber),
                        //   message: validationPayee.addPayee.routingNumberValid,
                        // },
                      ]}
                      name={`wireroutingNumber_${index}`}

                      label={
                        <span className={classNames.label}>
                          Wire Routing Number <sup style={{ top: 1 }}>*</sup>
                        </span>
                      }
                    >
                      <Input
                        placeholder={validationPayee.addPayee.payeePlaceholder}
                        size="small"
                        disabled={true}
                        value={account.wire_routing_number}
                      ></Input>
                    </Form.Item>
                    <Form.Item
                      label={
                        <span className={classNames.label}>
                          Address <sup style={{ top: 1 }}>*</sup>
                        </span>
                      }
                    >
                      <Form.Item
                        name={`payeeCountry_${index}`}

                        rules={[
                          { required: false, message: validation.country.required },
                        ]}
                      >
                        <CountryPicker
                          size="small"
                          placeholder={validation.country.placeholder}
                          onChange={(code) => {
                            setCountrySelected(code);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: false,
                            message: validation.street.required,
                          },
                          { max: MAX_MIN.street.max, message: validation.street.max },
                        ]}
                        name={`payeeStreet_${index}`}

                      >
                        <Input
                          placeholder={validation.street.placeholder}
                          size="small"
                        />
                      </Form.Item>
                      <Form.Item
                        name={`payeeStreet2_${index}`}

                        rules={[
                          {
                            max: MAX_MIN.street.max,
                            message: validation.street2.max,
                          },
                        ]}
                      >
                        <Input
                          placeholder={validation.street2.placeholder}
                          size="small"
                        />
                      </Form.Item>
                      <Form.Item
                        name={`payeeCity_${index}`}

                        rules={[
                          { required: false, message: validation.city.required },
                          { max: MAX_MIN.city.max, message: validation.city.max },
                        ]}
                      >
                        <Input
                          placeholder={validation.city.placeholder}
                          size="small"
                        />
                      </Form.Item>
                      <Form.Item
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        name={`payeeState_${index}`}

                        rules={[
                          { required: false, message: validation.state.required },
                        ]}
                      >
                        {countrySelected === "US" ? (
                          <Select
                            size="small"
                            showSearch
                            filterOption={(input, option) =>
                              option?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={validation.state.placeholder}
                          >
                            {usStateList().map((value) => (
                              <Select.Option key={value.code} value={value.code}>
                                {value.name}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            size="small"
                            placeholder={validation.state.placeholder}
                            readOnly={true}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        rules={[
                          { required: false, message: validation.postalCode.required },
                          // {
                          //   pattern: new RegExp(REGEX.postalCode),
                          //   message: validation.postalCode.valid,
                          // },
                        ]}
                        name={`payeePostalCode_${index}`}
                      >
                        <Input
                          placeholder={validation.postalCode.placeholder}
                          size="small"
                        />
                      </Form.Item>
                    </Form.Item>
                  </>
                  )}
                </div>
                )}
              </div>
            ))}
            </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
