import endpoints from "../../../endpoints";

export default async function getBookkeepingQueriesAndCount(business_id){
    let env = process.env.REACT_APP_ENV?.trim();
    
    const url = endpoints.SERVICES.DASHBOARD.QUERIES.BOOKKEEPING.GET_TRANSACTION_AND_COUNT[env]
    const secureLocalStore = window.localStorage || window.sessionStorage;
    
    const accessToken = secureLocalStore.getItem('accessToken');

    return await(
        await fetch(`${url}${business_id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'  // This is important to specify the content type
            }
        })
    ).json();
}
