import React, { useState, useRef, useEffect } from 'react';
import styles from "./Categorization.module.scss";
import { DownOutlined } from '@ant-design/icons';

// Helper function to filter the options based on the search input
const filterOptions = (accounts, searchTerm) => {
  if (!searchTerm) return accounts;

  return accounts
    .map((account) => {
      // Recursively filter child accounts
      const filteredChildren = filterOptions(account.child, searchTerm);
      const isMatch = account.name.toLowerCase().includes(searchTerm.toLowerCase());

      // Show the account if it matches or if any child matches
      if (isMatch || filteredChildren.length > 0) {
        return { ...account, child: filteredChildren };
      }
      return null;
    })
    .filter(Boolean); // Remove null entries
};

const renderOptions = (accounts, level = 0, handleSelect) => {
  return accounts.map((account) => (
    <React.Fragment key={account.id}>
      <div
        className={`${styles.option} ${styles[`level${level}`]}`}
        onClick={() => handleSelect(account.name)}
      >
        {account.name}
      </div>
      {account.child.length > 0 && (
        <div className={styles.subOptions}>
          {renderOptions(account.child, level + 1, handleSelect)}
        </div>
      )}
    </React.Fragment>
  ));
};

const CustomDropdown = ({ value, options, onChange }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // Track the search term
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = (id) => {
        onChange(id);
        setDropdownOpen(false);
    };

    const filteredOptions = filterOptions(options, searchTerm); // Filter options based on search term

    return (
        <div className={styles.customDropdownContainer} ref={dropdownRef}>
          <div className={styles.selectedValue} onClick={() => setDropdownOpen(!dropdownOpen)}>
            {value || 'Select an account'}
            <span style={{ color: '#d2d2d2' }}><DownOutlined /></span>
          </div>

          {dropdownOpen && (
            <div className={styles.optionsContainer}>
              {/* Search input field */}
              <div className={styles.searchContainer}>
                <input
                  style={{width: '100%', border: 'none'}}
                  type="text"
                  className={styles.searchInput}
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              {/* Render filtered options */}
              {filteredOptions.length > 0 ? (
                renderOptions(filteredOptions, 0, handleSelect)
              ) : (
                <div className={styles.noResults}>No results found</div>
              )}
            </div>
          )}
        </div>
    );
};

export default CustomDropdown;
