import endpoints from "../../../endpoints";

// function to send accountant message
export async function sendMessageAccountant(data) {
    let env = process.env.REACT_APP_ENV?.trim();
    const url = endpoints.SERVICES.DASHBOARD.CHATKITTY.SEND_MESSAGES_ACCOUNTANT[env] + data?.business_id;
    
    const secureLocalStore = window.localStorage || window.sessionStorage;
    const accessToken = secureLocalStore.getItem('accessToken');
    
    return await (
        await fetch(`${url}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data.messageData)
        })
    ).json();
}
