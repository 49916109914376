import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Spin } from "antd";
import moment from "moment";
import { CTAButton, Message, Modal } from "../../common";
import useLocalStorage from "../../../hooks/useLocalStorage";
import api from "../../API";
import generateErrorMessage from "../../util/functions/customError";
import { DISCLOSURE_TYPE, FORMATS } from "../../constants";
import className from "./VerifyAccount.module.scss";
import Bugsnag from "@bugsnag/browser";
import mixpanel from "mixpanel-browser";

export default function AccountStatus({
  visible,
  onCancel,
  onRetry,
  userData,
  inputData,
  onSuccess,
  isPerson,
}) {
  //Hooks
  const [isActive, setIsActive] = useState(isPerson ? [] : [1]);
  const [title, setTitle] = useState();
  const [, setProgress] = useLocalStorage("OTTERZ_op_bd");
  const [personProgress, setPersonProgress] = useLocalStorage(
    "OTTERZ_op_individualApplication"
  );

  const { data: application } = useQuery(
    "getApplicationByTenantId",
    () => api.onBoarding.getApplicationByTenantId(userData?.tenantId),
    {
      refetchOnWindowFocus: false,
    }
  );

  //Api calls
  const createIndiApplication = useMutation((data) => {
    return api.onBoarding.createApplication(data);
  });

  const updateIndiApplication = useMutation((data) => {
    const reqBody = {
      id: personProgress?.id || application?.payload?.otz_person_id,
      data,
    };

    return api.onBoarding.updateApplication(reqBody, false);
  });

  const createDisclosure = useMutation((data) => {
    return api.onBoarding.createDisclosure(data);
  });

  const createKYCKYB = useMutation((data) => {
    return api.onBoarding.createKYCKYB(data);
  });

  const callWebhook = useMutation((data) => {
    return api.onBoarding.callWebhook(data);
  });

  // const sendEmail = useMutation((data) =>
  //   api.Receivables.PaymentAcceptOnboard.sendInternalMail(data, true)
  // );

  useEffect(() => {
    if (isPerson) {
      setIsActive([]);
    } else {
      setIsActive([1]);
    }
    setTitle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  //Event handlers
  useEffect(() => {
    if (visible) {
      if (isPerson) {
        individualUser();
      } else {
        businessOwner();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const individualUser = () => {
    let reqData = {
      tenant_id: userData?.tenantId,
      ssn: getSSN(inputData?.ssnInput),
      dob: moment(inputData?.dateOfBirth).format(FORMATS.API_SEND_FORMAT),
      email: userData?.email,
      first_name: inputData?.first,
      last_name: inputData?.last,
      state: inputData?.state,
      address_line_1: inputData?.street,
      address_line_2: inputData?.street2 ? inputData?.street2 : "",
      city: inputData?.city,
      country_code: inputData?.country,
      postal_code: inputData?.postalCode,
      phone_number: userData?.phone_number,
      nationality: inputData?.nationality,
      is_customer: true,
    };

    if (!personProgress?.id && !application?.payload?.otz_person_id) {
      createIndiApplication.mutate(reqData, {
        onSuccess: (data) => {
          setIsActive([1]);
          Message({ type: "success", content: data?.message });
          setPersonProgress((prevVal) => {
            return { ...prevVal, id: data?.payload?.otz_person_id };
          });

          const reqPayload = {
            disclosure_date: new Date().toJSON(),
            otz_person_id: data?.payload?.otz_person_id,
            type: [
              DISCLOSURE_TYPE.PRIVACY_NOTICE,
              DISCLOSURE_TYPE.TERMS_AND_CONDITIONS,
              DISCLOSURE_TYPE.KYC_DATA_COLLECTION,
              DISCLOSURE_TYPE.REG_DD,
            ],
          };

          createDisclosure.mutate(reqPayload, {
            onSuccess: (disclosureData) => {
              setIsActive([1, 2]);

              const payload = {
                otz_person_id: data?.payload?.otz_person_id,
                customer_consent: true,
              };
              // if(response.status === 200) {
              //   mixpanel.track('Disclosure addition - success', {
              //     'Email': data.email,
              //     'Sign Up Date': new Date().toISOString(),
              //     'Company_ID': '',
              //     'Disclosure acceptance status': 'Accepted',
              //   });
              // } else {
              //   mixpanel.track('Disclosure addition - failure', {
              //     'Email': data.email,
              //     'Sign Up Date': new Date().toISOString(),
              //     'Company_ID': '',
              //     'Disclosure acceptance status': 'Failed',
              //   });
              // }
              callWebHookApi(payload);
            },
            onError: () => {
              setTitle("Something went wrong, please try again");
            },
          });
        },
        onError: (error) => {
          generateErrorMessage(error);
          setTitle("Something went wrong, please try again");
        },
      });
    } else {
      updateIndiApplication.mutate(reqData, {
        onSuccess: (data) => {
          setIsActive([1]);
          Message({ type: "success", content: data?.message });

          const reqPayload = {
            disclosure_date: new Date().toJSON(),
            otz_person_id:
              personProgress?.id || application?.payload?.otz_person_id,
            type: [
              DISCLOSURE_TYPE.PRIVACY_NOTICE,
              DISCLOSURE_TYPE.TERMS_AND_CONDITIONS,
              DISCLOSURE_TYPE.KYC_DATA_COLLECTION,
              DISCLOSURE_TYPE.REG_DD,
            ],
          };

          createDisclosure.mutate(reqPayload, {
            onSuccess: () => {
              setIsActive([1, 2]);

              const payload = {
                otz_person_id: data?.payload?.otz_person_id,
                customer_consent: true,
              };

              callWebHookApi(payload);
            },
            onError: () => {
              setTitle("Something went wrong, please try again");
            },
          });
        },
        onError: (error) => {
          generateErrorMessage(error);
          setTitle("Something went wrong, please try again");
        },
      });
    }
  };

  const businessOwner = () => {
    const reqPayload = {
      disclosure_date: new Date().toJSON(),
      otz_business_id: application?.payload?.otz_business_id,
      type: [
        DISCLOSURE_TYPE.PRIVACY_NOTICE,
        DISCLOSURE_TYPE.TERMS_AND_CONDITIONS,
        DISCLOSURE_TYPE.KYC_DATA_COLLECTION,
        DISCLOSURE_TYPE.REG_DD,
      ],
    };

    createDisclosure.mutate(reqPayload, {
      onSuccess: (disclosureData) => {
        setIsActive([1, 2]);
        setProgress((prevVal) => {
          return {
            ...prevVal,
            diclosure: true,
          };
        });
        const payload = {
          otz_business_id: application?.payload?.otz_business_id,
          customer_consent: true,
        };

        // const reqBody = {
        //   templateName: "ACCOUNT_APPLICATION_SUBMITTED",
        //   tenantId: userData?.tenantId,
        // };

        // sendEmail.mutate(reqBody);

        callWebHookApi(payload);
      },
      onError: () => {
        setTitle("Something went wrong, please try again");
      },
    });
  };

  // const callAccountApi = (personId) => {
  //   const accountPayload = {
  //     tenant_id: userData?.tenantId,

  //     ...(!isPerson
  //       ? {
  //           otz_business_id: progress?.id,
  //           relationship_type: "PRIMARY_ACCOUNT_HOLDER",
  //         }
  //       : {
  //           otz_person_id: personId,
  //           relationship_type: "PRIMARY_ACCOUNT_HOLDER",
  //         }),
  //   };

  //   createAccount.mutate(accountPayload, {
  //     onSuccess: () => {
  //       onSuccess();
  //     },
  //     onError: () => {
  //       setTitle("Something went wrong, please try again");
  //     },
  //   });
  // };

  // Define the sendSocureMail function
  async function sendSocureMail(data) {
    try {
      await api.onBoarding.sendSocureMail(data);
      mixpanel.identify(userData?.email);
      mixpanel.track("Socure Verification Links Sent", {
        "User Email ID": userData?.email,
      });
      mixpanel.track("Sign up - completed", {
        "Sign Up Date": new Date().toISOString(),
        "App permission status": userData?.approved,
        "Account type": userData?.planType,
      });
    } catch (error) {
      Bugsnag.notify("Failed to send emails for socure. Please try again.", error);
    }
  }


  const createKYCKYBApi = async (payload, id = "") => {
    // createKYCKYB.mutate(payload, {
    //   onSuccess: () => {
    //     setIsActive([1, 2, 3]);
    //     setProgress((prevVal) => {
    //       return {
    //         ...prevVal,
    //         kyb: true,
    //       };
    //     });
    //     // mixpanel.identify(payload.email);
    //     onSuccess();
    //   },
    //   onError: () => {
    //     setTitle("Something went wrong, please try again");
    //   },
    // });

    try {
      const businessRelationsResponse = await api.onBoarding.getBusinessRelations();
    
      const failedKYCPersons = businessRelationsResponse.payload.relationShips.filter(
        (relation) => relation.person_info.verification_status !== "ACCEPTED"
      );
    
      if (failedKYCPersons.length > 0) {
        const otzPersonIdsSet = new Set(); // Create a Set to store unique IDs
        
        failedKYCPersons.forEach((relation) => {
          otzPersonIdsSet.add(relation.person_info.otz_person_id); // Add IDs to the Set
        });
        
        const otzPersonIds = Array.from(otzPersonIdsSet); // Convert Set back to an Array
        
        // Send the mail
        await sendSocureMail({ otz_person_ids: otzPersonIds });
        setIsActive([1, 2, 3]);
        setProgress((prevVal) => {
          return {
            ...prevVal,
            kyb: true,
          };
        });
        // mixpanel.identify(payload.email);
        onSuccess();
      }
    } catch (error) {
      console.error("Something went wrong, please try again", error);
    }    
  };

  const callWebHookApi = (payload) => {
    const id = isPerson ? payload?.otz_person_id : payload?.otz_business_id;

    // const webHookPayload = {
    //   tenant_id: userData?.tenantId,
    //   customer_type: isPerson ? "PERSONAL" : "BUSINESS",
    // };

    createKYCKYBApi(payload, id);

    // callWebhook.mutate(webHookPayload, {
    //   onSuccess: () => {
    //     setIsActive([1, 2, 3]);
    //     createKYCKYBApi(payload, id);
    //   },
    //   onError: () => {
    //     // createKYCKYBApi(payload, id);
    //     setTitle("Something went wrong, please try again");
    //   },
    // });
  };

  //Helper
  const getSSN = (ssnNumber) => {
    return (
      ssnNumber?.slice(0, 3) +
      "-" +
      ssnNumber?.slice(3, 5) +
      "-" +
      ssnNumber?.slice(5)
    );
  };

  const showLoader = () => {
    return (
      createIndiApplication.isLoading ||
      createDisclosure.isLoading ||
      createKYCKYB.isLoading ||
      callWebhook.isLoading
    );
  };
  return (
    <Modal
      closable={false}
      visible={visible}
      maskClosable={false}
      onCloseClick={onCancel}
      onCancel={onCancel}
      title="Account Status"
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
      }}
      width={434}
    >
      <div>
        <span>{title || "We are processing your request wait..."}</span>
        <div className={className.statusContainer}>
          {showLoader() ? <Spin /> : null}
        </div>
        <div className={className.statusContainer}>
          <div
            className={className.status}
            style={{
              backgroundColor: isActive.includes(1) ? "#00ff00" : "#fff",
              borderColor: isActive.includes(1) ? "#00ff00" : "#000",
            }}
          ></div>
          <div
            className={className.status}
            style={{
              backgroundColor: isActive.includes(2) ? "#00ff00" : "#fff",
              borderColor: isActive.includes(2) ? "#00ff00" : "#000",
            }}
          ></div>
          <div
            className={className.status}
            style={{
              backgroundColor: isActive.includes(3) ? "#00ff00" : "#fff",
              borderColor: isActive.includes(3) ? "#00ff00" : "#000",
            }}
          ></div>
          {/* <div
            className={className.status}
            style={{
              backgroundColor: isActive.includes(4) ? "#00ff00" : "#fff",
              borderColor: isActive.includes(4) ? "#00ff00" : "#000",
            }}
          ></div> */}
        </div>
        {title ? (
          <CTAButton type="primary" onClick={onRetry}>
            Retry
          </CTAButton>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
}
